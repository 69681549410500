import React, { useEffect } from 'react';
// components
import GotoChromeBackdrop from './GotoChromeBackdrop';
// Style
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import AtarimChrome from '../media/svgs/atarim-chrome-icon.svg';
import AtarimChromeImage from '../media/images/chrome-based-collab.png';
// Utils
import {
  isChromeBrowser,
  isChromeExtensionInstalled,
} from '../utils/chromeExtension';

const makeClasses = makeStyles((theme) => ({
  copyWapper: {
    width: '70%',
  },
  imgWrapper: {
    width: '30%',
  },
  widgetImg: {
    borderRadius: 8,
  },
}));

const GotoChromeWideWidget = (props) => {
  const {} = props;
  const classes = makeClasses();
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [extState, setExtState] = React.useState(false);

  const extInstalled = isChromeExtensionInstalled();
  const chromeBrowser = isChromeBrowser();
  useEffect(() => {
    if (!extInstalled && chromeBrowser) {
      setExtState(true);
    }
  }, [extInstalled, chromeBrowser]);

  let widthVW = 50; // 50% of the viewport width
  let heightVH = 50; // 50% of the viewport height
  let widthInPixels = window.innerWidth * (widthVW / 100);
  let heightInPixels = window.innerHeight * (heightVH / 100);

  // Calculate left and top to center the popup
  let left = (window.innerWidth - widthInPixels) / 2;
  let top = 25;

  const handleOpenDownloadLink = () => {
    setOpenBackdrop(!openBackdrop);
    const features = `width=${widthInPixels},height=${heightInPixels},top=${top},left=${left}`;
    window.open(
      'https://chromewebstore.google.com/detail/atarim-visual-website-col/ekpmbeilnaahlccpgjchjjoonecikehf',
      '_blank',
      features
    );
  };

  return (
    <>
      {extState && (
        <div className="at-cs-chrome-extension-callout">
          <div className="at-cs-chrome-extension-callout-container">
            <div className={classes.copyWapper}>
              <div className="at-cs-chrome-extension-callout-title">
                Atarim Chrome Extension - Instantly Collaborate On Any Website
              </div>
              <div>
                Transform feedback into action instantly with our Chrome
                Extension. Perfect for web teams, streamline your projects
                directly from your browser.
              </div>
              <Button
                className="at-cs-btn btn-outline m-t-15 install-chrome-button"
                onClick={handleOpenDownloadLink}
              >
                <img
                  alt="atarim chrome icon "
                  src={AtarimChrome}
                  className="m-r-5"
                />
                Install Now
              </Button>
            </div>
            <div className={classes.imgWrapper}>
              <img
                className={classes.widgetImg}
                src={AtarimChromeImage}
                alt="img"
              />
            </div>
          </div>
          {openBackdrop && (
            <GotoChromeBackdrop
              open={openBackdrop}
              close={() => setOpenBackdrop(false)}
              closeWidgets={setExtState}
            />
          )}
        </div>
      )}
    </>
  );
};

export default GotoChromeWideWidget;
