import React from 'react';
// Styling
import { withStyles, Tooltip } from '@material-ui/core';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#4a5566 !important',
    color: '#fff !important',
    fontFamily: 'Roboto, sans-serif !important',
    fontSize: '12px !important',
    borderRadius: '5px !important',
    padding: '2px 6px !important',
    border: 'none',
    marginBottom: '20px',
    zIndex: '2147483649 !important',
    position: 'relative !important',
    fontWeight: '400 !important',
    letterSpacing: '0 !important',
    lineHeight: '1.43 !important',
  },
}))(Tooltip);

export default function CustomToolTip(props) {
  const { children, title, placement } = props;

  return (
    <HtmlTooltip
      title={<React.Fragment>{title}</React.Fragment>}
      placement={placement}
    >
      {children}
    </HtmlTooltip>
  );
}
