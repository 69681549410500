import React, { useEffect, useState } from 'react';
// Components
import { FiX } from 'react-icons/fi';
import LogoBasecamp from '../../media/images/logos/logo-basecamp.svg';
import LogoClickUp from '../../media/images/logos/logo-clickup.svg';
import LogoDollie from '../../media/images/logos/logo-dollie.png';
import LogoJira from '../../media/images/logos/logo-jira.svg';
import LogoMainWP from '../../media/images/logos/logo-mainwp.svg';
import LogoMake from '../../media/images/logos/logo-make.svg';
import LogoMonday from '../../media/images/logos/logo-monday.svg';
import LogoPabbly from '../../media/images/logos/logo-pabbly.svg';
import LogoRocket from '../../media/images/logos/logo-rocket.svg';
import LogoTrello from '../../media/images/logos/logo-trello.svg';
import LogoWebhooks from '../../media/images/logos/logo-webhooks.svg';
import LogoZapier from '../../media/images/logos/logo-zapier.svg';
import LogoSlack from '../../media/images/logos/slack.svg';
import LogoTeamwork from '../../media/images/logos/teamwork.svg';
import SelectedIcon from '../../media/svgs/marked-purple.svg?react';
import { BorderLinearProgress } from '../../utils/constants';
// Styling
import { Button, makeStyles } from '@material-ui/core';
// Utils
import clsx from 'clsx';

const makeClasses = makeStyles((theme) => ({
  titleCopy: {
    fontFamily: theme.text.normalText.fontFamily,
    fontWeight: 700,
    fontSize: 17,
    textAlign: 'left',
  },
  boldText: {
    fontFamily: theme.text.normalText.fontFamily,
  },
  h6options: {
    fontFamily: theme.text.normalText.fontFamily,
    color: '#272D3C',
    fontSize: '14px',
    fontWeight: 600,
    display: 'flex',
    marginTop: 20,
    alignItems: 'center',
    '& a': {
      display: 'inline-flex',
    },
    '& span': {
      display: 'inline-flex',
      paddingRight: 10,
    },
    '& svg': {
      fontSize: '20px',
      color: '#272D3C',
    },
    [theme.breakpoints.down(768)]: {
      fontSize: '13px',
    },
  },
  nextBtn: {
    margin: '0',
    // height: 56,
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: 14,
    color: theme.palette.primary.main,
    borderRadius: 5,
    border: 'none',
    backgroundColor: '#3ed696',
    '&:hover': {
      backgroundColor: '#33bf84',
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  selectedCompanyTypeItem: {
    outline: '2px solid #6D5DF3',
  },
  selectedIcon: {
    position: 'absolute',
    top: 5,
    right: 6,
    overflow: 'visible',
  },
  progressContainer: {
    marginBottom: 20,
  },

  progressBack: {
    '&:before': {
      width: '7px',
      height: '7px',
      border: '2px solid #6D5DF3',
      borderWidth: '2px 2px 0 0',
      color: '#6D5DF3',
      display: 'inline-block',
      marginRight: '2px',
      content: '""',
      marginBottom: '1px',
      '-moz-transform': '"rotate(-135deg)"',
      '-webkit-transform': 'rotate(-135deg)',
      '-o-transform': '"rotate(-135deg)"',
      '-ms-transform': '"rotate(-135deg)"',
      transform: 'rotate(-135deg)',
      fontSize: 14,
      fontWeight: 600,
    },
  },
}));

const SelectIntegration = (props) => {
  const classes = makeClasses();
  const {
    handleNext,
    handlePrev,
    integrationData,
    handleUpdateTrial,
    handlePostTrial,
    isContainerVisible,
    toggleContainer,
    activateTrial,
  } = props;

  const [selectedCompanyType, setSelectedCompanyType] = React.useState([]);
  const [input, setInput] = useState('');
  const [integrations, setIntegrations] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);

  const companyType = [
    { name: 'Dollie', src: LogoDollie, alt: 'Dollie Logo' },
    { name: 'zapier', src: LogoZapier, alt: 'Zapier Logo' },
    { name: 'Pabbly', src: LogoPabbly, alt: 'Pabbly Logo' },
    { name: 'Integromat', src: LogoMake, alt: 'Make Logo' },
    { name: 'ClickUp', src: LogoClickUp, alt: 'ClickUp Logo' },
    { name: 'Jira', src: LogoJira, alt: 'Jira Logo' },
    { name: 'Mainwp', src: LogoMainWP, alt: 'Mainwp Logo' },
    { name: 'Rocket', src: LogoRocket, alt: 'Rocket Logo' },
    { name: 'Basecamp', src: LogoBasecamp, alt: 'Basecamp Logo' },
    { name: 'Monday', src: LogoMonday, alt: 'Monday Logo' },
    { name: 'Trello', src: LogoTrello, alt: 'Trello Logo' },
    { name: 'TeamWork', src: LogoTeamwork, alt: 'Teamwork Logo' },
    { name: 'Slack', src: LogoSlack, alt: 'Slack Logo' },
    { name: 'Webhooks', src: LogoWebhooks, alt: 'Webhooks Logo' },
  ];

  useEffect(() => {
    if (integrationData) {
      setSelectedCompanyType(integrationData);
      const getName = companyType.map((each) => each.name);
      const othersValue = integrationData.filter(
        (item) => !getName.includes(item)
      );
      setIntegrations(othersValue);
    }
  }, [integrationData]);

  const handleSelect = (value) => {
    let temp = [...selectedCompanyType, value];
    if (!selectedCompanyType.includes(value)) {
      handleUpdateTrial('integrations', [...selectedCompanyType, value]);
    } else {
      const updatedInt = selectedCompanyType.filter((each) => each !== value);
      handleUpdateTrial('integrations', updatedInt);
    }

    setSelectedCompanyType(temp);
  };

  const handleNextForm = () => {
    handlePostTrial({
      integrations: selectedCompanyType,
    });
    activateTrial({ status: 'active' });
    handleNext();
  };

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    // Check for comma or Enter key
    if (
      (key === ',' || key === 'Enter') &&
      trimmedInput.length &&
      !integrations.includes(trimmedInput)
    ) {
      e.preventDefault();
      setIntegrations((prevState) => [...prevState, trimmedInput]);
      setSelectedCompanyType((prevState) => [...prevState, trimmedInput]);
      handleUpdateTrial('integrations', [...selectedCompanyType, trimmedInput]);
      setInput('');
    }

    if (
      key === 'Backspace' &&
      !input.length &&
      integrations.length &&
      isKeyReleased
    ) {
      const tagsCopy = [...integrations];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setIntegrations(tagsCopy);
      setSelectedCompanyType(tagsCopy);
      handleUpdateTrial('integrations', [...selectedCompanyType, poppedTag]);
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const deleteTag = (index) => {
    setIntegrations((prevState) => prevState.filter((tag, i) => i !== index));
  };

  return (
    <>
      <div className="at-cs-trial-wizard-modal">
        <div className={classes.progressContainer}>
          <BorderLinearProgress variant="determinate" value={82} />
          <div className="at-cs-trial-wizard-progress-label">
            <span className={classes.progressBack} onClick={handlePrev}>
              Back
            </span>
          </div>
        </div>
        <div className={classes.titleCopy}>
          Which of these platforms do you currently use in your workflow?
        </div>
        <div className="at-cs-trial-wizard-select-intergation-container">
          {companyType.map((item, index) => {
            return (
              <div
                className={`at-cs-trial-wizard-select-intergation-company-type-item ${
                  classes.companyTypeItem
                } ${
                  selectedCompanyType.includes(item.name)
                    ? classes.selectedCompanyTypeItem
                    : ''
                }`}
                onClick={() => handleSelect(item.name)}
                key={index}
              >
                <img src={item.src} alt={item.alt} />
                {selectedCompanyType.includes(item.name) && (
                  <SelectedIcon className={classes.selectedIcon} />
                )}
              </div>
            );
          })}
          <button
            onClick={toggleContainer}
            className={`at-cs-trial-wizard-select-intergation-company-type-item button ${
              isContainerVisible ? 'active' : ''
            }`}
          >
            Other
          </button>
        </div>

        {/* Other integrations input field */}
        {isContainerVisible && (
          <div className="at-cs-trial-wizard-select-intergation-other-container">
            {integrations.map((integrationTag, index) => (
              <div className="at-cs-trial-wizard-select-intergation-tag">
                {integrationTag}
                <button
                  className="at-cs-trial-wizard-select-intergation-tag-button"
                  onClick={() => deleteTag(index)}
                  onTouchEnd={() => deleteTag(index)}
                  type="button"
                >
                  <FiX />
                </button>
              </div>
            ))}
            <input
              value={input}
              placeholder="Please Specify"
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              onChange={onChange}
            />
          </div>
        )}
        <div className={classes.btnContainer}>
          <Button
            color="primary"
            variant="contained"
            classes={{ root: clsx(classes.nextBtn, classes.boldText) }}
            onClick={() => handleNextForm()}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

export default SelectIntegration;
