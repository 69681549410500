// Saga Utils
import { call, fork, put, take, takeLatest } from 'redux-saga/effects';
// Actions
import {
  FETCH_SITES_BASED_ON_PROJECTS,
  START_FETCH_DASHBOARD_DATA,
} from '../actions/dashboard';
import {
  CREATE_PROJECT,
  DELETE_PROJECT,
  ERROR_CREATE_PROJECT,
  ERROR_DELETE_PROJECT,
  ERROR_FETCH_PROJECTS,
  ERROR_UPDATE_PROJECT,
  FETCH_PROJECTS,
  SET_WEBSITE_IDS,
  SUCCESS_CREATE_PROJECT,
  SUCCESS_DELETE_PROJECT,
  SUCCESS_FETCH_PROJECTS,
  SUCCESS_UPDATE_PROJECT,
  UPDATE_PROJECT,
} from '../actions/projects';
// APIs
import {
  deleteProject,
  fetchProjects,
  storeProject,
  updateProject,
} from '../api/projects';
// Utils
import { getSelectedWorkspace } from '../utils/functions';

function* createProjectWorker(projectObj) {
  const data = yield call(storeProject, projectObj);
  if (data.status) {
    yield put({
      type: SUCCESS_CREATE_PROJECT,
      message: data.message || 'New Folder created',
      projectId: data.project_id,
    });
    yield put({
      type: START_FETCH_DASHBOARD_DATA,
      selectedWebsite: '',
      url: '/site',
      project_id: data.project_id,
      site_order: 'filter_by_project',
    });
    yield put({
      type: FETCH_PROJECTS,
    });
    yield put({
      type: FETCH_SITES_BASED_ON_PROJECTS,
    });
  } else {
    yield put({
      type: ERROR_CREATE_PROJECT,
      message: data.message || 'Error occured, try again later!',
    });
  }
}

export function* createProjectWatcher() {
  while (true) {
    const { projectObj } = yield take(CREATE_PROJECT);
    yield fork(createProjectWorker, projectObj);
  }
}

export function websiteIdWatcher() {
  takeLatest(SET_WEBSITE_IDS, websiteIdWorker);
}

function* websiteIdWorker(action) {
  yield put({
    type: SET_WEBSITE_IDS,
    websiteId: action.websiteId,
  });
}

function* fetchProjectsWorker() {
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const data = yield call(fetchProjects, selectedWorkspace?.id);

  if (data.status) {
    yield put({
      type: SUCCESS_FETCH_PROJECTS,
      data: data.data,
    });
  } else {
    yield put({
      type: ERROR_FETCH_PROJECTS,
      message: data.message || 'Error occured, try again later!',
    });
  }
}

export function* fetchProjectsWatcher() {
  yield takeLatest(FETCH_PROJECTS, fetchProjectsWorker);
}

function* updateProjectWorker(projectObj) {
  const data = yield call(updateProject, projectObj);

  if (data.status) {
    yield put({
      type: SUCCESS_UPDATE_PROJECT,
      message: data.message || 'Folder updated successfully',
      data: projectObj,
      projectId: projectObj?.id,
    });
    // get the updated selected project based sites
    yield put({
      type: START_FETCH_DASHBOARD_DATA,
      selectedWebsite: '',
      url: '/site',
      project_id: projectObj.id,
      site_order: 'filter_by_project',
    });
    yield put({
      type: FETCH_PROJECTS,
    });
    yield put({
      type: FETCH_SITES_BASED_ON_PROJECTS,
    });
  } else {
    yield put({
      type: ERROR_UPDATE_PROJECT,
      message: data.message || 'Error occured, try again later!',
    });
  }
}

export function* updateProjectWatcher() {
  while (true) {
    const { projectObj } = yield take(UPDATE_PROJECT);
    yield fork(updateProjectWorker, projectObj);
  }
}

function* deleteProjectWorker(projectObj) {
  const data = yield call(deleteProject, projectObj);

  if (data.status) {
    yield put({
      type: SUCCESS_DELETE_PROJECT,
      projectId: projectObj.project_id,
      message: data.message || 'Folder deleted successfully',
    });
  } else {
    yield put({
      type: ERROR_DELETE_PROJECT,
      message: data.result || 'Error occured, try again later!',
    });
  }
}

export function* deleteProjectWatcher() {
  while (true) {
    const { projectObj } = yield take(DELETE_PROJECT);
    yield fork(deleteProjectWorker, projectObj);
  }
}
