import React from 'react';
// Components
import SelectCompanyType from './UpgradeSelectCompany';
import InputPhoneNumber from './UpgradePhoneNumber';
import SelectTeamNumber from './SelectTeamNumber';

const UpgradeUserDetailStep = (props) => {
  const {
    detailSteps,
    handleSelectNext,
    handleSelectPrevDetail,
    data,
    handleUpdateTrial,
    handlePostTrial,
  } = props;

  const companyType = [
    { name: 'Under 20' },
    { name: '21 - 50' },
    { name: '51 - 100' },
    { name: '100+' },
  ];

  return (
    <>
      {detailSteps === 0 && (
        <SelectCompanyType
          companyType={companyType}
          handleSelectNext={handleSelectNext}
          handleSelectPrevDetail={handleSelectPrevDetail}
          companyFocus={data.focus || null}
          handleUpdateTrial={handleUpdateTrial}
          handlePostTrial={handlePostTrial}
        />
      )}
      {detailSteps === 1 && (
        <SelectTeamNumber
          companyType={companyType}
          handleSelectNext={handleSelectNext}
          handleSelectPrevDetail={handleSelectPrevDetail}
          teamSize={data.team_size || null}
          projectSize={data.project_count || null}
          handleUpdateTrial={handleUpdateTrial}
          handlePostTrial={handlePostTrial}
          companyFocus={data.focus || null}
        />
      )}
      {detailSteps === 2 && (
        <InputPhoneNumber
          companyType={companyType}
          handleSelectNext={handleSelectNext}
          handleSelectPrevDetail={handleSelectPrevDetail}
          companyNameData={data.company_name || null}
          phoneNumberData={data.phone_number || null}
          handleUpdateTrial={handleUpdateTrial}
          handlePostTrial={handlePostTrial}
        />
      )}
    </>
  );
};

export default UpgradeUserDetailStep;
