import { createTheme, makeStyles } from '@material-ui/core/styles';

const primaryColor = '#272D3C';

export const theme = createTheme({
  '@global': {
    body: {
      backgroundColor: '#ffffff',
    },
  },
  palette: {
    text: {
      primary: primaryColor,
    },
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: '#A4ABC5',
      contrastText: '#FFFFFF',
    },
    custom: {
      color: '#A4ABC5',
    },
  },

  overrides: {
    MuiAvatar: {
      root: {
        height: 45,
        width: 45,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
      },
    },
    MuiButton: {
      textPrimary: {
        color: '#272D3C',
      },
      containedPrimary: {
        backgroundColor: '#fff',
        color: '#272D3C',
        border: '1px solid #A4ABC5',
        height: 36,
        '&:hover': {
          color: '#272D3C',
          cursor: 'pointer',
          backgroundColor: '#ECF3F9',
        },
      },
      label: {
        textTransform: 'capitalize',
      },
      root: {
        boxShadow: '0px 0px 0px 0px !important',
        borderRadius: 100,
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        '&$checked': {
          color: primaryColor,
        },
      },
    },
  },
  heightHeaderAndItems: 75,
  text: {
    boldText: {
      fontFamily: 'Roboto, Helvetica,  Arial, sans-serif',
    },
    normalText: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
  },
});

export const useCommonStyles = makeStyles((theme) => {
  return {
    checkboxesTasksAttributes: {
      padding: 0,
      height: 19,
      '& svg': {
        fill: primaryColor,
        width: '0.7em',
      },
    },
    checkBoxItemsTaskFilter: {
      display: 'flex',
      alignItems: 'center',
      height: 20,
    },
    checkBoxLabelTaskFilter: {
      color: theme.palette.primary.main,
      fontSize: '14px !important',
      fontWeight: 400,
    },
    checkBoxLabelRootOverrideTaskFilter: {
      marginLeft: '0px !important',
      '&>.MuiButtonBase-root': {
        padding: '0px',
        marginRight: '5px',
      },
    },
    checkBoxLabelWPUserTaskFilter: {
      color: '#AAAAAA',
    },
    tasksFilterUsersList: {
      margin: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      backgroundClip: 'initial',
      border: 0,
      minHeight: 0,
      padding: 'initial',
      boxShadow: 'none',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      backgroundColor: '#d5e2f3',
    },
  };
});
