import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Components
import WorkspaceInviteForm from './WorkspaceInvite';
// Styling
import {
  Dialog,
  DialogTitle,
  FormControl,
  Select,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { MdClose } from 'react-icons/md';
import { RiCloseFill } from 'react-icons/ri';
// Utils
import { Avatar } from '@ui/Avatar';
import { NotificationManager } from 'react-notifications';
import { InviteRoleLabelFrontend } from '../../utils/constants';
// Actions
import {
  CREATE_WORKSPACE,
  EDIT_WORKSPACE,
  EMPTY_WORKSPACE_MESSAGE,
  FETCH_ACCOUNT_USERS,
  FETCH_WORKSPACE_OWNER,
} from '../../actions/workspace';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 720,
    maxHeight: '90vh',
    maxWidth: 'unset',
    borderRadius: 10,
    boxShadow: '#cedce7 0px 0px 20px 0px',
    [theme.breakpoints.down('xs')]: {
      height: '100vh',
    },
  },
  boldHeaderText: {
    fontFamily: theme.text.boldText.fontFamily,
    fontWeight: 700,
    fontSize: 24,
    textAlign: 'left',
    padding: '40px 40px 0 40px',
  },
  dialogDescCopy: {
    padding: '0 40px 20px 40px',
    '& .MuiDialogContentText-root': {
      fontSize: 14,
      color: '#A4ABC5',
      fontWeight: 500,
    },
  },
  h6options: {
    fontFamily: theme.text.normalText.fontFamily,
    color: '#272D3C',
    fontSize: 14,
    fontWeight: 500,
    marginTop: 14,
    marginBottom: 6,
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    [theme.breakpoints.down(768)]: {
      fontSize: '13px',
    },
  },
  addUserContainer: {
    gap: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    width: '100%',
    zIndex: 9999,
    borderRadius: 5,
    color: '#8791B2',
    fontWeight: 500,
    '& input': {
      padding: 10,
      height: 36,
      boxSizing: 'border-box',
    },
  },
  uploadFileControl: {
    opacity: 0,
    position: 'absolute',
    left: 0,
    top: 0,
    width: '140px',
    height: '100%',
    zIndex: 10,
    cursor: 'pointer',
  },
  boldText: {
    fontFamily: theme.text.normalText.fontFamily,
  },
  faviconPreview: {
    maxWidth: 180,
    maxHeight: 45,
    marginRight: 0,
    [theme.breakpoints.down(420)]: {
      maxWidth: '50%',
      width: '50%',
    },
  },
  autocomplete: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& .MuiAutocomplete-inputRoot': {
      gap: 4,
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.custom.color,
      fontSize: 14,
      fontWeight: 500,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#8791B2',
    },
    '& .MuiAutocomplete-input': {
      padding: '2.5px 4px !important',
    },
    '& .MuiAutocomplete-tag': {
      padding: '2.5px 4px !important',
      height: 26,
      margin: 0,
      backgroundColor: 'transparent !important',
      borderRadius: 5,
      border: '1px solid rgba(0, 0, 0, 0.23)',
      boxShadow: 'none',
    },
  },
  paper: {
    width: 500,
    maxWidth: '100%',
    overflow: 'hidden',
  },

  option: {
    fontSize: 14,
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: '#ECF3F9',
    },
  },

  popper: {
    zIndex: 2147483647,
  },
  commentSentByAvatar: {
    height: 25,
    width: 25,
  },
  inviteUsersButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    padding: '0 40px 40px 40px',
  },
  uploadFileControlContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    color: '#A4ABC5',
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: 14,
  },
  commentTextarea: {
    width: '100%',
    border: '0.5px solid rgba(0, 0, 0, 0.23)',
    resize: 'vertical',
    boxSizing: 'border-box',
    fontFamily: theme.text.normalText.fontFamily,
    background: '#ffffff',
    borderRadius: '12px',
    padding: '15px',
    color: '#4a5568',
    fontSize: 14,
    height: 100,
    minHeight: 80,
  },
  '.MuiOutlinedInput-root': {
    borderRadius: 8,
  },
  formControl: {
    boxSizing: 'border-box',
    fontFamily: theme.text.normalText.fontFamily,
    marginRight: 10,
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    height: 36,
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #8791B2',
    },
  },
  dropdownStyle: {
    marginTop: 50,
  },
}));

export default function CreateWorkspaceModal(props) {
  const {
    open,
    openCreateWorkplace,
    selectedWorkspace,
    title,
    editWorkSpace,
    setOpenLockFeatureModal,
  } = props;

  const {
    errorMessage,
    dataIsLoading,
    workspaceSuccess,
    accountUsers: usersList,
    ownerList,
  } = useSelector((state) => state.workspace);

  const { userData } = useSelector((state) => state.user);
  const styles = useStyles();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [workspaceOwner, setWorkspaceOwner] = useState('');
  const [workspaceOwnerErrorMsg, setWorkspaceOwnerErrorMsg] = useState('');
  const [workspaceName, setWorkspaceName] = useState('');
  const [preview, setPreview] = useState('');
  const [message, setMessage] = useState('');
  const [description, setDescription] = useState('');
  const [selectedUser, setSelectedUser] = useState([]);
  const [workspaceImage, setWorkspaceImage] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const formData = new FormData();
  const singleFormValues = {
    email: '',
    first_name: '',
    last_name: '',
    role: InviteRoleLabelFrontend[0].value,
  };

  useEffect(() => {
    const defaultUser = ownerList.find(
      (each) => each.normal_id === userData?.normal_id
    );
    setWorkspaceOwner(defaultUser || '');
    setWorkspaceOwnerErrorMsg('');
  }, [ownerList, open]);

  useEffect(() => {
    if (selectedUser) {
      setSelectedUsers(selectedUser);
    }
  }, [selectedUser]);

  useEffect(() => {
    dispatch({
      type: FETCH_WORKSPACE_OWNER,
    });
  }, []);

  const [formValue, setFormValue] = useState([singleFormValues]);

  const handleCheckIsvalid = () => {
    let status = false;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    formValue.map((each) => {
      if (each.email !== '' && !emailRegex.test(each.email)) {
        status = true;
      } else {
        status = false;
      }
    });
    return status;
  };

  const onClose = () => {
    openCreateWorkplace();
  };

  const handleWorkspaceOwnerChange = (e) => {
    let selectedOwner = e.target.value;
    setWorkspaceOwner(selectedOwner);
    if (selectedOwner?.normal_id !== userData?.normal_id) {
      workspaceOwner?.open_workspace_slots === 0 &&
        setWorkspaceOwnerErrorMsg(
          'Selected user has no workspace slots available.'
        );
    } else {
      setWorkspaceOwnerErrorMsg('');
    }
  };

  const handleChangName = (e) => {
    setWorkspaceName(e.target.value);
  };

  const onChangeFileControl = (e) => {
    // here we can create FormData object
    setWorkspaceImage(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
  };

  const updateTextarea = (e) => {
    setMessage(e.target.value);
  };

  const handleChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleChangePeople = (e, value) => {
    setSelectedUser(value);
  };

  const handleCreateWorkspace = () => {
    // check if workspace limit is reached
    if (workspaceOwner?.normal_id === userData?.normal_id) {
      if (!editWorkSpace && userData?.limit?.workspaces === 0) {
        setOpenLockFeatureModal(true);
        return;
      }
    }
    // else if (workspaceOwner && workspaceOwner?.open_workspace_slots === 0) {
    //   alert('please The account owner to upgrade');
    //   return;
    // }

    const myUser = selectedUser.map((each) => {
      return {
        id: each?.normal_id,
        role: each?.role,
      };
    });

    formData.append('owner_id', workspaceOwner?.normal_id);
    formData.append('name', workspaceName);
    formData.append('description', description);
    formData.append('message', message);
    formData.append('icon', workspaceImage);
    formData.append('users', JSON.stringify(myUser));
    formData.append('invite_requests', JSON.stringify(formValue));

    if (editWorkSpace) {
      dispatch({
        type: EDIT_WORKSPACE,
        id: selectedWorkspace?.id,
        formData,
      });
    } else {
      dispatch({
        type: CREATE_WORKSPACE,
        formData,
      });
    }
  };

  useEffect(() => {
    dispatch({ type: FETCH_ACCOUNT_USERS });
  }, []);

  useEffect(() => {
    if (workspaceSuccess) {
      NotificationManager.success(workspaceSuccess, '', 5000);
      dispatch({ type: EMPTY_WORKSPACE_MESSAGE, keyName: 'workspaceSuccess' });
      onClose();
    } else if (errorMessage) {
      NotificationManager.error(errorMessage, '', 5000);
      dispatch({ type: EMPTY_WORKSPACE_MESSAGE, keyName: 'errorMessage' });
    }
  }, [workspaceSuccess, editWorkSpace, errorMessage]);

  useEffect(() => {
    if (selectedWorkspace) {
      if (Object.keys(selectedWorkspace).length > 0) {
        setWorkspaceName(selectedWorkspace?.name || '');
        setPreview(selectedWorkspace?.icon);
        setMessage(selectedWorkspace?.description || '');
        setWorkspaceImage(selectedWorkspace?.icon);
        setSelectedUser(selectedWorkspace?.users);
        setFormValue(selectedWorkspace?.invite_requests || [singleFormValues]);
      }
    }
  }, [selectedWorkspace]);

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        classes={{
          paper: classes.dialog,
        }}
        className="at-cs-modal-popover workspace-modal"
      >
        <button
          className="at-cs-btn no-bg square large-icon red btn-right at-cs-btn-close-dialog"
          onClick={onClose}
        >
          <RiCloseFill />
        </button>
        <DialogTitle className={classes.boldHeaderText}>{title}</DialogTitle>
        <div className="px-10 pb-2 pt-5">
          <div className="flex w-full flex-col">
            <div className="mb-3 mt-6 flex w-full flex-row justify-between">
              <span className="font-sans text-base font-medium leading-none">
                Workspace Owner
              </span>
              <div className="flex flex-row gap-1 text-sm font-medium leading-none text-destructive">
                {workspaceOwnerErrorMsg}
              </div>
            </div>
            {/* <FiHelpCircle
                            onClick={() => window.open(`https://atarim.io/help/integrations/integrating-monday/`)}
                            style={{ cursor: "pointer" }}
                        /> */}
            <div className={classes.textareaContainer}>
              <FormControl
                id="workspace-monday-select"
                variant="outlined"
                size="small"
                className={classes.formControl}
              >
                <Select
                  value={workspaceOwner}
                  onChange={handleWorkspaceOwnerChange}
                  style={{
                    fontSize: 14,
                    color: '#8791B2',
                    fontWeight: 500,
                  }}
                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                >
                  {ownerList.map(
                    (user) => (
                      // AdminRoles.indexOf(user?.role) !== -1 ? (
                      <li
                        key={user.id}
                        value={user}
                        className="flex w-full justify-between px-3 py-1.5 text-sm transition-all duration-100 hover:cursor-pointer hover:bg-tertiary/10"
                      >
                        <span className="flex gap-1 text-secondary">
                          <Avatar
                            size="sm"
                            title={user?.name}
                            id={user?.normal_id}
                          ></Avatar>
                          <span className="flex max-w-[200px] items-center overflow-hidden text-ellipsis whitespace-nowrap font-semibold leading-none">
                            {user?.name}
                          </span>
                          <span className="flex max-w-[200px] items-center overflow-hidden text-ellipsis whitespace-nowrap font-normal leading-none">
                            ({user?.email})
                          </span>
                        </span>
                        <span className="text-secondary">
                          <span className="font-semibold">Team members:</span>{' '}
                          {user?.open_user_slots || 0}
                        </span>
                      </li>
                    )
                    // ) : null
                  )}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="flex w-full flex-col">
            <span className="mb-2 mt-6 font-sans text-base font-medium">
              Name your Workspace
            </span>
            {/* <FiHelpCircle
                                onClick={() => window.open(`https://atarim.io/help/integrations/integrating-monday/`)}
                                style={{ cursor: "pointer" }}
                            /> */}
          </div>
          <TextField
            classes={{ root: classes.textField }}
            variant="outlined"
            placeholder="Workspace Name"
            value={workspaceName}
            name={'name'}
            onChange={(e) => handleChangName(e)}
          />
          <div className="flex w-full flex-col">
            <span className="mb-2 mt-6 font-sans text-base font-medium">
              Workspace description
            </span>
            {/* <FiHelpCircle
                            onClick={() => window.open(`https://atarim.io/help/integrations/integrating-monday/`)}
                            style={{ cursor: "pointer" }}
                        /> */}
            <div className={classes.textareaContainer}>
              <textarea
                className={classes.commentTextarea}
                onChange={handleChangeDescription}
                value={description}
                placeholder="Write a description for this workspace"
              />
            </div>
          </div>
          <div className="flex w-full flex-col">
            <span className="mb-2 mt-6 font-sans text-base font-medium">
              Add a Workspace icon
            </span>
            {/* <FiHelpCircle
                                onClick={() => window.open(`https://atarim.io/help/integrations/integrating-monday/`)}
                                style={{ cursor: "pointer" }}
                            /> */}

            <div className="at-cs-favicon-upload-container">
              <div className={classes.uploadFileControlContainer}>
                <button className="at-cs-btn btn-outline">Upload image</button>
                Image should be 124x124px
                <input
                  type="file"
                  className={classes.uploadFileControl}
                  onChange={onChangeFileControl}
                  accept="image/*"
                />
              </div>
              <img
                className={classes.faviconPreview}
                src={preview ? preview : ` /assets/image-prev.png`}
                alt="workspace"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <span className="mb-2 mt-6 font-sans text-base font-medium">
              Add existing users to this Workspace
            </span>
            <Autocomplete
              className={`${styles.autocomplete} at-cs-invite-user-dropdown`}
              classes={{
                paper: styles.papers,
                option: styles.option,
                popper: styles.popper,
              }}
              multiple
              id="tags-standard"
              options={usersList}
              onChange={handleChangePeople}
              getOptionLabel={(option) => option.name || ''}
              value={selectedUsers}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Existing User"
                />
              )}
              renderOption={(option) => {
                return (
                  <>
                    {option.avatar && (
                      <Avatar
                        className="mr-1"
                        size="sm"
                        src={option.avatar}
                        title={option.name}
                        colour={'#6D5DF3'}
                        alt={option.name}
                      ></Avatar>
                    )}
                    <span style={{ marginLeft: 5 }}>{option.name}</span>
                  </>
                );
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    avatar={
                      option.avatar && (
                        <Avatar
                          className="mr-1 text-[8px]"
                          size="sm"
                          src={option.avatar}
                          title={option.name}
                          colour={'#6D5DF3'}
                          alt={option.name}
                        ></Avatar>
                      )
                    }
                    label={option.name}
                    {...getTagProps({ index })}
                    deleteIcon={<MdClose />}
                  />
                ))
              }
            />
          </div>
          <div className={classes.addUserContainer}>
            <span className="mt-6 font-sans text-base font-medium">
              Invite new users to this Workspace
            </span>
            <WorkspaceInviteForm
              setFormValue={setFormValue}
              formValue={formValue}
              singleFormValues={singleFormValues}
            />
            <div className={classes.textareaContainer}>
              <textarea
                className={classes.commentTextarea}
                onChange={updateTextarea}
                value={message}
                placeholder="Message (optional)"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-3 px-10 pb-10">
          <button className="at-cs-btn btn-outline red" onClick={onClose}>
            Cancel
          </button>
          <button
            className="at-cs-btn btn-green"
            onClick={handleCreateWorkspace}
            disabled={
              workspaceName === '' ||
              dataIsLoading ||
              handleCheckIsvalid() ||
              workspaceOwnerErrorMsg !== ''
            }
          >
            {`${editWorkSpace ? 'Edit' : 'Create'} Workspace`}
          </button>
        </div>
      </Dialog>
    </>
  );
}
