// Saga Utils
import {
  call,
  delay,
  fork,
  join,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
// Actions
import { SUCCESS_FETCH_PAGES } from '../actions/collaborate';
import {
  APPLY_FILTER_AFTER_ATTRIBUTE_CHANGE,
  ASANA_CREATE_TASK,
  ASANA_DEPLOY_TASKS,
  ASANA_GET_PROJECTS,
  ASANA_GET_SECTION,
  BASECAMP_CREATE_TASK,
  BASECAMP_DEPLOY_TASKS,
  BASECAMP_GET_PROJECTS,
  BASECAMP_GET_TODOLIST,
  CHANGE_AUTOMATION_STATUS,
  CLICKUP_CREATE_TASK,
  CLICKUP_DEPLOY_TASKS,
  CLICKUP_GET_FOLDERS,
  CLICKUP_GET_TASK_LIST,
  CREATE_AUTOMATION,
  CREATE_AUTOMATION_TEMPLATE,
  CREATE_NEW_GENERAL_TASK,
  DELETE_COMMENT,
  DELETE_INBOX_COMMENT_FILE,
  DELETE_WORKFLOW,
  EDIT_AUTOMATION,
  EDIT_COMMENT,
  EMAIL_FROM_DASHBOARD,
  EMPTY_SUGGESTION_LIST,
  ERROR_ASANA_CREATE_TASK,
  ERROR_ASANA_DEPLOY_TASKS,
  ERROR_ATTRIBUTES_FETCHING,
  ERROR_BASECAMP_CREATE_TASK,
  ERROR_BASECAMP_DEPLOY_TASKS,
  ERROR_CHANGE_AUTOMATION_STATUS,
  ERROR_CLICKUP_CREATE_TASK,
  ERROR_CLICKUP_DEPLOY_TASKS,
  ERROR_CREATE_AUTOMATION,
  ERROR_CREATE_AUTOMATION_TEMPLATE,
  ERROR_DELETE_COMMENT,
  ERROR_DELETE_INBOX_COMMENT_FILE,
  ERROR_DELETE_S3_IMAGES,
  ERROR_DELETE_TASK,
  ERROR_DELETE_WORKFLOW,
  ERROR_EDIT_COMMENT,
  ERROR_FETCHT_AUTOMATION_ACTION,
  ERROR_FETCHT_CONDITION_ACTION,
  ERROR_FETCHT_TRIGGERS,
  ERROR_FETCH_ALL_TASKS,
  ERROR_FETCH_ASSIGN_PROJECT,
  ERROR_FETCH_AUTOMATION_DETAIL,
  ERROR_FETCH_AUTOMATION_TAG,
  ERROR_FETCH_AUTOMATION_TEMPLATE,
  ERROR_FETCH_AUTOMATION_TEMPLATE_DETAIL,
  ERROR_FETCH_AUTOMATION_WORKFLOW,
  ERROR_FETCH_GENERAL_TASK_ATTRIBUTES,
  ERROR_FETCH_NEXT_ASSIGN_PROJECT,
  ERROR_FETCH_NEXT_TASKS_LIST,
  ERROR_FETCH_TAGS_SUGGESTION,
  ERROR_FETCH_TASK_ALL_SNIPPET,
  ERROR_FILE_UPLOAD,
  ERROR_FILTER_FETCHING,
  ERROR_GET_SPACE_LIST,
  ERROR_IN_SENDING_MESSAGE,
  ERROR_IN_SENDING_MESSAGE_NEW_TASK,
  ERROR_JIRA_CREATE_TASK,
  ERROR_JIRA_DEPLOY_TASKS,
  ERROR_MONDAY_CREATE_TASK,
  ERROR_MONDAY_DEPLOY_TASKS,
  ERROR_NEW_TASK_SNIPPET_SELECTED,
  ERROR_SAVE_NEW_GENERAL_TASK,
  ERROR_SEARCH,
  ERROR_SEARCH_TASK_CATEGORY,
  ERROR_SEND_IMAGE_TO_MEDIA,
  ERROR_START_EXPORT_TASK,
  ERROR_TASKS_FETCHING,
  ERROR_TEAMWORK_CREATE_TASK,
  ERROR_TEAMWORK_DEPLOY_TASKS,
  ERROR_TRELLO_CREATE_TASK,
  ERROR_TRELLO_DEPLOY_TASKS,
  ERROR_UPDATE_ESTIMATED_TIME,
  ERROR_UPDATE_INTERNAL_TASK,
  ERROR_UPDATE_TASK_ATTRIBUTES,
  FETCHT_AUTOMATION_ACTION,
  FETCHT_CONDITION_ACTION,
  FETCHT_TRIGGERS,
  FETCH_ALL_TASKS,
  FETCH_AUTOMATION_TAG,
  FETCH_AUTOMATION_TEMPLATE,
  FETCH_AUTOMATION_WORKFLOW,
  FETCH_NEXT_TASKS_LIST,
  FETCH_TAGS_SUGGESTION,
  GET_SEARCH_TASK_CATEGORY,
  GET_SPACE_LIST,
  JIRA_CREATE_TASK,
  JIRA_DEPLOY_TASKS,
  JIRA_GET_PROJECTS,
  MESSAGE_SENT,
  MESSAGE_SENT_NEW_TASK,
  MONDAY_CREATE_TASK,
  MONDAY_DEPLOY_TASKS,
  MONDAY_GET_BOARDS,
  MONDAY_GET_GROUP_LIST,
  NEW_GENERAL_TASK_SAVED_WITH_MESSAGE,
  NEW_TASK_SELECTED,
  NEW_TASK_SNIPPET_SELECTED,
  NEW_WEBSITE_SELECTED,
  SAVE_NEW_GENERAL_TASK,
  SEND_MESSAGE,
  SEND_MESSAGE_WITHOUT_POST,
  START_DELETE_S3_IMAGES,
  START_DELETE_TASK,
  START_EXPORT_TASK,
  START_FETCH_ASSIGN_PROJECT,
  START_FETCH_AUTOMATION_DETAIL,
  START_FETCH_AUTOMATION_TEMPLATE_DETAIL,
  START_FETCH_NEXT_ASSIGN_PROJECT,
  START_FETCH_TASK_ALL_SNIPPET,
  START_FILE_UPLOAD,
  START_FILTERING_TASKS,
  START_SEARCH,
  START_SEND_IMAGE_TO_MEDIA,
  SUCCESS_ASANA_CREATE_TASK,
  SUCCESS_ASANA_DEPLOY_TASKS,
  SUCCESS_ASANA_GET_PROJECTS,
  SUCCESS_ASANA_GET_SECTION,
  SUCCESS_ATTRIBUTES_FETCHING,
  SUCCESS_BASECAMP_CREATE_TASK,
  SUCCESS_BASECAMP_DEPLOY_TASKS,
  SUCCESS_BASECAMP_GET_PROJECTS,
  SUCCESS_BASECAMP_GET_TODOLIST,
  SUCCESS_CHANGE_AUTOMATION_STATUS,
  SUCCESS_CLICKUP_CREATE_TASK,
  SUCCESS_CLICKUP_DEPLOY_TASKS,
  SUCCESS_CLICKUP_GET_FOLDERS,
  SUCCESS_CLICKUP_GET_TASK_LIST,
  SUCCESS_CREATE_AUTOMATION,
  SUCCESS_CREATE_AUTOMATION_TEMPLATE,
  SUCCESS_DELETE_COMMENT,
  SUCCESS_DELETE_INBOX_COMMENT_FILE,
  SUCCESS_DELETE_S3_IMAGES,
  SUCCESS_DELETE_TASK,
  SUCCESS_DELETE_WORKFLOW,
  SUCCESS_EDIT_COMMENT,
  SUCCESS_FETCHT_AUTOMATION_ACTION,
  SUCCESS_FETCHT_CONDITION_ACTION,
  SUCCESS_FETCHT_TRIGGERS,
  SUCCESS_FETCH_ALL_TASKS,
  SUCCESS_FETCH_ASSIGN_PROJECT,
  SUCCESS_FETCH_AUTOMATION_DETAIL,
  SUCCESS_FETCH_AUTOMATION_TAG,
  SUCCESS_FETCH_AUTOMATION_TEMPLATE,
  SUCCESS_FETCH_AUTOMATION_TEMPLATE_DETAIL,
  SUCCESS_FETCH_AUTOMATION_WORKFLOW,
  SUCCESS_FETCH_GENERAL_TASK_ATTRIBUTES,
  SUCCESS_FETCH_NEXT_ASSIGN_PROJECT,
  SUCCESS_FETCH_NEXT_TASKS_LIST,
  SUCCESS_FETCH_TAGS_SUGGESTION,
  SUCCESS_FETCH_TASK_ALL_SNIPPET,
  SUCCESS_FILE_UPLOAD,
  SUCCESS_FILTER_FETCHING,
  SUCCESS_GET_SPACE_LIST,
  SUCCESS_JIRA_CREATE_TASK,
  SUCCESS_JIRA_DEPLOY_TASKS,
  SUCCESS_JIRA_GET_PROJECTS,
  SUCCESS_MONDAY_CREATE_TASK,
  SUCCESS_MONDAY_DEPLOY_TASKS,
  SUCCESS_MONDAY_GET_BOARDS,
  SUCCESS_MONDAY_GET_GROUP_LIST,
  SUCCESS_NEW_TASK_SNIPPET_SELECTED,
  SUCCESS_SAVE_NEW_GENERAL_TASK,
  SUCCESS_SEARCH,
  SUCCESS_SEARCH_TASK_CATEGORY,
  SUCCESS_SEND_IMAGE_TO_MEDIA,
  SUCCESS_START_EXPORT_TASK,
  SUCCESS_TASKS_FETCHING,
  SUCCESS_TEAMWORK_CREATE_TASK,
  SUCCESS_TEAMWORK_DEPLOY_TASKS,
  SUCCESS_TEAMWORK_GET_PROJECTS,
  SUCCESS_TEAMWORK_GET_TASK,
  SUCCESS_TEAMWORK_GET_TASK_LIST,
  SUCCESS_TRELLO_CREATE_TASK,
  SUCCESS_TRELLO_DEPLOY_TASKS,
  SUCCESS_TRELLO_GET_BOARDS,
  SUCCESS_TRELLO_GET_TASK_LIST,
  SUCCESS_UPDATE_ESTIMATED_TIME,
  SUCCESS_UPDATE_INTERNAL_TASK,
  SUCCESS_UPDATE_TASK_ATTRIBUTES,
  TEAMWORK_CREATE_TASK,
  TEAMWORK_DEPLOY_TASKS,
  TEAMWORK_GET_PROJECTS,
  TEAMWORK_GET_TASK,
  TEAMWORK_GET_TASK_LIST,
  TRELLO_CREATE_TASK,
  TRELLO_DEPLOY_TASKS,
  TRELLO_GET_BOARDS,
  TRELLO_GET_TASK_LIST,
  UPDATE_ESTIMATED_TIME,
  UPDATE_INTERNAL_TASK,
  UPDATE_NEWLY_ARRIVED_USER,
  UPDATE_TASK_ATTRIBUTES,
} from '../actions/tasks';
import { LOGOUT } from '../actions/user';
// APIs
import {
  asanaCreateTask,
  asanaDeployTasks,
  asanaGetProjects,
  asanaGetSection,
  attachFile,
  basecampCreateTask,
  basecampDeployTasks,
  basecampGetProjects,
  basecampGetTodoList,
  changeAutomationStatus,
  clickupCreateTask,
  clickupDeployTasks,
  clickupGetFolders,
  clickupGetTaskList,
  clickupTaskList,
  createAutomationTemplate,
  createCustomAutomation,
  deleteComment,
  deleteCommentFiles,
  deleteMultiTasks,
  deleteS3Images,
  deleteWorkflow,
  editComment,
  editCustomAutomation,
  exportTaskData,
  fetchAllSnippetSetting,
  fetchAllTasks,
  fetchAssignProjectSearch,
  fetchAssignProjects,
  fetchAutomationAction,
  fetchAutomationCondition,
  fetchAutomationDetail,
  fetchAutomationTag,
  fetchAutomationTemplate,
  fetchAutomationTemplateDetail,
  fetchAutomationWorkflow,
  fetchFilterAllTasks,
  fetchNewGeneralTaskAttributes,
  fetchNextAssignProjects,
  fetchSnippetDetailSetting,
  fetchTagsSuggestions,
  fetchTaskAttributes,
  fetchTaskFilters,
  fetchTaskList,
  fetchTriggers,
  filterTasks,
  jiraCreateTask,
  jiraDeployTasks,
  jiraGetProjects,
  mondayCreateTask,
  mondayDeployTasks,
  mondayGetGroupList,
  mondayGetboards,
  notifyServerToUpdateNewUser,
  saveGeneralTask,
  searchTaskCategory,
  sendImageToMedia,
  // deleteTask,
  sendMessage,
  teamWorkCreateTask,
  teamWorkDeployTasks,
  teamWorkGetProjects,
  teamWorkGetTask,
  teamWorkGetTaskList,
  trelloCreateTask,
  trelloDeployTasks,
  trelloGetTaskList,
  trelloGetboards,
  updateEstimatedTime,
  updateTaskAttributes,
} from '../api/tasks';
// Utils
import {
  CONSTANT_TASK_LISTING_PAGE_LIMIT,
  LogoutObjectForPutEffect,
} from '../utils/constants';
import {
  fetchInitFilterObject,
  getFilterObject,
  getIsAllTasks,
  getSelectedWebsiteId,
  getSelectedWorkspace,
  getTaskUpdateMsg,
} from '../utils/functions';
import { getFilterUrl } from '../utils/urls.js';
// Actions
import { NEW_BOARD_WEBSITE_SELECTED } from '../actions/board';
import { ERROR_EMAIL_TASKS, SUCCESS_EMAIL_TASKS } from '../actions/email';
import { CLICKUP_GET_TASKS } from '../actions/time_entries';

function* getTaskAttributes(action) {
  const { taskId } = action;
  if (taskId.split(',').length > 1) var taskIdnew = taskId.split(',')[0];
  else var taskIdnew = taskId;
  const data = yield call(fetchTaskAttributes, taskIdnew);

  if (data.status) {
    yield put({
      type: SUCCESS_ATTRIBUTES_FETCHING,
      attributes: data.data,
    });

    //  This check is to stop emails object object issue inside inbox tech info.
    if (data.data.hasOwnProperty('from_email')) {
      yield put({
        type: EMAIL_FROM_DASHBOARD,
        value: true,
      });
    } else {
      yield put({
        type: EMAIL_FROM_DASHBOARD,
        value: false,
      });
    }
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_ATTRIBUTES_FETCHING,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* fetchTasksAttributeSaga() {
  yield takeLatest(NEW_TASK_SELECTED, getTaskAttributes);
}

function* getTasksList(params) {
  const data = yield call(fetchTaskList, params);

  // check if emailselected
  const getEmailSelected = (state) => state.tasks.emailSelected;
  const emailSelected = yield select(getEmailSelected);

  if (emailSelected) {
    if (data.status) {
      yield put({
        type: SUCCESS_EMAIL_TASKS,
        payload: data.data,
      });
    } else if (data.unauthenticated) {
      yield put(LogoutObjectForPutEffect);
    } else {
      yield put({
        type: ERROR_EMAIL_TASKS,
        message: data.message || 'Something went wrong, try again later!',
      });
    }
  } else {
    if (data.status) {
      yield put({
        type: SUCCESS_TASKS_FETCHING,
        tasks: data.data,
        moreRecords: data.more_records,
        totalRecords: data.total_records,
        // append count
      });
    } else if (data.unauthenticated) {
      yield put(LogoutObjectForPutEffect);
    } else {
      if (data.message) {
        yield put({
          type: ERROR_TASKS_FETCHING,
          message: data.message || 'Something went wrong, try again later!',
        });
      } else {
        yield put({
          type: ERROR_TASKS_FETCHING,
          message: data.message || 'Something went wrong, try again later!',
        });
      }
    }
  }
}

function* getTasksFilters(websiteId) {
  const data = yield call(fetchTaskFilters, websiteId);
  if (data.status) {
    yield put({
      type: SUCCESS_FILTER_FETCHING,
      filters: data.data,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    if (data.message) {
      yield put({
        type: ERROR_FILTER_FETCHING,
        message: data.message || 'Something went wrong, try again later!',
      });
    } else {
      yield put({
        type: ERROR_FILTER_FETCHING,
        message: data.message || 'Something went wrong, try again later!',
      });
    }
  }
}

function* fetchTasksAndFilters(action) {
  const { websiteId, order_by = '' } = action;
  if (websiteId !== '') {
    const fetchTaskFiltersJob = yield fork(getTasksFilters, websiteId);
    const fetchTasksListJob = yield fork(getTasksList, {
      websiteId,
      order_by,
      offset: 0,
    });
    yield join([fetchTaskFiltersJob, fetchTasksListJob]);
  }
}

export function* manageTaskFlow() {
  yield takeLatest(
    [
      NEW_WEBSITE_SELECTED,
      SUCCESS_DELETE_TASK,
      NEW_GENERAL_TASK_SAVED_WITH_MESSAGE,
    ],
    fetchTasksAndFilters
  );
}

function* postTaskAttributes({
  keyOfAttributeUpdated,
  updateTaskAttributeParams,
}) {
  const data = yield call(updateTaskAttributes, updateTaskAttributeParams);
  if (data.status) {
    const newTagId =
      keyOfAttributeUpdated === 'task_tags' &&
      !updateTaskAttributeParams.is_delete
        ? { newTagId: data.tag_id }
        : {};
    const updateText = yield call(getTaskUpdateMsg);
    yield put({
      type: SUCCESS_UPDATE_TASK_ATTRIBUTES,
      updateText: updateText,
      data: data.tasks,
      params: {
        ...updateTaskAttributeParams,
        tasksCount: data.tasks_count,
        ...newTagId,
      }, // if params is task status we'll have to update task icon as well
    });
    // once tasks is updated I've to check if any filters were applied
    // if yes apply the filters else not
    const filtersApplied = yield call(getFilterObject);
    let applyFilter = filtersApplied.apply_filter;
    if (applyFilter) {
      yield put({ type: APPLY_FILTER_AFTER_ATTRIBUTE_CHANGE });
      // because I don't want the action dispatched here to have any effect in reducer related to changing filters
    }

    yield put({
      type: NEW_TASK_SELECTED,
      taskId: updateTaskAttributeParams.task_id,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_UPDATE_TASK_ATTRIBUTES,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* updateTaskAttributesSagas() {
  yield takeEvery(UPDATE_TASK_ATTRIBUTES, postTaskAttributes);
}

export function* deleteTaskWorker(taskIds, websiteId) {
  const payload = {
    task_id: taskIds,
  };

  const data = yield call(deleteMultiTasks, payload);

  if (data.status) {
    yield put({
      type: SUCCESS_DELETE_TASK,
      websiteId,
      message: 'Tasks deleted successfully!',
      remainingTasks: data.data.remaining_tasks,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_DELETE_TASK,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* deleteTaskSaga() {
  while (true) {
    const { taskId, websiteId } = yield take(START_DELETE_TASK);
    yield fork(deleteTaskWorker, taskId, websiteId);
    yield take([SUCCESS_DELETE_TASK, LOGOUT, ERROR_DELETE_TASK]);
  }
}

function* sendMessageWorker(params) {
  const data = yield call(sendMessage, params);

  if (data.status) {
    yield put({
      type: MESSAGE_SENT,
      comment: data.data,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_IN_SENDING_MESSAGE,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* sendMessageSaga() {
  while (true) {
    const { params } = yield take(SEND_MESSAGE);
    yield fork(sendMessageWorker, params);
    yield take([MESSAGE_SENT, LOGOUT, ERROR_IN_SENDING_MESSAGE]);
    // so long the current message is not sent, you can't send another message
  }
}

function* sendMessageWithoutPostWorker(params) {
  yield call(sendMessage, params);
}

export function* sendMessageWithoutPostWatcher() {
  while (true) {
    const { params } = yield take(SEND_MESSAGE_WITHOUT_POST);
    yield fork(sendMessageWithoutPostWorker, params);
  }
}

function* uploadFileWorker(params) {
  const data = yield call(attachFile, params);

  if (data.status) {
    yield put({
      type: SUCCESS_FILE_UPLOAD,
      comment: data.data,
      message: data.message || 'File uploaded successfully!',
    });
  } else {
    yield put({
      type: ERROR_FILE_UPLOAD,
      message: data.message[0][0] || 'Error uploading file, try again later',
    });
  }
}

export function* uploadFileWatcher() {
  while (true) {
    const { params } = yield take(START_FILE_UPLOAD);
    yield fork(uploadFileWorker, params);
  }
}

function* deleteS3Worker(params) {
  const data = yield call(deleteS3Images, params);
  if (data.status) {
    yield put({
      type: SUCCESS_DELETE_S3_IMAGES,
      message: data.message || 'Image deleted successfully!',
    });
    const getTaskId = (state) => state.tasks.selectedTask;
    const taskId = yield select(getTaskId);
    yield put({
      type: NEW_TASK_SELECTED,
      taskId: taskId,
    });
  } else {
    yield put({
      type: ERROR_DELETE_S3_IMAGES,
      message: data.message || 'Error encountered while deleting image',
    });
  }
}

export function* deleteS3Watcher() {
  while (true) {
    const { params } = yield take(START_DELETE_S3_IMAGES);
    yield fork(deleteS3Worker, params);
  }
}

function* filteringWatcher() {
  const filters = yield call(getFilterObject);
  const isAllTasks = yield call(getIsAllTasks);
  const selectedWebsite = yield call(getSelectedWebsiteId);

  // check if emailselected
  const getEmailSelected = (state) => state.tasks.emailSelected;
  const emailSelected = yield select(getEmailSelected);

  const url = getFilterUrl(isAllTasks, selectedWebsite, emailSelected);
  const selectedWorkspace = yield call(getSelectedWorkspace);
  const data = yield call(filterTasks, url, filters, selectedWorkspace?.id);

  if (emailSelected) {
    if (data.status) {
      yield put({
        type: SUCCESS_EMAIL_TASKS,
        payload: data.data,
      });
    } else if (data.unauthenticated) {
      yield put(LogoutObjectForPutEffect);
    } else {
      yield put({
        type: ERROR_EMAIL_TASKS,
        message: data.message || 'Something went wrong, try again later!',
      });
    }
  } else {
    if (data.status) {
      yield put({
        type: SUCCESS_TASKS_FETCHING,
        tasks: data.data,
        moreRecords: data.more_records,
        totalRecords: data.total_records,
      });
    } else if (data.unauthenticated) {
      yield put(LogoutObjectForPutEffect);
    } else {
      yield put({
        type: ERROR_TASKS_FETCHING,
        message: data.message || 'Something went wrong, try again later!',
      });
    }
  }
}

export function* startFilteringWatcher() {
  yield takeLatest(
    [START_FILTERING_TASKS, APPLY_FILTER_AFTER_ATTRIBUTE_CHANGE],
    filteringWatcher
  );
}

function* fetchAllTaskWorker() {
  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );
  const data = yield call(fetchAllTasks, {
    workspace_id: selectedWorkspace.id,
  });

  if (data.status) {
    yield put({
      type: SUCCESS_FETCH_ALL_TASKS,
      allTasks: data.data,
      moreRecords: data.more_records,
      totalRecords: data.total_records,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_FETCH_ALL_TASKS,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* fetchAllTasksWatcher() {
  while (true) {
    const { taskOrders } = yield take(FETCH_ALL_TASKS);
    yield fork(fetchAllTaskWorker, taskOrders);
    yield take([SUCCESS_FETCH_ALL_TASKS, LOGOUT, ERROR_FETCH_ALL_TASKS]);
  }
}

function* fetchAllTasksFilterWorker() {
  const data = yield call(fetchFilterAllTasks);
  if (data.status) {
    yield put({
      type: SUCCESS_FILTER_FETCHING,
      filters: data.data,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_FILTER_FETCHING,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* fetchAllTasksFilterWatcher() {
  while (true) {
    yield take([FETCH_ALL_TASKS, START_SEARCH]);

    yield fork(fetchAllTasksFilterWorker);
    yield take([SUCCESS_FILTER_FETCHING, LOGOUT, ERROR_FILTER_FETCHING]);
  }
}

export function* searchTasksWorker(action) {
  const { searchText, searchType } = action;
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const url = getFilterUrl(true); // will give me all tasks URL
  const response = yield call(
    filterTasks,
    url,
    {
      ...fetchInitFilterObject(),
      task_title_keyword: searchText,
      search_type: searchType,
    },
    selectedWorkspace?.id
  );
  if (response.status) {
    yield put({
      type: SUCCESS_SEARCH,
      tasks: response.data,
      moreRecords: response.more_records,
      totalRecords: response.total_records,
    });
  } else if (response.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_SEARCH,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* searchTasksWatcher() {
  yield takeLatest(START_SEARCH, searchTasksWorker);
}

function* updateEstimatedTimeWorker(taskId, newTime) {
  const data = yield call(updateEstimatedTime, taskId, newTime);
  if (data.status) {
    yield put({
      type: SUCCESS_UPDATE_ESTIMATED_TIME,
      message: 'Estimated time updated!',
      newTime: String(newTime),
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_UPDATE_ESTIMATED_TIME,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* updateEstimatedTimeWatcher() {
  while (true) {
    const { taskId, newTime } = yield take(UPDATE_ESTIMATED_TIME);
    yield fork(updateEstimatedTimeWorker, taskId, newTime);
    yield take([
      SUCCESS_UPDATE_ESTIMATED_TIME,
      LOGOUT,
      ERROR_UPDATE_ESTIMATED_TIME,
    ]);
  }
}

function* fetchCreateNewTaskDetailWorker(websiteId) {
  const data = yield call(fetchNewGeneralTaskAttributes, websiteId);

  if (data.status) {
    yield put({
      type: SUCCESS_FETCH_GENERAL_TASK_ATTRIBUTES,
      attributes: data.data,
    });
    yield put({
      type: SUCCESS_FETCH_PAGES,
      pages: data.data.pages.page,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_FETCH_GENERAL_TASK_ATTRIBUTES,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* fetchCreateNewTaskDetailWatcher() {
  while (true) {
    const { websiteId } = yield take(CREATE_NEW_GENERAL_TASK);
    yield fork(fetchCreateNewTaskDetailWorker, websiteId);
  }
}

export function* saveNewTaskWorker(
  urgency,
  status,
  users,
  page,
  message,
  screenWidth,
  screenHeight,
  browserName,
  browserVersion,
  internal,
  responsible_user
) {
  const data = yield call(saveGeneralTask, {
    users,
    urgency,
    status,
    page,
    message,
    screenWidth,
    screenHeight,
    browserName,
    browserVersion,
    internal,
    responsible_user,
  });

  if (data.status) {
    const websiteId = yield call(getSelectedWebsiteId);
    yield put({
      type: SUCCESS_SAVE_NEW_GENERAL_TASK,
      task_id: data.data.result,
      websiteId,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_SAVE_NEW_GENERAL_TASK,
      message:
        Object.keys(data.message)
          .map((key) => data.message[key])
          .join('\n') || 'Something went wrong, try again later!',
    });
  }
}

function* sendMessageNewTaskWorker(params) {
  const data = yield call(sendMessage, params);
  if (data.status) {
    yield put({
      type: MESSAGE_SENT_NEW_TASK,
      message: 'New task created!',
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_IN_SENDING_MESSAGE_NEW_TASK,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* saveNewTaskWatcher() {
  while (true) {
    const {
      urgency,
      status,
      users,
      page,
      message,
      screenWidth,
      screenHeight,
      browserName,
      browserVersion,
      internal,
      responsible_user,
    } = yield take(SAVE_NEW_GENERAL_TASK);

    yield fork(
      saveNewTaskWorker,
      urgency,
      status,
      users,
      page,
      message,
      screenWidth,
      screenHeight,
      browserName,
      browserVersion,
      internal,
      responsible_user
    );

    const action = yield take([
      SUCCESS_SAVE_NEW_GENERAL_TASK,
      LOGOUT,
      ERROR_SAVE_NEW_GENERAL_TASK,
    ]);

    if (action.type === SUCCESS_SAVE_NEW_GENERAL_TASK) {
      yield fork(sendMessageNewTaskWorker, {
        task_id: action.task_id,
        comment_content: message,
        is_note: false,
        comment_type: 'normal_text',
      });

      const messageStatus = yield take([
        MESSAGE_SENT_NEW_TASK,
        LOGOUT,
        ERROR_IN_SENDING_MESSAGE_NEW_TASK,
      ]);
      if (messageStatus.type === MESSAGE_SENT_NEW_TASK) {
        const websiteId = yield call(getSelectedWebsiteId);
        yield put({ type: NEW_GENERAL_TASK_SAVED_WITH_MESSAGE, websiteId });
      } else {
        // yield put();
      }
    }
  }
}

function* updateNewlyArrivedUserWorker(action) {
  const { data } = action;
  const getTask = (state) => state.tasks;
  const task = yield select(getTask);
  const { usersWatchingList, selectedTask } = task;
  const index = usersWatchingList.findIndex((u) => u.user_id === data.user_id);
  if (
    selectedTask === data.task_id &&
    (index === -1 || usersWatchingList[index].task_id !== data.task_id)
  ) {
    yield call(notifyServerToUpdateNewUser, selectedTask); // calling API just to update the newly arrived user
  }
}

export function* updateNewlyArrivedUserWatcher() {
  yield takeLatest(UPDATE_NEWLY_ARRIVED_USER, updateNewlyArrivedUserWorker);
}

function* fetchNextTasksWorker(action) {
  const { offset, order_by = '', direction = 'desc' } = action;

  const filtersApplied = yield call(getFilterObject);
  const isAllTasks = yield call(getIsAllTasks);
  const selectedWebsite = yield call(getSelectedWebsiteId);
  const selectedWorkspace = yield call(getSelectedWorkspace);
  let data = {};

  if (isAllTasks) {
    if (filtersApplied.apply_filter) {
      data = yield call(
        filterTasks,
        `${getFilterUrl(
          true
        )}?offset=${offset}&limit=${CONSTANT_TASK_LISTING_PAGE_LIMIT}`,
        filtersApplied,
        selectedWorkspace?.id
      );
    } else {
      data = yield call(fetchAllTasks, offset);
    }
  } else {
    if (filtersApplied.apply_filter) {
      data = yield call(
        filterTasks,
        `${getFilterUrl(
          false,
          selectedWebsite
        )}?offset=${offset}&limit=${CONSTANT_TASK_LISTING_PAGE_LIMIT}`,
        filtersApplied,
        selectedWorkspace?.id
      );
    } else {
      data = yield call(fetchTaskList, {
        websiteId: selectedWebsite,
        order_by,
        direction,
        offset,
      });
    }
  }
  if (data.status) {
    yield put({
      type: SUCCESS_FETCH_NEXT_TASKS_LIST,
      newTasks: data.data,
      moreRecords: data.more_records,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_FETCH_NEXT_TASKS_LIST,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* fetchNextTasksWatcher() {
  yield takeLatest(FETCH_NEXT_TASKS_LIST, fetchNextTasksWorker);
}

function* fetchTagsSuggestionWorker(action) {
  const { taskId, keyword, type } = action;
  if (type === EMPTY_SUGGESTION_LIST) {
    return;
  }
  yield delay(1000); // stop too many API calls
  const response = yield call(fetchTagsSuggestions, {
    task_id: taskId,
    keyword,
  });
  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_TAGS_SUGGESTION,
      suggestions: response.tags,
    });
  } else if (response.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_FETCH_TAGS_SUGGESTION,
      message: response.message || "Could'nt fetch list, please try later",
    });
  }
}

export function* fetchTagsSuggestionWatcher() {
  yield takeLatest(
    [FETCH_TAGS_SUGGESTION, EMPTY_SUGGESTION_LIST],
    fetchTagsSuggestionWorker
  );
}

function* getSearchTasksCategoryWatcher(action) {
  const { searchText } = action;
  const response = yield call(searchTaskCategory, {
    task_title_keyword: searchText,
  });
  if (response.status) {
    yield put({
      type: SUCCESS_SEARCH_TASK_CATEGORY,
      data: response.data,
    });
  } else if (response.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_SEARCH_TASK_CATEGORY,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* searchTasksCategoryWatcher() {
  yield takeLatest(GET_SEARCH_TASK_CATEGORY, getSearchTasksCategoryWatcher);
}

function* getExportTaskWatcher(action) {
  const { exportParams } = action;
  const response = yield call(exportTaskData, exportParams);
  if (response.status) {
    yield put({
      type: SUCCESS_START_EXPORT_TASK,
      taskData: response.data,
      type_of_export: exportParams.type,
    });
  } else if (response.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_START_EXPORT_TASK,
      message: response.message || 'Something went wrong, try again later!',
    });
  }
}

export function* exportTaskWatcher() {
  yield takeLatest(START_EXPORT_TASK, getExportTaskWatcher);
}

function* sendToMediaWorker(params) {
  const data = yield call(sendImageToMedia, params);

  if (data.status) {
    yield put({
      type: SUCCESS_SEND_IMAGE_TO_MEDIA,
      message:
        data.message ||
        "The Image was added to the website's media folder, you can use it from there",
    });
  } else {
    yield put({
      type: ERROR_SEND_IMAGE_TO_MEDIA,
      message:
        data.data.message ||
        'Install the latest plugin version & check your license is validated',
    });
  }
}

export function* sendToMediaWatcher() {
  while (true) {
    const { params } = yield take(START_SEND_IMAGE_TO_MEDIA);
    yield fork(sendToMediaWorker, params);
  }
}

function* updateInternalTaskWorker(params) {
  const data = yield call(updateTaskAttributes, params);
  if (data.status) {
    yield put({
      type: SUCCESS_UPDATE_INTERNAL_TASK,
      is_internal: params.internal,
      task_id: params.task_id,
      message: data.message || 'Task updated successfully',
    });
    const datas = yield call(fetchTaskAttributes, params.task_id);
    yield put({
      type: SUCCESS_ATTRIBUTES_FETCHING,
      attributes: datas.data,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_UPDATE_INTERNAL_TASK,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* updateInternalTaskWathcer() {
  while (true) {
    const { params } = yield take(UPDATE_INTERNAL_TASK);
    yield fork(updateInternalTaskWorker, params);
  }
}

function* editCommentWorker(action) {
  const { params } = action;

  const data = yield call(editComment, params);

  if (data.status) {
    yield put({
      type: SUCCESS_EDIT_COMMENT,
      message: data.message || 'Comment updated successfully',
      id: data.data.id,
      comment_content: data.data.comments,
      is_edited: data.data.is_edited,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_EDIT_COMMENT,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* editCommentWatcher() {
  yield takeLatest(EDIT_COMMENT, editCommentWorker);
}

function* deleteCommentWorker(action) {
  const { params } = action;

  const data = yield call(deleteComment, params);

  if (data.status) {
    yield put({
      type: SUCCESS_DELETE_COMMENT,
      id: data.data.id,
      is_deleted: data.data.is_deleted,
      updated_at: data.data.updated_at,
      message: data.message || 'Comment deleted successfully',
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_DELETE_COMMENT,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* deleteCommentWatcher() {
  yield takeLatest(DELETE_COMMENT, deleteCommentWorker);
}

function* clickupGetFoldersWorker() {
  const response = yield call(clickupGetFolders);

  if (response.status) {
    // select the first folder
    const folders = response.data.map((folder, index) =>
      index === 0
        ? {
            ...folder,
            selected: true,
          }
        : folder
    );
    yield put({
      type: SUCCESS_CLICKUP_GET_FOLDERS,
      folders: folders,
      message: 'Folders fetched successfully!' || response.message,
    });
    // fetch the task list
    if (folders.length > 0) {
      yield put({
        type: CLICKUP_GET_TASK_LIST,
        params: { folder_id: folders[0].id },
      });
    }
  } else {
    console.error('ClickUp Get Folders failed!', response.message);
  }
}

export function* clickupGetFoldersWatcher() {
  yield takeLatest(CLICKUP_GET_FOLDERS, clickupGetFoldersWorker);
}

function* clickupGetTaskListWorker(action) {
  const { params } = action;
  const response = yield call(clickupGetTaskList, params);

  if (response.status) {
    // select the first list
    const lists = response.data.map((list, index) =>
      index === 0
        ? {
            ...list,
            selected: true,
          }
        : list
    );
    yield put({
      type: SUCCESS_CLICKUP_GET_TASK_LIST,
      taskList: lists,
      message: 'Task list fetched successfully!' || response.message,
    });

    // fetch the tasks
    const obj = {
      task_list_id: lists.filter((l) => l.selected)[0].id,
    };
    yield put({
      type: CLICKUP_GET_TASKS,
      obj,
    });
  } else {
    console.error('ClickUp Get Task List failed!', response.message);
  }
}

export function* clickupGetTaskListWatcher() {
  yield takeLatest(CLICKUP_GET_TASK_LIST, clickupGetTaskListWorker);
}

function* clickupCreateTaskWorker(action) {
  const { params } = action;
  const response = yield call(clickupCreateTask, params);

  if (response.status) {
    yield put({
      type: SUCCESS_CLICKUP_CREATE_TASK,
      message: 'Task deployed to ClickUp!' || response.message,
    });
    yield put({
      type: NEW_WEBSITE_SELECTED,
      websiteId: params.site_id,
    });
  } else {
    yield put({
      type: ERROR_CLICKUP_CREATE_TASK,
      message: 'Task creation failed' || response.message,
    });
  }
}

export function* clickupCreateTaskWatcher() {
  yield takeLatest(CLICKUP_CREATE_TASK, clickupCreateTaskWorker);
}

function* clickupDeployTasksWorker(action) {
  const { params } = action;
  const response = yield call(clickupDeployTasks, params);

  if (response.status) {
    yield put({
      type: SUCCESS_CLICKUP_DEPLOY_TASKS,
      message: 'ClickUp tasks list deployed!' || response.message,
      siteId: params.site_id,
    });
    yield put({
      type: NEW_WEBSITE_SELECTED,
      websiteId: params.site_id,
    });
    yield put({
      type: NEW_BOARD_WEBSITE_SELECTED,
      websiteId: params.site_id,
    });
  } else {
    yield put({
      type: ERROR_CLICKUP_DEPLOY_TASKS,
      message: 'Creation of tasks list failed' || response.message,
    });
  }
}

export function* clickupDeployTasksWatcher() {
  yield takeLatest(CLICKUP_DEPLOY_TASKS, clickupDeployTasksWorker);
}

function* asanaGetProjectsWorker() {
  const response = yield call(asanaGetProjects);

  if (response.status) {
    // select the first folder
    const projects = response.data.map((folder, index) =>
      index === 0
        ? {
            ...folder,
            selected: true,
          }
        : folder
    );

    yield put({
      type: SUCCESS_ASANA_GET_PROJECTS,
      projects: projects,
      message: 'Projects fetched successfully!' || response.message,
    });
    // fetch the task list
    // if(folders.length > 0) {
    //     yield put({
    //         type: CLICKUP_GET_TASK_LIST,
    //         params: { "folder_id": folders[0].id }
    //     })
    // }
  } else {
    console.error('Asana Get Projects failed!', response.message);
  }
}

export function* asanaGetProjectsWatcher() {
  yield takeLatest(ASANA_GET_PROJECTS, asanaGetProjectsWorker);
}

function* asanaGetSectionWorker(action) {
  const { params } = action;
  const response = yield call(asanaGetSection, params);

  if (response.status) {
    // select the first list
    const sections = response.data.map((list, index) =>
      index === 0
        ? {
            ...list,
            selected: true,
          }
        : list
    );
    yield put({
      type: SUCCESS_ASANA_GET_SECTION,
      sectionList: sections,
      message: 'Section list fetched successfully!' || response.message,
    });
  } else {
    console.error('Asana Get Section failed!', response.message);
  }
}

export function* asanaGetSectionWatcher() {
  yield takeLatest(ASANA_GET_SECTION, asanaGetSectionWorker);
}

function* asanaCreateTaskWorker(action) {
  const { params } = action;
  const response = yield call(asanaCreateTask, params);

  if (response.status) {
    yield put({
      type: SUCCESS_ASANA_CREATE_TASK,
      message: 'Task deployed to Asana!' || response.message,
    });
    yield put({
      type: NEW_WEBSITE_SELECTED,
      websiteId: params.site_id,
    });
  } else {
    yield put({
      type: ERROR_ASANA_CREATE_TASK,
      message: 'Task creation failed' || response.message,
    });
  }
}

export function* asanaCreateTaskWatcher() {
  yield takeLatest(ASANA_CREATE_TASK, asanaCreateTaskWorker);
}

function* asanaDeployTasksWorker(action) {
  const { params } = action;
  const response = yield call(asanaDeployTasks, params);

  if (response.status) {
    yield put({
      type: SUCCESS_ASANA_DEPLOY_TASKS,
      message: 'Asana tasks list deployed!' || response.message,
      siteId: params.site_id,
    });
  } else {
    yield put({
      type: ERROR_ASANA_DEPLOY_TASKS,
      message: 'Creation of tasks list failed' || response.message,
    });
  }
}

export function* asanaDeployTasksWatcher() {
  yield takeLatest(ASANA_DEPLOY_TASKS, asanaDeployTasksWorker);
}

function* jiraGetProjectsWorker() {
  const response = yield call(jiraGetProjects);

  if (response.status) {
    // select the first Project
    const projects = response.data.map((proj, index) =>
      index === 0
        ? {
            ...proj,
            selected: true,
          }
        : proj
    );

    yield put({
      type: SUCCESS_JIRA_GET_PROJECTS,
      projects: projects,
      message: 'Projects fetched successfully!' || response.message,
    });
    // fetch the task list
    // if(folders.length > 0) {
    //     yield put({
    //         type: CLICKUP_GET_TASK_LIST,
    //         params: { "folder_id": folders[0].id }
    //     })
    // }
  } else {
    console.error('Jira Get Projects failed!', response.message);
  }
}

export function* jiraGetProjectsWatcher() {
  yield takeLatest(JIRA_GET_PROJECTS, jiraGetProjectsWorker);
}

function* jiraCreateTaskWorker(action) {
  const { params } = action;
  const response = yield call(jiraCreateTask, params);

  if (response.status) {
    yield put({
      type: SUCCESS_JIRA_CREATE_TASK,
      message: 'Task deployed to Jira!' || response.message,
    });
    yield put({
      type: NEW_WEBSITE_SELECTED,
      websiteId: params.site_id,
    });
  } else {
    yield put({
      type: ERROR_JIRA_CREATE_TASK,
      message: 'Task creation failed' || response.message,
    });
  }
}

export function* jiraCreateTaskWatcher() {
  yield takeLatest(JIRA_CREATE_TASK, jiraCreateTaskWorker);
}

function* jiraDeployTasksWorker(action) {
  const { params } = action;
  const response = yield call(jiraDeployTasks, params);

  if (response.status) {
    yield put({
      type: SUCCESS_JIRA_DEPLOY_TASKS,
      message: 'Jira tasks list deployed!' || response.message,
      siteId: params.site_id,
    });
  } else {
    yield put({
      type: ERROR_JIRA_DEPLOY_TASKS,
      message: 'Creation of tasks list failed' || response.message,
    });
  }
}

export function* jiraDeployTasksWatcher() {
  yield takeLatest(JIRA_DEPLOY_TASKS, jiraDeployTasksWorker);
}

function* teamWorkGetProjectsWorker() {
  const response = yield call(teamWorkGetProjects);

  if (response.status) {
    // select the first folder
    const projects = response.data.map((project, index) =>
      index === 0
        ? {
            ...project,
            selected: true,
          }
        : project
    );
    yield put({
      type: SUCCESS_TEAMWORK_GET_PROJECTS,
      projects: projects,
      message: 'Projects fetched successfully!' || response.message,
    });
    // fetch the task list
    if (projects.length > 0) {
      yield put({
        type: TEAMWORK_GET_TASK_LIST,
        params: { project_id: projects[0].id },
      });
    }
  } else {
    console.error('Teamwork Get Folders failed!', response.message);
  }
}

export function* teamWorkGetProjectsWatcher() {
  yield takeLatest(TEAMWORK_GET_PROJECTS, teamWorkGetProjectsWorker);
}

function* teamWorkGetTaskListWorker(action) {
  const { params } = action;
  const response = yield call(teamWorkGetTaskList, params.project_id);

  if (response.status) {
    // select the first list
    const lists = response.data.map((list, index) =>
      index === 0
        ? {
            ...list,
            selected: true,
          }
        : list
    );
    yield put({
      type: SUCCESS_TEAMWORK_GET_TASK_LIST,
      taskList: lists,
      message: 'Task list fetched successfully!' || response.message,
    });
  } else {
    console.error('ClickUp Get Task List failed!', response.message);
  }
}

export function* teamWorkGetTaskListWatcher() {
  yield takeLatest(TEAMWORK_GET_TASK_LIST, teamWorkGetTaskListWorker);
}

function* teamWorkGetTaskWorker(action) {
  const { params } = action;
  const response = yield call(teamWorkGetTask, params.project_id);

  if (response.status) {
    // select the first list
    const lists = response.data.map((list, index) =>
      index === 0
        ? {
            ...list,
            selected: true,
          }
        : list
    );
    yield put({
      type: SUCCESS_TEAMWORK_GET_TASK,
      teamWorkTask: lists,
      message: 'Task list fetched successfully!' || response.message,
    });
  } else {
    console.error('ClickUp Get Task List failed!', response.message);
  }
}

export function* teamWorkGetTaskWatcher() {
  yield takeLatest(TEAMWORK_GET_TASK, teamWorkGetTaskWorker);
}

function* teamWorkCreateTaskWorker(action) {
  const { params } = action;
  const response = yield call(teamWorkCreateTask, params);

  if (response.status) {
    yield put({
      type: SUCCESS_TEAMWORK_CREATE_TASK,
      message: 'Task deployed to TeamWork!' || response.message,
    });
    yield put({
      type: NEW_WEBSITE_SELECTED,
      websiteId: params.site_id,
    });
  } else {
    yield put({
      type: ERROR_TEAMWORK_CREATE_TASK,
      message: 'Task creation failed' || response.message,
    });
  }
}

export function* teamWorkCreateTaskWatcher() {
  yield takeLatest(TEAMWORK_CREATE_TASK, teamWorkCreateTaskWorker);
}

function* teamWorkDeployTasksWorker(action) {
  const { params } = action;
  const response = yield call(teamWorkDeployTasks, params);

  if (response.status) {
    yield put({
      type: SUCCESS_TEAMWORK_DEPLOY_TASKS,
      message: 'TeamWork tasks list deployed!' || response.message,
      siteId: params.site_id,
    });
    yield put({
      type: NEW_WEBSITE_SELECTED,
      websiteId: params.site_id,
    });
  } else {
    yield put({
      type: ERROR_TEAMWORK_DEPLOY_TASKS,
      message: 'Creation of tasks list failed' || response.message,
    });
  }
}

export function* teamWorkDeployTasksWatcher() {
  yield takeLatest(TEAMWORK_DEPLOY_TASKS, teamWorkDeployTasksWorker);
}

function* basecampGetProjectsWorker() {
  const response = yield call(basecampGetProjects);

  if (response.status) {
    // select the first folder
    const projects = response.data.map((folder, index) =>
      index === 0
        ? {
            ...folder,
            selected: true,
          }
        : folder
    );

    yield put({
      type: SUCCESS_BASECAMP_GET_PROJECTS,
      projects: projects,
      message: 'Projects fetched successfully!' || response.message,
    });
    if (projects.length > 0) {
      yield put({
        type: BASECAMP_GET_TODOLIST,
        params: {
          project_id: projects[0].id.toString(),
          todo_set_id: projects[0].todo_set_id.toString(),
        },
      });
    }
  } else {
    console.error('Basecamp Get Projects failed!', response.message);
  }
}

export function* basecampGetProjectsWatcher() {
  yield takeLatest(BASECAMP_GET_PROJECTS, basecampGetProjectsWorker);
}

function* basecampGetTodoListWorker(action) {
  const { params } = action;
  const response = yield call(basecampGetTodoList, params);

  if (response.status) {
    // select the first list
    const todos = response.data.map((list, index) =>
      index === 0
        ? {
            ...list,
            selected: true,
          }
        : list
    );
    yield put({
      type: SUCCESS_BASECAMP_GET_TODOLIST,
      todoList: todos,
      message: 'Todo list fetched successfully!' || response.message,
    });
  } else {
    console.error('Basecamp Get todolist failed!', response.message);
  }
}

export function* basecampGetTodoWatcher() {
  yield takeLatest(BASECAMP_GET_TODOLIST, basecampGetTodoListWorker);
}

function* basecampCreateTaskWorker(action) {
  const { params } = action;
  const response = yield call(basecampCreateTask, params);

  if (response.status) {
    yield put({
      type: SUCCESS_BASECAMP_CREATE_TASK,
      message: 'Task deployed to BaseCamp!' || response.message,
    });
    yield put({
      type: NEW_WEBSITE_SELECTED,
      websiteId: params.site_id,
    });
  } else {
    yield put({
      type: ERROR_BASECAMP_CREATE_TASK,
      message: 'Task creation failed' || response.message,
    });
  }
}

export function* basecampCreateTaskWatcher() {
  yield takeLatest(BASECAMP_CREATE_TASK, basecampCreateTaskWorker);
}

function* basecampDeployTasksWorker(action) {
  const { params } = action;
  const response = yield call(basecampDeployTasks, params);

  if (response.status) {
    yield put({
      type: SUCCESS_BASECAMP_DEPLOY_TASKS,
      message: 'Basecamp tasks list deployed!' || response.message,
      siteId: params.site_id,
    });
    yield put({
      type: NEW_WEBSITE_SELECTED,
      websiteId: params.site_id,
    });
  } else {
    yield put({
      type: ERROR_BASECAMP_DEPLOY_TASKS,
      message: 'Creation of tasks list failed' || response.message,
    });
  }
}

export function* basecampDeployTasksWatcher() {
  yield takeLatest(BASECAMP_DEPLOY_TASKS, basecampDeployTasksWorker);
}

function* trelloGetBoardsWorker() {
  const response = yield call(trelloGetboards);
  if (response.status) {
    // select the first folder
    const folders = response.data.map((folder, index) =>
      index === 0
        ? {
            ...folder,
            selected: true,
          }
        : folder
    );
    yield put({
      type: SUCCESS_TRELLO_GET_BOARDS,
      folders: folders,
      message: 'Folders fetched successfully!' || response.message,
    });
    // fetch the task list
    if (folders.length > 0) {
      yield put({
        type: TRELLO_GET_TASK_LIST,
        params: { board_id: folders[0].id },
      });
    }
  } else {
    console.error('ClickUp Get Folders failed!', response.message);
  }
}
export function* trelloGetBoardsWatcher() {
  yield takeLatest(TRELLO_GET_BOARDS, trelloGetBoardsWorker);
}
function* trelloGetTaskListWorker(action) {
  const { params } = action;
  const response = yield call(trelloGetTaskList, params);
  if (response.status) {
    // select the first list
    const lists = response.data.map((list, index) =>
      index === 0
        ? {
            ...list,
            selected: true,
          }
        : list
    );
    yield put({
      type: SUCCESS_TRELLO_GET_TASK_LIST,
      taskList: lists,
      message: 'Task list fetched successfully!' || response.message,
    });
  } else {
    console.error('ClickUp Get Task List failed!', response.message);
  }
}
export function* trelloGetTaskListWatcher() {
  yield takeLatest(TRELLO_GET_TASK_LIST, trelloGetTaskListWorker);
}
function* trelloCreateTaskWorker(action) {
  const { params } = action;
  const response = yield call(trelloCreateTask, params);
  if (response.status) {
    yield put({
      type: SUCCESS_TRELLO_CREATE_TASK,
      message: 'Task deployed to Trello!' || response.message,
    });
    yield put({
      type: NEW_WEBSITE_SELECTED,
      websiteId: params.site_id,
    });
  } else {
    yield put({
      type: ERROR_TRELLO_CREATE_TASK,
      message: 'Task creation failed' || response.message,
    });
  }
}
export function* trelloCreateTaskWatcher() {
  yield takeLatest(TRELLO_CREATE_TASK, trelloCreateTaskWorker);
}
function* trelloDeployTasksWorker(action) {
  const { params } = action;
  const response = yield call(trelloDeployTasks, params);
  if (response.status) {
    yield put({
      type: SUCCESS_TRELLO_DEPLOY_TASKS,
      message: 'Trello tasks list deployed!' || response.message,
      siteId: params.site_id,
    });
    yield put({
      type: NEW_WEBSITE_SELECTED,
      websiteId: params.site_id,
    });
  } else {
    yield put({
      type: ERROR_TRELLO_DEPLOY_TASKS,
      message: 'Creation of tasks list failed' || response.message,
    });
  }
}
export function* trelloDeployTasksWatcher() {
  yield takeLatest(TRELLO_DEPLOY_TASKS, trelloDeployTasksWorker);
}

function* fetchTriggersWorker() {
  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );
  const response = yield call(fetchTriggers, selectedWorkspace?.id);
  if (response.status) {
    yield put({
      type: SUCCESS_FETCHT_TRIGGERS,
      message: response.message || 'Triggers fetched succesfully',
      data: response.data,
    });
  } else {
    yield put({
      type: ERROR_FETCHT_TRIGGERS,
      // message: "Creation of tasks list failed" || response.message
    });
  }
}
export function* fetchTriggersWatcher() {
  yield takeLatest(FETCHT_TRIGGERS, fetchTriggersWorker);
}

function* fetchAutomationActionWorker() {
  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );
  const response = yield call(fetchAutomationAction, selectedWorkspace?.id);
  if (response.status) {
    yield put({
      type: SUCCESS_FETCHT_AUTOMATION_ACTION,
      message: response.message || 'Action fetched succesfully',
      data: response.data,
    });
  } else {
    yield put({
      type: ERROR_FETCHT_AUTOMATION_ACTION,
      // message: "Creation of tasks list failed" || response.message
    });
  }
}
export function* fetchAutomationActionWatcher() {
  yield takeLatest(FETCHT_AUTOMATION_ACTION, fetchAutomationActionWorker);
}

function* fetchAutomationConditionWorker() {
  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );
  const response = yield call(fetchAutomationCondition, selectedWorkspace?.id);
  if (response.status) {
    yield put({
      type: SUCCESS_FETCHT_CONDITION_ACTION,
      message: response.message || 'Condition fetched succesfully',
      data: response.data,
    });
  } else {
    yield put({
      type: ERROR_FETCHT_CONDITION_ACTION,
      // message: "Creation of tasks list failed" || response.message
    });
  }
}
export function* fetchAutomationConditionWatcher() {
  yield takeLatest(FETCHT_CONDITION_ACTION, fetchAutomationConditionWorker);
}

function* createCustomAutomationWorker(action) {
  const { params } = action;
  const response = yield call(createCustomAutomation, params);

  if (response.status) {
    yield put({
      type: SUCCESS_CREATE_AUTOMATION,
      message: 'Automation created successfully' || response.message,
      workflow: response.data,
    });
    yield put({
      type: FETCH_AUTOMATION_WORKFLOW,
    });
  } else {
    yield put({
      type: ERROR_CREATE_AUTOMATION,
      message: 'Creation of Automation failed' || response.message,
    });
  }
}

export function* createCustomAutomationWatcher() {
  yield takeLatest(CREATE_AUTOMATION, createCustomAutomationWorker);
}

function* fetchAutomationWorkflowWorker() {
  const selectedWorkspace = yield call(getSelectedWorkspace);

  const response = yield call(fetchAutomationWorkflow, selectedWorkspace?.id);

  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_AUTOMATION_WORKFLOW,
      message: response.message || 'Workflow fetched succesfully',
      data: response.data,
    });
  } else {
    yield put({
      type: ERROR_FETCH_AUTOMATION_WORKFLOW,
      message: 'Error fetching workflow' || response.message,
    });
  }
}
export function* fetchAutomationWorkflowWatcher() {
  yield takeLatest(FETCH_AUTOMATION_WORKFLOW, fetchAutomationWorkflowWorker);
}

function* fetchAutomationTagWorker(action) {
  const response = yield call(fetchAutomationTag, action.params);
  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_AUTOMATION_TAG,
      message: response.message || 'Tag fetched succesfully',
      data: response.data,
    });
  } else {
    yield put({
      type: ERROR_FETCH_AUTOMATION_TAG,
      // message: "Creation of automation failed" || response.message
    });
  }
}
export function* fetchAutomationTagWatcher() {
  yield takeLatest(FETCH_AUTOMATION_TAG, fetchAutomationTagWorker);
}

function* changeAutomationStatusWorker(action) {
  const response = yield call(changeAutomationStatus, action.params);
  if (response.status) {
    yield put({
      type: SUCCESS_CHANGE_AUTOMATION_STATUS,
      message: response.message || 'Workflow toggle changed successfully',
      data: response.data,
      status: action.params,
    });
  } else {
    yield put({
      type: ERROR_CHANGE_AUTOMATION_STATUS,
      message: 'workflow toggle change failed' || response.message,
      id: action.params.id,
    });
  }
}
export function* changeAutomationStatusWatcher() {
  yield takeLatest(CHANGE_AUTOMATION_STATUS, changeAutomationStatusWorker);
}

function* deleteWorkflowWorker(action) {
  const { params } = action;

  const data = yield call(deleteWorkflow, params);

  if (data.status) {
    yield put({
      type: SUCCESS_DELETE_WORKFLOW,
      message: data.message || 'Workflow deleted successfully',
      id: params,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_DELETE_WORKFLOW,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* deleteWorkflowWatcher() {
  yield takeLatest(DELETE_WORKFLOW, deleteWorkflowWorker);
}

function* fetchAutomationDetailWorker(action) {
  const response = yield call(fetchAutomationDetail, action.params);

  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_AUTOMATION_DETAIL,
      message: response.message || 'Workflow fetched succesfully',
      data: response.data,
    });
  } else {
    yield put({
      type: ERROR_FETCH_AUTOMATION_DETAIL,
      // message: "Creation of automation failed" || response.message
    });
  }
}

export function* fetchAutomationDetailWatcher() {
  yield takeLatest(START_FETCH_AUTOMATION_DETAIL, fetchAutomationDetailWorker);
}

function* editCustomAutomationWorker(action) {
  const { params, id } = action;
  const response = yield call(editCustomAutomation, params, id);

  if (response.status) {
    yield put({
      type: SUCCESS_CREATE_AUTOMATION,
      message: 'Workflow edited successfully' || response.message,
      workflow: response.data,
    });
    yield put({
      type: FETCH_AUTOMATION_WORKFLOW,
    });
  } else {
    yield put({
      type: ERROR_CREATE_AUTOMATION,
      message: 'Edit Automation failed' || response.message,
    });
  }
}

export function* editCustomAutomationWatcher() {
  yield takeLatest(EDIT_AUTOMATION, editCustomAutomationWorker);
}

function* createAutomationTemplateWorker(action) {
  const { params } = action;
  const response = yield call(createAutomationTemplate, params);

  if (response.status) {
    yield put({
      type: SUCCESS_CREATE_AUTOMATION_TEMPLATE,
      message: 'Automation template created successfully' || response.message,
      workflow: response.data,
    });
    yield put({
      type: FETCH_AUTOMATION_TEMPLATE,
    });
  } else {
    yield put({
      type: ERROR_CREATE_AUTOMATION_TEMPLATE,
      message: 'Creation of Automation template failed' || response.message,
    });
  }
}

export function* createAutomationTemplateWatcher() {
  yield takeLatest(CREATE_AUTOMATION_TEMPLATE, createAutomationTemplateWorker);
}

function* fetchAutomationTemplateWorker() {
  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );
  const response = yield call(fetchAutomationTemplate, selectedWorkspace?.id);
  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_AUTOMATION_TEMPLATE,
      message: response.message || 'Template fetched succesfully',
      data: response.data,
    });
  } else {
    yield put({
      type: ERROR_FETCH_AUTOMATION_TEMPLATE,
      message: 'Fetching template failed' || response.message,
    });
  }
}

export function* fetchAutomationTemplateWatcher() {
  yield takeLatest(FETCH_AUTOMATION_TEMPLATE, fetchAutomationTemplateWorker);
}

function* fetchAutomationTemplateDetailWorker(action) {
  const response = yield call(fetchAutomationTemplateDetail, action.params);
  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_AUTOMATION_TEMPLATE_DETAIL,
      message: response.message || 'Template fetched succesfully',
      data: response.data,
    });
  } else {
    yield put({
      type: ERROR_FETCH_AUTOMATION_TEMPLATE_DETAIL,
      // message: "Creation of automation failed" || response.message
    });
  }
}

export function* fetchAutomationTemplateDetailWatcher() {
  yield takeLatest(
    START_FETCH_AUTOMATION_TEMPLATE_DETAIL,
    fetchAutomationTemplateDetailWorker
  );
}

function* fetchAllSnippetWorker(action) {
  const { selectedTaskId } = action;

  const selectedWorkspace = yield call(getSelectedWorkspace);

  const response = yield call(fetchAllSnippetSetting, selectedWorkspace?.id);

  if (response.status === 200) {
    yield put({
      type: SUCCESS_FETCH_TASK_ALL_SNIPPET,
      message: response.message || 'Snippets fetched succesfully',
      data: response.result,
    });
    yield put({
      type: NEW_TASK_SNIPPET_SELECTED,
      snippetId: response.result[0].id,
      taskId: selectedTaskId,
    });
  } else {
    yield put({
      type: ERROR_FETCH_TASK_ALL_SNIPPET,
      message: 'Error fetching Snippets' || response.message,
    });
  }
}

export function* fetchAllTaskSnippetWatcher() {
  yield takeLatest(START_FETCH_TASK_ALL_SNIPPET, fetchAllSnippetWorker);
}

function* fetchSnippetDetailWorker(action) {
  const { snippetId, taskId } = action;
  const response = yield call(
    fetchSnippetDetailSetting,
    snippetId,
    taskId?.task_id
  );
  if (response.status === 200) {
    yield put({
      type: SUCCESS_NEW_TASK_SNIPPET_SELECTED,
      message: response.message || 'Snippet detail fetched succesfully',
      data: response.result,
    });
  } else {
    yield put({
      type: ERROR_NEW_TASK_SNIPPET_SELECTED,
      message: 'Error fetching snippet detail' || response.message,
    });
  }
}

export function* fetchTaskSnippetDetailWatcher() {
  yield takeLatest(NEW_TASK_SNIPPET_SELECTED, fetchSnippetDetailWorker);
}

function* mondayGetBoardsWorker() {
  const response = yield call(mondayGetboards);
  if (response.status) {
    // select the first folder
    const boards = response.data.map((board, index) =>
      index === 0
        ? {
            ...board,
            selected: true,
          }
        : board
    );
    yield put({
      type: SUCCESS_MONDAY_GET_BOARDS,
      boards: boards,
      message: 'Boards fetched successfully!' || response.message,
    });
    // fetch the task list
    if (boards.length > 0) {
      yield put({
        type: MONDAY_GET_GROUP_LIST,
        params: { board_id: boards[0].id },
      });
    }
  } else {
    console.error('Monday Get Folders failed!', response.message);
  }
}

export function* mondayGetBoardsWatcher() {
  yield takeLatest(MONDAY_GET_BOARDS, mondayGetBoardsWorker);
}

function* mondayGetGroupListWorker(action) {
  const { params } = action;
  const response = yield call(mondayGetGroupList, params);
  if (response.status) {
    // select the first list
    const lists = response.data.map((list, index) =>
      index === 0
        ? {
            ...list,
            selected: true,
          }
        : list
    );
    yield put({
      type: SUCCESS_MONDAY_GET_GROUP_LIST,
      group: lists,
      message: 'Group fetched successfully!' || response.message,
    });
  } else {
    console.error('Monday Get Group List failed!', response.message);
  }
}

export function* mondayGetGroupListWatcher() {
  yield takeLatest(MONDAY_GET_GROUP_LIST, mondayGetGroupListWorker);
}

function* mondayCreateTaskWorker(action) {
  const { params } = action;
  const response = yield call(mondayCreateTask, params);
  if (response.status) {
    yield put({
      type: SUCCESS_MONDAY_CREATE_TASK,
      message: 'Task deployed to Monday!' || response.message,
    });
    yield put({
      type: NEW_WEBSITE_SELECTED,
      websiteId: params.site_id,
    });
  } else {
    yield put({
      type: ERROR_MONDAY_CREATE_TASK,
      message: 'Task creation failed' || response.message,
    });
  }
}

export function* mondayCreateTaskWatcher() {
  yield takeLatest(MONDAY_CREATE_TASK, mondayCreateTaskWorker);
}

function* mondayDeployTasksWorker(action) {
  const { params } = action;
  const response = yield call(mondayDeployTasks, params);
  if (response.status) {
    yield put({
      type: SUCCESS_MONDAY_DEPLOY_TASKS,
      message: 'Monday tasks list deployed!' || response.message,
      siteId: params.site_id,
    });
    yield put({
      type: NEW_WEBSITE_SELECTED,
      websiteId: params.site_id,
    });
    yield put({
      type: NEW_BOARD_WEBSITE_SELECTED,
      websiteId: params.site_id,
    });
  } else {
    yield put({
      type: ERROR_MONDAY_DEPLOY_TASKS,
      message: 'Creation of tasks list failed' || response.message,
    });
  }
}

export function* mondayDeployTasksWatcher() {
  yield takeLatest(MONDAY_DEPLOY_TASKS, mondayDeployTasksWorker);
}

function* fetchAssignProjectsWorker(action) {
  const { searchKeyword } = action;
  let response;
  if (searchKeyword) {
    response = yield call(fetchAssignProjectSearch, searchKeyword);
  } else {
    response = yield call(fetchAssignProjects);
  }
  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_ASSIGN_PROJECT,
      message: response.message,
      data: response.data,
      moreAssignRecords: response.more_web_records,
    });
  } else {
    yield put({
      type: ERROR_FETCH_ASSIGN_PROJECT,
      message: 'Error fetching Assign projects' || response.message,
    });
  }
}

export function* fetchAssignProjectsWatcher() {
  yield takeLatest(START_FETCH_ASSIGN_PROJECT, fetchAssignProjectsWorker);
}

function* fetchNextAssignProjectsWorker(action) {
  const { offset } = action;
  const response = yield call(fetchNextAssignProjects, offset);

  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_NEXT_ASSIGN_PROJECT,
      message: response?.message,
      data: response.data,
      moreAssignRecords: response.more_web_records,
    });
  } else {
    yield put({
      type: ERROR_FETCH_NEXT_ASSIGN_PROJECT,
      message: 'Error fetching Assign projects' || response.message,
    });
  }
}

export function* fetchNextAssignProjectsWatcher() {
  yield takeLatest(
    START_FETCH_NEXT_ASSIGN_PROJECT,
    fetchNextAssignProjectsWorker
  );
}

// Delete inbox comment Files
function* deleteCommentFilesWorker(action) {
  const { params } = action;
  const response = yield call(deleteCommentFiles, params.data);
  if (response) {
    yield put({
      type: SUCCESS_DELETE_INBOX_COMMENT_FILE,
      data: response.result,
    });
  } else {
    yield put({
      type: ERROR_DELETE_INBOX_COMMENT_FILE,
      error: response.message,
    });
  }
}
export function* deleteCommentFilesWatcher() {
  yield takeLatest(DELETE_INBOX_COMMENT_FILE, deleteCommentFilesWorker);
}

function* clickupGetTasksListWorker() {
  const data = yield call(clickupTaskList);

  if (data.status) {
    yield put({
      type: SUCCESS_GET_SPACE_LIST,
      tasks: data.data,
    });
  } else {
    yield put({
      type: ERROR_GET_SPACE_LIST,
      message: data.message || 'Error occured, try again later!',
    });
  }
}

export function* clickupGetTasksListWatcher() {
  yield takeLatest(GET_SPACE_LIST, clickupGetTasksListWorker);
}
