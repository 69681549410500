export const NEW_WEBSITE_SELECTED = 'NEW_WEBSITE_SELECTED';
export const SUCCESS_TASKS_FETCHING = 'SUCCESS_TASKS_FETCHING';
export const ERROR_TASKS_FETCHING = 'ERROR_TASKS_FETCHING';
export const SUCCESS_FILTER_FETCHING = 'SUCCESS_FILTER_FETCHING';
export const ERROR_FILTER_FETCHING = 'ERROR_FILTER_FETCHING';
export const NEW_TASK_SELECTED = 'NEW_TASK_SELECTED';
export const SUCCESS_ATTRIBUTES_FETCHING = 'SUCCESS_ATTRIBUTES_FETCHING';
export const ERROR_ATTRIBUTES_FETCHING = 'ERROR_ATTRIBUTES_FETCHING';
export const UPDATE_TASK_ATTRIBUTES = 'UPDATE_TASK_ATTRIBUTES';
export const SUCCESS_UPDATE_TASK_ATTRIBUTES = 'SUCCESS_UPDATE_TASK_ATTRIBUTES';
export const ERROR_UPDATE_TASK_ATTRIBUTES = 'ERROR_UPDATE_TASK_ATTRIBUTES';
export const EMPTY_TASKS_MESSAGE = 'EMPTY_TASKS_MESSAGE';
export const START_DELETE_TASK = 'START_DELETE_TASK';
export const SUCCESS_DELETE_TASK = 'SUCCESS_DELETE_TASK';
export const ERROR_DELETE_TASK = 'ERROR_DELETE_TASK';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const MESSAGE_SENT = 'MESSAGE_SENT';
export const ERROR_IN_SENDING_MESSAGE = 'ERROR_IN_SENDING_MESSAGE';
export const INIT_FOR_NEXT_MESSAGE = 'INIT_FOR_NEXT_MESSAGE'; // once a message is sent we'll have to reset the message status flag
export const START_FILTERING_TASKS = 'START_FILTERING_TASKS';
export const APPLY_FILTER_AFTER_ATTRIBUTE_CHANGE =
  'APPLY_FILTER_AFTER_ATTRIBUTE_CHANGE';

export const FETCH_ALL_TASKS = 'FETCH_ALL_TASKS';
export const SUCCESS_FETCH_ALL_TASKS = 'SUCCESS_FETCH_ALL_TASKS';
export const ERROR_FETCH_ALL_TASKS = 'ERROR_FETCH_ALL_TASKS';
export const UPDATE_ESTIMATED_TIME = 'UPDATE_ESTIMATED_TIME';
export const SUCCESS_UPDATE_ESTIMATED_TIME = 'SUCCESS_UPDATE_ESTIMATED_TIME';
export const ERROR_UPDATE_ESTIMATED_TIME = 'ERROR_UPDATE_ESTIMATED_TIME';

export const CREATE_NEW_GENERAL_TASK = 'CREATE_NEW_GENERAL_TASK';
export const SUCCESS_FETCH_GENERAL_TASK_ATTRIBUTES =
  'SUCCESS_FETCH_GENERAL_TASK_ATTRIBUTES';
export const ERROR_FETCH_GENERAL_TASK_ATTRIBUTES =
  'ERROR_FETCH_GENERAL_TASK_ATTRIBUTES';
export const SET_ATTRIBUTES_FOR_NEW_TASK = 'SET_ATTRIBUTES_FOR_NEW_TASK';

export const SAVE_NEW_GENERAL_TASK = 'SAVE_NEW_GENERAL_TASK';
export const SUCCESS_SAVE_NEW_GENERAL_TASK = 'SUCCESS_SAVE_NEW_GENERAL_TASK';
export const ERROR_SAVE_NEW_GENERAL_TASK = 'ERROR_SAVE_NEW_GENERAL_TASK';

export const MESSAGE_SENT_NEW_TASK = 'MESSAGE_SENT_NEW_TASK';
export const ERROR_IN_SENDING_MESSAGE_NEW_TASK =
  'ERROR_IN_SENDING_MESSAGE_NEW_TASK';
export const NEW_GENERAL_TASK_SAVED_WITH_MESSAGE =
  'NEW_GENERAL_TASK_SAVED_WITH_MESSAGE';

export const REAL_TIME_COMMENT_RECEIVED = 'REAL_TIME_COMMENT_RECEIVED';
export const EMPTY_UNSEEN_COMMENTS_COUNT = 'EMPTY_UNSEEN_COMMENTS_COUNT';
export const UPDATE_USER_VIEWING_TASK = 'UPDATE_USER_VIEWING_TASK';
export const REMOVE_USER_IN_VIEWING_TASK_LIST =
  'REMOVE_USER_IN_VIEWING_TASK_LIST';
export const UPDATE_NEWLY_ARRIVED_USER = 'UPDATE_NEWLY_ARRIVED_USER';

export const LEAVING_TASKS_PAGE = 'LEAVING_TASKS_PAGE';

export const FETCH_NEXT_TASKS_LIST = 'FETCH_NEXT_TASKS_LIST';
export const SUCCESS_FETCH_NEXT_TASKS_LIST = 'SUCCESS_FETCH_NEXT_TASKS_LIST';
export const ERROR_FETCH_NEXT_TASKS_LIST = 'ERROR_FETCH_NEXT_TASKS_LIST';

export const START_SEARCH = 'START_SEARCH';
export const SUCCESS_SEARCH = 'SUCCESS_SEARCH';
export const ERROR_SEARCH = 'ERROR_SEARCH';

export const FETCH_TAGS_SUGGESTION = 'FETCH_TAGS_SUGGESTION';
export const SUCCESS_FETCH_TAGS_SUGGESTION = 'SUCCESS_FETCH_TAGS_SUGGESTION';
export const ERROR_FETCH_TAGS_SUGGESTION = 'ERROR_FETCH_TAGS_SUGGESTION';
export const EMPTY_SUGGESTION_LIST = 'EMPTY_SUGGESTION_LIST';

export const SET_MESSAGE_FOR_TASK_UPDATE = 'SET_MESSAGE_FOR_TASK_UPDATE';

export const GET_SEARCH_TASK_CATEGORY = 'GET_SEARCH_TASK_CATEGORY';
export const SUCCESS_SEARCH_TASK_CATEGORY = 'SUCCESS_SEARCH_TASK_CATEGORY';
export const ERROR_SEARCH_TASK_CATEGORY = 'ERROR_SEARCH_TASK_CATEGORY';

export const START_EXPORT_TASK = 'START_EXPORT_TASK';
export const SUCCESS_START_EXPORT_TASK = 'SUCCESS_START_EXPORT_TASK';
export const ERROR_START_EXPORT_TASK = 'ERROR_START_EXPORT_TASK';

export const START_FILE_UPLOAD = 'START_FILE_UPLOAD';
export const SUCCESS_FILE_UPLOAD = 'SUCCESS_FILE_UPLOAD';
export const ERROR_FILE_UPLOAD = 'ERROR_FILE_UPLOAD';

export const START_DELETE_S3_IMAGES = 'START_DELETE_S3_IMAGES';
export const SUCCESS_DELETE_S3_IMAGES = 'SUCCESS_DELETE_S3_IMAGES';
export const ERROR_DELETE_S3_IMAGES = 'ERROR_DELETE_S3_IMAGES';

export const START_SEND_IMAGE_TO_MEDIA = 'START_SEND_IMAGE_TO_MEDIA';
export const SUCCESS_SEND_IMAGE_TO_MEDIA = 'SUCCESS_SEND_IMAGE_TO_MEDIA';
export const ERROR_SEND_IMAGE_TO_MEDIA = 'ERROR_SEND_IMAGE_TO_MEDIA';
export const SEND_MESSAGE_WITHOUT_POST = 'SEND_MESSAGE_WITHOUT_POST';

export const SET_SITE_ORDER = 'SET_SITE_ORDER';

export const SET_CREATING_NEW_EMAIL_FLAG = 'SET_CREATING_NEW_EMAIL_FLAG';

export const UPDATE_INTERNAL_TASK = 'UPDATE_INTERNAL_TASK';
export const SUCCESS_UPDATE_INTERNAL_TASK = 'SUCCESS_UPDATE_INTERNAL_TASK';
export const ERROR_UPDATE_INTERNAL_TASK = 'ERROR_UPDATE_INTERNAL_TASK';

export const EDIT_COMMENT = 'EDIT_COMMENT';
export const SUCCESS_EDIT_COMMENT = 'SUCCESS_EDIT_COMMENT';
export const ERROR_EDIT_COMMENT = 'ERROR_EDIT_COMMENT';

export const DELETE_COMMENT = 'DELETE_COMMENT';
export const SUCCESS_DELETE_COMMENT = 'SUCCESS_DELETE_COMMENT';
export const ERROR_DELETE_COMMENT = 'ERROR_DELETE_COMMENT';

export const CLICKUP_GET_FOLDERS = 'CLICKUP_GET_FOLDERS';
export const SUCCESS_CLICKUP_GET_FOLDERS = 'SUCCESS_CLICKUP_GET_FOLDERS';

export const TEAMWORK_GET_PROJECTS = 'TEAMWORK_GET_PROJECTS';
export const SUCCESS_TEAMWORK_GET_PROJECTS = 'SUCCESS_TEAMWORK_GET_PROJECTS';
export const ERROR_TEAMWORK_GET_PROJECTS = 'ERROR_TEAMWORK_GET_PROJECTS';

export const ASANA_GET_PROJECTS = 'ASANA_GET_PROJECTS';
export const SUCCESS_ASANA_GET_PROJECTS = 'SUCCESS_ASANA_GET_PROJECTS';
export const ERROR_ASANA_GET_PROJECTS = 'ERROR_ASANA_GET_PROJECTS';

export const CLICKUP_GET_TASK_LIST = 'CLICKUP_GET_TASK_LIST';
export const SUCCESS_CLICKUP_GET_TASK_LIST = 'SUCCESS_CLICKUP_GET_TASK_LIST';

export const TEAMWORK_GET_TASK_LIST = 'TEAMWORK_GET_TASK_LIST';
export const SUCCESS_TEAMWORK_GET_TASK_LIST = 'SUCCESS_TEAMWORK_GET_TASK_LIST';
export const ERROR_TEAMWORK_GET_TASK_LIST = 'ERROR_TEAMWORK_GET_TASK_LIST';

export const TEAMWORK_GET_TASK = 'TEAMWORK_GET_TASK';
export const SUCCESS_TEAMWORK_GET_TASK = 'SUCCESS_TEAMWORK_GET_TASK';
export const ERROR_TEAMWORK_GET_TASK = 'ERROR_TEAMWORK_GET_TASK';

export const ASANA_GET_SECTION = 'ASANA_GET_SECTION';
export const SUCCESS_ASANA_GET_SECTION = 'SUCCESS_ASANA_GET_SECTION';
export const ERROR_ASANA_GET_SECTION = 'ERROR_ASANA_GET_SECTION';

export const CLICKUP_CREATE_TASK = 'CLICKUP_CREATE_TASK';
export const SUCCESS_CLICKUP_CREATE_TASK = 'SUCCESS_CLICKUP_CREATE_TASK';
export const ERROR_CLICKUP_CREATE_TASK = 'ERROR_CLICKUP_CREATE_TASK';

export const TEAMWORK_CREATE_TASK = 'TEAMWORK_CREATE_TASK';
export const SUCCESS_TEAMWORK_CREATE_TASK = 'SUCCESS_TEAMWORK_CREATE_TASK';
export const ERROR_TEAMWORK_CREATE_TASK = 'ERROR_TEAMWORK_CREATE_TASK';

export const ASANA_CREATE_TASK = 'ASANA_CREATE_TASK';
export const SUCCESS_ASANA_CREATE_TASK = 'SUCCESS_ASANA_CREATE_TASK';
export const ERROR_ASANA_CREATE_TASK = 'ERROR_ASANA_CREATE_TASK';

export const CLICKUP_DEPLOY_TASKS = 'CLICKUP_DEPLOY_TASKS';
export const SUCCESS_CLICKUP_DEPLOY_TASKS = 'SUCCESS_CLICKUP_DEPLOY_TASKS';
export const ERROR_CLICKUP_DEPLOY_TASKS = 'ERROR_CLICKUP_DEPLOY_TASKS';

export const ASANA_DEPLOY_TASKS = 'ASANA_DEPLOY_TASKS';
export const SUCCESS_ASANA_DEPLOY_TASKS = 'SUCCESS_ASANA_DEPLOY_TASKS';
export const ERROR_ASANA_DEPLOY_TASKS = 'ERROR_ASANA_DEPLOY_TASKS';

export const TEAMWORK_DEPLOY_TASKS = 'TEAMWORK_DEPLOY_TASKS';
export const SUCCESS_TEAMWORK_DEPLOY_TASKS = 'SUCCESS_TEAMWORK_DEPLOY_TASKS';
export const ERROR_TEAMWORK_DEPLOY_TASKS = 'ERROR_TEAMWORK_DEPLOY_TASKS';

export const UPDATE_TASK_LIST_SELECTED = 'UPDATE_TASK_LIST_SELECTED';

export const UPDATE_TEAMWORK_TASK_LIST_SELECTED =
  'UPDATE_TEAMWORK_TASK_LIST_SELECTED';

export const UPDATE_SECTION_LIST_SELECTED = 'UPDATE_SECTION_LIST_SELECTED';

export const UPDATE_JIRA_PROJECT_SELECTED = 'UPDATE_JIRA_PROJECT_SELECTED';

export const JIRA_GET_PROJECTS = 'JIRA_GET_PROJECTS';
export const SUCCESS_JIRA_GET_PROJECTS = 'SUCCESS_JIRA_GET_PROJECTS';
export const ERROR_JIRA_GET_PROJECTS = 'ERROR_JIRA_GET_PROJECTS';

export const JIRA_CREATE_TASK = 'JIRA_CREATE_TASK';
export const SUCCESS_JIRA_CREATE_TASK = 'SUCCESS_JIRA_CREATE_TASK';
export const ERROR_JIRA_CREATE_TASK = 'ERROR_JIRA_CREATE_TASK';

export const JIRA_DEPLOY_TASKS = 'JIRA_DEPLOY_TASKS';
export const SUCCESS_JIRA_DEPLOY_TASKS = 'SUCCESS_JIRA_DEPLOY_TASKS';
export const ERROR_JIRA_DEPLOY_TASKS = 'ERROR_JIRA_DEPLOY_TASKS';

export const BASECAMP_GET_PROJECTS = 'BASECAMP_GET_PROJECTS';
export const SUCCESS_BASECAMP_GET_PROJECTS = 'SUCCESS_BASECAMP_GET_PROJECTS';
export const ERROR_BASECAMP_GET_PROJECTS = 'ERROR_BASECAMP_GET_PROJECTS';

export const BASECAMP_GET_TODOLIST = 'BASECAMP_GET_TODOLIST';
export const SUCCESS_BASECAMP_GET_TODOLIST = 'SUCCESS_BASECAMP_GET_TODOLIST';
export const ERROR_BASECAMP_GET_TODOLIST = 'ERROR_BASECAMP_GET_TODOLIST';

export const UPDATE_BASECAMP_TODOLIST_SELECTED =
  'UPDATE_BASECAMP_TODOLIST_SELECTED';

export const BASECAMP_CREATE_TASK = 'BASECAMP_CREATE_TASK';
export const SUCCESS_BASECAMP_CREATE_TASK = 'SUCCESS_BASECAMP_CREATE_TASK';
export const ERROR_BASECAMP_CREATE_TASK = 'ERROR_BASECAMP_CREATE_TASK';

export const BASECAMP_DEPLOY_TASKS = 'BASECAMP_DEPLOY_TASKS';
export const SUCCESS_BASECAMP_DEPLOY_TASKS = 'SUCCESS_BASECAMP_DEPLOY_TASKS';
export const ERROR_BASECAMP_DEPLOY_TASKS = 'ERROR_BASECAMP_DEPLOY_TASKS';

export const TRELLO_GET_BOARDS = 'TRELLO_GET_BOARDS';
export const SUCCESS_TRELLO_GET_BOARDS = 'SUCCESS_TRELLO_GET_BOARDS';
export const ERROR_TRELLO_GET_BOARDS = 'ERROR_TRELLO_GET_BOARDS';

export const TRELLO_GET_TASK_LIST = 'TRELLO_GET_TASK_LIST';
export const SUCCESS_TRELLO_GET_TASK_LIST = 'SUCCESS_TRELLO_GET_TASK_LIST';
export const ERROR_TRELLO_GET_TASK_LIST = 'ERROR_TRELLO_GET_TASK_LIST';

export const UPDATE_TERLLO_TASK_SELECTED = 'UPDATE_TERLLO_TASK_SELECTED';

export const TRELLO_CREATE_TASK = 'TRELLO_CREATE_TASK';
export const SUCCESS_TRELLO_CREATE_TASK = 'SUCCESS_TRELLO_CREATE_TASK';
export const ERROR_TRELLO_CREATE_TASK = 'ERROR_TRELLO_CREATE_TASK';

export const TRELLO_DEPLOY_TASKS = 'TRELLO_DEPLOY_TASKS';
export const SUCCESS_TRELLO_DEPLOY_TASKS = 'SUCCESS_TRELLO_DEPLOY_TASKS';
export const ERROR_TRELLO_DEPLOY_TASKS = 'ERROR_TRELLO_DEPLOY_TASKS';

export const UPDATE_TEAMWORK_GET_TASK_SELECTED =
  'UPDATE_TEAMWORK_GET_TASK_SELECTED';

export const FETCHT_TRIGGERS = 'FETCHT_TRIGGERS';
export const SUCCESS_FETCHT_TRIGGERS = 'SUCCESS_FETCHT_TRIGGERS';
export const ERROR_FETCHT_TRIGGERS = 'ERROR_FETCHT_TRIGGERS';

export const FETCHT_AUTOMATION_ACTION = 'FETCHT_AUTOMATION_ACTION';
export const SUCCESS_FETCHT_AUTOMATION_ACTION =
  'SUCCESS_FETCHT_AUTOMATION_ACTION';
export const ERROR_FETCHT_AUTOMATION_ACTION = 'ERROR_FETCHT_AUTOMATION_ACTION';

export const FETCHT_CONDITION_ACTION = 'FETCHT_CONDITION_ACTION';
export const SUCCESS_FETCHT_CONDITION_ACTION =
  'SUCCESS_FETCHT_CONDITION_ACTION';
export const ERROR_FETCHT_CONDITION_ACTION = 'ERROR_FETCHT_CONDITION_ACTION';

export const CREATE_AUTOMATION = 'CREATE_AUTOMATION';
export const SUCCESS_CREATE_AUTOMATION = 'SUCCESS_CREATE_AUTOMATION';
export const ERROR_CREATE_AUTOMATION = 'ERROR_CREATE_AUTOMATION';

export const FETCH_AUTOMATION_WORKFLOW = 'FETCH_AUTOMATION_WORKFLOW';
export const SUCCESS_FETCH_AUTOMATION_WORKFLOW =
  'SUCCESS_FETCH_AUTOMATION_WORKFLOW';
export const ERROR_FETCH_AUTOMATION_WORKFLOW =
  'ERROR_FETCH_AUTOMATION_WORKFLOW';

export const FETCH_AUTOMATION_TAG = 'FETCH_AUTOMATION_TAG';
export const SUCCESS_FETCH_AUTOMATION_TAG = 'SUCCESS_FETCH_AUTOMATION_TAG';
export const ERROR_FETCH_AUTOMATION_TAG = 'ERROR_FETCH_AUTOMATION_TAG';

export const CHANGE_AUTOMATION_STATUS = 'CHANGE_AUTOMATION_STATUS';
export const SUCCESS_CHANGE_AUTOMATION_STATUS =
  'SUCCESS_CHANGE_AUTOMATION_STATUS';
export const ERROR_CHANGE_AUTOMATION_STATUS = 'ERROR_CHANGE_AUTOMATION_STATUS';

export const DELETE_WORKFLOW = 'DELETE_WORKFLOW';
export const SUCCESS_DELETE_WORKFLOW = 'SUCCESS_DELETE_WORKFLOW';
export const ERROR_DELETE_WORKFLOW = 'ERROR_DELETE_WORKFLOW';

export const EMAIL_FROM_DASHBOARD = 'EMAIL_FROM_DASHBOARD';

export const START_FETCH_AUTOMATION_DETAIL = 'START_FETCH_AUTOMATION_DETAIL';
export const SUCCESS_FETCH_AUTOMATION_DETAIL =
  'SUCCESS_FETCH_AUTOMATION_DETAIL';
export const ERROR_FETCH_AUTOMATION_DETAIL = 'ERROR_FETCH_AUTOMATION_DETAIL';

export const EDIT_AUTOMATION = 'EDIT_AUTOMATION';
export const SUCCESS_EDIT_AUTOMATION = 'SUCCESS_EDIT_AUTOMATION';
export const ERROR_EDIT_AUTOMATION = 'ERROR_EDIT_AUTOMATION';

export const CREATE_AUTOMATION_TEMPLATE = 'CREATE_AUTOMATION_TEMPLATE';
export const SUCCESS_CREATE_AUTOMATION_TEMPLATE =
  'SUCCESS_CREATE_AUTOMATION_TEMPLATE';
export const ERROR_CREATE_AUTOMATION_TEMPLATE =
  'ERROR_CREATE_AUTOMATION_TEMPLATE';

export const FETCH_AUTOMATION_TEMPLATE = 'FETCH_AUTOMATION_TEMPLATE';
export const SUCCESS_FETCH_AUTOMATION_TEMPLATE =
  'SUCCESS_FETCH_AUTOMATION_TEMPLATE';
export const ERROR_FETCH_AUTOMATION_TEMPLATE =
  'ERROR_FETCH_AUTOMATION_TEMPLATE';

export const START_FETCH_AUTOMATION_TEMPLATE_DETAIL =
  'START_FETCH_AUTOMATION_TEMPLATE_DETAIL';
export const SUCCESS_FETCH_AUTOMATION_TEMPLATE_DETAIL =
  'SUCCESS_FETCH_AUTOMATION_TEMPLATE_DETAIL';
export const ERROR_FETCH_AUTOMATION_TEMPLATE_DETAIL =
  'ERROR_FETCH_AUTOMATION_TEMPLATE_DETAIL';

export const SET_MINIMIZE_TIMER_COLLECTION = 'SET_MINIMIZE_TIMER_COLLECTION';

export const START_FETCH_TASK_ALL_SNIPPET = 'START_FETCH_TASK_ALL_SNIPPET';
export const SUCCESS_FETCH_TASK_ALL_SNIPPET = 'SUCCESS_FETCH_TASK_ALL_SNIPPET';
export const ERROR_FETCH_TASK_ALL_SNIPPET = 'ERROR_FETCH_TASK_ALL_SNIPPET';

export const NEW_TASK_SNIPPET_SELECTED = 'NEW_TASK_SNIPPET_SELECTED';
export const SUCCESS_NEW_TASK_SNIPPET_SELECTED =
  'SUCCESS_NEW_TASK_SNIPPET_SELECTED';
export const ERROR_NEW_TASK_SNIPPET_SELECTED =
  'ERROR_NEW_TASK_SNIPPET_SELECTED';

export const OPEN_INBOX_EMAIL_SNIPPET = 'OPEN_INBOX_EMAIL_SNIPPET';

export const MONDAY_GET_BOARDS = 'MONDAY_GET_BOARDS';
export const SUCCESS_MONDAY_GET_BOARDS = 'SUCCESS_MONDAY_GET_BOARDS';
export const ERROR_MONDAY_GET_BOARDS = 'ERROR_MONDAY_GET_BOARDS';

export const MONDAY_GET_GROUP_LIST = 'MONDAY_GET_GROUP_LIST';
export const SUCCESS_MONDAY_GET_GROUP_LIST = 'SUCCESS_MONDAY_GET_GROUP_LIST';
export const ERROR_MONDAY_GET_GROUP_LIST = 'ERROR_MONDAY_GET_GROUP_LIST';

export const UPDATE_GROUP_TASK_SELECTED = 'UPDATE_GROUP_TASK_SELECTED';

export const MONDAY_CREATE_TASK = 'MONDAY_CREATE_TASK';
export const SUCCESS_MONDAY_CREATE_TASK = 'SUCCESS_MONDAY_CREATE_TASK';
export const ERROR_MONDAY_CREATE_TASK = 'ERROR_MONDAY_CREATE_TASK';

export const MONDAY_DEPLOY_TASKS = 'MONDAY_DEPLOY_TASKS';
export const SUCCESS_MONDAY_DEPLOY_TASKS = 'SUCCESS_MONDAY_DEPLOY_TASKS';
export const ERROR_MONDAY_DEPLOY_TASKS = 'ERROR_MONDAY_DEPLOY_TASKS';

export const START_FETCH_ASSIGN_PROJECT = 'START_FETCH_ASSIGN_PROJECT';
export const SUCCESS_FETCH_ASSIGN_PROJECT = 'SUCCESS_FETCH_ASSIGN_PROJECT';
export const ERROR_FETCH_ASSIGN_PROJECT = 'ERROR_FETCH_ASSIGN_PROJECT';

export const START_FETCH_NEXT_ASSIGN_PROJECT =
  'START_FETCH_NEXT_ASSIGN_PROJECT';
export const SUCCESS_FETCH_NEXT_ASSIGN_PROJECT =
  'SUCCESS_FETCH_NEXT_ASSIGN_PROJECT';
export const ERROR_FETCH_NEXT_ASSIGN_PROJECT =
  'ERROR_FETCH_NEXT_ASSIGN_PROJECT';

export const DELETE_INBOX_COMMENT_FILE = 'DELETE_INBOX_COMMENT_FILE';
export const SUCCESS_DELETE_INBOX_COMMENT_FILE =
  'SUCCESS_DELETE_INBOX_COMMENT_FILE';
export const ERROR_DELETE_INBOX_COMMENT_FILE =
  'ERROR_DELETE_INBOX_COMMENT_FILE';

export const GET_SPACE_LIST = 'GET_SPACE_LIST';
export const SUCCESS_GET_SPACE_LIST = 'SUCCESS_GET_SPACE_LIST';
export const ERROR_GET_SPACE_LIST = 'ERROR_GET_SPACE_LIST';
