import { Slot } from '@radix-ui/react-slot';
import { cn } from '@utils/cn';
import { cva } from 'class-variance-authority';
import React from 'react';

const buttonVariants = cva(
  'inline-flex items-center duration-300 justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 relative',
  {
    variants: {
      variant: {
        primary:
          'bg-primary text-primary-foreground hover:brightness-90 border border-primary',
        secondary:
          'bg-white border border-colour-secondary-border text-secondary-foreground hover:bg-tertiary/[0.06]',
        destructive:
          'bg-destructive text-destructive-foreground text-white hover:bg-destructive/90',
        outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        ghost:
          'hover:bg-accent hover:text-accent-foreground hover:bg-tertiary/[0.06]',
        link: 'px-0 relative after:absolute font-base text-tertiary after:bg-tertiary after:bottom-2 after:h-[1px] after:w-full after:origin-bottom-right after:scale-x-0 hover:after:origin-bottom-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300',
      },
      size: {
        sm: 'h-9 rounded-lg px-3 text-sm gap-x-1 leading-tight',
        md: 'h-10 rounded-lg px-4 py-2 text-base gap-x-2',
        lg: 'h-11 rounded-lg px-8 py-3 text-lg gap-x-3',
        xl: 'h-12 rounded-lg px-12 py-4 text-xl gap-x-4',
        icon: 'h-9 w-9 p-2 rounded-lg overflow-hidden text-secondary min-w-9',
        link: 'px-0 py-2',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  }
);

const Button = React.forwardRef(function Button(
  {
    className,
    variant,
    size,
    leftIcon,
    rightIcon,
    loading,
    asChild = false,
    ...props
  },
  ref
) {
  const Comp = asChild ? Slot : 'button';
  return (
    <Comp
      className={cn(
        buttonVariants({ variant, size, className }),
        `${loading && 'text-opacity-0'}`
      )}
      ref={ref}
      {...props}
      disabled={loading || props.disabled}
    >
      {!!loading === true && (
        <span className="absolute inset-0 flex items-center justify-center">
          <span className="loader h-4 w-4 animate-rotate-360 rounded-full border-2 border-primary-foreground border-t-transparent duration-700 repeat-infinite" />
        </span>
      )}
      {leftIcon && leftIcon}
      {props.children}
      {rightIcon && rightIcon}
    </Comp>
  );
});

Button.displayName = 'Button';

export { Button, buttonVariants };
