import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
// Components
import PageLoading from './components/PageLoading';
// Styling
import 'react-notifications/lib/notifications.css';
// Utils
import { usePostHog } from 'posthog-js/react';
import { productFruits } from 'product-fruits';
import { sendMessageToSiteControl } from './utils/chromeExtension';
// Actions
import { ADD_NEW_SITE, GET_ITEM_DETAILS } from './actions/collaborate';
import { SETTINGS_PAGE_MOUNTED } from './actions/global_settings';
import {
  ACTIVATE_TRIAL,
  CLOSE_TRIAL_WIZARD,
  ELIGIBLE_TO_INACTIVE,
  EMPTY_PLANS_MESSAGE,
  FETCH_EXISTING_SITE_URL,
  HANDLE_PLANS_DIALOG,
  INVOICE_DOWNLOAD_URL,
  OPEN_CHECKOUT,
  POST_TRAIL_DETAIL,
  REMOVE_HOSTED_PAGE_DATA,
  SAVE_URL_SEARCH_HISTORY,
  SET_MAINTENANCE_FLAG,
  SET_TOKEN_IN_REDUX,
  START_PROFILE_FETCH,
  SUBSCRIPTION_DETAILS,
  UPDATE_REDIRECTING_TO_ATARIM,
  UPDATE_TRAIL_DETAIL,
  UPGRADE_PLANS,
} from './actions/user';
import { FETCH_WORKSPACES } from './actions/workspace';
import { router } from './browserRouter';

function Main() {
  const { workspaceData } = useSelector((state) => state.workspace);
  const { userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const posthogClient = usePostHog();

  // TODO: check the function of this with Hassan and move to a separate hook
  // Redirect when the user is already logged in, otherwise the redirect would happen in Home component
  // useEffect(() => {
  //   const getSearch = location.search;

  //   if (getSearch) {
  //     let redirect_val = new URLSearchParams(getSearch).get('atarim_redirect');
  //     let mainSiteURL = import.meta.env.VITE_APP_MAIN_WEBSITE_URL;
  //     if (
  //       redirect_val === `${mainSiteURL}/account/` ||
  //       redirect_val === `${mainSiteURL}/activate/` ||
  //       redirect_val === `${mainSiteURL}/upgrade/` ||
  //       redirect_val === `https://academy.atarim.io/courses/` ||
  //       redirect_val === `${mainSiteURL}/setting-up-dashboard/` ||
  //       redirect_val === `${mainSiteURL}/setting-up-websites/` ||
  //       redirect_val === `${mainSiteURL}/checkout/` ||
  //       redirect_val === `${mainSiteURL}/`
  //     ) {
  //       const atarim_redirect = new URLSearchParams(getSearch).get(
  //         'atarim_redirect'
  //       );

  //       localStorage.setItem('atarim_redirect', atarim_redirect);

  //       if (atarim_redirect !== null) {
  //         if (token) {
  //           cookies.setItem('parameter_1', token);
  //           cookies.setItem('parameter_2', userData?.parameter_2);
  //           cookies.setItem('parameter_3', userData?.parameter_3);
  //           cookies.setItem('parameter_4', userData?.parameter_4);

  //           // set cookie if checkout
  //           if (redirect_val === `${mainSiteURL}/checkout/`) {
  //             let expires;
  //             expires = 'Sat, 13 Nov 2050 16:00:49 GMT';
  //             document.cookie =
  //               'atarim_checkout_redirect=hellofromhassan' +
  //               expires +
  //               '; domain=atarim.io';
  //           }

  //           window.location.replace(
  //             `${atarim_redirect}?parameter_1=${token}&parameter_2=${userData.parameter_2}&parameter_3=${userData.parameter_3}&parameter_4=${userData.parameter_4}`
  //           );
  //         }
  //       }
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [token]);

  // TODO: move this logic to a separate hook
  useEffect(() => {
    if (userData) {
      const shouldFetchWorkspaces =
        !workspaceData || workspaceData.length === 0;
      if (shouldFetchWorkspaces) {
        dispatch({ type: FETCH_WORKSPACES });
      }

      window.gsc('params', {
        plan: !userData?.plan?.user_permissions && 'false',
      });
      const userInfo = {
        username: userData.email, // REQUIRED - any unique user identifier
        email: userData.email,
        firstname: userData.first_name,
        lastname: userData.last_name,
        // signUpAt: userData.,
        role: userData.role,
        props: { plan: userData.plan },
      };
      productFruits.init('02hLgOXsohviuqyd', 'en', userInfo, {
        disableLocationChangeDetection: true,
      });

      posthogClient.identify(userData.id + '_' + userData.email, {
        email: userData.email,
        plan: userData?.plan?.title,
        username: userData.username,
        environment: import.meta.env.VITE_APP_BASE_URL,
      });
    } else {
      posthogClient.reset();
    }
    sendMessageToSiteControl();
  }, [userData]);

  return <RouterProvider router={router} fallbackElement={<PageLoading />} />;
}

const mapStateToProps = (state) => ({
  userData: state.user.userData,
  isUnderMaintenance: state.user.isUnderMaintenance,
  redirecting: state.user.redirecting,
  licenseActivationLoading: state.user.licenseActivationLoading,
  pageRedirect: state.user.pageRedirect,
  pricingPlans: state.user.pricingPlans,
  upgradePlansLoading: state.user.upgradePlansLoading,
  isPlansDialogOpened: state.user.isPlansDialogOpened,
  hostedPagedata: state.user.hostedPagedata,
  startingCheckout: state.user.startingCheckout,
  plansSuccessMessage: state.user.plansSuccessMessage,
  plansErrorMessage: state.user.plansErrorMessage,
  userTrialData: state.user.userTrialData,
  trialStatus: state.user.trialStatus,
  existingWebsites: state.user.existingWebsites,
  searchText: state.tasks.searchText,
  taskExportData: state.tasks.taskExportData,
  isTaskExportLoading: state.tasks.isTaskExportLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setToken: (token) => dispatch({ type: SET_TOKEN_IN_REDUX, token }),
  fetchUserInfo: () => dispatch({ type: START_PROFILE_FETCH }),
  setMaintenanceFlag: (flag) =>
    dispatch({ type: SET_MAINTENANCE_FLAG, isUnderMaintenance: flag }),
  fetchGlobalSettings: () => dispatch({ type: SETTINGS_PAGE_MOUNTED }),
  // TODO: Remove this from the app for useSearchParams => saveUrlSearchHistory
  saveUrlSearchHistory: (url) =>
    dispatch({ type: SAVE_URL_SEARCH_HISTORY, url }),
  getItemDetails: (id, reload, targetGraphic, pageId) =>
    dispatch({ type: GET_ITEM_DETAILS, id, reload, targetGraphic, pageId }),
  updateRedirecting: (redirecting) =>
    dispatch({ type: UPDATE_REDIRECTING_TO_ATARIM, redirecting }),
  upgradePlans: (params) => dispatch({ type: UPGRADE_PLANS, params }),
  openCheckout: (params) => dispatch({ type: OPEN_CHECKOUT, params }),
  openPortalSession: () => dispatch({ type: INVOICE_DOWNLOAD_URL }),
  handlePlansDialog: (params) =>
    dispatch({ type: HANDLE_PLANS_DIALOG, params }),
  removeHostedPageData: () => dispatch({ type: REMOVE_HOSTED_PAGE_DATA }),
  getSubscriptionDetails: (id, rawId, users) =>
    dispatch({ type: SUBSCRIPTION_DETAILS, id, rawId, users }),
  emptyPlansMessage: () => dispatch({ type: EMPTY_PLANS_MESSAGE }),
  updateTrialValue: (key, value) =>
    dispatch({ type: UPDATE_TRAIL_DETAIL, key, value }),
  postTrialValue: (params) => dispatch({ type: POST_TRAIL_DETAIL, params }),
  activateTrial: (params) => dispatch({ type: ACTIVATE_TRIAL, params }),
  eligibleToInactiveTrial: (params) =>
    dispatch({ type: ELIGIBLE_TO_INACTIVE, params }),
  addNewSite: (params) => dispatch({ type: ADD_NEW_SITE, params }),
  fetchExistingSiteUrl: (params) =>
    dispatch({ type: FETCH_EXISTING_SITE_URL, params }),
  closeTrialWizard: (params) => dispatch({ type: CLOSE_TRIAL_WIZARD, params }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
