import { GoogleOAuthProvider } from '@react-oauth/google';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
// import * as Sentry from "@sentry/react";

// Sentry.init({
//     dsn: import.meta.env.VITE_APP_SENTRY_DSN,
//     integrations: [
//         Sentry.browserTracingIntegration(),
//         Sentry.replayIntegration({
//             maskAllText: false,
//             blockAllMedia: false,
//         }),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

if (typeof window !== 'undefined') {
  posthog.init(import.meta.env.VITE_APP_POSTHOG_PROJECT_API_KEY, {
    api_host: 'https://app.posthog.com',
  });
}
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <PostHogProvider client={posthog}>
    <GoogleOAuthProvider clientId={import.meta.env.VITE_APP_GOOGLE_CLIENT_ID}>
      <App />
    </GoogleOAuthProvider>
  </PostHogProvider>
);
