// Actions
import {
  DELETE_BOARD_COMMENT_FILE,
  DELETE_TASK,
  ERROR_DELETE_BOARD_COMMENT_FILE,
  FETCH_ALL_BOARD_TASKS,
  FETCH_BOARD_COLUMN,
  RESET_LOAD_MORE,
  SUCCESS_DELETE_BOARD_COMMENT_FILE,
  // SUCCESS_UPDATE_TASK_ATTRIBUTES as SUCCESS_UPDATE_TASK_ATTRIBUTES_BOARD,
  SUCCESS_DELETE_TASK as SUCCESS_DELETE_TASK_BOARD,
  SUCCESS_EMAIL_COMPLETE_TASKS,
  SUCCESS_EMAIL_CRITICAL_TASKS,
  SUCCESS_EMAIL_HIGH_TASKS,
  SUCCESS_EMAIL_IN_PROGRESS_TASKS,
  SUCCESS_EMAIL_LOW_TASKS,
  SUCCESS_EMAIL_MEDIUM_TASKS,
  SUCCESS_EMAIL_OPEN_TASKS,
  SUCCESS_EMAIL_PENDING_REVIEW_TASKS,
  SUCCESS_NEXT_EMAIL_COMPLETE_TASKS,
  SUCCESS_NEXT_EMAIL_CRITICAL_TASKS,
  SUCCESS_NEXT_EMAIL_HIGH_TASKS,
  SUCCESS_NEXT_EMAIL_IN_PROGRESS_TASKS,
  SUCCESS_NEXT_EMAIL_LOW_TASKS,
  SUCCESS_NEXT_EMAIL_MEDIUM_TASKS,
  SUCCESS_NEXT_EMAIL_OPEN_TASKS,
  SUCCESS_NEXT_EMAIL_PENDING_REVIEW_TASKS,
  SUCCESS_UPDATE_BOARD_TASK,
  UPDATE_BOARD_TASK,
  UPDATE_BOARD_TASK_ATTRIBUTES,
  UPDATE_TASK_ATTRIBUTES as UPDATE_TASK_ATTRIBUTES_BOARD,
} from '../actions/board';
import { REDIRECT_TO_TASKS } from '../actions/dashboard';
import {
  ERROR_COMPOSE_EMAIL,
  ERROR_EMAIL_TASKS,
  ERROR_EMAIL_TASK_TO_SITE,
  ERROR_REPLY_EMAIL,
  NEW_EMAIL_SELECTED,
  SET_EMAIL_SELECTED,
  SET_PLACEHOLDER_MAILBOX_SELECTED,
  SET_SELECTED_MAILBOX_ID,
  SUCCESS_COMPOSE_EMAIL,
  SUCCESS_EMAIL_TASKS,
  SUCCESS_EMAIL_TASK_TO_SITE,
  SUCCESS_FETCH_NEXT_EMAIL_TASKS,
  SUCCESS_REPLY_EMAIL,
} from '../actions/email';
import {
  APPLY_FILTER_AFTER_ATTRIBUTE_CHANGE,
  ASANA_CREATE_TASK,
  ASANA_GET_SECTION,
  BASECAMP_CREATE_TASK,
  BASECAMP_GET_TODOLIST,
  CHANGE_AUTOMATION_STATUS,
  CLICKUP_CREATE_TASK,
  CLICKUP_GET_TASK_LIST,
  CREATE_AUTOMATION,
  CREATE_AUTOMATION_TEMPLATE,
  CREATE_NEW_GENERAL_TASK,
  DELETE_INBOX_COMMENT_FILE,
  EDIT_AUTOMATION,
  EDIT_COMMENT,
  EMAIL_FROM_DASHBOARD,
  EMPTY_SUGGESTION_LIST,
  EMPTY_TASKS_MESSAGE,
  EMPTY_UNSEEN_COMMENTS_COUNT,
  ERROR_ASANA_CREATE_TASK,
  ERROR_ASANA_DEPLOY_TASKS,
  ERROR_ATTRIBUTES_FETCHING,
  ERROR_BASECAMP_CREATE_TASK,
  ERROR_BASECAMP_DEPLOY_TASKS,
  ERROR_CHANGE_AUTOMATION_STATUS,
  ERROR_CLICKUP_CREATE_TASK,
  ERROR_CLICKUP_DEPLOY_TASKS,
  ERROR_CREATE_AUTOMATION,
  ERROR_CREATE_AUTOMATION_TEMPLATE,
  ERROR_DELETE_INBOX_COMMENT_FILE,
  ERROR_DELETE_S3_IMAGES,
  ERROR_DELETE_TASK,
  ERROR_DELETE_WORKFLOW,
  ERROR_FETCH_ALL_TASKS,
  ERROR_FETCH_AUTOMATION_TEMPLATE,
  ERROR_FETCH_AUTOMATION_WORKFLOW,
  ERROR_FETCH_GENERAL_TASK_ATTRIBUTES,
  ERROR_FETCH_NEXT_TASKS_LIST,
  ERROR_FETCH_TAGS_SUGGESTION,
  ERROR_FETCH_TASK_ALL_SNIPPET,
  ERROR_FILE_UPLOAD,
  ERROR_FILTER_FETCHING,
  ERROR_IN_SENDING_MESSAGE,
  ERROR_IN_SENDING_MESSAGE_NEW_TASK,
  ERROR_JIRA_CREATE_TASK,
  ERROR_JIRA_DEPLOY_TASKS,
  ERROR_MONDAY_CREATE_TASK,
  ERROR_MONDAY_DEPLOY_TASKS,
  ERROR_NEW_TASK_SNIPPET_SELECTED,
  ERROR_SAVE_NEW_GENERAL_TASK,
  ERROR_SEARCH,
  ERROR_SEARCH_TASK_CATEGORY,
  ERROR_SEND_IMAGE_TO_MEDIA,
  ERROR_START_EXPORT_TASK,
  ERROR_TASKS_FETCHING,
  ERROR_TEAMWORK_CREATE_TASK,
  ERROR_TEAMWORK_DEPLOY_TASKS,
  ERROR_TRELLO_CREATE_TASK,
  ERROR_TRELLO_DEPLOY_TASKS,
  ERROR_UPDATE_ESTIMATED_TIME,
  ERROR_UPDATE_INTERNAL_TASK,
  ERROR_UPDATE_TASK_ATTRIBUTES,
  FETCH_ALL_TASKS,
  FETCH_AUTOMATION_TEMPLATE,
  FETCH_NEXT_TASKS_LIST,
  FETCH_TAGS_SUGGESTION,
  GET_SEARCH_TASK_CATEGORY,
  INIT_FOR_NEXT_MESSAGE,
  JIRA_CREATE_TASK,
  LEAVING_TASKS_PAGE,
  MESSAGE_SENT,
  MESSAGE_SENT_NEW_TASK,
  MONDAY_CREATE_TASK,
  MONDAY_GET_GROUP_LIST,
  NEW_TASK_SELECTED,
  NEW_TASK_SNIPPET_SELECTED,
  NEW_WEBSITE_SELECTED,
  OPEN_INBOX_EMAIL_SNIPPET,
  REAL_TIME_COMMENT_RECEIVED,
  REMOVE_USER_IN_VIEWING_TASK_LIST,
  SAVE_NEW_GENERAL_TASK,
  SET_ATTRIBUTES_FOR_NEW_TASK,
  SET_CREATING_NEW_EMAIL_FLAG,
  SET_MESSAGE_FOR_TASK_UPDATE,
  SET_MINIMIZE_TIMER_COLLECTION,
  SET_SITE_ORDER,
  START_EXPORT_TASK,
  START_FETCH_ASSIGN_PROJECT,
  START_FETCH_AUTOMATION_DETAIL,
  START_FETCH_AUTOMATION_TEMPLATE_DETAIL,
  START_FETCH_NEXT_ASSIGN_PROJECT,
  START_FETCH_TASK_ALL_SNIPPET,
  START_FILE_UPLOAD,
  START_FILTERING_TASKS,
  START_SEARCH,
  SUCCESS_ASANA_CREATE_TASK,
  SUCCESS_ASANA_DEPLOY_TASKS,
  SUCCESS_ASANA_GET_PROJECTS,
  SUCCESS_ASANA_GET_SECTION,
  SUCCESS_ATTRIBUTES_FETCHING,
  SUCCESS_BASECAMP_CREATE_TASK,
  SUCCESS_BASECAMP_DEPLOY_TASKS,
  SUCCESS_BASECAMP_GET_PROJECTS,
  SUCCESS_BASECAMP_GET_TODOLIST,
  SUCCESS_CHANGE_AUTOMATION_STATUS,
  SUCCESS_CLICKUP_CREATE_TASK,
  SUCCESS_CLICKUP_DEPLOY_TASKS,
  SUCCESS_CLICKUP_GET_FOLDERS,
  SUCCESS_CLICKUP_GET_TASK_LIST,
  SUCCESS_CREATE_AUTOMATION,
  SUCCESS_CREATE_AUTOMATION_TEMPLATE,
  SUCCESS_DELETE_COMMENT,
  SUCCESS_DELETE_INBOX_COMMENT_FILE,
  SUCCESS_DELETE_S3_IMAGES,
  SUCCESS_DELETE_TASK,
  SUCCESS_DELETE_WORKFLOW,
  SUCCESS_EDIT_COMMENT,
  SUCCESS_FETCHT_AUTOMATION_ACTION,
  SUCCESS_FETCHT_CONDITION_ACTION,
  SUCCESS_FETCHT_TRIGGERS,
  SUCCESS_FETCH_ALL_TASKS,
  SUCCESS_FETCH_ASSIGN_PROJECT,
  SUCCESS_FETCH_AUTOMATION_DETAIL,
  SUCCESS_FETCH_AUTOMATION_TAG,
  SUCCESS_FETCH_AUTOMATION_TEMPLATE,
  SUCCESS_FETCH_AUTOMATION_TEMPLATE_DETAIL,
  SUCCESS_FETCH_AUTOMATION_WORKFLOW,
  SUCCESS_FETCH_GENERAL_TASK_ATTRIBUTES,
  SUCCESS_FETCH_NEXT_ASSIGN_PROJECT,
  SUCCESS_FETCH_NEXT_TASKS_LIST,
  SUCCESS_FETCH_TAGS_SUGGESTION,
  SUCCESS_FETCH_TASK_ALL_SNIPPET,
  SUCCESS_FILE_UPLOAD,
  SUCCESS_FILTER_FETCHING,
  SUCCESS_GET_SPACE_LIST,
  SUCCESS_JIRA_CREATE_TASK,
  SUCCESS_JIRA_DEPLOY_TASKS,
  SUCCESS_JIRA_GET_PROJECTS,
  SUCCESS_MONDAY_CREATE_TASK,
  SUCCESS_MONDAY_DEPLOY_TASKS,
  SUCCESS_MONDAY_GET_BOARDS,
  SUCCESS_MONDAY_GET_GROUP_LIST,
  SUCCESS_NEW_TASK_SNIPPET_SELECTED,
  SUCCESS_SAVE_NEW_GENERAL_TASK,
  SUCCESS_SEARCH,
  SUCCESS_SEARCH_TASK_CATEGORY,
  // START_SEND_IMAGE_TO_MEDIA,
  SUCCESS_SEND_IMAGE_TO_MEDIA,
  SUCCESS_START_EXPORT_TASK,
  SUCCESS_TASKS_FETCHING,
  SUCCESS_TEAMWORK_CREATE_TASK,
  SUCCESS_TEAMWORK_DEPLOY_TASKS,
  SUCCESS_TEAMWORK_GET_PROJECTS,
  SUCCESS_TEAMWORK_GET_TASK,
  SUCCESS_TEAMWORK_GET_TASK_LIST,
  SUCCESS_TRELLO_CREATE_TASK,
  SUCCESS_TRELLO_DEPLOY_TASKS,
  SUCCESS_TRELLO_GET_BOARDS,
  SUCCESS_TRELLO_GET_TASK_LIST,
  SUCCESS_UPDATE_ESTIMATED_TIME,
  SUCCESS_UPDATE_INTERNAL_TASK,
  SUCCESS_UPDATE_TASK_ATTRIBUTES,
  TEAMWORK_CREATE_TASK,
  TEAMWORK_GET_TASK_LIST,
  TRELLO_CREATE_TASK,
  TRELLO_GET_TASK_LIST,
  UPDATE_BASECAMP_TODOLIST_SELECTED,
  UPDATE_GROUP_TASK_SELECTED,
  UPDATE_JIRA_PROJECT_SELECTED,
  UPDATE_SECTION_LIST_SELECTED,
  UPDATE_TASK_ATTRIBUTES,
  UPDATE_TASK_LIST_SELECTED,
  UPDATE_TEAMWORK_GET_TASK_SELECTED,
  UPDATE_TEAMWORK_TASK_LIST_SELECTED,
  UPDATE_TERLLO_TASK_SELECTED,
  UPDATE_USER_VIEWING_TASK,
} from '../actions/tasks';
import { LOGOUT } from '../actions/user';
import {
  bulkUpdateAddTags,
  bulkUpdateReorderCards,
  deleteCards,
  reorderCards,
} from '../utils/board_utils';

const messageStatus = {
  INIT: -1,
  SENT: 1,
  ERROR: 0,
};

const initState = {
  listIsLoading: false,
  filterIsLoading: false,
  attributeIsLoading: false,
  taskList: [],
  selectedTask: '',
  filters: {},
  attributes: {},
  newTask: false,
  isSavingNewTask: false,
  messageStatus: messageStatus.INIT,
  isAllTasks: false,
  successUpdateMessage: '',
  errorUpdateMessage: '',
  errorListMessage: '',
  errorFilterMessage: '',
  errorAttributeMessage: '',
  emptyUpdateMessage: '',
  newTaskSelectedStatus: '',
  newTaskSelectedUrgency: '',
  newTaskSelectedUsers: [],
  newCommentsOfOtherTasks: {}, // real time comments of tasks which are not the selected one, just stores count
  usersWatchingList: [], // real time users watching list
  leftTasksPage: false, // used as a flag to call /user/left-tasks-page
  isFetchingNextTaskList: false,
  errorFetchingNextTaskList: '',
  moreRecords: false,
  searchText: '',
  errorSearchMessage: '',
  errorNewTaskCreation: '',
  successNewTaskCreation: '',
  tagsSuggestionLoading: false,
  tagsSuggestionList: [],
  tagsSuggestionErrorMessage: '',
  totalRecords: 0,
  taskUpdateMessage: '',
  isTaskSearchLoading: false,
  searchTaskCategory: [],
  searchErrorMessage: '',
  isTaskExportLoading: false,
  taskExportData: [],
  taskExportErrorMessage: '',
  siteOrder: '',
  emails: [],
  emailSelected: false,
  creatingNewEmail: false,
  selectedMailboxId: '',
  placeholderMailboxSelected: false,
  clickupFolders: [],
  clickupTaskList: [],
  asanaProjects: [],
  asanaSection: [],
  jiraProjects: [],
  teamWorkProjects: [],
  teamWorkTaskList: [],
  teamWorkTask: [],
  isPushingTask: false,
  isPushingAsana: false,
  isPushingJira: false,
  isPushingTeamwork: false,
  emailMoreRecords: false,
  basecampProjects: [],
  basecampTodo: [],
  isPushingBasecamp: false,
  trelloBoards: [],
  trelloTasks: [],
  isPushingTrelloTask: false,
  automationTriggers: [],
  automationActions: [],
  automationCondition: [],
  isCreatingAutomation: false,
  automationWorkflow: [],
  automationTags: [],
  isFailed: false,
  isFetchingAutomationDetail: false,
  automationDetail: [],
  automationTemplates: [],
  automationTemplateDetail: {},
  isFetchingAutomationTemplate: false,
  minimizeTimerCollection: false,
  cannedSnippetList: [],
  isFetchingSnippet: false,
  errorGetSnippet: '',
  successGetSnippet: '',
  selectedSnippet: '',
  snippetIsLoading: false,
  errorSnippetMessage: '',
  snippetDetails: {},
  openInboxSnippetModal: false,
  mondayBoards: [],
  mondayGroup: [],
  isLoadingAssign: false,
  assignProject: [],
  moreAssignRecords: false,
  openEmailTaskList: [],
  inProgressEmailTaskList: [],
  pendingReviewEmailTaskList: [],
  completeEmailTaskList: [],
  emailTaskUrgencyLow: [],
  emailTaskUrgencyMedium: [],
  emailTaskUrgencyHigh: [],
  emailTaskUrgencyCritical: [],
  moreEmailLowRecords: false,
  moreEmailMediumRecords: false,
  moreEmailHighRecords: false,
  moreEmailCriticalRecords: false,
  moreEmailOpenRecords: false,
  moreEmailInProgressRecords: false,
  moreEmailPendingReviewRecords: false,
  moreEmailCompleteRecords: false,
  emailOpenListIsLoading: false,
  emailInProgressListIsLoading: false,
  emailPendingReviewListIsLoading: false,
  emailCompleteListIsLoading: false,
  emailLowListIsLoading: false,
  emailMediumListIsLoading: false,
  emailHighListIsLoading: false,
  emailCriticalListIsLoading: false,
  openEmailTaskListCount: 0,
  inProgressEmailTaskListCount: 0,
  pendingReviewEmailTaskListCount: 0,
  completeEmailTaskListCount: 0,
  emailTaskUrgencyLowCount: 0,
  emailTaskUrgencyMediumCount: 0,
  emailTaskUrgencyHighCount: 0,
  emailTaskUrgencyCriticalCount: 0,
  isUploadingFile: false,
  clickupSpaceList: [],
};

const tasks = (previousState = initState, action) => {
  switch (action.type) {
    case NEW_WEBSITE_SELECTED:
      return {
        ...initState, // initState is used because it is as
        // good as changing everything to their default value when new website is selected
        listIsLoading: true,
        filterIsLoading: true,
        attributeIsLoading: true,
        newCommentsOfOtherTasks: previousState.newCommentsOfOtherTasks,
        usersWatchingList: previousState.usersWatchingList,
        siteOrder: previousState.siteOrder,
        clickupFolders: previousState.clickupFolders,
        clickupTaskList: previousState.clickupTaskList,
        jiraProjects: previousState.jiraProjects,
        asanaProjects: previousState.asanaProjects,
        asanaSection: previousState.asanaSection,
        teamWorkProjects: previousState.teamWorkProjects,
        teamWorkTaskList: previousState.teamWorkTaskList,
        basecampProjects: previousState.basecampProjects,
        basecampTodo: previousState.basecampTodo,
        trelloBoards: previousState.trelloBoards,
        trelloTasks: previousState.trelloTasks,
        minimizeTimerCollection: previousState.minimizeTimerCollection,
        mondayBoards: previousState.mondayBoards,
        mondayGroup: previousState.mondayGroup,
      };
    case START_SEARCH:
      return {
        ...initState,
        listIsLoading: true,
        attributeIsLoading: true,
        filterIsLoading: true,
        isAllTasks: true,
        searchText: action.searchText,
      };
    case ERROR_SEARCH:
      return {
        ...previousState,
        listIsLoading: false,
        attributeIsLoading: false,
        errorSearchMessage: action.message,
      };
    case SUCCESS_SEARCH:
      return {
        ...previousState,
        listIsLoading: false,
        attributeIsLoading: action.tasks.length > 0, // if there are tasks then show loading else do not
        taskList: action.tasks,
        moreRecords: action.moreRecords,
        totalRecords: action.totalRecords,
      };
    case FETCH_ALL_TASKS:
      return {
        ...initState,
        listIsLoading: true,
        attributeIsLoading: true,
        isAllTasks: true,
        filterIsLoading: true,
      };
    case SUCCESS_FETCH_ALL_TASKS:
      return {
        ...previousState,
        listIsLoading: false,
        taskList: action.allTasks,
        attributeIsLoading: action.allTasks.length > 0,
        errorListMessage: '',
        moreRecords: action.moreRecords,
        totalRecords: action.totalRecords,
      };
    case ERROR_FETCH_ALL_TASKS:
      return {
        ...previousState,
        errorListMessage: action.message,
        listIsLoading: false,
        attributeIsLoading: false,
      };
    case START_FILTERING_TASKS:
      const { changedFilterKey, changedFilterValue } = action.filterObj;

      return {
        ...previousState,
        listIsLoading: true,
        attributeIsLoading: true,
        taskList: [],
        selectedTask: '',
        filters: {
          ...previousState.filters,
          [changedFilterKey]: previousState.filters[changedFilterKey].map(
            (t) =>
              t.value === changedFilterValue.value
                ? { ...t, checked: changedFilterValue.checked }
                : t
          ),
        },
      };
    case APPLY_FILTER_AFTER_ATTRIBUTE_CHANGE:
      return {
        ...previousState,
        listIsLoading: true,
        attributeIsLoading: true,
        taskList: [],
        selectedTask: '',
      };
    case SUCCESS_TASKS_FETCHING:
      return {
        ...previousState,
        errorListMessage: '',
        listIsLoading: false,
        taskList: action.tasks,
        // attributes: {},
        attributeIsLoading: false,
        isSavingNewTask: false, // this is done to remove loader properly
        errorUpdateMessage: '', // when general task is successfully SAVED
        moreRecords: action.moreRecords,
        totalRecords: action.totalRecords,
        emailSelected: false,
      };
    case ERROR_TASKS_FETCHING:
      return {
        ...previousState,
        errorListMessage: action.message,
        listIsLoading: false,
        taskList: [],
        attributeIsLoading: false,
      };
    case SUCCESS_FILTER_FETCHING:
      return {
        ...previousState,
        filters: action.filters,
        errorFilterMessage: '',
        filterIsLoading: false,
      };
    case ERROR_FILTER_FETCHING:
      return {
        ...previousState,
        filters: {},
        filterIsLoading: false,
        errorFilterMessage: action.message,
      };
    case NEW_TASK_SELECTED:
      return {
        ...previousState,
        selectedTask: action.taskId,
        attributeIsLoading: true,
        errorAttributeMessage: '',
        newTask: false,
        attributes: {},
        creatingNewEmail: false,
        isFailed: false,
      };
    case REDIRECT_TO_TASKS:
      return {
        ...previousState,
        // selectedWebsite: action.websiteId,
        // selectedFeedWebsite: action.websiteId,
        selectedTask: action.task_id,
      };
    case CREATE_NEW_GENERAL_TASK:
      return {
        ...previousState,
        selectedTask: '',
        newTask: true,
        attributes: {},
        newTaskSelectedStatus: '',
        newTaskSelectedUrgency: '',
        newTaskSelectedUsers: [],
      };
    case FETCH_BOARD_COLUMN: {
      const mapStateToLoading = {
        open: 'emailOpenListIsLoading',
        'in-progress': 'emailInProgressListIsLoading',
        'pending-review': 'emailPendingReviewListIsLoading',
        complete: 'emailCompleteListIsLoading',
        low: 'emailLowListIsLoading',
        medium: 'emailMediumListIsLoading',
        high: 'emailHighListIsLoading',
        critical: 'emailCriticalListIsLoading',
      };
      const mapStateToMoreRecords = {
        open: 'moreEmailOpenRecords',
        'in-progress': 'moreEmailInProgressRecords',
        'pending-review': 'moreEmailPendingReviewRecords',
        complete: 'moreEmailCompleteRecords',
        low: 'moreEmailLowRecords',
        medium: 'moreEmailMediumRecords',
        high: 'moreEmailHighRecords',
        critical: 'moreEmailCriticalRecords',
      };
      const loadingStates = {};
      const moreRecordStates = {};
      // If we are getting new data for a column, make the loading state true.
      for (let datum of action.params.data) {
        const curLoadingState = mapStateToLoading[datum.column_type];
        const curMoreRecordState = mapStateToMoreRecords[datum.column_type];
        if (curLoadingState) loadingStates[curLoadingState] = true;
        if (curMoreRecordState) moreRecordStates[curMoreRecordState] = true;
      }

      return {
        ...previousState,
        ...loadingStates,
        ...moreRecordStates,
      };
    }
    case SUCCESS_ATTRIBUTES_FETCHING:
      return {
        ...previousState,
        attributeIsLoading: false,
        errorAttributeMessage: '',
        attributes: action.attributes,
      };
    case ERROR_ATTRIBUTES_FETCHING:
      return {
        ...previousState,
        attributeIsLoading: false,
        errorAttributeMessage: action.message,
        attributes: [],
      };
    case UPDATE_TASK_ATTRIBUTES_BOARD:
      const { movedCards, updateTaskAttributeParams } = action;

      if (action.emailSelected.email_selected) {
        if (action.keyOfAttributeUpdated == 'task_tags') {
          previousState = bulkUpdateAddTags(
            previousState,
            movedCards,
            updateTaskAttributeParams.value,
            action.emailSelected.email_selected
          );
        } else {
          previousState = bulkUpdateReorderCards(
            previousState,
            movedCards,
            updateTaskAttributeParams.method,
            updateTaskAttributeParams.value,
            action.emailSelected.email_selected
          );
        }
      }

      return {
        ...previousState,
        emailOpenListIsLoading: false,
        emailInProgressListIsLoading: false,
        emailPendingReviewListIsLoading: false,
        emailCompleteListIsLoading: false,
        emailLowListIsLoading: false,
        emailMediumListIsLoading: false,
        emailHighListIsLoading: false,
        emailCriticalListIsLoading: false,
      };

    case UPDATE_TASK_ATTRIBUTES:
      let newAtrribute = previousState.attributes;
      if (action.keyOfAttributeUpdated === 'notify_wordpress_users') {
        newAtrribute = {
          ...previousState.attributes,
          notify_wordpress_users:
            previousState.attributes.notify_wordpress_users.map((each) =>
              each.value === action.updateTaskAttributeParams.value.id
                ? {
                    ...each,
                    checked: action.updateTaskAttributeParams.value.checked,
                  }
                : each
            ),
        };
      } else if (action.keyOfAttributeUpdated === 'notify_wp_feedback_users') {
        newAtrribute = {
          ...previousState.attributes,
          notify_wp_feedback_users:
            previousState.attributes.notify_wp_feedback_users.map((each) =>
              each.value === action.updateTaskAttributeParams.value.id
                ? {
                    ...each,
                    checked: action.updateTaskAttributeParams.value.checked,
                  }
                : each
            ),
        };
      } else if (action.keyOfAttributeUpdated === 'task_status') {
        newAtrribute = {
          ...previousState.attributes,
          task_status: previousState.attributes.task_status.map((each) =>
            each.value === action.updateTaskAttributeParams?.value
              ? { ...each, selected: true }
              : each
          ),
        };
      } else if (action.keyOfAttributeUpdated === 'task_urgency') {
        newAtrribute = {
          ...previousState.attributes,
          task_urgency: previousState.attributes.task_urgency.map((each) =>
            each.value === action.updateTaskAttributeParams?.value
              ? { ...each, selected: true }
              : each
          ),
        };
      }
      return {
        ...previousState,
        attributes: newAtrribute,
      };
    case UPDATE_BOARD_TASK_ATTRIBUTES:
      let newBoardAtrribute = previousState.attributes;
      if (action.keyOfAttributeUpdated === 'notify_wordpress_users') {
        newBoardAtrribute = {
          ...previousState.attributes,
          notify_wordpress_users:
            previousState.attributes.notify_wordpress_users.map((each) =>
              each.value === action.updateTaskAttributeParams.value.id
                ? {
                    ...each,
                    checked: action.updateTaskAttributeParams.value.checked,
                  }
                : each
            ),
        };
      } else if (action.keyOfAttributeUpdated === 'notify_wp_feedback_users') {
        newBoardAtrribute = {
          ...previousState.attributes,
          notify_wp_feedback_users:
            previousState.attributes.notify_wp_feedback_users.map((each) =>
              each.value === action.updateTaskAttributeParams.value.id
                ? {
                    ...each,
                    checked: action.updateTaskAttributeParams.value.checked,
                  }
                : each
            ),
        };
      }
      return {
        ...previousState,
        attributes: newBoardAtrribute,
      };

    case UPDATE_BOARD_TASK: {
      // action.task_event.task_event.sortable('cancel');
      if (action.task_event.email_selected) {
        previousState = reorderCards(
          previousState,
          action.movedCard,
          action.task_event.email_selected
        );
      }
      return {
        ...previousState,
        updatingTask: true,
      };
    }

    case SUCCESS_UPDATE_BOARD_TASK:
      // action.task_event.task_event.sortable('cancel');
      if (action.data && action.emailSelected) {
        return {
          ...previousState,
          emails: action.data,
          successUpdateMessage: 'Task updated!',
        };
      } else {
        return {
          ...previousState,
        };
      }
    case SUCCESS_UPDATE_TASK_ATTRIBUTES:
      const attributeUpdated = action.params;
      const data = previousState.emails.map((t) =>
        t.task_id === attributeUpdated.task_id
          ? {
              ...t,
              is_complete:
                attributeUpdated.method === 'status'
                  ? attributeUpdated.value === 'complete'
                  : t.is_complete,
              task_status:
                attributeUpdated.method === 'status'
                  ? attributeUpdated.value
                  : t.task_status,
              task_priority:
                attributeUpdated.method === 'priority'
                  ? attributeUpdated.value
                  : t.task_priority,
              task_title:
                attributeUpdated.method === 'task_title'
                  ? attributeUpdated.value
                  : t.task_title,
              task_tags:
                attributeUpdated.method === 'task_tags'
                  ? attributeUpdated.is_delete
                    ? t.task_tags.filter((t) => t.id !== attributeUpdated.id)
                    : t.task_tags.concat({
                        value: attributeUpdated.value,
                        id: attributeUpdated.newTagId,
                      })
                  : t.task_tags,
            }
          : t
      );

      const dataTask = previousState.taskList.map((t) =>
        t.task_id === attributeUpdated.task_id
          ? {
              ...t,
              is_complete:
                attributeUpdated.method === 'status'
                  ? attributeUpdated.value === 'complete'
                  : t.is_complete,
              task_status:
                attributeUpdated.method === 'status'
                  ? attributeUpdated.value
                  : t.task_status,
              task_priority:
                attributeUpdated.method === 'priority'
                  ? attributeUpdated.value
                  : t.task_priority,
              task_title:
                attributeUpdated.method === 'task_title'
                  ? attributeUpdated.value
                  : t.task_title,
              task_tags:
                attributeUpdated.method === 'task_tags'
                  ? attributeUpdated.is_delete
                    ? t.task_tags.filter((t) => t.id !== attributeUpdated.id)
                    : t.task_tags.concat({
                        value: attributeUpdated.value,
                        id: attributeUpdated.newTagId,
                      })
                  : t.task_tags,
            }
          : t
      );
      if (action.emailSelected) {
        return {
          ...previousState,
          emails: data,
          successUpdateMessage: 'Task Attribute Updated',
        };
      } else {
        return {
          ...previousState,
          taskList: dataTask,
          successUpdateMessage: 'Task Attribute Updated',
        };
      }
    case ERROR_UPDATE_TASK_ATTRIBUTES:
      return {
        ...previousState,
        successUpdateMessage: '',
        errorUpdateMessage: action.message,
      };
    case EMPTY_TASKS_MESSAGE:
      return {
        ...previousState,
        [action.keyName]: '',
        taskUpdateMessage: '',
      };
    case DELETE_TASK:
      if (action.isEmail)
        previousState = deleteCards(previousState, action.deletedCards, true);
      return {
        ...previousState,
        isDeletingTask: true,
        successDeleteTaskMessage: '',
        errorDeleteTaskMessage: '',
      };
    case ERROR_DELETE_TASK:
      return {
        ...previousState,
        errorUpdateMessage: action.message,
        successUpdateMessage: '',
      };
    case SUCCESS_DELETE_TASK:
      return {
        ...previousState,
        errorUpdateMessage: '',
        successUpdateMessage: action.message,
        selectedTask: '',
      };
    // used in board page to automatically close the popup when successfully deleted
    case SUCCESS_DELETE_TASK_BOARD:
      return {
        ...previousState,
        attributes: {},
      };
    case MESSAGE_SENT:
      return {
        ...previousState,
        messageStatus: messageStatus.SENT,
        attributes: {
          ...previousState.attributes,
          comments: previousState.attributes.comments.concat([action.comment]),
        },
      };
    case ERROR_IN_SENDING_MESSAGE:
      return {
        ...previousState,
        messageStatus: messageStatus.ERROR,
        errorAttributeMessage: action.message,
      };
    case MESSAGE_SENT_NEW_TASK:
      return {
        ...previousState,
        messageStatus: messageStatus.SENT,
        successNewTaskCreation: action.message,
      };
    case ERROR_IN_SENDING_MESSAGE_NEW_TASK:
      return {
        ...previousState,
        messageStatus: messageStatus.ERROR,
        errorNewTaskCreation: action.message,
      };
    case INIT_FOR_NEXT_MESSAGE:
      return {
        ...previousState,
        messageStatus: messageStatus.INIT,
      };
    case SUCCESS_UPDATE_ESTIMATED_TIME:
      return {
        ...previousState,
        successUpdateMessage: action.message,
        errorUpdateMessage: '',
        attributes: {
          ...previousState.attributes,
          estimated_time: action.newTime,
        },
      };
    case ERROR_UPDATE_ESTIMATED_TIME:
      return {
        ...previousState,
        successUpdateMessage: '',
        errorUpdateMessage: action.message,
      };
    case SUCCESS_FETCH_GENERAL_TASK_ATTRIBUTES:
      return {
        ...previousState,
        attributes: action.attributes,
        newTaskSelectedStatus: action.attributes.task_status[0].value,
        newTaskSelectedUrgency: action.attributes.task_urgency[0].value,
        newTaskSelectedUsers: [],
        attributeIsLoading: false,
      };
    case ERROR_FETCH_GENERAL_TASK_ATTRIBUTES:
      return {
        ...previousState,
        successUpdateMessage: '',
        errorUpdateMessage: action.message,
        attributeIsLoading: false,
      };
    case SET_ATTRIBUTES_FOR_NEW_TASK:
      return {
        ...previousState,
        [action.name]: action.value,
      };
    case SAVE_NEW_GENERAL_TASK:
      return {
        ...previousState,
        isSavingNewTask: true,
        errorUpdateMessage: '',
        isFailed: false,
      };
    case SUCCESS_SAVE_NEW_GENERAL_TASK:
      return {
        ...previousState,
        isSavingNewTask: false,
        errorUpdateMessage: '',
        isFailed: false,
      };
    case ERROR_SAVE_NEW_GENERAL_TASK:
      return {
        ...previousState,
        isSavingNewTask: false,
        errorUpdateMessage: action.message,
        isFailed: true,
      };
    case REAL_TIME_COMMENT_RECEIVED:
      if (action.comment.task_id === previousState.selectedTask) {
        return {
          ...previousState,
          attributes: {
            ...previousState.attributes,
            comments: previousState.attributes.comments.concat([
              action.comment,
            ]),
          },
        };
      } else {
        // check if task_id already exists in newCommentsOfOtherTasks
        // if true, increment by one
        // else add new record
        const temp = { ...previousState.newCommentsOfOtherTasks }; // creating copy of previousState.newCommentsOfOtherTasks
        temp[action.comment.task_id] = temp[action.comment.task_id]
          ? temp[action.comment.task_id] + 1
          : 1;
        return {
          ...previousState,
          newCommentsOfOtherTasks: temp,
        };
      }
    case EMPTY_UNSEEN_COMMENTS_COUNT:
      const temp = { ...previousState.newCommentsOfOtherTasks };
      delete temp[action.taskId];
      return {
        ...previousState,
        newCommentsOfOtherTasks: temp,
      };
    case UPDATE_USER_VIEWING_TASK:
      const { user } = action;
      const copyUserList = previousState.usersWatchingList.slice();
      const index = copyUserList.findIndex((u) => u.user_id === user.user_id);
      if (index === -1) {
        copyUserList.push(user);
      } else {
        copyUserList[index].task_id = user.task_id;
      }
      return {
        ...previousState,
        usersWatchingList: copyUserList,
      };
    case REMOVE_USER_IN_VIEWING_TASK_LIST:
      const tempUserList = previousState.usersWatchingList.slice();
      const tIndex = tempUserList.findIndex((u) => u.user_id === action.userId);
      if (tIndex !== -1) {
        tempUserList.splice(tIndex, 1);
      }
      return {
        ...previousState,
        usersWatchingList: tempUserList,
      };
    case FETCH_NEXT_TASKS_LIST:
      return {
        ...previousState,
        isFetchingNextTaskList: true,
        errorFetchingNextTaskList: '',
      };
    case SUCCESS_FETCH_NEXT_TASKS_LIST:
      const newTaskList = previousState.taskList.concat(action.newTasks);
      return {
        ...previousState,
        isFetchingNextTaskList: false,
        errorFetchingNextTaskList: '',
        taskList: newTaskList.filter(
          (n, i) => newTaskList.findIndex((c) => n.task_id === c.task_id) === i
        ),
        moreRecords: action.moreRecords, // this is done to protect from calling next page API infinitely
        // which happens when another logged in user creates a new task
      };
    case ERROR_FETCH_NEXT_TASKS_LIST:
      return {
        ...previousState,
        isFetchingNextTaskList: false,
        errorFetchingNextTaskList: action.message,
      };
    case FETCH_TAGS_SUGGESTION:
      return {
        ...previousState,
        tagsSuggestionLoading: true,
        tagsSuggestionErrorMessage: '',
        tagsSuggestionList: [],
      };
    case SUCCESS_FETCH_TAGS_SUGGESTION:
      return {
        ...previousState,
        tagsSuggestionLoading: false,
        tagsSuggestionList: action.suggestions,
        tagsSuggestionErrorMessage: '',
      };
    case ERROR_FETCH_TAGS_SUGGESTION:
      return {
        ...previousState,
        tagsSuggestionErrorMessage: action.message,
        tagsSuggestionLoading: false,
        tagsSuggestionList: [],
      };
    case EMPTY_SUGGESTION_LIST:
      return {
        ...previousState,
        tagsSuggestionList: [],
        tagsSuggestionLoading: false,
        tagsSuggestionErrorMessage: '',
      };
    case SET_MESSAGE_FOR_TASK_UPDATE:
      return {
        ...previousState,
        taskUpdateMessage: 'The task has been completed',
      };
    case LOGOUT:
      return initState;
    case LEAVING_TASKS_PAGE:
      return initState;
    case GET_SEARCH_TASK_CATEGORY:
      return {
        ...previousState,
        isTaskSearchLoading: true,
      };
    case SUCCESS_SEARCH_TASK_CATEGORY:
      return {
        ...previousState,
        isTaskSearchLoading: false,
        searchTaskCategory: action.data,
      };
    case ERROR_SEARCH_TASK_CATEGORY:
      return {
        ...previousState,
        isTaskSearchLoading: false,
        searchErrorMessage: action.message,
      };
    case START_EXPORT_TASK:
      return {
        ...previousState,
        isTaskExportLoading: true,
      };
    case SUCCESS_START_EXPORT_TASK:
      return {
        ...previousState,
        isTaskExportLoading: false,
        taskExportData: [action.taskData, action.type_of_export],
      };
    case ERROR_START_EXPORT_TASK:
      return {
        ...previousState,
        isTaskExportLoading: false,
        taskExportErrorMessage: action.message,
      };
    case SUCCESS_DELETE_S3_IMAGES:
      return {
        ...previousState,
        errorUpdateMessage: '',
        successUpdateMessage: action.message,
        // selectedTask: ""
      };
    case ERROR_DELETE_S3_IMAGES:
      return {
        ...previousState,
        errorUpdateMessage: action.message,
        successUpdateMessage: '',
      };
    case SUCCESS_SEND_IMAGE_TO_MEDIA:
      return {
        ...previousState,
        errorUpdateMessage: '',
        successUpdateMessage: action.message,
      };
    case ERROR_SEND_IMAGE_TO_MEDIA:
      return {
        ...previousState,
        errorUpdateMessage: action.message,
        successUpdateMessage: '',
      };
    case SET_SITE_ORDER:
      return {
        ...previousState,
        siteOrder: action.value,
      };
    case START_FILE_UPLOAD:
      return {
        ...previousState,
        isUploadingFile: true,
        errorUpdateMessage: '',
      };
    case SUCCESS_FILE_UPLOAD:
      return {
        ...previousState,
        attributes: {
          ...previousState.attributes,
          comments: previousState.attributes.comments.concat([action.comment]),
        },
        isUploadingFile: false,
        errorUpdateMessage: '',
      };
    case ERROR_FILE_UPLOAD:
      return {
        ...previousState,
        errorUpdateMessage: action.message,
        isUploadingFile: false,
      };
    case NEW_EMAIL_SELECTED:
      return {
        ...initState,
        listIsLoading: true,
        attributeIsLoading: true,
        emailSelected: true,
        clickupFolders: previousState.clickupFolders,
        clickupTaskList: previousState.clickupTaskList,
        emailOpenListIsLoading: true,
        emailInProgressListIsLoading: true,
        emailPendingReviewListIsLoading: true,
        emailCompleteListIsLoading: true,
        emailLowListIsLoading: true,
        emailMediumListIsLoading: true,
        emailHighListIsLoading: true,
        emailCriticalListIsLoading: true,
      };
    case SUCCESS_EMAIL_TASKS:
      return {
        ...previousState,
        emails: action.payload,
        emailSelected: true,
        listIsLoading: false,
        attributeIsLoading: false,
        emailMoreRecords: action.moreRecords,
      };
    case ERROR_EMAIL_TASKS:
      return {
        ...previousState,
        listIsLoading: false,
      };
    case SET_CREATING_NEW_EMAIL_FLAG:
      return {
        ...previousState,
        creatingNewEmail: action.bool,
        selectedTask: '',
      };
    case SUCCESS_REPLY_EMAIL:
      return {
        ...previousState,
        successUpdateMessage: action.message,
        errorUpdateMessage: '',
      };
    case ERROR_REPLY_EMAIL:
      return {
        ...previousState,
        successUpdateMessage: '',
        errorUpdateMessage: action.message,
      };
    case SUCCESS_COMPOSE_EMAIL:
      return {
        ...previousState,
        successUpdateMessage: action.message,
        errorUpdateMessage: '',
      };
    case ERROR_COMPOSE_EMAIL:
      return {
        ...previousState,
        successUpdateMessage: '',
        errorUpdateMessage: action.message,
      };
    case CLICKUP_CREATE_TASK:
      return {
        ...previousState,
        isPushingTask: true,
      };
    case ASANA_CREATE_TASK:
      return {
        ...previousState,
        isPushingAsana: true,
      };
    case JIRA_CREATE_TASK:
      return {
        ...previousState,
        isPushingJira: true,
      };
    case TEAMWORK_CREATE_TASK:
      return {
        ...previousState,
        isPushingTeamwork: true,
      };
    case SUCCESS_MONDAY_DEPLOY_TASKS:
    case SUCCESS_TRELLO_DEPLOY_TASKS:
    case SUCCESS_TEAMWORK_DEPLOY_TASKS:
    case SUCCESS_BASECAMP_DEPLOY_TASKS:
    case SUCCESS_JIRA_DEPLOY_TASKS:
    case SUCCESS_ASANA_DEPLOY_TASKS:
    case SUCCESS_CLICKUP_DEPLOY_TASKS:
    case SUCCESS_CLICKUP_CREATE_TASK:
    case SUCCESS_EMAIL_TASK_TO_SITE:
      return {
        ...previousState,
        successUpdateMessage: action.message,
        errorUpdateMessage: '',
        isPushingTask: false,
      };
    case SUCCESS_ASANA_CREATE_TASK:
      return {
        ...previousState,
        successUpdateMessage: action.message,
        errorUpdateMessage: '',
        isPushingAsana: false,
      };
    case SUCCESS_JIRA_CREATE_TASK:
      return {
        ...previousState,
        successUpdateMessage: action.message,
        errorUpdateMessage: '',
        isPushingJira: false,
      };
    case SUCCESS_TEAMWORK_CREATE_TASK:
      return {
        ...previousState,
        successUpdateMessage: action.message,
        errorUpdateMessage: '',
        isPushingTeamwork: false,
      };
    case ERROR_MONDAY_DEPLOY_TASKS:
    case ERROR_TRELLO_DEPLOY_TASKS:
    case ERROR_TEAMWORK_DEPLOY_TASKS:
    case ERROR_BASECAMP_DEPLOY_TASKS:
    case ERROR_JIRA_DEPLOY_TASKS:
    case ERROR_ASANA_DEPLOY_TASKS:
    case ERROR_CLICKUP_DEPLOY_TASKS:
    case ERROR_CLICKUP_CREATE_TASK:
    case ERROR_UPDATE_INTERNAL_TASK:
    case ERROR_EMAIL_TASK_TO_SITE:
    case ERROR_ASANA_CREATE_TASK:
      return {
        ...previousState,
        successUpdateMessage: '',
        isPushingTask: false,
        errorUpdateMessage: action.message,
        isPushingAsana: false,
      };
    case ERROR_JIRA_CREATE_TASK:
      return {
        ...previousState,
        successUpdateMessage: '',
        errorUpdateMessage: action.message,
        isPushingJira: false,
      };
    case ERROR_TEAMWORK_CREATE_TASK:
      return {
        ...previousState,
        successUpdateMessage: '',
        errorUpdateMessage: action.message,
        isPushingTeamwork: false,
      };
    case SET_SELECTED_MAILBOX_ID:
      return {
        ...previousState,
        selectedMailboxId: action.id,
      };
    case SET_EMAIL_SELECTED:
      return {
        ...previousState,
        emailSelected: action.value,
      };
    case FETCH_ALL_BOARD_TASKS:
      return {
        ...previousState,
        emailSelected: false,
      };
    case SET_PLACEHOLDER_MAILBOX_SELECTED:
      return {
        ...previousState,
        placeholderMailboxSelected: action.value,
      };
    case SUCCESS_UPDATE_INTERNAL_TASK:
      const tasks = previousState.taskList.map((t) =>
        t.task_id === action.task_id
          ? {
              ...t,
              is_internal: action.is_internal,
            }
          : t
      );

      const emails = previousState.emails.map((e) =>
        e.task_id === action.task_id
          ? {
              ...e,
              is_internal: action.is_internal,
            }
          : e
      );

      return {
        ...previousState,
        attributes: {
          ...previousState.attributes,
          is_internal: action.is_internal,
        },
        taskList: tasks,
        emails: emails,
      };
    case SUCCESS_CLICKUP_GET_FOLDERS:
      return {
        ...previousState,
        clickupFolders: action.folders,
      };
    case SUCCESS_CLICKUP_GET_TASK_LIST:
      return {
        ...previousState,
        clickupTaskList: action.taskList,
      };
    case SUCCESS_TEAMWORK_GET_PROJECTS:
      return {
        ...previousState,
        teamWorkProjects: action.projects,
      };
    case SUCCESS_TEAMWORK_GET_TASK_LIST:
      return {
        ...previousState,
        teamWorkTaskList: action.taskList,
      };

    case SUCCESS_TEAMWORK_GET_TASK:
      return {
        ...previousState,
        teamWorkTask: action.teamWorkTask,
      };
    case SUCCESS_DELETE_COMMENT:
      const deleteComments = previousState.attributes.comments.map((t) =>
        t.id === action.id
          ? {
              ...t,
              comments: 'Comment has been deleted',
              is_deleted: action.is_deleted,
              updated_at: action.updated_at,
            }
          : t
      );
      return {
        ...previousState,
        attributes: {
          ...previousState.attributes,
          comments: deleteComments,
        },
      };
    case EDIT_COMMENT:
      const commentsObj = previousState.attributes.comments.map((t) =>
        t.id === action.params.comment_id
          ? {
              ...t,
              comments: action.params.comment_content,
              is_edited: true,
            }
          : t
      );
      return {
        ...previousState,
        attributes: {
          ...previousState.attributes,
          comments: commentsObj,
        },
      };
    case SUCCESS_EDIT_COMMENT:
      const comments = previousState.attributes.comments.map((t) =>
        t.id === action.id
          ? {
              ...t,
              comments: action.comment_content,
              is_edited: action.is_edited,
            }
          : t
      );
      return {
        ...previousState,
        attributes: {
          ...previousState.attributes,
          comments: comments,
        },
      };
    case CLICKUP_GET_TASK_LIST: {
      const folders = previousState.clickupFolders.map((folder) =>
        folder.id === action.params.folder_id
          ? {
              ...folder,
              selected: true,
            }
          : folder
      );
      return {
        ...previousState,
        clickupFolders: folders,
      };
    }
    case TEAMWORK_GET_TASK_LIST: {
      const folders = previousState.teamWorkProjects.map((project) =>
        project.id === action.params.project_id
          ? {
              ...project,
              selected: true,
            }
          : {
              ...project,
              selected: false,
            }
      );
      return {
        ...previousState,
        teamWorkProjects: folders,
      };
    }
    case UPDATE_TASK_LIST_SELECTED: {
      const list = previousState.clickupTaskList.map((task) =>
        task.id === action.id
          ? {
              ...task,
              selected: true,
            }
          : task.selected === true
            ? {
                ...task,
                selected: false,
              }
            : task
      );
      return {
        ...previousState,
        clickupTaskList: list,
      };
    }
    case UPDATE_TEAMWORK_TASK_LIST_SELECTED: {
      const list = previousState.teamWorkTaskList.map((task) =>
        task.id === action.id
          ? {
              ...task,
              selected: true,
            }
          : task.selected === true
            ? {
                ...task,
                selected: false,
              }
            : task
      );
      return {
        ...previousState,
        teamWorkTaskList: list,
      };
    }
    case SUCCESS_ASANA_GET_PROJECTS:
      return {
        ...previousState,
        asanaProjects: action.projects,
      };
    case ASANA_GET_SECTION: {
      const projects = previousState.asanaProjects.map((project) =>
        project.id === action.params.project_id
          ? {
              ...project,
              selected: true,
            }
          : {
              ...project,
              selected: false,
            }
      );
      return {
        ...previousState,
        asanaProjects: projects,
      };
    }
    case SUCCESS_ASANA_GET_SECTION:
      return {
        ...previousState,
        asanaSection: action.sectionList,
      };
    case SUCCESS_JIRA_GET_PROJECTS:
      return {
        ...previousState,
        jiraProjects: action.projects,
      };
    case UPDATE_SECTION_LIST_SELECTED: {
      const list = previousState.asanaSection.map((task) =>
        task.id === action.id
          ? {
              ...task,
              selected: true,
            }
          : task.selected === true
            ? {
                ...task,
                selected: false,
              }
            : task
      );
      return {
        ...previousState,
        asanaSection: list,
      };
    }
    case UPDATE_JIRA_PROJECT_SELECTED: {
      const list = previousState.jiraProjects.map((proj) =>
        proj.id === action.id
          ? {
              ...proj,
              selected: true,
            }
          : proj.selected === true
            ? {
                ...proj,
                selected: false,
              }
            : proj
      );
      return {
        ...previousState,
        jiraProjects: list,
      };
    }
    case SUCCESS_FETCH_NEXT_EMAIL_TASKS:
      const newEmailList = previousState.emails.concat(action.payload);

      return {
        ...previousState,
        emails: newEmailList.filter(
          (n, i) => newEmailList.findIndex((c) => n.task_id === c.task_id) === i
        ),
        emailMoreRecords: action.moreRecords, // this is done to protect from calling next page API infinitely
        // which happens when another logged in user creates a new task
      };
    case SUCCESS_BASECAMP_GET_PROJECTS:
      return {
        ...previousState,
        basecampProjects: action.projects,
      };
    case BASECAMP_GET_TODOLIST: {
      const projects = previousState.basecampProjects.map((project) =>
        project.id == action.params.project_id
          ? {
              ...project,
              selected: true,
            }
          : {
              ...project,
              selected: false,
            }
      );
      return {
        ...previousState,
        basecampProjects: projects,
      };
    }
    case SUCCESS_BASECAMP_GET_TODOLIST:
      return {
        ...previousState,
        basecampTodo: action.todoList,
      };
    case UPDATE_BASECAMP_TODOLIST_SELECTED: {
      const list = previousState.basecampTodo.map((task) =>
        task.id === action.id
          ? {
              ...task,
              selected: true,
            }
          : task.selected === true
            ? {
                ...task,
                selected: false,
              }
            : task
      );
      return {
        ...previousState,
        basecampTodo: list,
      };
    }
    case BASECAMP_CREATE_TASK:
      return {
        ...previousState,
        isPushingBasecamp: true,
      };
    case SUCCESS_BASECAMP_CREATE_TASK:
      return {
        ...previousState,
        successUpdateMessage: action.message,
        errorUpdateMessage: '',
        isPushingBasecamp: false,
      };
    case ERROR_BASECAMP_CREATE_TASK:
      return {
        ...previousState,
        successUpdateMessage: '',
        errorUpdateMessage: action.message,
        isPushingBasecamp: false,
      };
    case SUCCESS_TRELLO_GET_BOARDS:
      return {
        ...previousState,
        trelloBoards: action.folders,
      };
    case SUCCESS_TRELLO_GET_TASK_LIST:
      return {
        ...previousState,
        trelloTasks: action.taskList,
      };
    case TRELLO_GET_TASK_LIST:
      const folders = previousState.trelloBoards.map((folder) =>
        folder.id === action.params.board_id
          ? {
              ...folder,
              selected: true,
            }
          : folder
      );
      return {
        ...previousState,
        trelloBoards: folders,
      };
    case UPDATE_TERLLO_TASK_SELECTED:
      const list = previousState.trelloTasks.map((task) =>
        task.id === action.id
          ? {
              ...task,
              selected: true,
            }
          : task.selected === true
            ? {
                ...task,
                selected: false,
              }
            : task
      );
      return {
        ...previousState,
        trelloTasks: list,
      };
    case TRELLO_CREATE_TASK:
      return {
        ...previousState,
        isPushingTrelloTask: true,
      };
    case SUCCESS_TRELLO_CREATE_TASK:
      return {
        ...previousState,
        successUpdateMessage: action.message,
        errorUpdateMessage: '',
        isPushingTrelloTask: false,
      };
    case ERROR_TRELLO_CREATE_TASK:
      return {
        ...previousState,
        successUpdateMessage: '',
        isPushingTrelloTask: false,
        errorUpdateMessage: action.message,
      };
    case UPDATE_TEAMWORK_GET_TASK_SELECTED: {
      const list = previousState.teamWorkTask.map((task) =>
        task.id === action.id
          ? {
              ...task,
              selected: true,
            }
          : task.selected === true
            ? {
                ...task,
                selected: false,
              }
            : task
      );
      return {
        ...previousState,
        teamWorkTask: list,
      };
    }
    case SUCCESS_FETCHT_TRIGGERS:
      return {
        ...previousState,
        automationTriggers: action.data,
      };
    case SUCCESS_FETCHT_AUTOMATION_ACTION:
      return {
        ...previousState,
        automationActions: action.data,
      };
    case SUCCESS_FETCHT_CONDITION_ACTION:
      return {
        ...previousState,
        automationCondition: action.data,
      };
    case CREATE_AUTOMATION:
      return {
        ...previousState,
        isCreatingAutomation: true,
        errorUpdateMessage: '',
        successUpdateMessage: '',
        workflow: null,
      };
    case SUCCESS_CREATE_AUTOMATION:
      return {
        ...previousState,
        isCreatingAutomation: true,
        errorUpdateMessage: '',
        successUpdateMessage: action.message,
        workflow: action.workflow,
      };
    case SUCCESS_FETCH_AUTOMATION_WORKFLOW:
      return {
        ...previousState,
        automationWorkflow: action.data,
        isCreatingAutomation: false,
      };
    case ERROR_FETCH_AUTOMATION_WORKFLOW:
      return {
        ...previousState,
        automationWorkflow: [],
        isCreatingAutomation: false,
        successUpdateMessage: '',
        errorUpdateMessage: action.message,
      };
    case ERROR_CREATE_AUTOMATION:
      return {
        ...previousState,
        isCreatingAutomation: false,
        errorUpdateMessage: action.message,
        successUpdateMessage: '',
      };
    case SUCCESS_FETCH_AUTOMATION_TAG:
      return {
        ...previousState,
        automationTags: action.data,
      };
    case CHANGE_AUTOMATION_STATUS:
      const changedStatus = previousState.automationWorkflow.map((flow) =>
        flow.id === action.params.id
          ? {
              ...flow,
              status: action.params.status,
            }
          : flow
      );
      return {
        ...previousState,
        automationWorkflow: changedStatus,
        successUpdateMessage: '',
        errorUpdateMessage: '',
      };
    case SUCCESS_CHANGE_AUTOMATION_STATUS:
      return {
        ...previousState,
        successUpdateMessage: action.message,
        errorUpdateMessage: '',
      };
    case ERROR_CHANGE_AUTOMATION_STATUS:
      const changedStatusFailed = previousState.automationWorkflow.map(
        (flow) =>
          flow.id === action.id
            ? {
                ...flow,
                status: !flow.status,
              }
            : flow
      );
      return {
        ...previousState,
        successUpdateMessage: '',
        automationWorkflow: changedStatusFailed,
        errorUpdateMessage: action.message,
      };
    case SUCCESS_DELETE_WORKFLOW:
      const deleteWorkflow = previousState.automationWorkflow.filter(
        (w) => w.id !== action.id
      );
      return {
        ...previousState,
        automationWorkflow: deleteWorkflow,
        errorUpdateMessage: '',
        successUpdateMessage: action.message,
      };
    case ERROR_DELETE_WORKFLOW:
      return {
        ...previousState,
        successUpdateMessage: '',
        errorUpdateMessage: action.message,
      };
    case EMAIL_FROM_DASHBOARD:
      return {
        ...previousState,
        emailFromDashboard: action.value,
      };
    case START_FETCH_AUTOMATION_DETAIL:
      return {
        ...previousState,
        automationDetail: {},
        isFetchingAutomationDetail: true,
      };
    case SUCCESS_FETCH_AUTOMATION_DETAIL:
      return {
        ...previousState,
        automationDetail: action.data,
        isFetchingAutomationDetail: false,
      };
    case EDIT_AUTOMATION:
      return {
        ...previousState,
        isCreatingAutomation: true,
        errorUpdateMessage: '',
        successUpdateMessage: '',
        workflow: null,
      };
    case CREATE_AUTOMATION_TEMPLATE:
      return {
        ...previousState,
        isCreatingAutomation: true,
        errorUpdateMessage: '',
        successUpdateMessage: '',
        // workflow: null
      };
    case SUCCESS_CREATE_AUTOMATION_TEMPLATE:
      return {
        ...previousState,
        isCreatingAutomation: false,
        errorUpdateMessage: '',
        successUpdateMessage: action.message,
        // workflow: action.workflow
      };
    case ERROR_CREATE_AUTOMATION_TEMPLATE:
      return {
        ...previousState,
        isCreatingAutomation: false,
        errorUpdateMessage: action.message,
        successUpdateMessage: '',
      };
    case FETCH_AUTOMATION_TEMPLATE:
      return {
        ...previousState,
        automationTemplates: [],
        isFetchingAutomationTemplate: true,
        errorUpdateMessage: '',
        successUpdateMessage: '',
      };
    case SUCCESS_FETCH_AUTOMATION_TEMPLATE:
      return {
        ...previousState,
        automationTemplates: action.data,
        isFetchingAutomationTemplate: false,
      };
    case ERROR_FETCH_AUTOMATION_TEMPLATE:
      return {
        ...previousState,
        // automationTemplates: action.data,
        isFetchingAutomationTemplate: false,
        errorUpdateMessage: action.message,
        successUpdateMessage: '',
      };
    case START_FETCH_AUTOMATION_TEMPLATE_DETAIL:
      return {
        ...previousState,
        automationTemplateDetail: {},
        automationDetail: {},
        isFetchingAutomationDetail: true,
      };
    case SUCCESS_FETCH_AUTOMATION_TEMPLATE_DETAIL:
      return {
        ...previousState,
        automationTemplateDetail: action.data,
        isFetchingAutomationDetail: false,
      };
    case SET_MINIMIZE_TIMER_COLLECTION:
      return {
        ...previousState,
        minimizeTimerCollection: action.value,
      };
    case START_FETCH_TASK_ALL_SNIPPET:
      return {
        ...previousState,
        cannedSnippetList: [],
        isFetchingSnippet: true,
        errorGetSnippet: '',
        successGetSnippet: '',
      };
    case SUCCESS_FETCH_TASK_ALL_SNIPPET:
      return {
        ...previousState,
        cannedSnippetList: action.data,
        isFetchingSnippet: false,
        errorGetSnippet: '',
        // successGetSnippet: action.message,
      };
    case ERROR_FETCH_TASK_ALL_SNIPPET:
      return {
        ...previousState,
        isFetchingSnippet: false,
        errorGetSnippet: action.message,
        successGetSnippet: '',
      };
    case NEW_TASK_SNIPPET_SELECTED:
      return {
        ...previousState,
        selectedSnippet: action.snippetId,
        snippetIsLoading: true,
        errorSnippetMessage: '',
        snippetDetails: {},
      };
    case SUCCESS_NEW_TASK_SNIPPET_SELECTED:
      return {
        ...previousState,
        snippetIsLoading: false,
        errorSnippetMessage: '',
        snippetDetails: action.data,
      };
    case ERROR_NEW_TASK_SNIPPET_SELECTED:
      return {
        ...previousState,
        snippetIsLoading: false,
        errorSnippetMessage: action.message,
        snippetDetails: {},
      };
    case OPEN_INBOX_EMAIL_SNIPPET:
      return {
        ...previousState,
        openInboxSnippetModal: !previousState.openInboxSnippetModal,
      };
    case SUCCESS_MONDAY_GET_BOARDS:
      return {
        ...previousState,
        mondayBoards: action.boards,
      };
    case MONDAY_GET_GROUP_LIST:
      const boardsMonday = previousState.mondayBoards.map((folder) =>
        folder.id === action.params.board_id
          ? {
              ...folder,
              selected: true,
            }
          : folder
      );
      return {
        ...previousState,
        mondayBoards: boardsMonday,
      };
    case SUCCESS_MONDAY_GET_GROUP_LIST:
      return {
        ...previousState,
        mondayGroup: action.group,
      };
    case UPDATE_GROUP_TASK_SELECTED:
      const listGroup = previousState.mondayGroup.map((task) =>
        task.id === action.id
          ? {
              ...task,
              selected: true,
            }
          : task.selected === true
            ? {
                ...task,
                selected: false,
              }
            : task
      );
      return {
        ...previousState,
        mondayGroup: listGroup,
      };
    case MONDAY_CREATE_TASK:
      return {
        ...previousState,
        isPushingMondayTask: true,
      };
    case SUCCESS_MONDAY_CREATE_TASK:
      return {
        ...previousState,
        successUpdateMessage: action.message,
        errorUpdateMessage: '',
        isPushingTrelloTask: false,
      };
    case ERROR_MONDAY_CREATE_TASK:
      return {
        ...previousState,
        successUpdateMessage: '',
        isPushingTrelloTask: false,
        errorUpdateMessage: action.message,
      };
    case START_FETCH_ASSIGN_PROJECT:
      return {
        ...previousState,
        isLoadingAssign: true,
        assignProject: [],
      };
    case SUCCESS_FETCH_ASSIGN_PROJECT:
      return {
        ...previousState,
        isLoadingAssign: false,
        // isError: false,
        assignProject: action.data,
        moreAssignRecords: action.moreAssignRecords,
      };
    case START_FETCH_NEXT_ASSIGN_PROJECT:
      return {
        ...previousState,
        isLoadingAssign: false,
        // isError: false,
        assignProject: previousState.assignProject.concat({
          id: 'loadMore',
          name: 'more',
        }),
        moreAssignRecords: action.moreAssignRecords,
      };
    case SUCCESS_FETCH_NEXT_ASSIGN_PROJECT:
      let newAssign = previousState.assignProject.filter(
        (each) => each.id !== 'loadMore'
      );
      return {
        ...previousState,
        isLoadingAssign: false,
        // isError: false,
        assignProject: newAssign.concat(action.data),
        moreAssignRecords: action.moreAssignRecords,
      };

    case RESET_LOAD_MORE:
      return {
        ...previousState,
        moreEmailOpenRecords: false,
        moreEmailInProgressRecords: false,
        moreEmailPendingReviewRecords: false,
        moreEmailCompleteRecords: false,
        moreEmailCriticalRecords: false,
        moreEmailHighRecords: false,
        moreEmailLowRecords: false,
        moreEmailMediumRecords: false,
        emailOpenListIsLoading: false,
        emailInProgressListIsLoading: false,
        emailPendingReviewListIsLoading: false,
        emailCompleteListIsLoading: false,
        emailLowListIsLoading: false,
        emailMediumListIsLoading: false,
        emailHighListIsLoading: false,
        emailCriticalListIsLoading: false,
      };
    case SUCCESS_EMAIL_OPEN_TASKS:
      return {
        ...previousState,
        openEmailTaskList: action.payload,
        emailSelected: true,
        listIsLoading: false,
        attributeIsLoading: false,
        moreEmailOpenRecords: action.moreRecords,
        emailOpenListIsLoading: false,
        openEmailTaskListCount: action.totalColumnRecords,
      };
    case SUCCESS_EMAIL_IN_PROGRESS_TASKS:
      return {
        ...previousState,
        inProgressEmailTaskList: action.payload,
        emailSelected: true,
        listIsLoading: false,
        attributeIsLoading: false,
        moreEmailInProgressRecords: action.moreRecords,
        emailInProgressListIsLoading: false,
        inProgressEmailTaskListCount: action.totalColumnRecords,
      };
    case SUCCESS_EMAIL_PENDING_REVIEW_TASKS:
      return {
        ...previousState,
        pendingReviewEmailTaskList: action.payload,
        emailSelected: true,
        listIsLoading: false,
        attributeIsLoading: false,
        moreEmailPendingReviewRecords: action.moreRecords,
        emailPendingReviewListIsLoading: false,
        pendingReviewEmailTaskListCount: action.totalColumnRecords,
      };
    case SUCCESS_EMAIL_COMPLETE_TASKS:
      return {
        ...previousState,
        completeEmailTaskList: action.payload,
        emailSelected: true,
        listIsLoading: false,
        attributeIsLoading: false,
        moreEmailCompleteRecords: action.moreRecords,
        emailCompleteListIsLoading: false,
        completeEmailTaskListCount: action.totalColumnRecords,
      };
    case SUCCESS_EMAIL_LOW_TASKS:
      return {
        ...previousState,
        emailTaskUrgencyLow: action.payload,
        emailSelected: true,
        listIsLoading: false,
        attributeIsLoading: false,
        moreEmailLowRecords: action.moreRecords,
        emailLowListIsLoading: false,
        emailTaskUrgencyLowCount: action.totalColumnRecords,
      };
    case SUCCESS_EMAIL_MEDIUM_TASKS:
      return {
        ...previousState,
        emailTaskUrgencyMedium: action.payload,
        emailSelected: true,
        listIsLoading: false,
        attributeIsLoading: false,
        moreEmailMediumRecords: action.moreRecords,
        emailMediumListIsLoading: false,
        emailTaskUrgencyMediumCount: action.totalColumnRecords,
      };
    case SUCCESS_EMAIL_HIGH_TASKS:
      return {
        ...previousState,
        emailTaskUrgencyHigh: action.payload,
        emailSelected: true,
        listIsLoading: false,
        attributeIsLoading: false,
        moreEmailHighRecords: action.moreRecords,
        emailHighListIsLoading: false,
        emailTaskUrgencyHighCount: action.totalColumnRecords,
      };
    case SUCCESS_EMAIL_CRITICAL_TASKS:
      return {
        ...previousState,
        emailTaskUrgencyCritical: action.payload,
        emailSelected: true,
        listIsLoading: false,
        attributeIsLoading: false,
        moreEmailCriticalRecords: action.moreRecords,
        emailCriticalListIsLoading: false,
        emailTaskUrgencyCriticalCount: action.totalColumnRecords,
      };
    case SUCCESS_NEXT_EMAIL_OPEN_TASKS:
      return {
        ...previousState,
        openEmailTaskList: previousState.openEmailTaskList.concat(
          action.payload
        ),
        emailSelected: true,
        listIsLoading: false,
        attributeIsLoading: false,
        moreEmailOpenRecords: action.moreRecords,
      };
    case SUCCESS_NEXT_EMAIL_IN_PROGRESS_TASKS:
      return {
        ...previousState,
        inProgressEmailTaskList: previousState.inProgressEmailTaskList.concat(
          action.payload
        ),
        emailSelected: true,
        listIsLoading: false,
        attributeIsLoading: false,
        moreEmailInProgressRecords: action.moreRecords,
      };
    case SUCCESS_NEXT_EMAIL_PENDING_REVIEW_TASKS:
      return {
        ...previousState,
        pendingReviewEmailTaskList:
          previousState.pendingReviewEmailTaskList.concat(action.payload),
        emailSelected: true,
        listIsLoading: false,
        attributeIsLoading: false,
        moreEmailPendingReviewRecords: action.moreRecords,
      };
    case SUCCESS_NEXT_EMAIL_COMPLETE_TASKS:
      return {
        ...previousState,
        completeEmailTaskList: previousState.completeEmailTaskList.concat(
          action.payload
        ),
        emailSelected: true,
        listIsLoading: false,
        attributeIsLoading: false,
        moreEmailCompleteRecords: action.moreRecords,
      };
    case SUCCESS_NEXT_EMAIL_LOW_TASKS:
      return {
        ...previousState,
        emailTaskUrgencyLow: previousState.emailTaskUrgencyLow.concat(
          action.payload
        ),
        emailSelected: true,
        listIsLoading: false,
        attributeIsLoading: false,
        moreEmailLowRecords: action.moreRecords,
      };
    case SUCCESS_NEXT_EMAIL_MEDIUM_TASKS:
      return {
        ...previousState,
        emailTaskUrgencyMedium: previousState.emailTaskUrgencyMedium.concat(
          action.payload
        ),
        emailSelected: true,
        listIsLoading: false,
        attributeIsLoading: false,
        moreEmailMediumRecords: action.moreRecords,
      };
    case SUCCESS_NEXT_EMAIL_HIGH_TASKS:
      return {
        ...previousState,
        emailTaskUrgencyHigh: previousState.emailTaskUrgencyHigh.concat(
          action.payload
        ),
        emailSelected: true,
        listIsLoading: false,
        attributeIsLoading: false,
        moreEmailHighRecords: action.moreRecords,
      };
    case SUCCESS_NEXT_EMAIL_CRITICAL_TASKS:
      return {
        ...previousState,
        emailTaskUrgencyCritical: previousState.emailTaskUrgencyCritical.concat(
          action.payload
        ),
        emailSelected: true,
        listIsLoading: false,
        attributeIsLoading: false,
        moreEmailCriticalRecords: action.moreRecords,
      };

    case DELETE_INBOX_COMMENT_FILE:
      const deletedFileIds = action.params.data.ids;
      const deletedFilesInComment = previousState.attributes.comments.map(
        (comment) => {
          if (comment.id == action.params.commentId) {
            const removedFiles = comment.files.filter(
              (f) => !deletedFileIds.includes(f.id)
            );
            return {
              ...comment,
              files: removedFiles,
            };
          } else {
            return comment;
          }
        }
      );
      return {
        ...previousState,
        attributes: {
          ...previousState.attributes,
          comments: deletedFilesInComment,
        },
      };
    case SUCCESS_DELETE_INBOX_COMMENT_FILE:
      return {
        ...previousState,
        successUpdateMessage: 'SUCCESS',
      };
    case ERROR_DELETE_INBOX_COMMENT_FILE:
      return {
        ...previousState,
      };
    case DELETE_BOARD_COMMENT_FILE:
      const deletedFileIdss = action.data.ids;
      const deletedFilesInComments = previousState.attributes.comments.map(
        (comment) => {
          if (comment.id == action.commentId) {
            const removedFiles = comment.files.filter(
              (f) => !deletedFileIdss.includes(f.id)
            );
            return {
              ...comment,
              files: removedFiles,
            };
          } else {
            return comment;
          }
        }
      );
      return {
        ...previousState,
        attributes: {
          ...previousState.attributes,
          comments: deletedFilesInComments,
        },
      };
    case SUCCESS_DELETE_BOARD_COMMENT_FILE:
      return {
        ...previousState,
      };
    case ERROR_DELETE_BOARD_COMMENT_FILE:
      return {
        ...previousState,
      };
    case SUCCESS_GET_SPACE_LIST:
      return {
        ...previousState,
        clickupSpaceList: action.tasks,
      };
    default:
      return previousState;
  }
};

export default tasks;
