import React from 'react';
// Styling
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

export default function PlusIcon(props) {
  const { clickAction, disabled } = props;

  return (
    <Button
      className="at-cs-btn no-bg square m-r-8"
      color="primary"
      onClick={clickAction}
      disabled={disabled}
    >
      <AddIcon />
    </Button>
  );
}
