/* global chrome */
const localChromeExtensionId = 'nncchknkhhhibkhogeffpenlcaficbga';
const publishedChromeExtensionId = 'ekpmbeilnaahlccpgjchjjoonecikehf';

export const isChromeExtensionInstalled = () => {
  try {
    window.hasOwnProperty(chrome);
  } catch (exception) {
    // Browser is not chrome
    return false;
  }
  if (!chrome || !chrome.runtime) {
    // Extension is not installed
    return false;
  }
  return true;
};

export const sendMessageToExtension = (message, responseCallback) => {
  try {
    window.hasOwnProperty(chrome);
  } catch (exception) {
    // Browser is not chrome
    return;
  }
  if (!chrome || !chrome.runtime) {
    // Extension is not installed
    return;
  }

  try {
    chrome.runtime.sendMessage(
      localChromeExtensionId,
      message,
      responseCallback
    );
  } catch (exception) {
    console.error(
      `Could not communicate with ${localChromeExtensionId}: ${exception.message}`
    );
  }

  try {
    chrome.runtime.sendMessage(
      publishedChromeExtensionId,
      message,
      responseCallback
    );
  } catch (exception) {
    console.error(
      `Could not communicate with ${publishedChromeExtensionId}: ${exception.message}`
    );
  }
};

export const sendMessageToSiteControl = (message, responseCallback) => {
  try {
    window.hasOwnProperty(chrome);
  } catch (exception) {
    // Browser is not chrome
    return;
  }
  if (!chrome || !chrome.runtime) {
    // Extension is not installed
    return;
  }

  try {
    window.gsc('params', {
      ext: 'installed',
    });
  } catch (exception) {
    console.error(exception);
  }
};

export const isChromeBrowser = () => {
  try {
    window.hasOwnProperty(chrome);
    if (navigator.userAgentData) {
      return navigator.userAgentData.brands.some(
        (brand) => brand.brand === 'Google Chrome'
      );
    }

    // return !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
  } catch (exception) {
    // Browser is not chrome
    return false;
  }
};
