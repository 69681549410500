import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import 'intersection-observer';
import { useDispatch, useSelector } from 'react-redux';

export const useDesktopView = () => {
  const query = useMediaQuery({
    query: '(min-device-width: 1280px)',
  });
  return query;
};

export const useMobileView = () => {
  const query = useMediaQuery({
    query: '(max-device-width: 600px)',
  });
  return query;
};

export const useIntersection = ({ root = null, rootMargin, threshold = 0 }) => {
  const [entry, updateEntry] = useState({});
  const [node, setNode] = useState(null);

  const observer = useRef(
    new window.IntersectionObserver(([entry]) => updateEntry(entry), {
      root,
      rootMargin,
      threshold,
    })
  );

  useEffect(() => {
    const { current: currentObserver } = observer;
    currentObserver.disconnect();

    if (node) currentObserver.observe(node);

    return () => currentObserver.disconnect();
  }, [node]);

  return [setNode, entry];
};

// encapsulates state and dispatch
export const useReduxStore = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  return { state, dispatch };
};
