import { Suspense, useEffect } from 'react';
import { NotificationContainer } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { SETTINGS_PAGE_MOUNTED } from '../actions/global_settings';
import PageLoading from '../components/PageLoading';
import TrialHeaderBar from '../components/collaborate/TrialHeaderBar';
import SiteNav from '../components/navs/SiteNav';
import PlanUpgrading from '../components/plans/PlansUpgrading';
import UpgradeStartWizard from '../components/plans/UpgradeStartWizard';
import usePluginActivationRedirect from '../hooks/pluginRedirect';
import { useAuth } from '../hooks/useAuth';
import useExport from '../hooks/useExport';
import useWorkspaceChangeEffect from '../hooks/workspaceChange';
import { sendMessageToExtension } from '../utils/chromeExtension';
import ErrorBoundary from './ErrorBoundary';

export default function Layout() {
  const { userData, userTrialData, token } = useSelector((state) => state.user);
  const { collaborateState } = useSelector((state) => state.collaborate);
  const dispatch = useDispatch();
  const { isLoadingAuth } = useAuth();
  const location = useLocation();

  usePluginActivationRedirect();
  useWorkspaceChangeEffect();
  //TODO: Move this to inbox and board, once board is a functional component
  useExport();

  // TODO: this may be moved to a separate hook or removed
  useEffect(() => {
    if (!isLoadingAuth) {
      if (token && localStorage.getItem('token') !== 'guestToken') {
        dispatch({ type: SETTINGS_PAGE_MOUNTED });
      } else if (!token) {
        sendMessageToExtension({ type: 'logout' });
      }
    }
  }, [token, isLoadingAuth]);

  return (
    <ErrorBoundary>
      <NotificationContainer />
      {userData ? (
        <>
          {location.pathname !== '/fetching' && <SiteNav location={location} />}
          <div className="content">
            {location.pathname !== '/collaborate' && <TrialHeaderBar />}
            <Suspense fallback={<PageLoading />}>
              <Outlet />
              {userData && !userData.subscription && (
                <PlanUpgrading title={'Renew Subscription'} />
              )}
              {userTrialData?.status === 'inactive' &&
                userData?.role === 'admin' &&
                !collaborateState && (
                  <UpgradeStartWizard
                    user={userData}
                    trialData={userTrialData}
                    isPlansDialogOpened={
                      userTrialData?.status === 'inactive' ? true : false
                    }
                    collabMode={
                      location.pathname === '/collaborate' ? true : false
                    }
                  />
                )}
            </Suspense>
          </div>
        </>
      ) : (
        <div className="content">
          <Suspense fallback={<PageLoading />}>
            <Outlet />
          </Suspense>
        </div>
      )}
    </ErrorBoundary>
  );
}
