import { useEffect, useState } from 'react';
// Utils
import * as FileSaver from 'file-saver';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';

const useExport = () => {
  const { taskExportData, isTaskExportLoading } = useSelector(
    (state) => state.tasks
  );
  const [exportDialogStatus, setExportDialogStatus] = useState(false);

  useEffect(() => {
    if (taskExportData[0]?.length > 0 && !isTaskExportLoading) {
      setExportDialogStatus(false);
      exportToCSV(taskExportData[0], taskExportData[1]);
    } else {
      if (exportDialogStatus && !isTaskExportLoading) {
        NotificationManager.success('No record found', '', 5000);
      }
    }
  }, [isTaskExportLoading, taskExportData]);

  const exportToCSV = (csvData, fileExtension) => {
    const date = new Date();
    const fileName = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const ws = XLSX.utils.json_to_sheet(csvData);
    ws.views = [{ state: 'frozen', xSplit: 2, ySplit: 1 }];
    const wb = {
      Sheets: { Tasks: ws },
      SheetNames: ['Tasks'],
    };
    const excelBuffer = XLSX.write(wb, {
      bookType: fileExtension,
      type: 'array',
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + '.' + fileExtension);
    NotificationManager.success('Exported successfully', '', 5000);
  };
}; // end

export default useExport;
