import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// Component
import { useDispatch, useSelector } from 'react-redux';
import GotoChromeButton from '../GotoChromeButton';
import TimerCollection from '../TimerCollection';
import PlanUpgrading from '../plans/PlansUpgrading';
import FrillWidget from './FrillWidget';
import Notifications from './Notifications';
import WorkspaceNav from './workspace-nav';
// Styling
import { Badge, CircularProgress, Slide } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Avatar } from '@ui/Avatar';
import { Button } from '@ui/Button';
import { Progress } from '@ui/Progress';
import {
  BiCog,
  BiDetail,
  BiDollarCircle,
  BiEnvelope,
  BiHelpCircle,
  BiPowerOff,
  BiTimeFive,
} from 'react-icons/bi';
import { BsBarChart, BsGrid } from 'react-icons/bs';
import {
  FaAngleLeft,
  FaAngleRight,
  FaBullhorn,
  FaRegBell,
  FaRegBellSlash,
} from 'react-icons/fa';
import { IoAnalytics } from 'react-icons/io5';
import { LuUsers, LuWorkflow } from 'react-icons/lu';
import { PiKanban } from 'react-icons/pi';
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarHeader,
  SubMenu,
} from 'react-pro-sidebar';
// Utils
import { AdminRoles } from '../../utils/constants';
// Actions

import clsx from 'clsx';
import { MAKE_SIDENAV_FALSE } from '../../actions/global_settings';
import {
  NEW_TASK_SELECTED,
  NEW_WEBSITE_SELECTED,
  SEND_MESSAGE,
  SEND_MESSAGE_WITHOUT_POST,
  SET_MINIMIZE_TIMER_COLLECTION,
} from '../../actions/tasks';
import {
  FETCH_NOT_LOGGED_ENTRIES,
  SELECT_TIMER_ENTRY,
  SET_CURRENT_TIMER_TASK_ID,
  SET_TIMER_PLAY,
  START_DELETE_ENTRY,
  START_UPDATE_ENTRY,
  UPDATE_NOT_LOGGED,
} from '../../actions/time_entries';
import {
  COLLAPSE_SIDE_NAV,
  GET_NOTIFICATIONS,
  HANDLE_PLANS_DIALOG,
  OPEN_CLOSE_NOTIFICATIONS_MODAL,
} from '../../actions/user';
import { useAuth } from '../../hooks/useAuth';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '../../ui/components/Sheet';
const classes = (theme) => ({
  whatsNewWrap: {
    position: 'relative',
    opacity: '1',
    display: 'block',
    marginLeft: 2,
    color: '#fff',
    cursor: 'pointer',
    '& .headway-badge': {
      position: 'absolute',
      top: '-15px',
      left: 7,
    },
  },
  whatsNewLine: {
    display: 'flex',
    alignItems: 'center',
  },
  whatsNewText: {
    marginLeft: 11,
  },
  paper: {
    left: '270px !important',
    overflow: 'hidden',
  },
  paperBell: {
    overflow: 'scroll',
    minHeight: '100%',
    width: '339px',
    padding: '0 10px 10px 10px',
    marginTop: '15px',
    pointerEvents: 'auto',
    fontFamily: theme.text.normalText.fontFamily,
  },
  notifications: {
    display: 'flex',
  },
  popover: {
    pointerEvents: 'none',
  },
  bullIcon: {
    opacity: '1',
  },
  userAvatar: {
    width: '80px',
    height: '80px',
    borderRadius: '8px',
  },
  loaderContainer: {
    textAlign: 'center',
  },
  loader: {
    color: '#A4ABC5',
    '&:hover': {
      color: '#279AF1',
    },
  },
});

const SiteNav = ({ classes }) => {
  const { logoutUser } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openWidget, setOpenWidget] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // Redux state
  const {
    selectedIndex,
    user,
    token,
    notificationDetails,
    moreUnreadWebRecords,
    moreReadWebRecords,
    unreadCount,
    muteState,
    readNotifications,
    unreadNotifications,
    readNotificationsOffsetCounter,
    unreadNotificationsOffsetCounter,
    isNotificationsModalOpen,
    navInCollaborate,
    menuCollapse,
    notLoggedEntries,
    selectedTimerEntry,
    currentTimerTaskId,
    timerPlay,
    minimizeTimerCollection,
    isPlansDialogOpened,
    selectedWorkspace,
    isLoading,
    updatingNotification,
  } = useSelector((state) => ({
    selectedIndex: state.user.selectedIndex,
    user: state.user.userData,
    token: state.user.token,
    notificationDetails: state.user.notificationDetails,
    moreUnreadWebRecords: state.user.moreUnreadWebRecords,
    moreReadWebRecords: state.user.moreReadWebRecords,
    unreadCount: state.user.unreadCount,
    muteState: state.user.muteState,
    readNotifications: state.user.readNotifications,
    unreadNotifications: state.user.unreadNotifications,
    readNotificationsOffsetCounter: state.user.readNotificationsOffsetCounter,
    unreadNotificationsOffsetCounter:
      state.user.unreadNotificationsOffsetCounter,
    isNotificationsModalOpen: state.user.isNotificationsModalOpen,
    navInCollaborate: state.collaborate.navInCollaborate,
    menuCollapse: state.user.menuCollapse,
    isPlansDialogOpened: state.user.isPlansDialogOpened,
    selectedWorkspace: state.workspace.selectedWorkspace,
    isLoading: state.user.isLoading,
    updatingNotification: state.user.updatingNotification,
    //Timer Entries
    notLoggedEntries: state.time_entries.notLoggedEntries,
    selectedTimerEntry: state.time_entries.selectedTimerEntry,
    currentTimerTaskId: state.time_entries.currentTimerTaskId,
    timerPlay: state.time_entries.timerPlay,
    minimizeTimerCollection: state.tasks.minimizeTimerCollection,
  }));

  useEffect(() => {
    dispatch({ type: GET_NOTIFICATIONS });
    if (notLoggedEntries.length <= 0) {
      dispatch({ type: FETCH_NOT_LOGGED_ENTRIES });
    }
  }, [dispatch, notLoggedEntries.length]);

  const isUserAdmin = () => {
    return AdminRoles.indexOf(selectedWorkspace.role) !== -1;
  };

  const menuIconClick = () => {
    dispatch({ type: COLLAPSE_SIDE_NAV });
  };

  const handleClickWidget = () => {
    setOpenWidget(!openWidget);
  };

  const handleCloseWidget = () => {
    setOpenWidget(false);
  };

  const Transition = React.forwardRef((props, ref) => (
    <Slide direction="right" ref={ref} {...props} />
  ));

  const openCloseNotificationModal = useCallback(
    (param) => {
      dispatch({ type: OPEN_CLOSE_NOTIFICATIONS_MODAL, param });
    },
    [dispatch]
  );

  const selectTask = useCallback(
    (taskId) => {
      dispatch({ type: NEW_TASK_SELECTED, taskId });
    },
    [dispatch]
  );

  const websiteSelected = useCallback(
    (websiteID) => {
      dispatch({ type: NEW_WEBSITE_SELECTED, websiteId: websiteID });
    },
    [dispatch]
  );

  //TODO: Move these to time entries component
  const setTimerEntry = useCallback(
    (id) => {
      dispatch({ type: SELECT_TIMER_ENTRY, id });
    },
    [dispatch]
  );

  const deleteEntry = useCallback(
    (id) => {
      dispatch({ type: START_DELETE_ENTRY, id });
    },
    [dispatch]
  );

  const setCurrentTimerTaskId = useCallback(
    (id) => {
      dispatch({ type: SET_CURRENT_TIMER_TASK_ID, id });
    },
    [dispatch]
  );

  const setTimerPlay = useCallback(
    (value) => {
      dispatch({ type: SET_TIMER_PLAY, value });
    },
    [dispatch]
  );

  const updateFloatingSpentTime = useCallback(
    (entryObj) => {
      dispatch({ type: UPDATE_NOT_LOGGED, entryObj, taskLog: true });
    },
    [dispatch]
  );

  const updateEntry = useCallback(
    (entryObj) => {
      dispatch({ type: START_UPDATE_ENTRY, entryObj, taskLog: true });
    },
    [dispatch]
  );

  const sendMessage = useCallback(
    (params) => {
      dispatch({ type: SEND_MESSAGE, params });
    },
    [dispatch]
  );

  const updateNotLogged = useCallback(
    (entryObj) => {
      dispatch({ type: UPDATE_NOT_LOGGED, entryObj });
    },
    [dispatch]
  );

  const sendMessageWithoutPosting = useCallback(
    (params) => {
      dispatch({ type: SEND_MESSAGE_WITHOUT_POST, params });
    },
    [dispatch]
  );

  const fetchNotLoggedEntries = useCallback(() => {
    dispatch({ type: FETCH_NOT_LOGGED_ENTRIES });
  }, [dispatch]);

  const setMinimizeTimerCollection = useCallback(
    (value) => {
      dispatch({ type: SET_MINIMIZE_TIMER_COLLECTION, value });
    },
    [dispatch]
  );

  const handleClickIncreaseResources = () => {
    if (user.plan.from_free_plan) {
      dispatch({ type: HANDLE_PLANS_DIALOG, params: true });
    } else {
      if (user.plan.upsell_url) {
        window.open(user.plan.upsell_url);
      } else {
        window.open(`https://atarim.io/upgrade/`);
      }
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBillingClick = () => {
    if (user.plan.from_free_plan) {
      dispatch({ type: MAKE_SIDENAV_FALSE });
      navigate('/billing');
    } else {
      window.open(
        `${import.meta.env.VITE_APP_MAIN_WEBSITE_URL}/account/?parameter_1=${token}&parameter_2=${
          user.parameter_2
        }&parameter_3=${user.parameter_3}&parameter_4=${user.parameter_4}${
          user.source ? `&_from=${user.source}` : ''
        }`
      );
    }
  };

  return (
    <>
      <div
        id="sitenav-header"
        className={`sitenav-header hidden md:block ${
          !navInCollaborate && location.pathname === '/collaborate'
            ? 'closed'
            : 'open'
        }`}
      >
        {/* collapsed props to change menu size using menucollapse state */}
        <ProSidebar collapsed={menuCollapse}>
          <div>
            <SidebarHeader>
              <div className="logotext">
                {menuCollapse ? (
                  <img
                    className="logo collapsed"
                    alt="Atarim Logo"
                    src={
                      '/assets/New_logo_request_v4_without_padding_white_with_purple_accent.svg'
                    }
                  />
                ) : (
                  <img
                    className="logo"
                    alt="Atarim Logo"
                    src={'/assets/9304_New_logo_request_v5c.svg'}
                  />
                )}
              </div>
              {location.pathname !== '/collaborate' && (
                <div className="closemenu" onClick={menuIconClick}>
                  {/* changing menu collapse icon on click */}
                  {menuCollapse ? <FaAngleRight /> : <FaAngleLeft />}
                </div>
              )}
            </SidebarHeader>
            <SidebarContent>
              <Menu>
                <li className="pro-menu-item workspace-nav-item">
                  <div
                    className={`pro-inner-item transition ${menuCollapse && 'justify-center'}`}
                  >
                    <div className={`relative h-5 ${!menuCollapse && 'mr-2'}`}>
                      {selectedWorkspace.project_count > 0 ? (
                        <span className="absolute -top-0.5 left-[15px] z-[1] h-2 w-2 rounded-[50%] bg-[#E72D67] outline outline-[2px] outline-[#2f326a]"></span>
                      ) : null}
                      <Avatar
                        src={selectedWorkspace?.icon}
                        alt={selectedWorkspace?.name}
                        id={selectedWorkspace?.id}
                        size="fit"
                        className="text-[8px]"
                        title={selectedWorkspace.name}
                      />
                    </div>
                    {!menuCollapse && (
                      <span className="workspace-name">
                        {selectedWorkspace?.name}
                      </span>
                    )}
                  </div>
                  <div className="pro-inner-list-item popper-element">
                    <WorkspaceNav />
                  </div>
                </li>
                <MenuItem
                  icon={<BsGrid />}
                  onClick={() => {
                    navigate('/');
                  }}
                  active={location.pathname === '/'}
                  selected={selectedIndex === 8}
                  className="tooltip"
                >
                  <span className="menu-item-name">Projects</span>
                  <span className="tooltip-content">Projects</span>
                </MenuItem>
                <MenuItem
                  icon={<BiEnvelope />}
                  onClick={() => {
                    navigate('/tasks');
                  }}
                  active={location.pathname === '/tasks'}
                  selected={selectedIndex === 2}
                  className="tooltip"
                >
                  <span className="menu-item-name">Inbox</span>
                  <span className="tooltip-content">Inbox</span>
                </MenuItem>
                <MenuItem
                  icon={<PiKanban />}
                  onClick={() => {
                    navigate('/board');
                  }}
                  active={location.pathname === '/board'}
                  selected={selectedIndex === 3}
                  className="tooltip"
                >
                  <span className="menu-item-name">Boards</span>
                  <span className="tooltip-content">Boards</span>
                </MenuItem>
                <MenuItem
                  icon={<BiDetail />}
                  onClick={() => {
                    navigate('/forms');
                  }}
                  active={location.pathname === '/forms'}
                  selected={selectedIndex === 7}
                  className="tooltip"
                >
                  <span className="menu-item-name">Forms</span>
                  <span className="tooltip-content">Forms</span>
                </MenuItem>
              </Menu>

              <Menu iconShape="" className="item-2">
                <MenuItem
                  icon={<LuWorkflow />}
                  onClick={() => {
                    navigate('/workflows');
                  }}
                  active={location.pathname === '/workflows'}
                  selected={selectedIndex === 11}
                  className="tooltip icon-workflows"
                >
                  <span className="menu-item-name">Workflows</span>
                  <span className="tooltip-content">Workflows</span>
                  <span className="pill-new">New</span>
                </MenuItem>
                <div className="at-cs-timer-entry-button">
                  {notLoggedEntries.length > 0 ? (
                    <span className="at-cs-timer-entry-count collapsed">
                      {notLoggedEntries.length}
                    </span>
                  ) : null}
                  <MenuItem
                    active={location.pathname === '/time-entries'}
                    selected={selectedIndex === 10}
                    title={'Time Entries'}
                    label="Time Entries"
                    icon={<BiTimeFive />}
                    onClick={() => {
                      navigate('/time-entries');
                    }}
                    className="at-cs-active-timer-popout"
                  >
                    <span className="menu-item-name">
                      Time Entries
                      {notLoggedEntries.length > 0 ? (
                        <span className="py-p ml-1 h-4 min-w-4 justify-center rounded-full bg-destructive px-1.5 text-sm font-medium leading-none text-white">
                          {notLoggedEntries.length}
                        </span>
                      ) : null}
                    </span>
                    <div className="at-cs-time-container-popout">
                      <TimerCollection
                        notLoggedEntries={notLoggedEntries || []}
                        setTimerEntry={setTimerEntry}
                        selectedTimerEntry={selectedTimerEntry}
                        deleteEntry={deleteEntry}
                        currentTimerTaskId={currentTimerTaskId}
                        setCurrentTimerTaskId={setCurrentTimerTaskId}
                        timerPlay={timerPlay}
                        setTimerPlay={setTimerPlay}
                        updateFloatingSpentTime={updateFloatingSpentTime}
                        updateEntry={updateEntry}
                        sendMessage={sendMessage}
                        updateNotLogged={updateNotLogged}
                        sendMessageWithoutPosting={sendMessageWithoutPosting}
                        fetchNotLoggedEntries={fetchNotLoggedEntries}
                        minimizeTimerCollection={minimizeTimerCollection}
                        setMinimizeTimerCollection={setMinimizeTimerCollection}
                      />
                    </div>
                  </MenuItem>
                </div>

                <MenuItem
                  icon={<IoAnalytics />}
                  onClick={() => {
                    navigate('/dashboard');
                  }}
                  active={location.pathname === '/dashboard'}
                  selected={selectedIndex === 10}
                  className="tooltip"
                >
                  <span className="menu-item-name">Analytics</span>
                  <span className="tooltip-content">Analytics</span>
                </MenuItem>
                {isUserAdmin() ? (
                  <MenuItem
                    icon={<LuUsers />}
                    onClick={() => {
                      navigate('/user');
                    }}
                    active={location.pathname === '/users'}
                    selected={selectedIndex === 5}
                    className="tooltip"
                  >
                    <span className="menu-item-name">People</span>
                    <span className="tooltip-content">People</span>
                  </MenuItem>
                ) : (
                  <></>
                )}
                {isUserAdmin() ? (
                  <MenuItem
                    icon={<BiCog />}
                    onClick={() => {
                      navigate('/settings');
                    }}
                    active={location.pathname === '/settings'}
                    selected={selectedIndex === 4}
                    className="tooltip"
                  >
                    <span className="menu-item-name">Settings</span>
                    <span className="tooltip-content">Settings</span>
                  </MenuItem>
                ) : (
                  <></>
                )}
                {user?.user_permissions?.can_impersonate ? (
                  <MenuItem
                    icon={<LuUsers />}
                    onClick={() => {
                      navigate('/impersonate-user');
                    }}
                    active={location.pathname === '/impersonate-user'}
                    selected={selectedIndex === 4}
                    className="tooltip"
                  >
                    <span className="menu-item-name">Impersonate</span>
                    <span className="tooltip-content">Impersonate</span>
                  </MenuItem>
                ) : (
                  <></>
                )}
              </Menu>

              <Menu iconShape="" className="item-3">
                {isUserAdmin() ? (
                  <MenuItem
                    icon={<BiDollarCircle />}
                    onClick={handleBillingClick}
                    className="tooltip"
                  >
                    <span className="menu-item-name">Billing</span>
                    <span className="tooltip-content">Billing</span>
                  </MenuItem>
                ) : (
                  <></>
                )}
                <MenuItem
                  icon={<BiHelpCircle />}
                  onClick={() => window.open('https://support.atarim.io/')}
                  className="tooltip"
                >
                  <span className="menu-item-name">Help</span>
                  <span className="tooltip-content">Help</span>
                </MenuItem>

                <SubMenu
                  title={'Resources'}
                  label="Resources"
                  icon={<BsBarChart />}
                >
                  <Menu iconShape="" className="item-4">
                    {isLoading ? (
                      <div className={classes.loaderContainer}>
                        <CircularProgress
                          className={classes.loader}
                          size={20}
                        />
                      </div>
                    ) : user.plan ? (
                      <>
                        <div className="progress-wrapper">
                          <div className="progress-heading">
                            <span>
                              <b>Projects</b>
                            </span>
                            <span>
                              {user.plan.websites > 0
                                ? `${
                                    user.plan.websites - user.limit.websites
                                  }/${user.plan.websites}`
                                : user.plan.websites === -1
                                  ? `${user.limit.websites}/Unlimited`
                                  : `Maxed Out`}
                            </span>
                          </div>
                          <Progress
                            variant="primary"
                            progress={
                              user.plan.websites === -1
                                ? 5
                                : ((user.plan.websites - user.limit.websites) /
                                    user.plan.websites) *
                                  100
                            }
                          />
                        </div>
                        <div className="progress-wrapper">
                          <div className="progress-heading">
                            <span>
                              <b>Storage</b>
                            </span>
                            <span>
                              {user.limit.storage_limit > 0
                                ? `${(
                                    user.limit.storage_used_bytes / 1073741824
                                  ).toFixed(2)}/${user.limit.storage_limit}GB`
                                : `${user.limit.storage_limit}GB`}
                            </span>
                          </div>
                          <Progress
                            variant="primary"
                            progress={
                              (user.limit.storage_used_bytes /
                                1073741824 /
                                user.limit.storage_limit) *
                              100
                            }
                          />
                        </div>
                        {isUserAdmin() && (
                          <>
                            <div className="progress-wrapper">
                              <div className="progress-heading">
                                <span>
                                  <b>Team Members</b>
                                </span>
                                <span>
                                  {user.plan.users > 0
                                    ? `${user.plan.users - user.limit.users}/${
                                        user.plan.users
                                      }`
                                    : user.plan.users === -1
                                      ? `${user.limit.users}/Unlimited`
                                      : `Maxed Out`}
                                </span>
                              </div>
                              <Progress
                                variant="primary"
                                progress={
                                  ((user.plan.users - user.limit.users) /
                                    user.plan.users) *
                                  100
                                }
                              />
                            </div>
                            <div className="progress-wrapper">
                              <div className="progress-heading">
                                <span>
                                  <b>Workspaces</b>
                                </span>
                                <span>
                                  {user.plan.workspace > 0
                                    ? `${
                                        user.plan.workspace -
                                        user.limit.workspaces
                                      }/${user.plan.workspace}`
                                    : user.plan.workspace === -1
                                      ? `${user.limit.workspaces}/Unlimited`
                                      : `Maxed Out`}
                                </span>
                              </div>
                              <Progress
                                variant="primary"
                                progress={
                                  ((user.plan.workspace -
                                    user.limit.workspaces) /
                                    user.plan.workspace) *
                                  100
                                }
                              />
                            </div>
                            <div className="button-nav-container">
                              <button
                                className="at-cs-btn btn-green w-100"
                                onClick={() => handleClickIncreaseResources()}
                                disabled={user.role === 'contributor'}
                              >
                                Increase Resources
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    ) : null}
                  </Menu>
                </SubMenu>
              </Menu>
            </SidebarContent>
          </div>
          <SidebarContent className="footer">
            <Sheet open={isNotificationsModalOpen} modal={false}>
              <SheetContent
                onClose={() => openCloseNotificationModal(false)}
                side={'left'}
                className={`${menuCollapse ? 'left-[70px]' : 'left-[227px]'} duration-7 z-[999] p-0 transition-all`}
              >
                <SheetHeader className="px-2 pt-2">
                  <SheetTitle>Notifications</SheetTitle>
                </SheetHeader>
                <Notifications
                  moreUnreadWebRecords={moreUnreadWebRecords}
                  moreReadWebRecords={moreReadWebRecords}
                  notificationDetails={notificationDetails}
                  muteState={muteState}
                  readNotifications={readNotifications}
                  unreadNotifications={unreadNotifications}
                  readNotificationsOffsetCounter={
                    readNotificationsOffsetCounter
                  }
                  unreadNotificationsOffsetCounter={
                    unreadNotificationsOffsetCounter
                  }
                  updatingNotification={updatingNotification}
                  selectTask={selectTask}
                  websiteSelected={websiteSelected}
                />
              </SheetContent>
            </Sheet>
            <Sheet open={openWidget} modal={false}>
              <SheetContent
                onClose={handleCloseWidget}
                side={'left'}
                className={`${menuCollapse ? 'left-[70px]' : 'left-[227px]'} z-[999] w-fit max-w-fit overflow-hidden bg-[#2F326A] p-0 text-white transition-all duration-75 sm:max-w-fit`}
              >
                <SheetHeader>
                  <SheetTitle className="bg-[#2F326A] px-2 pt-2 text-white">
                    What's New
                  </SheetTitle>
                  <FrillWidget />
                </SheetHeader>
              </SheetContent>
            </Sheet>

            <GotoChromeButton menuCollapse={menuCollapse} />
            {/* <Popover
              open={isNotificationsModalOpen}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              classes={{ paper: classes.paperBell, root: classes.popover }}
              className={
                menuCollapse
                  ? 'bell-notification-collapsed-container'
                  : 'bell-notification-container'
              }
              TransitionComponent={Transition}
              transitionDuration={500}
            >
              <Notifications
                // loadingNotifications={loadingNotifications}
                moreUnreadWebRecords={moreUnreadWebRecords}
                moreReadWebRecords={moreReadWebRecords}
                notificationDetails={notificationDetails}
                muteState={muteState}
                readNotifications={readNotifications}
                unreadNotifications={unreadNotifications}
                readNotificationsOffsetCounter={readNotificationsOffsetCounter}
                unreadNotificationsOffsetCounter={
                  unreadNotificationsOffsetCounter
                }
                updatingNotification={updatingNotification}
                selectTask={selectTask}
                websiteSelected={websiteSelected}
              />
            </Popover> */}
            {/* <Popover
              open={openWidget}
              onClose={handleCloseWidget}
              className={
                menuCollapse
                  ? 'whats-new-collapsed-container'
                  : 'whats-new-container'
              }
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              TransitionComponent={Transition}
              transitionDuration={500}
            >
              <FrillWidget />
            </Popover> */}
            <Menu>
              {user.role !== 'collaborator' ? (
                <>
                  <MenuItem
                    className="tooltip"
                    title="Notifications"
                    onClick={() => {
                      openCloseNotificationModal(!isNotificationsModalOpen);
                      handleCloseWidget();
                    }}
                  >
                    <span className="tooltip-content">Notifications</span>
                    <Badge
                      badgeContent={muteState ? 0 : unreadCount}
                      className="notification-counter"
                    >
                      {!muteState ? (
                        <FaRegBell size={20} className={classes.bullIcon} />
                      ) : (
                        <FaRegBellSlash
                          size={30}
                          className={classes.bullIcon}
                        />
                      )}
                    </Badge>
                    <span className={classes.whatsNewText}>Notifications</span>
                  </MenuItem>
                  <MenuItem
                    className="tooltip"
                    title="What's New"
                    onClick={() => {
                      handleClickWidget(true);
                      openCloseNotificationModal(false);
                    }}
                  >
                    <span className="tooltip-content">What's New</span>
                    <span className="notification-counter">
                      <FaBullhorn size={20} />
                      <span className={classes.whatsNewText}>What's New</span>
                    </span>
                  </MenuItem>
                </>
              ) : (
                <></>
              )}
              <div className="user-details flex gap-2">
                <Avatar
                  src={user.avatar}
                  borderRadius="lg"
                  size="fit"
                  className="max-h-12 max-w-12"
                  id={user.normal_id}
                  alt={user.name}
                  onClick={handleClick}
                  title={user.name}
                />

                {/* <span className="status active"></span>
                  </span> */}
                <span className="username">{user.name}</span>
                <div className="pro-inner-list-item popper-element">
                  <div className="popper-inner menu-user-popout">
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      disableAutoFocusItem
                      disablePortal
                    >
                      <MenuItem
                        onClick={() => {
                          navigate('/user');
                        }}
                      >
                        <Avatar
                          src={user.avatar}
                          borderRadius="lg"
                          size="lg"
                          className="h-12 w-12"
                          id={user.normal_id}
                          alt={user.name}
                          onClick={handleClick}
                          title={user.name}
                        />
                        <div className="ml-2 flex flex-col gap-1">
                          <span className="username font-medium leading-none text-black/80">
                            {user.name}
                          </span>
                          <Link
                            to={`/user`}
                            className="at-ch-user-view-profile leading-none"
                            title="My Profile"
                          >
                            View Profile
                          </Link>
                        </div>
                      </MenuItem>
                      <hr className="at-ch-user-details-group-divider" />
                      <MenuItem onClick={handleClose}>
                        <Button
                          className="w-full border-0 bg-transparent outline-none"
                          size="sm"
                          leftIcon={<BiPowerOff size={24} />}
                          onClick={() => {
                            logoutUser();
                          }}
                        >
                          Logout
                        </Button>
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>
      <footer className="w-100 fixed bottom-0 z-[100] flex h-16 flex-row justify-between bg-[#2F326A] px-2 text-[10px] md:hidden">
        <span
          className={clsx(
            'flex flex-col items-center justify-center gap-1 p-2',
            location.pathname === '/settings' ? 'text-tertiary' : 'text-white'
          )}
          onClick={() => {
            navigate('/settings?tab_flag=workspace');
          }}
          active={location.pathname === '/tasks'}
          selected={selectedIndex === 2}
        >
          <Avatar
            src={selectedWorkspace?.icon}
            alt={selectedWorkspace?.name}
            id={selectedWorkspace?.id}
            size="xs"
            className="my-0.5"
            title={selectedWorkspace.name}
          />
          Workspaces
        </span>
        <span
          className={clsx(
            'flex flex-col items-center justify-center gap-1 p-2',
            location.pathname === '/tasks' ? 'text-tertiary' : 'text-white'
          )}
          onClick={() => {
            navigate('/tasks');
          }}
          active={location.pathname === '/tasks'}
          selected={selectedIndex === 2}
        >
          <BiEnvelope size={24} /> Inbox
        </span>

        <span
          className="flex flex-col items-center justify-center p-2 text-white"
          onClick={() => {
            navigate('/user');
          }}
        >
          <Avatar
            src={user.avatar}
            borderRadius="lg"
            size="lg"
            id={user.normal_id}
            alt={user.name}
            onClick={handleClick}
            title={user.name}
          />
        </span>
        <span
          className={clsx(
            'flex flex-col items-center justify-center gap-1 p-2',
            location.pathname === '/board' ? 'text-tertiary' : 'text-white'
          )}
          onClick={() => {
            navigate('/board');
          }}
          active={location.pathname === '/board'}
          selected={selectedIndex === 3}
        >
          <PiKanban size={24} /> Boards
        </span>

        {user.role !== 'collaborator' && (
          <span
            className="flex flex-col items-center justify-center gap-1 p-2 text-white"
            title="Notifications"
            onClick={() =>
              openCloseNotificationModal(!isNotificationsModalOpen)
            }
          >
            {!muteState && (
              <Badge badgeContent={muteState ? 0 : unreadCount} className=" ">
                {!muteState ? (
                  <FaRegBell size={20} className="my-0.5" />
                ) : (
                  <FaRegBellSlash size={30} className={classes.bullIcon} />
                )}
              </Badge>
            )}
            Notifications
          </span>
        )}
      </footer>
      {isPlansDialogOpened && <PlanUpgrading closeParentDialogs={() => {}} />}
    </>
  );
};

export default withStyles(classes)(SiteNav);
