import React, { useState } from 'react';
// Styling
import { makeStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  FormControlLabel,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
} from '@material-ui/core';
// Utils
import clsx from 'clsx';
import { toHMS } from '../utils/functions';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 10,
    fontFamily: theme.text.normalText.fontFamily,
    minWidth: 500,
  },
  boldText: {
    fontFamily: theme.text.normalText.fontFamily,
    fontWeight: 500,
    color: theme.palette.text.primary,
    fontSize: 17,
    borderBottom: '1px solid #EFF2F6',
    padding: '18.5px 20px',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    borderBottom: '1px solid #eff2f6',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 550,
    minWidth: 550,
    padding: '10px 0px',
  },
  task: {
    fontSize: 14,
    fontWeight: 500,
  },
  website: {
    fontSize: 14,
    color: theme.palette.custom.color,
  },
  row: {
    display: 'flex',
  },
  timeSpent: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
  },
  typography: {
    fontWeight: 500,
    paddingRight: 10,
  },
  field: {
    paddingRight: 10,
  },
  checkBoxLabel: {
    fontSize: 14,
  },
  description: {
    width: '100%',
    resize: 'none',
    boxSizing: 'border-box',
    borderColor: 'rgb(0, 0, 0, 0.3)',
    borderRadius: 4,
    padding: '10px 10px 0px 10px',
    height: 100,
    fontSize: 14,
    fontFamily: theme.text.normalText.fontFamily,
  },
  okBtn: {
    backgroundColor: '#3ed696',
    color: theme.palette.text.primary,
    fontWeight: 500,
    borderRadius: 5,
    padding: '6px 16px',
    fontSize: '14px',
    height: 36,
    '&:hover': {
      backgroundColor: '#33bf84',
    },
    '&:disabled': {
      backgroundColor: '#ECF3F9',
      color: theme.palette.custom.color,
    },
  },
  cancelBtn: {
    color: theme.palette.custom.color,
    borderRadius: 5,
    padding: '6px 16px',
    fontSize: 14,
    marginRight: 10,
    backgroud: 'transparent',
    height: 36,
    '&:hover': {
      backgroundColor: '#ECF3F9',
    },
  },
  PopcancelOkBtn: {
    padding: 20,
  },
}));

export default function LogTimeModal(props) {
  const {
    closeLogModal,
    updateEntry,
    id,
    userId,
    startTime,
    endTime,
    spentTime,
    // description,
    // billable,
    taskName,
    siteName,
    localDescription,
    localBillable,
    taskId,
    // sendMessage,
    // timerPlay,
    sendMessageWithoutPosting,
    localTimer,
    timerItemIndex,
    selectedItem,
  } = props;

  const classes = useStyles();

  const [stateSpentTime, setStateSpentTime] = useState(spentTime);
  // if logTimeModal is open for selected item the description remain same
  const [stateDescription, setStateDescription] = useState(
    timerItemIndex == selectedItem ? localDescription : ''
  );
  const [stateBillable, setStateBillable] = useState(localBillable);
  const [completeTask, setCompleteTask] = useState(false);

  const logTime = (e) => {
    e.preventDefault();
    // update the existing entry into the dB
    updateEntry({
      id: id,
      user_id: userId,
      start_time: startTime,
      end_time: endTime,
      spent_time: stateSpentTime,
      description: stateDescription,
      billable: stateBillable,
      logged: true,
      complete: completeTask,
      task_id: taskId,
    });
    // send a note
    var desc = stateDescription ? stateDescription : '';
    var comment_content = '';
    if (stateBillable)
      comment_content = `${toHMS(stateSpentTime)} seconds logged for this task (Billable) \n ${desc}`;
    else
      comment_content = `${toHMS(stateSpentTime)} seconds logged for this task \n ${desc}`;

    sendMessageWithoutPosting({
      task_id: taskId,
      comment_content: comment_content,
      is_note: true,
      comment_type: 'normal_text',
      complete_task: false,
    });
    // close the modal
    closeLogModal();
  };

  const updateDescription = (e) => {
    setStateDescription(e.target.value);
  };

  const removeTags = (str) => {
    if (str === null || str === '') return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, '');
  };

  return (
    <div className={classes.container}>
      <DialogTitle className={classes.boldText} disableTypography>
        Create a Time Entry
      </DialogTitle>
      <DialogContent>
        <div className={classes.details}>
          <span className={classes.website}>{siteName}</span>
          <span className={classes.task}>{removeTags(taskName)}</span>
        </div>
        <div className={classes.row}>
          <div className={classes.timeSpent}>
            <span className={classes.typography}>Time Spent:</span>
            <span className={classes.field}>{toHMS(localTimer)}</span>
          </div>
          <span>
            {
              <FormControlLabel
                control={
                  <Checkbox
                    checked={stateBillable}
                    color="primary"
                    value={Boolean(stateBillable)}
                  />
                }
                label="Billable"
                classes={{ label: clsx(classes.checkBoxLabel) }}
                style={{
                  marginRight: 0,
                }}
                onChange={() => setStateBillable((prev) => !prev)}
              />
            }
          </span>
        </div>
        <div>
          <textarea
            className={classes.description}
            onChange={updateDescription}
            value={stateDescription}
            placeholder="Describe what you are working on..."
          />
        </div>
        <div className={classes.lastRow}>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={completeTask}
                  color="primary"
                  value={Boolean(completeTask)}
                />
              }
              label="Complete task"
              classes={{ label: clsx(classes.checkBoxLabel) }}
              onChange={() => setCompleteTask((prev) => !prev)}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.PopcancelOkBtn}>
        <Button
          onClick={closeLogModal}
          color="primary"
          className={classes.cancelBtn}
        >
          Cancel
        </Button>
        <Button
          onClick={logTime}
          color="primary"
          className={classes.okBtn}
          disabled={stateSpentTime === 0}
        >
          Create a Time Entry
        </Button>
      </DialogActions>
    </div>
  );
}
