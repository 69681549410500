import {
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import {
  ADD_TO_PROJECT,
  ADD_TO_SITE,
  ERROR_ADD_TO_PROJECT,
  ERROR_ADD_TO_SITE,
  ERROR_DELETE_USER,
  ERROR_FETCH_FEED_USER,
  ERROR_FETCH_MORE_SITE_LIST_USER,
  ERROR_FETCH_NEXT_USERS_LIST_ACTIVITY_PAGE,
  ERROR_FETCH_PROJECT_USER,
  ERROR_FETCH_SITE_LIST_USER,
  ERROR_FETCH_USERS_LIST_ACTIVITY_PAGE,
  ERROR_REMOVE_INVITED_TEAM,
  ERROR_RE_INVITE_USER,
  ERROR_UPDATE_NOTIFICATION,
  ERROR_UPDATE_PROFILE,
  ERROR_UPLOAD_IMAGE,
  FETCH_FEED_USER,
  FETCH_INVITED_USERS_LIST_ACTIVITY_PAGE,
  FETCH_MORE_SITE_LIST_USER,
  FETCH_NEXT_USERS_LIST_ACTIVITY_PAGE,
  FETCH_PROJECT_USER,
  FETCH_SITE_LIST_USER,
  FETCH_USERS_LIST_ACTIVITY_PAGE,
  INVITE_USER,
  INVITE_USER_NOT_SENT,
  INVITE_USER_SENT,
  REMOVE_INVITED_TEAM,
  START_DELETE_USER,
  START_RE_INVITE_USER,
  START_UPLOAD_IMAGE,
  SUCCESS_ADD_TO_PROJECT,
  SUCCESS_ADD_TO_SITE,
  SUCCESS_DELETE_USER,
  SUCCESS_FETCH_FEED_USER,
  SUCCESS_FETCH_INVITED_USERS_LIST_ACTIVITY_PAGE,
  SUCCESS_FETCH_MORE_SITE_LIST_USER,
  SUCCESS_FETCH_NEXT_USERS_LIST_ACTIVITY_PAGE,
  SUCCESS_FETCH_PROJECT_USER,
  SUCCESS_FETCH_SITE_LIST_USER,
  SUCCESS_FETCH_USERS_LIST_ACTIVITY_PAGE,
  SUCCESS_REMOVE_INVITED_TEAM,
  SUCCESS_RE_INVITE_USER,
  SUCCESS_UPDATE_NOTIFICATION,
  SUCCESS_UPDATE_PROFILE,
  SUCCESS_UPLOAD_IMAGE,
  UPDATE_NOTIFICATION,
  UPDATE_PROFILE,
} from '../actions/activity';

import { LOGOUT } from '../actions/user';

import {
  addProjectToUser,
  addSiteToUser,
  deleteUser,
  fetchAllUsersList,
  fetchFeed,
  fetchProjectForUser,
  fetchSiteForUser,
  inviteUser,
  invitedUserlist,
  reInviteUser,
  removeInvitedTeam,
  updateNotification,
  updateUserProfile,
} from '../api/activity';
// Utils
import { LogoutObjectForPutEffect } from '../utils/constants';
// Utils
// Actions
import { SUCCESS_REMOVE_COLLABORATE_SITE } from '../actions/dashboard';

function* updateUserNotificationWorker(paramObj) {
  const data = yield call(updateNotification, paramObj);
  if (data.status) {
    yield put({
      type: SUCCESS_UPDATE_NOTIFICATION,
      message: data.message || 'Notification preference updated',
      paramObj,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_UPDATE_NOTIFICATION,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* updateUserNotificationWatcher() {
  while (true) {
    const { paramObj } = yield take(UPDATE_NOTIFICATION);
    yield fork(updateUserNotificationWorker, paramObj);
  }
}

function* inviteUserWorker(users) {
  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );
  const data = yield call(inviteUser, { users }, selectedWorkspace?.id);
  if (data.status) {
    yield put({
      type: INVITE_USER_SENT,
      message: `Invitation sent to user${users.length > 1 ? 's' : ''}`,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    /* The below loop has been written as creating user friendly message was not possible at server side  */
    const mapErrorToInputFields = { first_name: [], last_name: [], email: [] };
    let mailExists = '';
    data.message.forEach((m) => {
      m.forEach((t) => {
        const field = t.match(/users\.[0-9]+\.(email|first_name|last_name)/);
        if (field.length > 0) {
          const fieldArray = field[0].split('.'); // users, 0, email|first_name|last_name
          mapErrorToInputFields[fieldArray[2]].push(parseInt(fieldArray[1]));
          if (!mailExists && fieldArray[2] === 'email' && /taken/.test(t)) {
            mailExists = 'Some email(s) are already taken!';
          }
        }
      });
    });
    yield put({
      type: INVITE_USER_NOT_SENT,
      message: `Please review your input!${mailExists.length > 0 ? ' ' : ''}${mailExists}`, // because data.message will be an array if error occurs
      mapErrorToInputFields,
    });
  }
}

export function* inviteUserWatcher() {
  while (true) {
    const { form } = yield take(INVITE_USER);
    yield fork(inviteUserWorker, form);
    yield take([INVITE_USER_SENT, LOGOUT, INVITE_USER_NOT_SENT]);
  }
}

function* fetchAllUsersListWorker(projectId) {
  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );

  const data = yield call(
    fetchAllUsersList,
    projectId,
    undefined,
    selectedWorkspace?.id
  );

  if (data.status) {
    yield put({
      type: SUCCESS_FETCH_USERS_LIST_ACTIVITY_PAGE,
      usersList: data.data,
      moreUsersRecord: data.more_web_records,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_FETCH_USERS_LIST_ACTIVITY_PAGE,
      message: data.message || 'Invite not sent, try again later!',
    });
  }
}

export function* fetchAllUsersListWatcher() {
  while (true) {
    const { projectId } = yield take(FETCH_USERS_LIST_ACTIVITY_PAGE);
    yield fork(fetchAllUsersListWorker, projectId);
    yield take([
      SUCCESS_FETCH_USERS_LIST_ACTIVITY_PAGE,
      LOGOUT,
      ERROR_FETCH_USERS_LIST_ACTIVITY_PAGE,
    ]);
  }
}
//// fetch next users list when scrolled down
function* fetchNextUsersListWorker(projectId, pageNo) {
  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );

  const data = yield call(
    fetchAllUsersList,
    projectId,
    pageNo,
    selectedWorkspace?.id
  );

  if (data.status) {
    yield put({
      type: SUCCESS_FETCH_NEXT_USERS_LIST_ACTIVITY_PAGE,
      usersList: data.data,
      moreUsersRecord: data.more_web_records,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_FETCH_NEXT_USERS_LIST_ACTIVITY_PAGE,
      message: data.message || 'Invite not sent, try again later!',
    });
  }
}

export function* fetchNextUsersListWatcher() {
  while (true) {
    const { projectId, pageNo } = yield take(
      FETCH_NEXT_USERS_LIST_ACTIVITY_PAGE
    );
    yield fork(fetchNextUsersListWorker, projectId, pageNo);
    yield take([
      SUCCESS_FETCH_NEXT_USERS_LIST_ACTIVITY_PAGE,
      LOGOUT,
      ERROR_FETCH_NEXT_USERS_LIST_ACTIVITY_PAGE,
    ]);
  }
}

function* fetchFeedsWorker(action) {
  const { pageNumber, userId, status } = action;

  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );

  const data = yield call(
    fetchFeed,
    userId,
    pageNumber,
    status,
    selectedWorkspace?.id
  );
  if (data.status) {
    yield put({
      type: SUCCESS_FETCH_FEED_USER,
      feeds: data.data.data,
      currentPage: data.data.current_page,
      lastPage: data.data.last_page,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_FETCH_FEED_USER,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* fetchFeedsWatcher() {
  yield takeLatest(FETCH_FEED_USER, fetchFeedsWorker);
  // I've used takeLatest here as it is possible for a user to select first user A
  // before any response is received the user changes to user B
  // first user B's response arrives then user A's
  // so even though user B is selected one will se the data of user A which is WRONG!
}

function* fetchSiteListUserWorker(userId, pageNo, limit, projectId) {
  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );

  const data = yield call(
    fetchSiteForUser,
    userId,
    pageNo,
    limit,
    projectId,
    selectedWorkspace?.id
  );
  if (data.status) {
    yield put({
      type: SUCCESS_FETCH_SITE_LIST_USER,
      sites: data.data,
      moreSitesRecord: data.moreSitesRecord,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_FETCH_SITE_LIST_USER,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* fetchSiteListUserWatcher() {
  while (true) {
    const { userId, pageNo, limit, projectId } =
      yield take(FETCH_SITE_LIST_USER);
    yield fork(fetchSiteListUserWorker, userId, pageNo, limit, projectId);
  }
}

function* newFetchSiteListUserWorker(userId, pageNo, limit, projectId) {
  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );

  const data = yield call(
    fetchSiteForUser,
    userId,
    pageNo,
    limit,
    projectId,
    selectedWorkspace?.id
  );
  if (data.status) {
    yield put({
      type: SUCCESS_FETCH_MORE_SITE_LIST_USER,
      sites: data.data,
      moreSitesRecord: data.moreSitesRecord,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_FETCH_MORE_SITE_LIST_USER,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* newFetchSiteListUserWatcher() {
  while (true) {
    const { userId, pageNo, limit, projectId } = yield take(
      FETCH_MORE_SITE_LIST_USER
    );
    yield fork(newFetchSiteListUserWorker, userId, pageNo, limit, projectId);
  }
}

function* addSiteToUserWorker(websiteId, isAdd, userId, collab) {
  const data = yield call(addSiteToUser, {
    site_id: websiteId,
    attach_contributor: isAdd,
    user_id: userId,
  });

  if (data.status) {
    yield put({
      type: SUCCESS_ADD_TO_SITE,
      websiteId,
      isAdd,
    });
    if (collab) {
      yield put({
        type: SUCCESS_REMOVE_COLLABORATE_SITE,
        siteId: websiteId,
      });
    }
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_ADD_TO_SITE,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* addSiteToUserWatcher() {
  while (true) {
    const { websiteId, isAdd, userId, collab } = yield take(ADD_TO_SITE);
    yield fork(addSiteToUserWorker, websiteId, isAdd, userId, collab);
  }
}

function* uploadAvatarWorker(userId, avatar) {
  const data = yield call(updateUserProfile, userId, avatar);
  if (data.status) {
    yield put({
      type: SUCCESS_UPLOAD_IMAGE,
      userId,
      avatar: data.data.avatar,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_UPLOAD_IMAGE,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* uploadAvatarWatcher() {
  while (true) {
    const { userId, avatar } = yield take(START_UPLOAD_IMAGE);
    yield fork(uploadAvatarWorker, userId, avatar);
  }
}

function* updateUserProfileWorker(userId, paramObj) {
  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );
  const data = yield call(
    updateUserProfile,
    userId,
    paramObj,
    selectedWorkspace?.id
  );
  if (data.status) {
    const key = Object.keys(paramObj)[0];
    yield put({
      type: SUCCESS_UPDATE_PROFILE,
      userId,
      key,
      value: paramObj[key],
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_UPDATE_PROFILE,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* updateUserProfileWatcher() {
  while (true) {
    const { userId, paramObj } = yield take(UPDATE_PROFILE);
    yield fork(updateUserProfileWorker, userId, paramObj);
  }
}

function* deleteUserWorker(action) {
  const { userId } = action;
  const response = yield call(deleteUser, userId);
  if (response.status) {
    yield put({
      type: SUCCESS_DELETE_USER,
      userId,
      message: 'User successfully deleted!',
    });
  } else if (response.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_DELETE_USER,
      message: response.message || 'User not deleted, try again later!',
    });
  }
}

export function* deleteUserWatcher() {
  yield takeLatest(START_DELETE_USER, deleteUserWorker);
}

function* startReInvitationUser(action) {
  const { userId } = action;
  const response = yield call(reInviteUser, userId);
  if (response.status) {
    yield put({
      type: SUCCESS_RE_INVITE_USER,
      message: 'User has been reinvited',
      userId,
    });
  } else if (response.unauthenticated) {
    // earlier I did not test for this condition,  maybe I had forgotten
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_RE_INVITE_USER,
      message: 'Re-Invite not sent, try again later!',
      userId,
    });
  }
}

export function* startReInvitationUserWatcher() {
  yield takeEvery(START_RE_INVITE_USER, startReInvitationUser);
}

function* fetchProjectUserWorker(userId) {
  const selectedWorkspace = yield select(
    (state) => state.workspace.selectedWorkspace
  );

  const data = yield call(fetchProjectForUser, userId, selectedWorkspace?.id);
  if (data.status) {
    yield put({
      type: SUCCESS_FETCH_PROJECT_USER,
      projects: data.data,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_FETCH_PROJECT_USER,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* fetchProjectUserWatcher() {
  while (true) {
    const { userId } = yield take(FETCH_PROJECT_USER);
    yield fork(fetchProjectUserWorker, userId);
  }
}

function* addProjectToUserWorker(action) {
  const { projectId, userId, isAdd } = action;

  const data = yield call(addProjectToUser, {
    project_id: projectId,
    user_id: userId,
    attach_contributor: isAdd,
  });

  if (data.status) {
    yield put({
      type: SUCCESS_ADD_TO_PROJECT,
      projectId,
      isAdd,
    });
  } else if (data.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    yield put({
      type: ERROR_ADD_TO_PROJECT,
      message: data.message || 'Something went wrong, try again later!',
    });
  }
}

export function* addProjectToUserWatcher() {
  yield takeLatest(ADD_TO_PROJECT, addProjectToUserWorker);
}

function* fetchInvitedUserWorker() {
  const response = yield call(invitedUserlist);
  if (response.status) {
    yield put({
      type: SUCCESS_FETCH_INVITED_USERS_LIST_ACTIVITY_PAGE,
      data: response.data,
      message: 'User successfully deleted!',
    });
  } else if (response.unauthenticated) {
    yield put(LogoutObjectForPutEffect);
  } else {
    // yield put({
    //     type: ERROR_FETCH_INVITED_USERS_LIST_ACTIVITY_PAGE,
    //     message: response.message || "Try again later!"
    // });
  }
}

export function* fetchInvitedUserWatcher() {
  yield takeLatest(
    FETCH_INVITED_USERS_LIST_ACTIVITY_PAGE,
    fetchInvitedUserWorker
  );
}

function* removeInvitedTeamWorker(action) {
  const { params } = action;
  const response = yield call(removeInvitedTeam, params);
  if (response.status) {
    yield put({
      type: SUCCESS_REMOVE_INVITED_TEAM,
      message: response.result,
      userId: params?.token_id,
    });
  } else {
    yield put({
      type: ERROR_REMOVE_INVITED_TEAM,
      message: 'Something went wrong, try again later!',
    });
  }
}

export function* removeInvitedTeamWatcher() {
  yield takeLatest(REMOVE_INVITED_TEAM, removeInvitedTeamWorker);
}
