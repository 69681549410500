import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Styling
import { Button, Dialog, makeStyles } from '@material-ui/core';
import qs from 'qs';
import { RiCloseFill } from 'react-icons/ri';
// Actions
import { HANDLE_PLANS_DIALOG } from '../../actions/user';

const makeClasses = makeStyles((theme) => ({
  upgradeImgShadow: {
    width: '50%',
    height: '15%',
    display: 'block',
    left: '25%',
    top: '45%',
    position: 'absolute',
    boxShadow: '0px 0px 119px 50px #292170',
    zIndex: '-1',
    borderRadius: '104px',
  },
}));

const LockFeatureModal = (props) => {
  const {
    openLockFeatureModal,
    lockTitle,
    lockParagraph,
    lockImg,
    handleCloseLock,
    upSellUrl,
    featureString,
  } = props;

  const { userData } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const classes = makeClasses();

  let url = qs.parse(upSellUrl, { ignoreQueryPrefix: true }).users;

  const unlockClick = (path) => {
    if (userData?.plan?.from_free_plan) {
      dispatch({
        type: HANDLE_PLANS_DIALOG,
        params: true,
      });
    } else {
      window.open(path);
    }
  };

  return (
    <>
      <Dialog
        open={openLockFeatureModal}
        classes={{
          paper: classes.dialogUpgrade,
        }}
        className="at-cs-modal-popover m-auto my-0 w-[750px]"
      >
        <div className="border-b p-5 text-lg font-semibold">
          Let's Unlock This Feature
        </div>
        <span
          className="at-cs-btn no-bg square large-icon red btn-right absolute right-4 top-4"
          onClick={handleCloseLock}
        >
          <RiCloseFill />
        </span>
        <div className="flex flex-row p-3.5">
          <div className="relative p-2.5">
            <div className={classes.upgradeImgShadow}></div>
            <img
              className="-mx-2.5 block min-w-[480px]"
              src={lockImg}
              alt="img"
            />
          </div>
          <div className="flex flex-col gap-4 rounded-md border px-3.5 py-5">
            <div className="text-base font-bold leading-none">{lockTitle}</div>
            <p className="text-sm">{lockParagraph}</p>
            {url ? (
              <Button
                className="at-cs-btn btn-green w-100"
                onClick={() =>
                  unlockClick(`${upSellUrl}&feature=${featureString}`)
                }
              >
                Upgrade To Unlock
              </Button>
            ) : (
              <Button
                className="at-cs-btn btn-green w-100"
                onClick={() => unlockClick(`${upSellUrl}`)}
              >
                Upgrade To Unlock
              </Button>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default LockFeatureModal;
