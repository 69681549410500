import React, { useEffect } from 'react';
// Styling
import { makeStyles } from '@material-ui/core';
import SelectedIcon from '../../media/svgs/marked-purple.svg?react';
// Utils
import clsx from 'clsx';
import { BorderLinearProgress } from '../../utils/constants';

const makeClasses = makeStyles((theme) => ({
  titleCopy: {
    fontFamily: theme.text.normalText.fontFamily,
    fontWeight: 700,
    fontSize: 17,
    textAlign: 'left',
  },
  boldText: {
    fontFamily: theme.text.normalText.fontFamily,
  },
  nextBtn: {
    margin: '0',
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: 14,
    color: theme.palette.primary.main,
    border: 'none',
    borderRadius: 5,
    backgroundColor: '#3ed696',
    '&:hover': {
      backgroundColor: '#33bf84',
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },

  companyTypeItem: {
    position: 'relative',
    flexBasis: '33%',
    height: '50px',
    border: '1px solid #EFF2F6',
    borderRadius: 5,
    background: '#FFF',
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    fontWeight: 500,
  },
  selectedCompanyTypeItem: {
    outline: '2px solid #6D5DF3',
    color: '#6D5DF3',
  },
  selectedIcon: {
    position: 'absolute',
    top: 5,
    right: 6,
    overflow: 'visible',
  },
  progressContainer: {
    marginBottom: 20,
  },
  descCopy: {
    fontFamily: theme.text.normalText.fontFamily,
    color: '#272D3C',
    fontSize: '14px',
    lineHeight: '20px',
    margin: '10px 0px 10px 0px !important',
  },
}));

const SelectCompanyType = (props) => {
  const classes = makeClasses();
  const { handleSelectNext, companyFocus, handleUpdateTrial, handlePostTrial } =
    props;

  const [selectedCompanyType, setSelectedCompanyType] = React.useState(null);

  const companyType = [
    { name: 'Your team', value: 'team' },
    { name: 'Your clients', value: 'client' },
    { name: 'Both', value: 'both' },
  ];

  const handleSelect = (key, value) => {
    handleUpdateTrial(key, value);
    setSelectedCompanyType(value);
    handleNextForm(value);
  };

  useEffect(() => {
    if (companyFocus) {
      setSelectedCompanyType(companyFocus);
    }
  }, [companyFocus]);

  const handleNextForm = (value) => {
    handlePostTrial({
      focus: value,
    });
    handleSelectNext(selectedCompanyType);
  };

  return (
    <>
      <div className="at-cs-trial-wizard-modal">
        <div className={classes.progressContainer}>
          <BorderLinearProgress variant="determinate" value={2} />
        </div>
        <div className={classes.titleCopy}>Customize your account</div>
        <div className={classes.descCopy}>
          Tell us more about what you do, and we'll adjust your Atarim account
          to fit your team's unique collaboration style perfectly. You're here
          to work with:
        </div>
        <div className="my-6 flex w-full gap-2 md:gap-6">
          {companyType.map((item, index) => {
            return (
              <div
                className={clsx(
                  'relative flex h-24 w-full cursor-pointer items-center justify-center rounded-lg border font-semibold',
                  selectedCompanyType === item.value &&
                    classes.selectedCompanyTypeItem
                )}
                onClick={() => handleSelect('focus', item.value)}
                key={index}
              >
                {item.name}
                {selectedCompanyType === item.value && (
                  <SelectedIcon className={classes.selectedIcon} />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SelectCompanyType;
