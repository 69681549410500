import axios from 'axios';

export const baseUrl = import.meta.env.VITE_APP_BASE_URL;

const api = axios.create({
  baseURL: baseUrl,
  // timeout: 30000,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
});

api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // extract error message
    if (error.response?.data?.message) {
      error.message = error.response.data.message;
    }

    // extract validation errors
    if (error.response?.data?.errors) {
      // combine all validation errors into a single message
      const errors = Object.values(error.response.data.errors).flat();
      error.errors = {
        message: errors.join(', '),
        ...error.response.data.errors,
      };
    }
    return Promise.reject(error);
  }
);

export default api;
