import React, { useEffect } from 'react';
// Styling
import { makeStyles } from '@material-ui/core';
import SelectedIcon from '../../media/svgs/marked-purple.svg?react';
// Utils
import clsx from 'clsx';
import { BorderLinearProgress } from '../../utils/constants';

const makeClasses = makeStyles((theme) => ({
  titleCopy: {
    fontFamily: theme.text.normalText.fontFamily,
    fontWeight: 700,
    fontSize: 17,
    textAlign: 'left',
  },
  boldText: {
    fontFamily: theme.text.normalText.fontFamily,
  },
  nextBtn: {
    margin: '0',
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: 14,
    color: theme.palette.primary.main,
    borderRadius: 5,
    backgroundColor: '#3ed696',
    border: 'none',
    '&:hover': {
      backgroundColor: '#33bf84',
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },

  companyTypeItem: {
    position: 'relative',
    flexBasis: '33%',
    height: '50px',
    border: '1px solid #EFF2F6',
    borderRadius: 5,
    background: '#FFF',
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    textAlign: 'center',
    cursor: 'pointer',
  },
  selectedCompanyTypeItem: {
    outline: '2px solid #6D5DF3',
    color: '#6D5DF3',
  },
  selectedIcon: {
    position: 'absolute',
    top: 5,
    right: 6,
    overflow: 'visible',
  },
  progressContainer: {
    marginBottom: 20,
  },
  progressBack: {
    '&:before': {
      width: '7px',
      height: '7px',
      border: '2px solid #6D5DF3',
      borderWidth: '2px 2px 0 0',
      color: '#6D5DF3',
      display: 'inline-block',
      marginRight: '2px',
      content: '""',
      marginBottom: '1px',
      '-moz-transform': '"rotate(-135deg)"',
      '-webkit-transform': 'rotate(-135deg)',
      '-o-transform': '"rotate(-135deg)"',
      '-ms-transform': '"rotate(-135deg)"',
      transform: 'rotate(-135deg)',
      fontSize: 14,
      fontWeight: 600,
    },
  },
  descCopy: {
    fontFamily: theme.text.normalText.fontFamily,
    color: '#272D3C',
    fontSize: '14px',
    lineHeight: '20px',
    margin: '10px 0px 10px 0px !important',
  },
}));

const SelectTeamNumber = (props) => {
  const classes = makeClasses();
  const {
    handleSelectNext,
    handleSelectPrevDetail,
    teamSize,
    handleUpdateTrial,
    handlePostTrial,
    companyFocus,
    projectSize,
  } = props;

  const [selectedCompanyType, setSelectedCompanyType] = React.useState(null);
  const [selectedProjectType, setSelectedProjectType] = React.useState(null);

  const companyType = [
    { name: 'Under 20' },
    { name: '21-50' },
    { name: '51-100' },
    { name: '100+' },
  ];

  const companyClientType = [
    { name: 'Under 20' },
    { name: '21-100' },
    { name: '101-500' },
    { name: '500+' },
  ];

  useEffect(() => {
    if (teamSize) {
      setSelectedCompanyType(teamSize);
    }
  }, [teamSize]);

  useEffect(() => {
    if (projectSize) {
      setSelectedProjectType(projectSize);
    }
  }, [projectSize]);

  const handleSelect = (key, value) => {
    if (key === 'team_size') {
      handleUpdateTrial(key, value);
      setSelectedCompanyType(value);
      if (companyFocus === 'both') {
        selectedProjectType && handleNextForm(value, selectedProjectType);
      } else {
        handleNextForm(value, null);
      }
    } else {
      handleUpdateTrial(key, value);
      setSelectedProjectType(value);
      if (companyFocus === 'both') {
        selectedCompanyType && handleNextForm(selectedCompanyType, value);
      } else {
        handleNextForm(null, value);
      }
    }
  };

  const handleNextForm = (team, project) => {
    handlePostTrial({
      team_size: team,
      project_count: project,
    });
    handleSelectNext(selectedCompanyType);
  };

  return (
    <>
      <div className="at-cs-trial-wizard-modal">
        <div className={classes.progressContainer}>
          <BorderLinearProgress variant="determinate" value={18} />
          <div className="at-cs-trial-wizard-progress-label">
            <span
              className={classes.progressBack}
              onClick={handleSelectPrevDetail}
            >
              Back
            </span>
          </div>
        </div>
        {(companyFocus === 'team' || companyFocus === 'both') && (
          <>
            <div className={classes.titleCopy}>
              How many team members do you have
            </div>
            <div className={classes.descCopy}>
              Team members and managers within your organisation that work on
              visual deliverables on a daily/weekly basis. Excluding one-off
              stakeholders and clients.
            </div>
            <div className="my-6 flex w-full gap-2 md:gap-6">
              {companyType.map((item, index) => {
                return (
                  <div
                    className={clsx(
                      'relative flex h-24 w-full cursor-pointer items-center justify-center rounded-lg border font-semibold',
                      selectedCompanyType === item.name &&
                        classes.selectedCompanyTypeItem
                    )}
                    onClick={() => handleSelect('team_size', item.name)}
                    key={index}
                  >
                    {item.name}
                    {selectedCompanyType === item.name && (
                      <SelectedIcon className={classes.selectedIcon} />
                    )}
                  </div>
                );
              })}
            </div>
          </>
        )}
        {(companyFocus === 'client' || companyFocus === 'both') && (
          <>
            <div className={classes.titleCopy}>
              How many clients/projects do you manage yearly?
            </div>
            <div className={classes.descCopy}>
              Including new projects and ongoing contracts, retainers, and
              maintenace plans.
            </div>
            <div className="my-6 flex w-full gap-2 md:gap-6">
              {companyClientType.map((item, index) => {
                return (
                  <div
                    className={clsx(
                      'relative flex h-24 w-full cursor-pointer items-center justify-center rounded-lg border font-semibold',
                      selectedProjectType === item.name &&
                        classes.selectedCompanyTypeItem
                    )}
                    onClick={() => handleSelect('project_count', item.name)}
                    key={index}
                  >
                    {item.name}
                    {selectedProjectType === item.name && (
                      <SelectedIcon className={classes.selectedIcon} />
                    )}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SelectTeamNumber;
