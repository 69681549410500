export const START_FETCH_DASHBOARD_DATA = 'START_FETCH_DASHBOARD_DATA';
export const SUCCESS_FETCH_DASHBOARD_DATA = 'SUCCESS_FETCH_DASHBOARD_DATA';
export const ERROR_FETCH_DASHBOARD_DATA = 'ERROR_FETCH_DASHBOARD_DATA';
export const REDIRECT_TO_TASKS = 'REDIRECT_TO_TASKS';

export const RESET_ADDED_MILESTONE = 'RESET_ADDED_MILESTONE';

export const FETCH_CONTRIBUTORS_OF_SITE = 'FETCH_CONTRIBUTORS_OF_SITE';
export const FETCH_CONTRIBUTORS_OF_SITE_SUCCESS =
  'FETCH_CONTRIBUTORS_OF_SITE_SUCCESS';
export const FETCH_CONTRIBUTORS_OF_SITE_ERROR =
  'FETCH_CONTRIBUTORS_OF_SITE_ERROR';

export const UPDATE_CONTRIBUTOR_STATUS_IN_SITE =
  'UPDATE_CONTRIBUTOR_STATUS_IN_SITE';
export const UPDATE_CONTRIBUTOR_STATUS_IN_SITE_SUCCESS =
  'UPDATE_CONTRIBUTOR_STATUS_IN_SITE_SUCCESS';
export const UPDATE_CONTRIBUTOR_STATUS_IN_SITE_ERROR =
  'UPDATE_CONTRIBUTOR_STATUS_IN_SITE_ERROR';

export const EMPTY_MESSAGE_DASHBOARD = 'EMPTY_MESSAGE_DASHBOARD';

export const FETCH_NEXT_WEB_LIST = 'FETCH_NEXT_WEB_LIST';
export const SUCCESS_FETCH_NEXT_WEB_LIST = 'SUCCESS_FETCH_NEXT_WEB_LIST';
export const ERROR_FETCH_NEXT_WEB_LIST = 'ERROR_FETCH_NEXT_WEB_LIST';

export const SOFT_DELETE_SITE = 'SOFT_DELETE_SITE';
export const SUCCESS_SOFT_DELETE_SITE = 'SUCCESS_SOFT_DELETE_SITE';
export const ERROR_SOFT_DELETE_SITE = 'ERROR_SOFT_DELETE_SITE';

export const START_FETCH_ARCHIVED_SITES = 'START_FETCH_ARCHIVED_SITES';
export const SUCCESS_FETCH_ARCHIVED_SITES = 'SUCCESS_FETCH_ARCHIVED_SITES';
export const ERROR_FETCH_ARCHIVED_SITES = 'ERROR_FETCH_ARCHIVED_SITES';

export const START_ACTIVATE_ARCHIVED_SITE = 'START_ACTIVATE_ARCHIVED_SITE';
export const SUCCESS_ACTIVATE_ARCHIVED_SITE = 'SUCCESS_ACTIVATE_ARCHIVED_SITE';
export const ERROR_ACTIVATE_ARCHIVED_SITE = 'ERROR_ACTIVATE_ARCHIVED_SITE';

export const START_SYNC_SITE_IMAGE = 'START_SYNC_SITE_IMAGE';
export const SUCCESS_SYNC_SITE_IMAGE = 'SUCCESS_SYNC_SITE_IMAGE';
export const ERROR_SYNC_SITE_IMAGE = 'ERROR_SYNC_SITE_IMAGE';

export const ADD_GRAPHIC_SITE = 'ADD_GRAPHIC_SITE';
export const SUCCESS_ADD_GRAPHIC_SITE = 'SUCCESS_ADD_GRAPHIC_SITE';
export const ERROR_ADD_GRAPHIC_SITE = 'ERROR_ADD_GRAPHIC_SITE';

export const FETCH_SITE_SETTINGS = 'FETCH_SITE_SETTINGS';
export const SUCCESS_FETCH_SITE_SETTINGS = 'SUCCESS_FETCH_SITE_SETTINGS';
export const ERROR_FETCH_SITE_SETTINGS = 'ERROR_FETCH_SITE_SETTINGS';
export const UPDATE_SITE_SETTINGS = 'UPDATE_SITE_SETTINGS';

export const SAVE_SITE_SETTINGS = 'SAVE_SITE_SETTINGS';
export const SUCCESS_SAVE_SITE_SETTINGS = 'SUCCESS_SAVE_SITE_SETTINGS';
export const ERROR_SAVE_SITE_SETTINGS = 'ERROR_SAVE_SITE_SETTINGS';

export const FETCH_SITES_BASED_ON_PROJECTS = 'FETCH_SITES_BASED_ON_PROJECTS';
export const SUCCESS_FETCH_SITES_BASED_ON_PROJECTS =
  'SUCCESS_FETCH_SITES_BASED_ON_PROJECTS';
export const ERROR_FETCH_SITES_BASED_ON_PROJECTS =
  'ERROR_FETCH_SITES_BASED_ON_PROJECTS';

export const COLLAPSE_PROJECT_SITES = 'COLLAPSE_PROJECT_SITES';

export const FETCH_MILESTONES = 'FETCH_ALL_MILESTONES';
export const SUCCESS_FETCH_MILESTONES = 'SUCCESS_FETCH_ALL_MILESTONES';
export const ERROR_FETCH_MILESTONES = 'ERROR_FETCH_ALL_MILESTONES';

export const ADD_MILESTONE = 'ADD_MILESTONE';
export const SUCCESS_ADD_MILESTONE = 'SUCCESS_ADD_MILESTONE';
export const ERROR_ADD_MILESTONE = 'ERROR_ADD_MILESTONE';

export const GET_MILESTONE_DETAILS = 'GET_MILESTONE_DETAILS';
export const SUCCESS_GET_MILESTONE_DETAILS = 'SUCCESS_GET_MILESTONE_DETAILS';
export const ERROR_GET_MILESTONE_DETAILS = 'ERROR_GET_MILESTONE_DETAILS';

export const COMPLETE_MILESTONE = 'COMPLETE_MILESTONE';
export const SUCCESS_COMPLETE_MILESTONE = 'SUCCESS_COMPLETE_MILESTONE';
export const ERROR_COMPLETE_MILESTONE = 'ERROR_COMPLETE_MILESTONE';

export const UPDATE_MILESTONE_DEATILS_IN_REDUCER =
  'UPDATE_MILESTONE_DEATILS_IN_REDUCER';
export const UPDATE_MILESTONE_DETAILS = 'UPDATE_MILESTONE_DETAILS';
export const SUCCESS_UPDATE_MILESTONE_DETAILS =
  'SUCCESS_UPDATE_MILESTONE_DETAILS';
export const ERROR_UPDATE_MILESTONE_DETAILS = 'ERROR_UPDATE_MILESTONE_DETAILS';

export const DELETE_MILESTONE = 'DELETE_MILESTONE';
export const SUCCESS_DELETE_MILESTONE = 'SUCCESS_DELETE_MILESTONE';
export const ERROR_DELETE_MILESTONE = 'ERROR_DELETE_MILESTONE';

export const MERGE_WEBSITES = 'MERGE_WEBSITES';
export const SUCCESS_MERGE_WEBSITES = 'SUCCESS_MERGE_WEBSITES';
export const ERROR_MERGE_WEBSITES = 'ERROR_MERGE_WEBSITES';
export const CLOSE_MERGE_WEBSITES = 'CLOSE_MERGE_WEBSITES';

export const CREATE_ROCKET_SITE = 'CREATE_ROCKET_SITE';
export const SUCCESS_CREATE_ROCKET_SITE = 'SUCCESS_CREATE_ROCKET_SITE';
export const ERROR_CREATE_ROCKET_SITE = 'ERROR_CREATE_ROCKET_SITE';

export const FETCH_ROCKET_LOCATION = 'FETCH_ROCKET_LOCATION';
export const SUCCESS_FETCH_ROCKET_LOCATION = 'SUCCESS_FETCH_ROCKET_LOCATION';
export const ERROR_FETCH_ROCKET_LOCATION = 'ERROR_FETCH_ROCKET_LOCATION';

export const FETCH_SOURCE_WEBSITE = 'FETCH_SOURCE_WEBSITE';
export const SUCCESS_FETCH_SOURCE_WEBSITE = 'SUCCESS_FETCH_SOURCE_WEBSITE';
export const ERROR_FETCH_SOURCE_WEBSITE = 'ERROR_FETCH_SOURCE_WEBSITE';

export const FETCH_NEXT_SOURCE_WEBSITE = 'FETCH_NEXT_SOURCE_WEBSITE';
export const SUCCESS_FETCH_NEXT_SOURCE_WEBSITE =
  'SUCCESS_FETCH_NEXT_SOURCE_WEBSITE';
export const ERROR_FETCH_NEXT_SOURCE_WEBSITE =
  'ERROR_FETCH_NEXT_SOURCE_WEBSITE';

export const FETCH_DESTINATION_WEBSITES = 'FETCH_DESTINATION_WEBSITES';
export const SUCCESS_DESTINATION_WEBSITES = 'SUCCESS_DESTINATION_WEBSITES';
export const ERROR_DESTINATION_WEBSITES = 'ERROR_DESTINATION_WEBSITES';

export const FETCH_NEXT_DESTINATION_WEBSITES =
  'FETCH_NEXT_DESTINATION_WEBSITES';
export const SUCCESS_NEXT_DESTINATION_WEBSITES =
  'SUCCESS_NEXT_DESTINATION_WEBSITES';
export const ERROR_NEXT_DESTINATION_WEBSITES =
  'ERROR_NEXT_DESTINATION_WEBSITES';
export const UPDATE_SINGLE_ITEM_FOLDER_NAME = 'UPDATE_SINGLE_ITEM_FOLDER_NAME';

export const FETCH_NEXT_PROJECT_SITES = 'FETCH_NEXT_PROJECT_SITES';
export const SUCCESS_FETCH_NEXT_PROJECT_SITES =
  'SUCCESS_FETCH_NEXT_PROJECT_SITES';
export const ERROR_FETCH_NEXT_PROJECT_SITES = 'ERROR_FETCH_NEXT_PROJECT_SITES';

export const SET_NEXT_PROJECT_SITE_ID = 'SET_NEXT_PROJECT_SITE_ID';

export const SUCCESS_SORT_SITES_BASED_ON_PROJECTS =
  'SUCCESS_SORT_SITES_BASED_ON_PROJECTS';
export const ERROR_SORT_SITES_BASED_ON_PROJECTS =
  'ERROR_SORT_SITES_BASED_ON_PROJECTS';

export const START_NEXT_ARCHIVED_SITES = 'START_NEXT_ARCHIVED_SITES';
export const SUCCESS_NEXT_ARCHIVED_SITES = 'SUCCESS_START_NEXT_ARCHIVED_SITES';
export const ERROR_NEXT_ARCHIVED_SITES = 'ERROR_START_NEXT_ARCHIVED_SITES';

export const CREATE_INSTAWP_SITE = 'CREATE_INSTAWP_SITE';
export const SUCCESS_CREATE_INSTAWP_SITE = 'SUCCESS_CREATE_INSTAWP_SITE';
export const ERROR_CREATE_INSTAWP_SITE = 'ERROR_CREATE_INSTAWP_SITE';

export const SEARCH_ARCHIVED = 'SEARCH_ARCHIVED';
export const SUCCESS_SEARCH_ARCHIVED = 'SUCCESS_SEARCH_ARCHIVED';
export const ERROR_SEARCH_ARCHIVED = 'ERROR_SEARCH_ARCHIVED';

export const EMPTY_ARCHIVED_SITES = 'EMPTY_ARCHIVED_SITES';

export const SUCCESS_FETCH_USER_SITES_DATA = 'SUCCESS_FETCH_USER_SITES_DATA';
export const SUCCESS_FETCH_NEXT_USER_SITES_DATA =
  'SUCCESS_FETCH_NEXT_USER_SITES_DATA';

export const SUCCESS_REMOVE_COLLABORATE_SITE =
  'SUCCESS_REMOVE_COLLABORATE_SITE';

export const FETCH_ALL_PROJECTS_FOR_EXPORT = 'FETCH_ALL_PROJECTS_FOR_EXPORT';
export const SUCCESS_FETCH_ALL_PROJECTS_FOR_EXPORT =
  'SUCCESS_FETCH_ALL_PROJECTS_FOR_EXPORT';
export const ERROR_FETCH_ALL_PROJECTS_FOR_EXPORT =
  'ERROR_FETCH_ALL_PROJECTS_FOR_EXPORT';
export const RESET_HAS_GOTTEN_PROJECT_EXPORT_DATA =
  'RESET_HAS_GOTTEN_PROJECT_EXPORT_DATA';

export const UPDATE_DASHBOARD_IMAGE = 'UPDATE_DASHBOARD_IMAGE';

export const DELETE_SITE = 'DELETE_SITE';
export const SUCCESS_DELETE_SITE = 'SUCCESS_DELETE_SITE';
export const ERROR_DELETE_SITE = 'ERROR_DELETE_SITE';
