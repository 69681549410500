import React, { useCallback, useEffect, useState } from 'react';
import { FaRegClock } from 'react-icons/fa';
// Components
// Styling
import { Avatar, Tooltip, Typography, makeStyles } from '@material-ui/core';
import AvatarUser from 'react-avatar';
import {
  // FiBell,
  FiBellOff,
  FiCheck,
  FiEyeOff,
  FiPaperclip,
  FiTrash2,
} from 'react-icons/fi';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import animationData from '../../media/jsons/spinner';
import CheckFilled from '../../media/svgs/check-filled.svg?react';
import CheckOutline from '../../media/svgs/check-outline.svg?react';
import CommentIcon from '../../media/svgs/comment.svg?react';
import StatusIcon from '../../media/svgs/status.svg?react';
import TagIcon from '../../media/svgs/taskTag.svg?react';
import UrgencyIcon from '../../media/svgs/urgency.svg?react';
import UserIcon from '../../media/svgs/user.svg?react';
// Utils
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  CHECK_ALL_NOTIFICATIONS,
  GET_NOTIFICATIONS_DETAILS,
  GET_READ_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATIONS,
  INCREASE_READ_COUNTER,
  INCREASE_UNREAD_COUNTER,
  MUTE_UNMUTE_NOTIFICATIONS,
  OPEN_CLOSE_NOTIFICATIONS_MODAL,
  UPDATE_USER_NOTIFICATION,
} from '../../actions/user';
import { useIntersection } from '../../utils/customHooks';
import { reactAvatarInitials } from '../../utils/functions';

const makeClasses = makeStyles((theme) => ({
  popoverTop: {
    zIndex: 20,
    background: '#ffff',
    width: '100%',
  },
  notificationTop: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '600',
    fontSize: '14px',
    justifyContent: 'space-between',
    fontFamily: theme.text.normalText.fontFamily,
    padding: '10px 10px',
    width: 'auto',
  },
  topIcons: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '12px',
    gap: '15px',
    fontSize: '20px',
  },
  actionHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  pagesWrapper: {
    display: 'flex',
    fontSize: '14px',
    fontFamily: theme.text.normalText.fontFamily,
  },
  pgTitle: {
    cursor: 'pointer',
    borderBottom: '2px solid #279AF1',
    color: '#279AF1',
    fontWeight: '500',
    padding: '3px 10px 15px 10px',
  },
  pgTitleUnselected: {
    cursor: 'pointer',
    color: '#A4ABC5',
    fontWeight: '500',
    padding: '3px 10px 15px 10px',
  },
  circle: {
    width: '31px',
    height: '31px',
    backgroundColor: '#dbdbd7',
    borderRadius: '50%',
  },
  notificationCard: {
    cursor: 'pointer',
    width: '100%',
    minHeight: '60px',
    padding: '8px 8px',
    display: 'flex !important',
    flexDirection: 'row',
    borderRadius: '10px',
    alignItems: 'center !important',
    gap: '10px',
    [theme.breakpoints.down(834)]: {
      width: '100%',
    },
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#ECF3F9',
    },
    transform: 'translateX(0%)',
  },
  fadeOut: {
    cursor: 'pointer',
    width: '315px',
    height: '60px',
    display: 'flex !important',
    flexDirection: 'row',
    padding: '3px',
    borderRadius: '10px',
    alignItems: 'center !important',
    gap: '10px',
    [theme.breakpoints.down(834)]: {
      width: '100%',
    },
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#ECF3F9',
    },
    transform: 'translateX(-100vw)',
    transition: 'all .7s ease-in-out',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  liftCards: {
    cursor: 'pointer',
    width: '315px',
    height: '60px',
    display: 'flex !important',
    flexDirection: 'row',
    padding: '3px',
    borderRadius: '10px',
    alignItems: 'center !important',
    gap: '10px',
    [theme.breakpoints.down(834)]: {
      width: '100%',
    },
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#ECF3F9',
    },
    transform: 'translateY(-7.3vh)',
    transition: 'all .4s ease-in-out',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  notifiycardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontSize: '14px',
    height: '60px',
    width: '100%',
    [theme.breakpoints.down(834)]: {
      justifyContent: 'space-evenly',
      width: '100%',
    },
  },
  cardTopText: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: '#A4ABC5',
    fontWeight: 500,
    fontSize: '14px',
    [theme.breakpoints.down(340)]: {
      justifyContent: 'space-between',
      width: '90%',
    },
  },
  notifiycardContentSpan: {
    fontWeight: 'bold',
    fontSize: '14px',
    width: '250px',
  },
  allNotificationCards: {
    width: '100%',
    // quick hack until we replace nav component
    height: 'calc(100vh - 80px)',
    overflowY: 'auto',
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
    flexDirection: 'column',
    visibility: 'visible',
    opacity: 1,
    transition: 'opacity 0.3s ',
  },
  tabChange: {
    opacity: 0,
  },
  notificationCardIcon: {
    cursor: 'pointer',
    marginBottom: '-2px',
    marginLeft: '10px',
  },
  taskStatusIconComplete: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '15px',
    height: '15px',
    position: 'absolute',
    marginLeft: '28px',
    marginTop: '18px',
    borderRadius: ' 13px 50px 50px 50px',
    background: '#69DC9E',
    color: '#fff',
  },
  taskStatusIcon1: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22px',
    height: '22px',
    position: 'absolute',
    marginLeft: '28px',
    marginTop: '18px',
    borderRadius: ' 13px 50px 50px 50px',
    background: '#fcd227',
    color: '#fff',
  },
  taskStatusIcon2: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22px',
    height: '22px',
    position: 'absolute',
    marginLeft: '28px',
    marginTop: '18px',
    borderRadius: ' 13px 50px 50px 50px',
    background: '#ffa532',
    color: '#fff',
  },
  taskStatusIcon3: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22px',
    height: '22px',
    position: 'absolute',
    marginLeft: '28px',
    marginTop: '18px',
    borderRadius: ' 13px 50px 50px 50px',
    background: '#3ed696',
    color: '#fff',
  },
  taskStatusIconBlack: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22px',
    height: '22px',
    position: 'absolute',
    marginLeft: '28px',
    marginTop: '18px',
    borderRadius: ' 13px 50px 50px 50px',
    background: '#000000',
    color: '#fff',
  },
  taskStatusIcon: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22px',
    height: '22px',
    position: 'absolute',
    marginLeft: '28px',
    marginTop: '18px',
    borderRadius: ' 13px 50px 50px 50px',
    background: '#3ed696',
    color: '#fff',
  },
  NoType: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22px',
    height: '22px',
    position: 'absolute',
    marginLeft: '28px',
    marginTop: '18px',
    borderRadius: ' 13px 50px 50px 50px',
    background: '#dee5eb',
    color: '#fff',
  },
  taskUrgencyIcon1: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22px',
    height: '22px',
    position: 'absolute',
    marginLeft: '28px',
    marginTop: '18px',
    borderRadius: ' 13px 50px 50px 50px',
    background: '#3ed696',
    color: '#fff',
  },
  taskUrgencyIcon2: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22px',
    height: '22px',
    position: 'absolute',
    marginLeft: '28px',
    marginTop: '18px',
    borderRadius: ' 13px 50px 50px 50px',
    background: '#fcd227',
    color: '#fff',
  },
  taskUrgencyIcon3: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22px',
    height: '22px',
    position: 'absolute',
    marginLeft: '28px',
    marginTop: '18px',
    borderRadius: ' 13px 50px 50px 50px',
    background: '#ffa532',
    color: '#fff',
  },
  taskUrgencyIcon4: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22px',
    height: '22px',
    position: 'absolute',
    marginLeft: '28px',
    marginTop: '18px',
    borderRadius: ' 13px 50px 50px 50px',
    background: '#ff5a48',
    color: '#fff',
  },
  taskUrgencyIcon5: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22px',
    height: '22px',
    position: 'absolute',
    marginLeft: '28px',
    marginTop: '18px',
    borderRadius: ' 13px 50px 50px 50px',
    background: '#ECF3F9',
    color: '#fff',
  },
  taskTagChange: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22px',
    height: '22px',
    position: 'absolute',
    marginLeft: '28px',
    marginTop: '18px',
    borderRadius: ' 13px 50px 50px 50px',
    background: '#279af1',
    color: '#fff',
  },
  emailIcon: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22px',
    height: '22px',
    position: 'absolute',
    marginLeft: '28px',
    marginTop: '18px',
    borderRadius: ' 13px 50px 50px 50px',
    background: '#7c6df4',
    color: '#fff',
  },
  cardGreyText: {
    color: '#A4ABC5',
    width: '254px',
  },
  cardIcon: {
    minWidth: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  nodata: {
    width: '100%',
    fontWeight: 400,
    fontSize: '17px',
    height: '100%',
    paddingTop: '30vh',
    color: '#A4ABC5',
    textAlign: 'center',
    fontFamily: theme.text.normalText.fontFamily,
  },
  userAvatar: {
    alignSelf: 'center',
    marginVertical: 'auto',
    cursor: 'pointer',
    borderRadius: '50%',
    '&>img': {
      borderRadius: '50%',
    },
  },
  icon: {
    cursor: 'pointer',
  },
  bellPlaceHolder: {
    width: '100%',
    marginTop: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerIconCross: {
    color: '#E72D67',
  },
  headerIcon: {
    width: '18px',
    height: '18px',
    color: '#A4ABC5',
  },
  loadingNextNotifications: {
    fontFamily: theme.text.normalText.fontFamily,
    textAlign: 'center',
    color: '#279AF1',
    height: 25,
    fontSize: 14,
    fontWeight: 500,
    padding: '15px 0px',
  },
  stagesLoader: {
    width: '100%',
    paddingTop: '30vh',
    height: '100%',
  },
  deleteTask: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22px',
    height: '22px',
    position: 'absolute',
    marginLeft: '28px',
    marginTop: '18px',
    borderRadius: ' 13px 50px 50px 50px',
    background: '#E72D67',
    color: '#ffff',
  },
  newNote: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22px',
    height: '22px',
    position: 'absolute',
    marginLeft: '28px',
    marginTop: '18px',
    borderRadius: ' 13px 50px 50px 50px',
    background: '#279af1',
    color: '#ffff',
  },
  taskStatusIconWhite: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22px',
    height: '22px',
    position: 'absolute',
    marginLeft: '28px',
    marginTop: '18px',
    border: '1px solid #000000',
    borderRadius: ' 13px 50px 50px 50px',
    background: '#ffff',
    color: '#000000',
  },
  fileUploadIcon: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '22px',
    height: '22px',
    position: 'absolute',
    marginLeft: '28px',
    marginTop: '18px',
    borderRadius: ' 13px 50px 50px 50px',
    background: '#7c6df4',
    color: '#fff',
  },
}));
const Notifications = (props) => {
  const {
    // loadingNotifications,
    readNotifications,
    unreadNotifications,
    moreUnreadWebRecords,
    moreReadWebRecords,
    // muteUnmuteNotifications,
    muteState,
    readNotificationsOffsetCounter,
    unreadNotificationsOffsetCounter,
    updatingNotification,
    selectTask,
    websiteSelected,
  } = props;

  const { loadingNotifications } = useSelector((state) => ({
    loadingNotifications: state.user.loadingNotifications,
  }));

  const classes = makeClasses();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isRead, setIsRead] = useState(false);
  const [setTarget, entry] = useIntersection({ threshold: 0.1 });
  const [removingCard, setIsRemovingCard] = useState(null);
  const [liftCards, setLiftCards] = useState(null);
  const [cards, setCards] = useState([]);
  const [tabChange, setTabChange] = useState(false);

  const notificationDetails = (n, index) => {
    if (n.type === 'delete_task') {
      return;
    }
    if (n.type === 'page_approved') {
      if (!updatingNotification && !isRead) {
        setIsRemovingCard(n.id);

        const data = cards.filter((c) => c.id !== n.id);

        setTimeout(() => {
          setLiftCards(index);
        }, 350);

        setTimeout(() => {
          setLiftCards(null);
          setIsRemovingCard(null);
          setCards([...data]);
          updateNotification(n.id, true);
        }, 700);
      }
      return;
    }
    if (n.type === 'new_user_registered') {
      navigate('/user');
      updateNotification(n.id, true);
    }
    if (n.site?.id) {
      navigate(`/tasks?website=${n.site.id}&taskid=${n.task.id}`, '_self');
      websiteSelected(n.site.id);
      selectTask(n.task.id);
      updateNotification(n.id, true);
    }
    if (n.inbox?.id) {
      navigate(`/tasks?website=${n.inbox.id}&taskid=${n.task.id}`, '_self');
      websiteSelected(n.inbox.id);
      selectTask(n.task.id);
      updateNotification(n.id, true);
    }
  };

  const handleClick = (e, id, boolean, index) => {
    e.stopPropagation();
    if (!updatingNotification) {
      setIsRemovingCard(id);
      const data = cards.filter((c) => c.id !== id);
      setTimeout(() => {
        setLiftCards(index);
      }, 350);
      setTimeout(() => {
        setLiftCards(null);
        setIsRemovingCard(null);
        setCards([...data]);
        updateNotification(id, boolean);
      }, 700);
    }
  };

  const getReadNotifications = (offset) => {
    dispatch({ type: GET_READ_NOTIFICATIONS, offset });
  };

  const getUnreadNotifications = (offset) => {
    dispatch({ type: GET_UNREAD_NOTIFICATIONS, offset });
  };

  const getNotificationDetails = useCallback(
    (params) => {
      dispatch({ type: GET_NOTIFICATIONS_DETAILS, params });
    },
    [dispatch]
  );

  const updateNotification = useCallback(
    (id, markAllAsRead) => {
      dispatch({ type: UPDATE_USER_NOTIFICATION, id, markAllAsRead });
    },
    [dispatch]
  );

  const checkAllRead = useCallback(
    (param) => {
      dispatch({ type: CHECK_ALL_NOTIFICATIONS, param });
    },
    [dispatch]
  );

  const muteUnmuteNotifications = useCallback(
    (param) => {
      dispatch({ type: MUTE_UNMUTE_NOTIFICATIONS, param });
    },
    [dispatch]
  );

  const increaseReadCounter = useCallback(
    (param) => {
      dispatch({ type: INCREASE_READ_COUNTER, param });
    },
    [dispatch]
  );

  const increaseUnreadCounter = useCallback(
    (param) => {
      dispatch({ type: INCREASE_UNREAD_COUNTER, param });
    },
    [dispatch]
  );

  const openCloseNotificationModal = useCallback(
    (param) => {
      dispatch({ type: OPEN_CLOSE_NOTIFICATIONS_MODAL, param });
    },
    [dispatch]
  );

  // const muteUnmuteFunc = () => {
  //     muteUnmuteNotifications({ is_muted: !muteState })
  //     if (muteState && cards.length == 0) {
  //         setTimeout(() => {
  //             getUnreadNotifications(0);
  //             getReadNotifications(0);
  //         }, 1000);
  //     }
  // }

  const tabChangeFunc = (boolean) => {
    setIsRead(boolean);
    setTabChange(true);
    setTimeout(() => {
      setTabChange(false);
    }, 200);
  };

  useEffect(() => {
    const { intersectionRatio } = entry;
    if (intersectionRatio >= 0.1 && moreReadWebRecords && isRead) {
      getReadNotifications(readNotificationsOffsetCounter);
      increaseReadCounter(readNotificationsOffsetCounter + 1);
    }

    if (intersectionRatio >= 0.1 && moreUnreadWebRecords && !isRead) {
      getUnreadNotifications(unreadNotificationsOffsetCounter);
      increaseUnreadCounter(unreadNotificationsOffsetCounter + 1);
    }
  }, [entry]);

  useEffect(() => {
    if (
      !muteState &&
      readNotifications.length == 0 &&
      unreadNotifications.length == 0 &&
      !loadingNotifications
    ) {
      getUnreadNotifications(0);
      getReadNotifications(0);
    }
  }, []);

  useEffect(() => {
    if (isRead) {
      setCards(readNotifications);
    }
    if (!isRead) {
      setCards(unreadNotifications);
    }
  }, [readNotifications, unreadNotifications, isRead]);

  return (
    <>
      <span className={classes.popoverTop}>
        <div className={classes.actionHeader}>
          <div className={classes.pagesWrapper}>
            <div
              onClick={() => tabChangeFunc(false)}
              className={!isRead ? classes.pgTitle : classes.pgTitleUnselected}
            >
              Unread
            </div>
            <div
              onClick={() => tabChangeFunc(true)}
              className={isRead ? classes.pgTitle : classes.pgTitleUnselected}
            >
              Read
            </div>
          </div>
          {!isRead && unreadNotifications.length > 0 && (
            <div className={classes.topIcons}>
              <Tooltip
                title="Mark all as Read"
                placement="bottom"
                arrow
                onClick={() => {
                  unreadNotifications.length > 0 &&
                    checkAllRead({ mark_as_read: true });
                }}
              >
                <div className={clsx(classes.icon)}>
                  <FiCheck color="#A4ABC5" width={20} height={20} />
                </div>
              </Tooltip>
              {/* <Tooltip title={muteState ? 'Un-mute Notifications' : 'Mute Notifications'} placement="bottom" arrow>
                            <div
                                onClick={() => { muteUnmuteFunc() }}
                                className={clsx(
                                    classes.icon,
                                )}
                            >
                                {
                                    !muteState ?
                                        <FiBell color='#A4ABC5' width={20} height={20} />
                                        :
                                        <FiBellOff color='#A4ABC5' width={20} height={20} />}
                            </div>
                        </Tooltip> */}
              {/* <Tooltip title="Close" placement="bottom" arrow>
              <div
                className={clsx(classes.icon)}
                onClick={() => openCloseNotificationModal(false)}
              >
                <FiX color="#E72D67" width={20} height={20} />
              </div>
            </Tooltip> */}
            </div>
          )}
        </div>
      </span>
      {muteState ? (
        <div className={classes.bellPlaceHolder}>
          <FiBellOff color="#6D5DF3" size={120} />
          <p className={classes.muted}>Notifications Muted</p>
        </div>
      ) : (
        <div
          className={
            tabChange ? classes.tabChange : classes.allNotificationCards
          }
        >
          {loadingNotifications && cards.length == 0 ? (
            <div className={classes.stagesLoader}>
              <Lottie
                options={{
                  animationData: animationData,
                }}
                height={100}
                width={100}
              />
            </div>
          ) : !isRead &&
            !loadingNotifications &&
            unreadNotifications.length < 1 ? (
            <h2 className={classes.nodata}>No Unread Notifications</h2>
          ) : isRead &&
            !loadingNotifications &&
            readNotifications.length < 1 ? (
            <h2 className={classes.nodata}>No Read Notifications</h2>
          ) : (
            <>
              {cards.map((n, index) => {
                // remove html tags
                const removedTags = n.description
                  ? n.description.replace(/(<([^>]+)>)/gi, '')
                  : 'dfdfdf';
                return (
                  <div
                    onClick={() => {
                      notificationDetails(n, index);
                    }}
                    key={index}
                    className={
                      removingCard == n.id
                        ? classes.fadeOut
                        : liftCards !== null && liftCards < index
                          ? classes.liftCards
                          : classes.notificationCard
                    }
                  >
                    <Tooltip placement="right" title={n?.user?.name}>
                      {n.user?.avatar ? (
                        <Avatar
                          src={n.user?.avatar}
                          className={classes.userAvatar}
                        />
                      ) : (
                        <AvatarUser
                          name={
                            typeof n?.user?.name !== 'undefined'
                              ? reactAvatarInitials(n.user?.name)
                              : ''
                          }
                          size="45px"
                          className={clsx(
                            classes.userAvatar,
                            classes.makeUnselectable
                          )}
                        />
                      )}
                    </Tooltip>
                    {n.type == 'change_status' ? (
                      <Tooltip
                        placement="right"
                        title={`status changed to ${n.type_status}`}
                      >
                        {n.type_status === 'pending-review' ? (
                          <div className={classes.taskStatusIcon1}>
                            <StatusIcon width={12} height={12} />
                          </div>
                        ) : n.type_status === 'in-progress' ? (
                          <div className={classes.taskStatusIcon2}>
                            <StatusIcon width={12} height={12} />
                          </div>
                        ) : n.type_status === 'complete' ? (
                          <div className={classes.taskStatusIcon3}>
                            <StatusIcon width={12} height={12} />
                          </div>
                        ) : n.type_status === 'open' ? (
                          <div className={classes.taskStatusIconBlack}>
                            <StatusIcon width={12} height={12} />
                          </div>
                        ) : (
                          <div className={classes.taskStatusIconBlack}>
                            <StatusIcon width={12} height={12} />
                          </div>
                        )}
                      </Tooltip>
                    ) : n.type === 'add_new_task' ? (
                      <Tooltip placement="right" title="New Task Added">
                        <div className={classes.emailIcon}>
                          <CommentIcon width={12} height={12} />
                        </div>
                      </Tooltip>
                    ) : n.type == 'new_comment' ? (
                      <Tooltip placement="right" title="New Comment Added">
                        <div className={classes.emailIcon}>
                          <CommentIcon width={12} height={12} color="#fff" />
                        </div>
                      </Tooltip>
                    ) : n.type == 'change_urgency' ? (
                      <Tooltip
                        placement="right"
                        title={`Urgency changed to ${n.type_status}`}
                      >
                        {n.type_status == 'low' ? (
                          <div className={classes.taskUrgencyIcon1}>
                            <UrgencyIcon width={12} height={12} color="#fff" />
                          </div>
                        ) : n.type_status == 'medium' ? (
                          <div className={classes.taskUrgencyIcon2}>
                            <UrgencyIcon width={12} height={12} color="#fff" />
                          </div>
                        ) : n.type_status == 'high' ? (
                          <div className={classes.taskUrgencyIcon3}>
                            <UrgencyIcon width={12} height={12} color="#fff" />
                          </div>
                        ) : n.type_status == 'critical' ? (
                          <div className={classes.taskUrgencyIcon4}>
                            <UrgencyIcon width={12} height={12} color="#fff" />
                          </div>
                        ) : (
                          <div className={classes.taskUrgencyIcon5}>
                            <UrgencyIcon width={12} height={12} color="#fff" />
                          </div>
                        )}
                      </Tooltip>
                    ) : n.type == 'change_notify_site_users' ? (
                      <Tooltip placement="right" title="Notify Users">
                        <div className={classes.taskStatusIcon}>
                          <UserIcon width={12} height={12} color="#fff" />
                        </div>
                      </Tooltip>
                    ) : n.type == 'change_task_tags' ? (
                      <Tooltip placement="right" title="Task Tags">
                        <div className={classes.taskTagChange}>
                          <TagIcon width={12} height={12} color="#fff" />
                        </div>
                      </Tooltip>
                    ) : n.type == 'add_new_general_task' ? (
                      <Tooltip placement="right" title="Added New General Task">
                        <div className={classes.taskStatusIcon}>
                          <CommentIcon width={12} height={12} />
                        </div>
                      </Tooltip>
                    ) : n.type === 'delete_task' ? (
                      <Tooltip placement="right" title="Task has been deleted">
                        <div className={classes.deleteTask}>
                          <FiTrash2 />
                        </div>
                      </Tooltip>
                    ) : n.type === 'trash_comment' ? (
                      <Tooltip
                        placement="right"
                        title="Comment has been deleted"
                      >
                        <div className={classes.deleteTask}>
                          <FiTrash2 />
                        </div>
                      </Tooltip>
                    ) : n.type === 'new_note' ? (
                      <Tooltip placement="right" title="New Note">
                        {n.type_status === 'new-note-normal-text' ? (
                          <div className={classes.emailIcon}>
                            <CommentIcon width={12} height={12} />
                          </div>
                        ) : (
                          <div className={classes.newNote}>
                            <FaRegClock />
                          </div>
                        )}
                      </Tooltip>
                    ) : n.type === 'new_user_registered' ? (
                      <Tooltip placement="right" title="New User Registred">
                        <div className={classes.newNote}>
                          <UserIcon width={12} height={12} color="#fff" />
                        </div>
                      </Tooltip>
                    ) : n.type === 'new_comment_image/png' ? (
                      <Tooltip placement="right" title="New User Registred">
                        <div className={classes.emailIcon}>
                          <CommentIcon width={12} height={12} color="#fff" />
                        </div>
                      </Tooltip>
                    ) : n.type === 'change_notify_wp_feedback_users' ? (
                      <Tooltip placement="right" title="Notify Users">
                        <div className={classes.newNote}>
                          <UserIcon width={12} height={12} color="#fff" />
                        </div>
                      </Tooltip>
                    ) : n.type === 'update-comment' ? (
                      <Tooltip placement="right" title="New User Registred">
                        <div className={classes.emailIcon}>
                          <CommentIcon width={12} height={12} color="#fff" />
                        </div>
                      </Tooltip>
                    ) : n.type === 'internal_task' ? (
                      <>
                        {n.type_status === '1' ? (
                          <Tooltip
                            placement="right"
                            title="Task Set as Internal"
                          >
                            <div className={classes.taskStatusIconBlack}>
                              <FiEyeOff width={12} height={12} />
                            </div>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            placement="right"
                            title="Task Unset As Internal"
                          >
                            <div className={classes.taskStatusIconWhite}>
                              <FiEyeOff width={12} height={12} />
                            </div>
                          </Tooltip>
                        )}
                      </>
                    ) : n.type === 'update_comment' ? (
                      <Tooltip placement="right" title="New User Registred">
                        <div className={classes.emailIcon}>
                          <CommentIcon width={12} height={12} color="#fff" />
                        </div>
                      </Tooltip>
                    ) : n.type === 'uploaded_file' ? (
                      <Tooltip placement="right" title="File Uploaded">
                        <div className={classes.emailIcon}>
                          <FiPaperclip width={12} height={12} color="#fff" />
                        </div>
                      </Tooltip>
                    ) : n.type === 'page_approved' ? (
                      <Tooltip placement="right" title="Page Approved">
                        <div className={classes.taskStatusIcon}>
                          <FiCheck width={12} height={12} color="#fff" />
                        </div>
                      </Tooltip>
                    ) : (
                      <div className={classes.NoType}></div>
                    )}
                    <p className={classes.notifiycardContent}>
                      <span className={classes.cardTopText}>
                        <Tooltip title={n.user?.name}>
                          <span>
                            {n.user?.name?.split('').slice(0, 21)}
                            {n.user?.name?.length > 29 && '...'}
                          </span>
                        </Tooltip>
                        <span className={classes.cardIcon}>
                          <Tooltip title={n.created_at}>
                            <span> {n.created_at} </span>
                          </Tooltip>
                          <span>
                            {n.is_read ? (
                              <Tooltip title="Click to Mark as Unread">
                                <CheckFilled
                                  onClick={(e) => {
                                    handleClick(e, n.id, false, index);
                                  }}
                                  className={classes.notificationCardIcon}
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Click to Mark as Read">
                                <CheckOutline
                                  onClick={(e) => {
                                    handleClick(e, n.id, true, index);
                                  }}
                                  className={classes.notificationCardIcon}
                                />
                              </Tooltip>
                            )}
                          </span>
                        </span>
                      </span>
                      <Tooltip title={n.title}>
                        <span className={classes.notifiycardContentSpan}>
                          {n.title?.split('').slice(0, 30)}
                          {n.title.length > 29 && '...'}
                        </span>
                      </Tooltip>
                      <Tooltip title={removedTags}>
                        <span className={classes.cardGreyText}>
                          {removedTags.split('').slice(0, 33)}
                          {removedTags.length > 32 && '...'}
                        </span>
                      </Tooltip>
                    </p>
                  </div>
                );
              })}
              {(isRead && moreReadWebRecords) ||
              (!isRead && moreUnreadWebRecords) ? (
                <Typography
                  className={classes.loadingNextNotifications}
                  variant="h5"
                  component="h5"
                  ref={setTarget}
                >
                  Loading...
                </Typography>
              ) : null}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Notifications;
