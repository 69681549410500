import React, { Component } from 'react';
import { Button, withStyles } from '@material-ui/core';
import ErrorIllustration from '../media/svgs/something-went-wrong.svg?react';
import { VscRefresh } from 'react-icons/vsc';

const classes = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    height: '100%',
    width: '100%',
    fontFamily: theme.text.normalText.fontFamily,
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.text.primary,
  },
  errorIllustration: {
    width: '100%',
    height: '100%',
    maxWidth: 500,
    maxHeight: 500,
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 22,
    fontWeight: 700,
    color: '#272D3C',
    marginBottom: 8,
  },
  errorSubText: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '1.5',
    marginBottom: 20,
  },
  button: {
    color: theme.palette.text.primary,
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: 14,
    fontWeight: 500,
    backgroundColor: '#3ed696',
    borderRadius: 5,
    border: 'none',
    maxHeight: 36,
    height: 36,
    '&:hover': {
      backgroundColor: '#33bf84',
    },
  },
});

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by error boundary:', error, errorInfo);
  }

  render() {
    const { classes } = this.props;

    if (this.state.hasError) {
      return (
        <section className={classes.container}>
          <div>
            <ErrorIllustration className={classes.errorIllustration} />
          </div>
          <div className={classes.errorContainer}>
            <span className={classes.errorText}>
              Oops something went wrong!
            </span>
            <span className={classes.errorSubText}>
              Try to refresh this page or contact us if the problem persists.
            </span>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => window.location.reload()}
              startIcon={<VscRefresh />}
            >
              Refresh
            </Button>
          </div>
        </section>
      );
    }

    return this.props.children;
  }
}

export default withStyles(classes)(ErrorBoundary);
