import { ThemeProvider } from '@material-ui/core/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import './App.css';
import Main from './Main';
import reducer from './reducers';
import rootSaga from './sagas';
import './style.css';
import { theme } from './theme.js';
import leaveTaskMiddleware from './utils/leaveTaskMiddleware';
const tagManagerArgs = {
  gtmId: import.meta.env.VITE_GTM_ID,
};
TagManager.initialize(tagManagerArgs);

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
export const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(sagaMiddleware, leaveTaskMiddleware))
);

sagaMiddleware.run(rootSaga);

const queryClient = new QueryClient();
queryClient.setQueryDefaults(['authenticate'], {
  staleTime: Infinity,
  gcTime: Infinity,
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <DndProvider backend={HTML5Backend}>
            <div className="App">
              <Main />
            </div>
          </DndProvider>
        </ThemeProvider>
      </Provider>
      {!import.meta.env.VITE_APP_BASE_URL.includes('atarim.io') && (
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-right" />
      )}
    </QueryClientProvider>
  );
}

export default App;
