import React, { useEffect } from 'react';
// Styling
import { makeStyles, Button, TextField } from '@material-ui/core';
// Utils
import clsx from 'clsx';
import { BorderLinearProgress } from '../../utils/constants';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const makeClasses = makeStyles((theme) => ({
  titleCopy: {
    fontFamily: theme.text.normalText.fontFamily,
    fontWeight: 700,
    fontSize: 17,
    textAlign: 'left',
    marginTop: 10,
  },
  boldText: {
    fontFamily: theme.text.normalText.fontFamily,
  },
  nextBtn: {
    margin: '0',
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: 14,
    color: theme.palette.primary.main,
    border: 'none',
    borderRadius: 5,
    backgroundColor: '#3ed696',
    '&:hover': {
      backgroundColor: '#33bf84',
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    gap: 5,
    alignItems: 'center',
  },
  textField: {
    width: '100%',
    '& input': {
      padding: 10,
      height: 36,
      boxSizing: 'border-box',
    },
    [theme.breakpoints.down('xs')]: {
      '& label, input': {
        fontSize: 13,
      },
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E72D67' /* Set your desired error border color */,
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: '#E72D67' /* Set your desired error label color */,
    },
  },
  fieldsGroup: {
    display: 'flex',
    alignItems: 'baseline',
  },
  progressContainer: {
    marginBottom: 20,
  },
  progressBack: {
    '&:before': {
      width: '7px',
      height: '7px',
      border: '2px solid #6D5DF3',
      borderWidth: '2px 2px 0 0',
      color: '#6D5DF3',
      display: 'inline-block',
      marginRight: '2px',
      content: '""',
      marginBottom: '1px',
      '-moz-transform': '"rotate(-135deg)"',
      '-webkit-transform': 'rotate(-135deg)',
      '-o-transform': '"rotate(-135deg)"',
      '-ms-transform': '"rotate(-135deg)"',
      transform: 'rotate(-135deg)',
      fontSize: 14,
      fontWeight: 600,
    },
  },
  validationError: {
    fontFamily: theme.text.normalText.fontFamily,
    color: '#e54f6d',
    fontSize: '12px',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
  },
  phoneNumberContainer: {
    marginTop: 16,
    width: '100%',
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: 14,
    '& .PhoneInput': {
      height: 32,
      position: 'relative',
    },
    '& .PhoneInputCountry': {
      position: 'absolute',
      top: 'calc(50% - 9px)',
      left: '8px',
    },
    '& .PhoneInputCountryIcon': {
      height: 18,
      width: 26,
      border: 0,
      boxShadow: 'none',
      backgroundColor: '#fff',
      '&:focus': {
        border: 'solid 1px #bec1c5',
      },
    },
    '& .PhoneInputCountrySelect': {
      fontSize: 14,
      fontFamily: theme.text.normalText.fontFamily,
      '&:focus': {
        border: 'solid 1px #bec1c5',
      },
      '& option': {
        border: 'solid 1px red',
        padding: 40,
        // backgroundColor: "#ECF3F9",
        color: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: '#ECF3F9',
        },
      },
    },
    '& .PhoneInputInput': {
      height: '100%',
      borderRadius: 5,
      border: 'solid 1px #bec1c5',
      fontFamily: theme.text.normalText.fontFamily,
      fontSize: 14,
      paddingLeft: '54px',
      color: '#272D3C',
      '&:focus-visible': {
        // outline: "2px solid blue",
        outline: 'none',
      },
      '&:focus': {
        border: 'solid 1px #272d3c',
      },
    },
  },
}));

const InputPhoneNumber = (props) => {
  const {
    handleSelectNext,
    handleSelectPrevDetail,
    companyNameData,
    phoneNumberData,
    handleUpdateTrial,
    handlePostTrial,
  } = props;

  const classes = makeClasses();

  const [companyName, setCompanyName] = React.useState('');
  const [value, setValue] = React.useState('');

  useEffect(() => {
    if (companyNameData) {
      setCompanyName(companyNameData);
    }
  }, [companyNameData]);

  useEffect(() => {
    if (phoneNumberData) {
      setValue(phoneNumberData);
    }
  }, [phoneNumberData]);

  const handleChange = (key, i) => (event) => {
    const value = event.target.value;
    if (key === 'company_name') {
      setCompanyName(value);
    }
  };

  const handleSave = () => {
    handlePostTrial({
      company_name: companyName,
      phone_number: value,
    });
    handleSelectNext(2);
  };

  const handleBlur = (key) => {
    if (key === 'company_name') {
      handleUpdateTrial('company_name', companyName);
    } else if (key === 'phone_number') {
      handleUpdateTrial('phone_number', value);
    }
  };

  return (
    <>
      <div className="at-cs-trial-wizard-modal">
        <div className={classes.progressContainer}>
          <BorderLinearProgress variant="determinate" value={34} />
          <div className="at-cs-trial-wizard-progress-label">
            <span
              className={classes.progressBack}
              onClick={handleSelectPrevDetail}
            >
              Back
            </span>
          </div>
        </div>
        <div className={classes.titleCopy}>
          Name Your Workspace (Company Name)
        </div>
        <div className={classes.fieldsGroup}>
          <TextField
            placeholder="Company Name"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            value={companyName}
            onChange={handleChange('company_name')}
            onBlur={() => handleBlur('company_name')}
            autoFocus
          />
        </div>
        <div className={classes.titleCopy}>
          Enter your phone number (optional)
        </div>
        <div className={classes.fieldsGroup}>
          <div className={classes.phoneNumberContainer}>
            <PhoneInput
              international
              placeholder="Enter phone number"
              defaultCountry="GB"
              value={value}
              onChange={setValue}
              onBlur={() => handleBlur('phone_number')}
            />
          </div>
        </div>

        <div className={classes.btnContainer}>
          <Button
            color="primary"
            variant="contained"
            classes={{ root: clsx(classes.nextBtn, classes.boldText) }}
            onClick={() => handleSave(2)}
            disabled={companyName === ''}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

export default InputPhoneNumber;
