import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BsCheck2 } from 'react-icons/bs';

export const tableData = [
  {
    data: [
      <strong>Monthly Price:</strong>,
      'FREE',
      '$20 /+$8 per extra seat',
      '$35 /+$10 per extra seat',
      '$50 /+$15 per extra seat',
      'Custom',
    ],
  },
  {
    data: [
      <strong>Yearly Price:</strong>,
      'FREE',
      '$200 /+$80 per extra seat	',
      '$350 /+$100 per extra seat	',
      '$500 /+$150 per extra seat',
      'Custom',
    ],
  },
  { data: ['Workspaces:', '1', '1 ', '1', '5', 'Custom'] },
  {
    data: [
      'Active Projects:',
      '3 Active Projects',
      '20 Active Projects	',
      '60 Active Projects',
      '200 Active Projects',
      'Custom',
    ],
  },
  {
    data: [
      'Clients & Collaborators:',
      'Unlimited',
      'Unlimited',
      'Unlimited',
      'Unlimited',
      'Custom',
    ],
  },
  { data: ['Team Members:', '1', '1-5', '1-10', '1-15', 'Custom'] },
  { data: ['Asset Storage', '1GB', '10GB', '30GB', '100GB', 'Custom'] },
  {
    data: [
      'Visual Collaboration on ANY Website',
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Email-Based Support Desk',
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Productivity KPIs',
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Kanban Boards',
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      '1 Click Login to WordPress Sites',
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      '1 Click to "Go To Task"',
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Automated Screenshots',
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Status & Priority per Task',
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Automated Debugging Info',
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'WordPress Admin Visual Collaboration',
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'File Uploads (Gather Content)',
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Image Optimizations',
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      '1 Click "Push To Media Folder"',
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Guest Mode (No Login/Registration)',
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Global Settings & Permissions',
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Basic Integrations',
      <AiOutlineClose size={20} color={'#D51341'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Internal Tasks',
      <AiOutlineClose size={20} color={'#D51341'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Automated Reports',
      <AiOutlineClose size={20} color={'#D51341'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Configure User Permissions',
      <AiOutlineClose size={20} color={'#D51341'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Edit & Delete Comments',
      <AiOutlineClose size={20} color={'#D51341'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Unlimited Designs Per Asset',
      <AiOutlineClose size={20} color={'#D51341'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Design Hotspots',
      <AiOutlineClose size={20} color={'#D51341'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Design Versions',
      <AiOutlineClose size={20} color={'#D51341'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Page & Design Approvals',
      <AiOutlineClose size={20} color={'#D51341'} />,
      <AiOutlineClose size={20} color={'#D51341'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Internal WordPress Task Center',
      <AiOutlineClose size={20} color={'#D51341'} />,
      <AiOutlineClose size={20} color={'#D51341'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Remove Atarim branding for your clients and collaborators',
      <AiOutlineClose size={20} color={'#D51341'} />,
      <AiOutlineClose size={20} color={'#D51341'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Deep Native Integrations',
      <AiOutlineClose size={20} color={'#D51341'} />,
      <AiOutlineClose size={20} color={'#D51341'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Time Tracking & Brandable Time Sheets',
      <AiOutlineClose size={20} color={'#D51341'} />,
      <AiOutlineClose size={20} color={'#D51341'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Team Management & Activity Tracking',
      <AiOutlineClose size={20} color={'#D51341'} />,
      <AiOutlineClose size={20} color={'#D51341'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Multiple Inboxes (Coming Soon)',
      <AiOutlineClose size={20} color={'#D51341'} />,
      <AiOutlineClose size={20} color={'#D51341'} />,
      <AiOutlineClose size={20} color={'#D51341'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Multiple Brands (Coming Soon)',
      <AiOutlineClose size={20} color={'#D51341'} />,
      <AiOutlineClose size={20} color={'#D51341'} />,
      <AiOutlineClose size={20} color={'#D51341'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Custom API Docs Access',
      <AiOutlineClose size={20} color={'#D51341'} />,
      <AiOutlineClose size={20} color={'#D51341'} />,
      <AiOutlineClose size={20} color={'#D51341'} />,
      <AiOutlineClose size={20} color={'#D51341'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Dedicated Success Manager & Team Training',
      <AiOutlineClose size={20} color={'#D51341'} />,
      <AiOutlineClose size={20} color={'#D51341'} />,
      <AiOutlineClose size={20} color={'#D51341'} />,
      <AiOutlineClose size={20} color={'#D51341'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Advanced Security',
      <AiOutlineClose size={20} color={'#D51341'} />,
      <AiOutlineClose size={20} color={'#D51341'} />,
      <AiOutlineClose size={20} color={'#D51341'} />,
      <AiOutlineClose size={20} color={'#D51341'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
  {
    data: [
      'Slack Support',
      <AiOutlineClose size={20} color={'#D51341'} />,
      <AiOutlineClose size={20} color={'#D51341'} />,
      <AiOutlineClose size={20} color={'#D51341'} />,
      <AiOutlineClose size={20} color={'#D51341'} />,
      <BsCheck2 size={20} color={'#17DE88'} />,
    ],
  },
];
