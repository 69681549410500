import { cn } from '@utils/cn';
import { cva } from 'class-variance-authority';
import * as React from 'react';

const seperatorVariants = cva(
  'flex w-full items-center whitespace-nowrap text-xs font-semibold text-gray-300 before:border-gray-200 after:border-gray-200 border-gray-200  before:flex-1 before:border-t after:flex-1 after:border-t after:content-[""] border-t',
  {
    variants: {
      variant: {
        primary: 'before:border-solid after:border-solid border-solid',
        dashed: 'before:border-dashed after:border-dashed border-dashed',
        dotted: 'before:border-dotted after:border-dotted border-dotted',
        double: 'before:border-double after:border-double border-double',
      },
      size: {
        sm: 'before:mr-1 after:ml-1 after:border-t before:border-t border-t',
        md: 'before:mr-1 after:ml-1 after:border-t-2 before:border-t-2 border-t-2',
        lg: 'before:mr-1.5 after:ml-1.5 after:border-t-3 before:border-t-3 border-t-3',
        xl: 'before:mr-2 after:ml-2 after:border-t-4 before:border-t-4 border-t-4',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  }
);

const Separator = React.forwardRef(function Separator(
  {
    className,
    orientation = 'horizontal',
    variant,
    size,
    label,
    labelPosition,
    ...props
  },
  ref
) {
  return (
    <span
      className={cn(
        seperatorVariants({ variant, size, className }),
        `${label ? 'border-none' : 'before:hidden after:hidden'}`,
        `${label && labelPosition === 'left' ? 'before:hidden' : ''}`,
        `${label && labelPosition === 'right' ? 'after:hidden' : ''}`
      )}
      ref={ref}
      {...props}
    >
      {label}
    </span>
  );
});
Separator.displayName = 'Separator';

export default Separator;
