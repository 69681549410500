import React, { useEffect, useLayoutEffect, useState } from 'react';
// Components
import TimerItem from './TimerItem';
// Styling
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { FiTrash2 } from 'react-icons/fi';
// Utils
import clsx from 'clsx';

const useClasses = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FFF',
    fontFamily: theme.text.normalText.fontFamily,
    border: '1px solid #eff2f6',
    borderRadius: 5,
    boxShadow: '0 0 15px rgba(0,0,0,0.19)',
  },
  header: {
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
    fontWeight: 700,
    paddingBottom: 10,
    color: '#272D3C',
  },
  details: {
    marginTop: 10,
  },
  description: {
    width: '100%',
    margin: '0',
    resize: 'none',
    boxSizing: 'border-box',
    borderColor: 'rgb(0, 0, 0, 0.3)',
    color: '#272D3C',
    borderRadius: 4,
    padding: '10px 10px 0px 10px',
    fontFamily: theme.text.normalText.fontFamily,
    height: 100,
    fontSize: 14,
  },
  checkBoxLabel: {
    fontSize: 14,
    fontFamily: theme.text.normalText.fontFamily,
    color: '#272D3C',
  },
  lastRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0 0 0',
    marginTop: '-5px',
  },
  billable: {
    padding: '5px 10px',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 5,
  },
}));

export default function TimerCollection(props) {
  const {
    notLoggedEntries,
    setTimerEntry,
    selectedTimerEntry,
    deleteEntry,
    currentTimerTaskId,
    setCurrentTimerTaskId,
    timerPlay,
    setTimerPlay,
    updateFloatingSpentTime,
    updateEntry,
    sendMessage,
    updateNotLogged,
    sendMessageWithoutPosting,
    fetchNotLoggedEntries,
    minimizeTimerCollection,
    setMinimizeTimerCollection,
  } = props;

  const classes = useClasses();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(99);
  const [description, setDescription] = useState('');
  const [billable, setBillable] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  // const [minimize, setMinimize]         = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = notLoggedEntries.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const renderItems = currentItems.map((entry, timerItemIndex) => (
    <TimerItem
      key={entry.id}
      id={entry.id}
      userId={entry.user_id}
      // date={entry.date}
      startTime={entry.start_time}
      endTime={entry.end_time}
      taskId={entry.encrypted_task_id}
      taskName={entry.task_name}
      siteName={entry.site_name}
      spentTime={entry.spent_time}
      description={entry.description}
      billable={entry.billable}
      setDescription={setDescription}
      setBillable={setBillable}
      setShowDetails={setShowDetails}
      setTimerEntry={setTimerEntry}
      selectedTimerEntry={selectedTimerEntry}
      showDetails={showDetails}
      currentTimerTaskId={currentTimerTaskId}
      setCurrentTimerTaskId={setCurrentTimerTaskId}
      timerPlay={timerPlay}
      setTimerPlay={setTimerPlay}
      updateFloatingSpentTime={updateFloatingSpentTime}
      updateEntry={updateEntry}
      localDescription={description}
      localBillable={billable}
      sendMessage={sendMessage}
      running={entry.running}
      updateNotLogged={updateNotLogged}
      sendMessageWithoutPosting={sendMessageWithoutPosting}
      timerItemIndex={timerItemIndex}
      setSelectedItem={setSelectedItem}
      selectedItem={selectedItem}
    />
  ));

  useEffect(() => {
    if (localStorage.getItem('minimize') === null) {
      setMinimizeTimerCollection(false);
    } else {
      setMinimizeTimerCollection(JSON.parse(localStorage.getItem('minimize')));
    }
  }, [minimizeTimerCollection]);

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(notLoggedEntries.length / 3)) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const updateDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleDelete = () => {
    setShowDetails(false);
    deleteEntry(selectedTimerEntry);
    if ((notLoggedEntries.length - 1) % 3 === 0) handlePrev();
  };

  // const handleSetMinimize = (value) => {
  //     setMinimizeTimerCollection(value)
  //     localStorage.setItem('minimize', value);
  // }

  const onVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      fetchNotLoggedEntries();
    }
  };

  useLayoutEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  return (
    <div className="at-cs-timer-container">
      <div className={classes.header}>
        <div>
          Active Timers
          <span className="py-p ml-1 h-4 min-w-4 justify-center rounded-full bg-destructive px-1.5 text-sm font-medium leading-none text-white">
            {notLoggedEntries.length}
          </span>
        </div>
      </div>
      <div className="at-cs-timer-content">
        {minimizeTimerCollection === false && renderItems}
      </div>
      {showDetails && minimizeTimerCollection === false && (
        <div className={classes.details}>
          <div>
            <textarea
              className={classes.description}
              onChange={updateDescription}
              value={description}
              placeholder="Describe what you are working on..."
            />
          </div>
          <div className={classes.lastRow}>
            <div>
              {
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={billable}
                      color="primary"
                      value={Boolean(billable)}
                    />
                  }
                  label="Billable"
                  classes={{ label: clsx(classes.checkBoxLabel) }}
                  style={{
                    marginRight: 0,
                  }}
                  onChange={() => setBillable((prev) => !prev)}
                />
              }
            </div>
            <div className={classes.buttonContainer}>
              <Button
                className="at-cs-btn btn-outline red"
                onClick={handleDelete}
              >
                <FiTrash2 size={16} className={classes.icon} />
                Delete
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
