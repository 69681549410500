import { useSelector } from 'react-redux';

export const useTrialSelector = () =>
  useSelector((state) => ({
    upgradePlans: state.user.pricingPlans,
    upgradePlansLoading: state.user.upgradePlansLoading,
    trialStatus: state.user.trialStatus,
    existingWebsites: state.user.existingWebsites,
    isPlansDialogOpened: state.user.isPlansDialogOpened,
    userData: state.user.userData,
    userTrialData: state.user.userTrialData,
  }));
