import { useDispatch } from 'react-redux';
import { ADD_NEW_SITE } from '../actions/collaborate';
import {
  ACTIVATE_TRIAL,
  CLOSE_TRIAL_WIZARD,
  ELIGIBLE_TO_INACTIVE,
  FETCH_EXISTING_SITE_URL,
  POST_TRAIL_DETAIL,
  UPDATE_TRAIL_DETAIL,
} from '../actions/user';

export const useTrialActions = () => {
  const dispatch = useDispatch();

  return {
    updateTrialValue: (key, value) =>
      dispatch({ type: UPDATE_TRAIL_DETAIL, key, value }),
    postTrialValue: (params) => dispatch({ type: POST_TRAIL_DETAIL, params }),
    activateTrial: (params) => dispatch({ type: ACTIVATE_TRIAL, params }),
    addNewSite: (params) => dispatch({ type: ADD_NEW_SITE, params }),
    eligibleToInactiveTrial: (params) =>
      dispatch({ type: ELIGIBLE_TO_INACTIVE, params }),
    fetchExistingSiteUrl: (params) =>
      dispatch({ type: FETCH_EXISTING_SITE_URL, params }),
    closeTrialWizard: (params) =>
      dispatch({ type: CLOSE_TRIAL_WIZARD, params }),
  };
};
