import React from 'react';
// Components
import PlusIcon from '../PlusIcon';
// Styling
import {
  Button,
  FormControl,
  OutlinedInput,
  Select,
  TextField,
  makeStyles,
} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
// Utils
import { MenuItem } from 'react-pro-sidebar';
import { InviteRoleLabelFrontend } from '../../utils/constants';

const useClasses = makeStyles((theme) => ({
  formActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'initial',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  textField: {
    margin: 0,
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      '& label, input': {
        fontSize: 13,
      },
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E72D67' /* Set your desired error border color */,
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: '#E72D67' /* Set your desired error label color */,
    },
  },
  actionButton: {
    minWidth: 36,
    width: 36,
    height: 36,
    borderRadius: '50%',
    color: '#8791B2',
  },
  removeActionButtonPlaceholder: {
    minWidth: 45,
  },
}));

function WorkspaceInviteForm(props) {
  const { singleFormValues, formValue, setFormValue } = props;

  const classes = useClasses();

  const showRemoveFormButton = formValue.length > 1;

  const handleChange = (key, index) => (e) => {
    const value = e.target.value;
    // for optimization purpose React uses a single "e"
    // directly using e.target.value sometimes can lead to "e" being null or undefined which will cause error
    setFormValue((prevFormValue) =>
      prevFormValue.map((f, i) => (i === index ? { ...f, [key]: value } : f))
    );
  };

  const handleAddForm = (addAfterIndex) => () => {
    setFormValue((prevFormValue) => {
      const temp = prevFormValue.concat([]);
      temp.splice(addAfterIndex + 1, 0, singleFormValues);
      return temp;
    });
  };

  const handleRemoveForm = (index) => () => {
    setFormValue((prevFormValue) =>
      prevFormValue.filter((fv, i) => index !== i)
    );
  };

  return (
    <>
      {
        <div className="grid gap-2.5">
          {formValue.map((fv, i) => (
            <>
              {
                <div className="flex gap-2" key={i}>
                  <div className="setInputHeight w-100 flex flex-col gap-2 align-baseline md:flex-row">
                    <TextField
                      label="Full Name"
                      placeholder="Full Name"
                      className="w-100 my-0"
                      variant="outlined"
                      value={fv.first_name}
                      onChange={handleChange('first_name', i)}
                      // error={formValue[i].name === "" ? true : false}
                      // error={addErrorToFields.first_name ? addErrorToFields.first_name.indexOf(i) !== -1 : false}
                    />
                    <TextField
                      label="Email Address"
                      placeholder="Email Address"
                      className="w-100 my-0"
                      variant="outlined"
                      value={fv.email}
                      onChange={handleChange('email', i)}
                      // error={formValue[i].email === "" ? true : false}
                      // error={addErrorToFields.last_name ? addErrorToFields.last_name.indexOf(i) !== -1 : false}
                    />
                    <FormControl className="at-cs-collab-select w-100 my-0">
                      <Select
                        onChange={handleChange('role', i)}
                        input={<OutlinedInput id="user-role" />}
                        classes={{ root: classes.selectRoleForUserControl }}
                        value={fv.role}
                      >
                        {InviteRoleLabelFrontend.map((k) => (
                          <MenuItem
                            className="at-cs-collab-select-item"
                            key={k.value}
                            value={k.value}
                          >
                            {k.type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className={classes.formActions}>
                    <PlusIcon
                      className={classes.actionButton}
                      clickAction={handleAddForm(i)}
                    />
                    {showRemoveFormButton ? (
                      <Button
                        className="at-cs-btn no-bg square"
                        onClick={handleRemoveForm(i)}
                      >
                        <RemoveIcon />
                      </Button>
                    ) : (
                      <div className={classes.removeActionButtonPlaceholder}>
                        {' '}
                      </div>
                    )}
                  </div>
                </div>
              }
            </>
          ))}
        </div>
      }
    </>
  );
}

export default WorkspaceInviteForm;
