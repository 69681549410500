import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { cn } from '@utils/cn';
import { cva } from 'class-variance-authority';
import CryptoJS from 'crypto-js';
import React, { forwardRef } from 'react';
import { _numberPRNG, _stringAsciiPRNG } from '../helper';

// darker tones, as the foreground color will be white
const defaultColors = [
  '#6D5DF3',
  '#3ED696',
  '#FFA532',
  '#FF5A48',
  '#279AF1',
  '#282B5A',
  //  '#FCD227',
];

function getRandomColor(value, colors = defaultColors) {
  // if no value is passed, always return transparent color otherwise
  // a rerender would show a new color which would will
  // give strange effects when an interface is loading
  // and gets rerendered a few consequent times
  if (!value) return 'transparent';

  // value based random color index
  // the reason we don't just use a random number is to make sure that
  // a certain value will always get the same color assigned given
  // a fixed set of colors
  const colorIndex =
    typeof value === 'string'
      ? _stringAsciiPRNG(value, colors.length)
      : _numberPRNG(value, colors.length);
  return colors[colorIndex];
}

const buttonVariants = cva(
  'relative flex h-full w-full shrink-0 overflow-hidden font-normal border-none aspect-square',
  {
    variants: {
      size: {
        xs: 'size-5 text-[8px]',
        sm: 'size-6 text-[10px]',
        md: 'size-8 text-xs',
        lg: 'size-12 text-lg',
        fit: 'w-full h-full max-w-max max-h-max',
      },
      borderRadius: {
        sm: 'rounded-sm',
        md: 'rounded-[4px]',
        lg: 'rounded-lg',
        full: 'rounded-full',
      },
    },
    defaultVariants: {
      size: 'md',
      borderRadius: 'md',
    },
  }
);

const Avatar = forwardRef((props, ref) => {
  const { className, size, borderRadius, id, ...rest } = props;
  return (
    <AvatarPrimitive.Root
      ref={ref}
      className={cn(
        'relative flex shrink-0 overflow-hidden border',
        buttonVariants({ size, borderRadius }),
        className
      )}
    >
      <AvatarImage
        email={props.email}
        src={props.src}
        alt={props.alt}
        className={className}
      />
      <AvatarFallback
        title={props.title}
        id={id}
        colour={props.colour}
        className={className}
      />
    </AvatarPrimitive.Root>
  );
});
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = forwardRef(({ email, alt, src }, ref) => {
  const defaultImage = email
    ? `https://gravatar.com/avatar/${CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex)}?d=404`
    : src
      ? src
      : null;
  return (
    <AvatarPrimitive.Image
      ref={ref}
      className="h-full w-full object-cover"
      src={defaultImage}
      alt={alt}
    />
  );
});
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = forwardRef(({ title, colour, id }, ref) => {
  // const [fontColour, setFontColour] = useState('#000000');
  const initials = title
    ?.split(' ')
    .map((name) => name.replace(/[^a-zA-Z]/g, '')) // Remove non-alphabetic characters
    .map((name, _, array) => (array.length === 1 ? name.slice(0, 2) : name[0]))
    .slice(0, 2)
    .join('');

  // useEffect(() => {
  //   if (!colour) return;
  //   setFontColour(getContrastingColor(colour));
  // }, [colour]);

  return (
    <AvatarPrimitive.Fallback
      style={{
        backgroundColor: colour ?? getRandomColor(id ?? title),
        color: 'white', // theme seems to be white currently, so we can't use fontColour yet.
      }}
      ref={ref}
      className="flex h-full w-full items-center justify-center uppercase"
    >
      {initials}
    </AvatarPrimitive.Fallback>
  );
});
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

export { Avatar, AvatarFallback, AvatarImage };
