import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// components
import GotoChromeBackdrop from './GotoChromeBackdrop';
// Utils
import { Button } from '@material-ui/core';
import AtarimChrome from '../media/svgs/atarim-chrome-icon.svg';
import {
  isChromeBrowser,
  isChromeExtensionInstalled,
} from '../utils/chromeExtension';

const makeClasses = makeStyles((theme) => ({
  gotoIcon: {
    marginRight: 5,
    fontSize: 20,
  },
  gotoIconCollasped: {
    fontSize: 20,
  },
  gotoIchromeCopy: {
    textAlign: 'center',
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: 14,
    padding: 0,
    maxWidth: 175,
    margin: '0 auto',
  },
  gotoIchromeBtnContainer: {
    padding: 0,
  },
  container: {
    // display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid #FFFFFF',
    fontFamily: theme.text.normalText.fontFamily,
    fontSize: 14,
    borderRadius: 16,
    flexDirection: 'column',
    padding: 10,
  },
}));

const GotoChromeWizardButton = (props) => {
  const { menuCollapse } = props;
  const classes = makeClasses();
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [extState, setExtState] = React.useState(false);

  const extInstalled = isChromeExtensionInstalled();
  const chromeBrowser = isChromeBrowser();
  useEffect(() => {
    if (!extInstalled && chromeBrowser) {
      setExtState(true);
    }
  }, [extInstalled, chromeBrowser]);
  // Threshold for small screens, e.g., less than 1200px
  const smallScreenThreshold = 1200;
  let features;
  if (window.innerWidth < smallScreenThreshold) {
    // For smaller screens, open the window in full screen or set minimum dimensions
    features = 'top=0,left=0,width=100,height=100';
  } else {
    // For larger screens, calculate dimensions as before
    let widthVW = 50; // 50% of the viewport width
    let heightVH = 50; // 50% of the viewport height
    let widthInPixels = window.innerWidth * (widthVW / 100);
    let heightInPixels = window.innerHeight * (heightVH / 100);
    let left = (window.innerWidth - widthInPixels) / 2;
    let top = 25;
    features = `width=${widthInPixels},height=${heightInPixels},top=${top},left=${left}`;
  }

  const handleOpenDownloadLink = () => {
    setOpenBackdrop(!openBackdrop);
    window.open(
      'https://chromewebstore.google.com/detail/atarim-visual-website-col/ekpmbeilnaahlccpgjchjjoonecikehf',
      '_blank',
      features
    );
  };

  return (
    <>
      {extState && (
        <div className={classes.container}>
          <div className={classes.gotoIchromeCopy}>
            Instantly Collaborate On Any Website
          </div>
          <div className={classes.gotoIchromeBtnContainer}>
            <Button
              className="at-cs-btn btn-white w-100 m-t-10 at-cs-goto-chrome-button"
              onClick={handleOpenDownloadLink}
            >
              <img
                alt="atarim chrome icon"
                src={AtarimChrome}
                className="m-r-5"
              />
              <span>Install Chrome Extension</span>
            </Button>
          </div>

          {openBackdrop && (
            <GotoChromeBackdrop
              open={openBackdrop}
              close={() => setOpenBackdrop(false)}
              closeWidgets={setExtState}
            />
          )}
        </div>
      )}
    </>
  );
};

export default GotoChromeWizardButton;
