export const SETTINGS_PAGE_MOUNTED = 'SETTINGS_PAGE_MOUNTED';
export const SETTINGS_PAGE_UNMOUNTED = 'SETTINGS_PAGE_UNMOUNTED';
export const GLOBAL_SETTINGS_FETCHED = 'GLOBAL_SETTINGS_FETCHED';
export const GLOBAL_SETTINGS_NOT_FETCHED = 'GLOBAL_SETTINGS_NOT_FETCHED';
export const UPDATE_GLOBAL_SETTINGS = 'UPDATE_GLOBAL_SETTINGS';

export const SAVE_GLOBAL_SETTINGS = 'SAVE_GLOBAL_SETTINGS';
export const SUCCESS_SAVE_GLOBAL_SETTINGS = 'SUCCESS_SAVE_GLOBAL_SETTINGS';
export const ERROR_SAVE_GLOBAL_SETTINGS = 'ERROR_SAVE_GLOBAL_SETTINGS';

export const RESET_SETTINGS = 'RESET_SETTINGS';
export const SUCCESS_RESET_SETTINGS = 'SUCCESS_RESET_SETTINGS';
export const ERROR_RESET_SETTINGS = 'ERROR_RESET_SETTINGS';

export const EMPTY_MESSAGE_SETTINGS = 'EMPTY_MESSAGE_SETTINGS';

export const CHANGE_SIDE_NAV_STATUS = 'CHANGE_SIDE_NAV_STATUS';

export const MAKE_SIDENAV_FALSE = 'MAKE_SIDENAV_FALSE';

export const CHANGE_ICON_SIDENAV_STATUS = 'CHANGE_ICON_SIDENAV_STATUS';

export const START_REFRESH_WEBHOOK_TOKEN = 'START_REFRESH_WEBHOOK_TOKEN';
export const SUCCESS_REFRESH_WEBHOOK_TOKEN = 'SUCCESS_REFRESH_WEBHOOK_TOKEN';
export const ERROR_REFRESH_WEBHOOK_TOKEN = 'ERROR_REFRESH_WEBHOOK_TOKEN';

export const START_CHANGE_WEBHOOK_STATUS = 'START_CHANGE_WEBHOOK_STATUS';
export const SUCCESS_CHANGE_WEBHOOK_STATUS = 'SUCCESS_CHANGE_WEBHOOK_STATUS';
export const ERROR_CHANGE_WEBHOOK_STATUS = 'ERROR_CHANGE_WEBHOOK_STATUS';

export const START_CHANGE_CUSTOM_WEBHOOK = 'START_CHANGE_CUSTOM_WEBHOOK';
export const SUCCESS_CHANGE_CUSTOM_WEBHOOK = 'SUCCESS_CHANGE_CUSTOM_WEBHOOK';
export const ERROR_CHANGE_CUSTOM_WEBHOOK = 'ERROR_CHANGE_CUSTOM_WEBHOOK';

export const START_CHANGE_SLACK_SETTINGS = 'START_CHANGE_SLACK_SETTINGS';
export const SUCCESS_CHANGE_SLACK_SETTINGS = 'SUCCESS_CHANGE_SLACK_SETTINGS';
export const ERROR_CHANGE_SLACK_SETTINGS = 'ERROR_CHANGE_SLACK_SETTINGS';

export const START_CHANGE_INTEGRATION_TOGGLE_SETTINGS =
  'START_CHANGE_INTEGRATION_TOGGLE_SETTINGS';
export const SUCCESS_CHANGE_INTEGRATION_TOGGLE_SETTINGS =
  'SUCCESS_CHANGE_INTEGRATION_TOGGLE_SETTINGS';
export const ERROR_CHANGE_INTEGRATION_TOGGLE_SETTINGS =
  'ERROR_CHANGE_INTEGRATION_TOGGLE_SETTINGS';

export const START_CHANGE_AUTOMATIC_TOGGLE_SETTINGS =
  'START_CHANGE_AUTOMATIC_TOGGLE_SETTINGS';
export const SUCCESS_CHANGE_AUTOMATIC_TOGGLE_SETTINGS =
  'SUCCESS_CHANGE_AUTOMATIC_TOGGLE_SETTINGS';
export const ERROR_CHANGE_AUTOMATIC_TOGGLE_SETTINGS =
  'ERROR_CHANGE_AUTOMATIC_TOGGLE_SETTINGS';

export const VERIFY_FORWARDING = 'VERIFY_FORWARDING';
export const SUCCESS_VERIFY_FORWARDING = 'SUCCESS_VERIFY_FORWARDING';
export const ERROR_VERIFY_FORWARDING = 'ERROR_VERIFY_FORWARDING';

export const VERIFY_DOMAIN = 'VERIFY_DOMAIN';
export const SUCCESS_VERIFY_DOMAIN = 'SUCCESS_VERIFY_DOMAIN';
export const ERROR_VERIFY_DOMAIN = 'ERROR_VERIFY_DOMAIN';

export const CLICKUP_COMPLETE_AUTH = 'CLICKUP_COMPLETE_AUTH';
export const SUCCESS_CLICKUP_COMPLETE_AUTH = 'SUCCESS_CLICKUP_COMPLETE_AUTH';

export const CLICKUP_GET_TEAMS = 'CLICKUP_GET_TEAMS';
export const SUCCESS_CLICKUP_GET_TEAMS = 'SUCCESS_CLICKUP_GET_TEAMS';
export const ERROR_CLICKUP_GET_TEAMS = 'ERROR_CLICKUP_GET_TEAMS';

export const CLICKUP_STORE_TEAM = 'CLICKUP_STORE_TEAM';
export const SUCCESS_CLICKUP_STORE_TEAM = 'SUCCESS_CLICKUP_STORE_TEAM';
export const ERROR_CLICKUP_STORE_TEAM = 'ERROR_CLICKUP_STORE_TEAM';

export const CLICKUP_GET_WORKSPACES = 'CLICKUP_GET_WORKSPACES';
export const SUCCESS_CLICKUP_GET_WORKSPACES = 'SUCCESS_CLICKUP_GET_WORKSPACES';
export const ERROR_CLICKUP_GET_WORKSPACES = 'ERROR_CLICKUP_GET_WORKSPACES';

export const ASANA_GET_WORKSPACES = 'ASANA_GET_WORKSPACES';
export const SUCCESS_ASANA_GET_WORKSPACES = 'SUCCESS_ASANA_GET_WORKSPACES';
export const ERROR_ASANA_GET_WORKSPACES = 'ERROR_ASANA_GET_WORKSPACES';

export const ASANA_GET_TEAM = 'ASANA_GET_TEAM';
export const SUCCESS_ASANA_GET_TEAM = 'SUCCESS_ASANA_GET_TEAM';
export const ERROR_ASANA_GET_TEAM = 'ERROR_ASANA_GET_TEAM';

export const CLICKUP_STORE_WORKSPACE = 'CLICKUP_STORE_WORKSPACE';
export const SUCCESS_CLICKUP_STORE_WORKSPACE =
  'SUCCESS_CLICKUP_STORE_WORKSPACE';
export const ERROR_CLICKUP_STORE_WORKSPACE = 'ERROR_CLICKUP_STORE_WORKSPACE';

export const ASANA_STORE_WORKSPACE = 'ASANA_STORE_WORKSPACE';
export const SUCCESS_ASANA_STORE_WORKSPACE = 'SUCCESS_ASANA_STORE_WORKSPACE';
export const ERROR_ASANA_STORE_WORKSPACE = 'ERROR_ASANA_STORE_WORKSPACE';

export const ASANA_STORE_TEAMS = 'ASANA_STORE_TEAMS';
export const SUCCESS_ASANA_STORE_TEAMS = 'SUCCESS_ASANA_STORE_TEAMS';
export const ERROR_ASANA_STORE_TEAMS = 'ERROR_ASANA_STORE_TEAMS';

export const JIRA_GET_WEBSITES = 'JIRA_GET_WEBSITES';
export const SUCCESS_JIRA_GET_WEBSITES = 'SUCCESS_JIRA_GET_WEBSITES';
export const ERROR_JIRA_GET_WEBSITES = 'ERROR_JIRA_GET_WEBSITES';

export const JIRA_STORE_WEBSITES = 'JIRA_STORE_WEBSITES';
export const SUCCESS_JIRA_STORE_WEBSITES = 'SUCCESS_JIRA_STORE_WEBSITES';
export const ERROR_JIRA_STORE_WEBSITES = 'ERROR_JIRA_STORE_WEBSITES';

export const CLICKUP_GET_FOLDERS_SETTINGS = 'CLICKUP_GET_FOLDERS_SETTINGS';
export const SUCCESS_CLICKUP_GET_FOLDERS_SETTINGS =
  'SUCCESS_CLICKUP_GET_FOLDERS_SETTINGS';
export const ERROR_CLICKUP_GET_FOLDERS_SETTINGS =
  'ERROR_CLICKUP_GET_FOLDERS_SETTINGS';

export const CLICKUP_GET_TASK_LIST_SETTINGS = 'CLICKUP_GET_TASK_LIST_SETTINGS';
export const SUCCESS_CLICKUP_GET_TASK_LIST_SETTING =
  'SUCCESS_CLICKUP_GET_TASK_LIST_SETTING';
export const ERROR_CLICKUP_GET_TASK_LIST_SETTINGS =
  'ERROR_CLICKUP_GET_TASK_LIST_SETTINGS';

export const UPDATE_TASK_LIST_SELECTED_SETTINGS =
  'UPDATE_TASK_LIST_SELECTED_SETTINGS';

export const UPDATE_FOLDER_SELECTED_SETTINGS =
  'UPDATE_FOLDER_SELECTED_SETTINGS';

export const STORE_INTEGRATION_REQUIREMENT = 'STORE_INTEGRATION_REQUIREMENT';
export const SUCCESS_STORE_INTEGRATION_REQUIREMENT =
  'SUCCESS_STORE_INTEGRATION_REQUIREMENT';
export const ERROR_STORE_INTEGRATION_REQUIREMENT =
  'ERROR_STORE_INTEGRATION_REQUIREMENT';

export const ASANA_GET_PROJECTS_SETTINGS = 'ASANA_GET_PROJECTS_SETTINGS';
export const SUCCESS_ASANA_GET_PROJECTS_SETTINGS =
  'SUCCESS_ASANA_GET_PROJECTS_SETTINGS';
export const ERROR_ASANA_GET_PROJECTS_SETTINGS =
  'ERROR_ASANA_GET_PROJECTS_SETTINGS';

export const ASANA_GET_SECTION_SETTINGS = 'ASANA_GET_SECTION_SETTINGS';
export const SUCCESS_ASANA_GET_SECTION_SETTINGS =
  'SUCCESS_ASANA_GET_SECTION_SETTINGS';
export const ERROR_ASANA_GET_SECTION_SETTINGS =
  'ERROR_ASANA_GET_SECTION_SETTINGS';

export const UPDATE_SECTION_SELECTED_SETTINGS =
  'UPDATE_SECTION_SELECTED_SETTINGS';

export const UPDATE_JIRA_WEBSITE_SELECTED_SETTINGS =
  'UPDATE_JIRA_WEBSITE_SELECTED_SETTINGS';

export const UPDATE_PROJECT_SELECTED_SETTINGS =
  'UPDATE_PROJECT_SELECTED_SETTINGS';

export const TEAMWORK_GET_PROJECTS_SETTINGS = 'TEAMWORK_GET_PROJECTS_SETTINGS';
export const SUCCESS_TEAMWORK_GET_PROJECTS_SETTINGS =
  'SUCCESS_TEAMWORK_GET_PROJECTS_SETTINGS';
export const ERROR_TEAMWORK_GET_PROJECTS_SETTINGS =
  'ERROR_TEAMWORK_GET_PROJECTS_SETTINGS';

export const TEAMWORK_GET_TASKLIST_SETTINGS = 'TEAMWORK_GET_TASKLIST_SETTINGS';
export const SUCCESS_TEAMWORK_GET_TASKLIST_SETTINGS =
  'SUCCESS_TEAMWORK_GET_TASKLIST_SETTINGS';
export const ERROR_TEAMWORK_GET_TASKLIST_SETTINGS =
  'ERROR_TEAMWORK_GET_TASKLIST_SETTINGS';

export const UPDATE_TEAMWORK_PROJECT_SELECTED_SETTINGS =
  'UPDATE_TEAMWORK_PROJECT_SELECTED_SETTINGS';

export const UPDATE_TEAMWORK_TASK_SELECTED_SETTINGS =
  'UPDATE_TEAMWORK_TASK_SELECTED_SETTINGS';

export const JIRA_GET_PROJECTS_SETTINGS = 'JIRA_GET_PROJECTS_SETTINGS';
export const SUCCESS_JIRA_GET_PROJECTS_SETTINGS =
  'SUCCESS_JIRA_GET_PROJECTS_SETTINGS';
export const ERROR_JIRA_GET_PROJECTS_SETTINGS =
  'ERROR_JIRA_GET_PROJECTS_SETTINGS';

export const UPDATE_JIRA_PROJECT_SELECTED_SETTINGS =
  'UPDATE_JIRA_PROJECT_SELECTED_SETTINGS';

export const START_CHANGE_MAINWP_SETTINGS = 'START_CHANGE_MAINWP_SETTINGS';
export const SUCCESS_CHANGE_MAINWP_SETTINGS = 'SUCCESS_CHANGE_MAINWP_SETTINGS';
export const ERROR_CHANGE_MAINWP_SETTINGS = 'ERROR_CHANGE_MAINWP_SETTINGS';

export const START_CONNECT_ROCKET_SETTING = 'START_CONNECT_ROCKET_SETTING';
export const SUCCESS_CONNECT_ROCKET_SETTING = 'SUCCESS_CONNECT_ROCKET_SETTING';
export const ERROR_CONNECT_ROCKET_SETTING = 'ERROR_CONNECT_ROCKET_SETTING';

export const BASECAMP_GET_PROJECTS_SETTINGS = 'BASECAMP_GET_PROJECTS_SETTINGS';
export const SUCCESS_BASECAMP_GET_PROJECTS_SETTINGS =
  'SUCCESS_BASECAMP_GET_PROJECTS_SETTINGS';

export const BASECAMP_GET_SECTION_SETTINGS = 'BASECAMP_GET_SECTION_SETTINGS';
export const SUCCESS_BASECAMP_GET_SECTION_SETTINGS =
  'SUCCESS_BASECAMP_GET_SECTION_SETTINGS';
export const ERROR_BASECAMP_GET_SECTION_SETTINGS =
  'ERROR_BASECAMP_GET_SECTION_SETTINGS';

export const UPDATE_BASECAMP_PROJECT_SELECTED_SETTINGS =
  'UPDATE_BASECAMP_PROJECT_SELECTED_SETTINGS';
export const UPDATE_BASECAMP_TASK_SELECTED_SETTINGS =
  'UPDATE_BASECAMP_TASK_SELECTED_SETTINGS';

export const TRELLO_GET_PROJECTS_SETTINGS = 'TRELLO_GET_PROJECTS_SETTINGS';
export const SUCCESS_TRELLO_GET_PROJECTS_SETTINGS =
  'SUCCESS_TRELLO_GET_PROJECTS_SETTINGS';
export const ERROR_TRELLO_GET_PROJECTS_SETTINGS =
  'ERROR_TRELLO_GET_PROJECTS_SETTINGS';

export const TRELLO_GET_LIST_SETTINGS = 'TRELLO_GET_LIST_SETTINGS';
export const SUCCESS_TRELLO_GET_LIST_SETTINGS =
  'SUCCESS_TRELLO_GET_LIST_SETTINGS';
export const ERROR_TRELLO_GET_LIST_SETTINGS = 'ERROR_TRELLO_GET_LIST_SETTINGS';

export const UPDATE_TRELLO_BOARD_SELECTED_SETTINGS =
  'UPDATE_TRELLO_BOARD_SELECTED_SETTINGS';

export const UPDATE_TRELLO_LIST_SELECTED_SETTINGS =
  'UPDATE_TRELLO_LIST_SELECTED_SETTINGS';

export const START_CHANGE_INSTAWP_SETTINGS = 'START_CHANGE_INSTAWP_SETTINGS';
export const SUCCESS_CHANGE_INSTAWP_SETTINGS =
  'SUCCESS_CHANGE_INSTAWP_SETTINGS';
export const ERROR_CHANGE_INSTAWP_SETTINGS = 'ERROR_CHANGE_INSTAWP_SETTINGS';

export const START_CREATE_SNIPPET = 'START_CREATE_SNIPPET';
export const SUCCESS_CREATE_SNIPPET = 'SUCCESS_CREATE_SNIPPET';
export const ERROR_CREATE_SNIPPET = 'ERROR_CREATE_SNIPPET';

export const START_FETCH_ALL_SNIPPET = 'START_FETCH_ALL_SNIPPET';
export const SUCCESS_FETCH_ALL_SNIPPET = 'SUCCESS_FETCH_ALL_SNIPPET';
export const ERROR_FETCH_ALL_SNIPPET = 'ERROR_FETCH_ALL_SNIPPET';

export const NEW_SNIPPET_SELECTED = 'NEW_SNIPPET_SELECTED';
export const SUCCESS_NEW_SNIPPET_SELECTED = 'SUCCESS_NEW_SNIPPET_SELECTED';
export const ERROR_NEW_SNIPPET_SELECTED = 'ERROR_NEW_SNIPPET_SELECTED';

export const START_SNIPPET_DELETE = 'START_SNIPPET_DELETE';
export const SUCCESS_SNIPPET_DELETE = 'SUCCESS_SNIPPET_DELETE';
export const ERROR_SNIPPET_DELETE = 'ERROR_SNIPPET_DELETE';

export const START_UPDATE_SNIPPET = 'START_UPDATE_SNIPPET';
export const SUCCESS_UPDATE_SNIPPET = 'SUCCESS_UPDATE_SNIPPET';
export const ERROR_UPDATE_SNIPPET = 'ERROR_UPDATE_SNIPPET';

export const START_CREATE_SNIPPET_TEMPLATE = 'START_CREATE_SNIPPET_TEMPLATE';
export const SUCCESS_CREATE_SNIPPET_TEMPLATE =
  'SUCCESS_CREATE_SNIPPET_TEMPLATE';
export const ERROR_CREATE_SNIPPET_TEMPLATE = 'ERROR_CREATE_SNIPPET_TEMPLATE';

export const START_FETCH_ALL_SNIPPET_TEMPLATE =
  'START_FETCH_ALL_SNIPPET_TEMPLATE';
export const SUCCESS_FETCH_ALL_SNIPPET_TEMPLATE =
  'SUCCESS_FETCH_ALL_SNIPPET_TEMPLATE';
export const ERROR_FETCH_ALL_SNIPPET_TEMPLATE =
  'ERROR_FETCH_ALL_SNIPPET_TEMPLATE';

export const IMPORT_SNIPPET_TEMPLATE = 'IMPORT_SNIPPET_TEMPLATE';
export const SUCCESS_IMPORT_SNIPPET_TEMPLATE =
  'SUCCESS_IMPORT_SNIPPET_TEMPLATE';
export const ERROR_IMPORT_SNIPPET_TEMPLATE = 'ERROR_IMPORT_SNIPPET_TEMPLATE';

export const MONDAY_GET_WORKSPACE = 'MONDAY_GET_WORKSPACE';
export const SUCCESS_MONDAY_GET_WORKSPACE = 'SUCCESS_MONDAY_GET_WORKSPACE';
export const ERROR_MONDAY_GET_WORKSPACE = 'ERROR_MONDAY_GET_WORKSPACE';

export const UPDATE_MONDAY_WORKSPACE_SELECTED_SETTINGS =
  'UPDATE_MONDAY_WORKSPACE_SELECTED_SETTINGS';

export const MONDAY_STORE_WORKSPACE = 'MONDAY_STORE_WORKSPACE';
export const SUCCESS_MONDAY_STORE_WORKSPACE = 'SUCCESS_MONDAY_STORE_WORKSPACE';
export const ERROR_MONDAY_STORE_WORKSPACE = 'ERROR_MONDAY_STORE_WORKSPACE';

export const START_CHANGE_MONDAY_SETTINGS = 'START_CHANGE_MONDAY_SETTINGS';
export const SUCCESS_CHANGE_MONDAY_SETTINGS = 'SUCCESS_CHANGE_MONDAY_SETTINGS';
export const ERROR_CHANGE_MONDAY_SETTINGS = 'ERROR_CHANGE_MONDAY_SETTINGS';

export const MONDAY_GET_BOARD_SETTINGS = 'MONDAY_GET_BOARD_SETTINGS';
export const SUCCESS_MONDAY_GET_BOARD_SETTINGS =
  'SUCCESS_MONDAY_GET_BOARD_SETTINGS';
export const ERROR_MONDAY_GET_BOARD_SETTINGS =
  'ERROR_MONDAY_GET_BOARD_SETTINGS';

export const MONDAY_GET_GROUP_SETTINGS = 'MONDAY_GET_GROUP_SETTINGS';
export const SUCCESS_MONDAY_GET_GROUP_SETTINGS =
  'SUCCESS_MONDAY_GET_GROUP_SETTINGS';
export const ERROR_MONDAY_GET_GROUP_SETTINGS =
  'ERROR_MONDAY_GET_GROUP_SETTINGS';

export const UPDATE_MONDAY_BOARD_SELECTED_SETTINGS =
  'UPDATE_MONDAY_BOARD_SELECTED_SETTINGS';

export const UPDATE_MONDAY_GROUP_SELECTED_SETTINGS =
  'UPDATE_MONDAY_GROUP_SELECTED_SETTINGS';

export const VALIDATE_EMAIL_INBOX = 'VALIDATE_EMAIL_INBOX';
export const SUCCESS_VALIDATE_EMAIL_INBOX = 'SUCCESS_VALIDATE_EMAIL_INBOX';
export const ERROR_VALIDATE_EMAIL_INBOX = 'ERROR_VALIDATE_EMAIL_INBOX';
