import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  FETCH_SITES_BASED_ON_PROJECTS,
  START_FETCH_DASHBOARD_DATA,
} from '../actions/dashboard';
import { GET_ALL_FORMS, RESET_DISCOVERY_FLOW } from '../actions/discovery_flow';
import { SETTINGS_PAGE_MOUNTED } from '../actions/global_settings';
import {
  FETCH_ALL_ENTRIES,
  FETCH_NOT_LOGGED_ENTRIES,
} from '../actions/time_entries';
import { START_PROFILE_FETCH } from '../actions/user';

const useWorkspaceChangeEffect = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const token = useSelector((state) => state.user.token);
  const selectedWorkspace = useSelector(
    (state) => state.workspace.selectedWorkspace
  );
  const isLoading = useSelector((state) => state.dashboard.isLoading);
  // const queryClient = useQueryClient();

  useEffect(() => {
    if (!token) return;

    // Common actions for all routes
    dispatch({ type: START_PROFILE_FETCH, switchWorkspace: true });
    dispatch({ type: FETCH_NOT_LOGGED_ENTRIES });
    dispatch({ type: FETCH_SITES_BASED_ON_PROJECTS });

    // Route-specific actions
    if (
      !location.pathname.includes('/users') &&
      location.pathname !== '/workflows' &&
      !isLoading
    ) {
      dispatch({
        type: START_FETCH_DASHBOARD_DATA,
        url: '/site',
        selectedWebsite: '',
      });
    }

    if (location.pathname.includes('/forms')) {
      dispatch({ type: GET_ALL_FORMS });
      dispatch({ type: RESET_DISCOVERY_FLOW });
    } else if (location.pathname.includes('/time-entries')) {
      dispatch({
        type: FETCH_ALL_ENTRIES,
        screen: 'time-entries',
      });
    } else if (
      location.pathname.includes('/settings') ||
      location.pathname.includes('/users')
    ) {
      dispatch({ type: SETTINGS_PAGE_MOUNTED });
    }
  }, [selectedWorkspace]);
};

export default useWorkspaceChangeEffect;
