import { combineReducers } from 'redux';
import dashboard from './DashboardReducer';
import user from './UserReducer';
import tasks from './TasksReducer';
import activity from './ActivityReducer';
import global_settings from './GlobalSettingsReducer';
import board from './BoardReducer';
import home from './HomeReducer';
import time_entries from './TimeEntriesReducer';
import email from './EmailReducer';
import projects from './ProjectReducer';
import collaborate from './CollaborateReducer';
import discovery_flow from './DiscoveryFlowReducer';
import workspace from './WorkspaceReducer';

const appReducer = combineReducers({
  dashboard,
  user,
  tasks,
  activity,
  global_settings,
  board,
  home,
  time_entries,
  email,
  projects,
  collaborate,
  discovery_flow,
  workspace,
});

export default appReducer;
