import React, { useState } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  ELIGIBLE_TO_INACTIVE,
  HANDLE_PLANS_DIALOG,
  REJECT_TRIAL,
} from '../../actions/user';
import { dayLeftForTrial, isThreeDaysAfter } from '../../utils/functions';

const TrialHeaderBar = () => {
  const [showTrialHeader, setShowTrialHeader] = useState(true);
  const { userTrialData, userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigateToUpgrade = () => {
    if (userData.plan.from_free_plan && userData.subscription) {
      dispatch({ type: HANDLE_PLANS_DIALOG, params: true });
    } else {
      const upgradeUrl =
        userData.plan.upsell_url ||
        `${import.meta.env.VITE_APP_MAIN_WEBSITE_URL}/upgrade?_from=${userData.source}`;
      window.open(upgradeUrl);
    }
  };

  const handleCloseUpgradeHeader = () => {
    dispatch({ type: REJECT_TRIAL, params: true });
    setShowTrialHeader(false);
  };

  const navigateToStartWizard = () => {
    dispatch({ type: ELIGIBLE_TO_INACTIVE, params: 'inactive' });
    navigate('/start-wizard');
  };

  return (
    <>
      {userData?.trial?.status === 'ineligible' &&
        userData?.plan?.title === 'Atarim - Free Forever' &&
        (!userData?.trial?.trial_rejected_at ||
          isThreeDaysAfter(userData?.trial?.trial_rejected_at)) &&
        userData?.role === 'admin' && (
          <section className="at-cs-trial-header-bar">
            <span className="at-cs-trial-header-bar-copy">
              Get a FREE 14 day trial of Atarim Premium!
            </span>
            <button
              type="button"
              onClick={navigateToStartWizard}
              tabIndex="0"
              className="at-cs-trial-header-bar-button-start"
            >
              Unlock Premium
            </button>
            <button
              onClick={handleCloseUpgradeHeader}
              tabIndex="0"
              type="button"
              className="at-cs-trial-header-bar-button-close"
            >
              <IoCloseSharp />
            </button>
          </section>
        )}
      {userTrialData?.activation_date &&
        dayLeftForTrial(userTrialData?.activation_date) <= 14 &&
        showTrialHeader &&
        userData?.role === 'admin' &&
        userData?.trial?.status === 'active' &&
        (!userData?.trial?.trial_rejected_at ||
          dayLeftForTrial(userTrialData?.activation_date) <= 3) && (
          <section className="at-cs-trial-header-bar">
            <span className="at-cs-trial-header-bar-copy">
              You have{' '}
              <b> {dayLeftForTrial(userTrialData?.activation_date)} days</b>{' '}
              left on your free trial of Atarim, with every feature unlocked
            </span>
            <button
              type="button"
              onClick={handleNavigateToUpgrade}
              tabIndex="0"
              className="at-cs-trial-header-bar-button-start"
            >
              Choose A Plan
            </button>
            <button
              onClick={handleCloseUpgradeHeader}
              tabIndex="0"
              type="button"
              className="at-cs-trial-header-bar-button-close"
            >
              <IoCloseSharp />
            </button>
          </section>
        )}
      {userData?.trial?.status === 'expired' &&
        !userData?.trial?.trial_rejected_at &&
        userData?.role === 'admin' && (
          <section className="at-cs-trial-header-bar">
            <span className="at-cs-trial-header-bar-copy">
              Your free 14 day trial has ended.
            </span>
            <button
              type="button"
              onClick={handleNavigateToUpgrade}
              tabIndex="0"
              className="at-cs-trial-header-bar-button-start"
            >
              Upgrade your account
            </button>
            <button
              onClick={handleCloseUpgradeHeader}
              tabIndex="0"
              type="button"
              className="at-cs-trial-header-bar-button-close"
            >
              <IoCloseSharp />
            </button>
          </section>
        )}
    </>
  );
};

export default TrialHeaderBar;
