import API from '../api';
import { ENTRIES_LISTING_PAGE_LIMIT } from '../utils/constants';

export function fetchAllEntries(
  timeScreen,
  offset = 0,
  limit = ENTRIES_LISTING_PAGE_LIMIT,
  reqBody,
  selectedWorkspaceId
) {
  return API.get(
    `/time-entries?offset=${offset}&limit=${limit}&screen=${timeScreen}${selectedWorkspaceId ? `&workspace_id=${selectedWorkspaceId}` : ''}`,
    reqBody
  );
}

export function fetchNextEntries(
  timeScreen,
  offset,
  limit = ENTRIES_LISTING_PAGE_LIMIT,
  reqBody,
  selectedWorkspaceId
) {
  return API.get(
    `/time-entries?offset=${offset}&limit=${limit}&screen=${timeScreen}${selectedWorkspaceId ? `&workspace_id=${selectedWorkspaceId}` : ''}`,
    reqBody
  );
}

export function storeEntry(body) {
  return API.post(`/time-entries/store`, body);
}

export function updateEntry(body) {
  return API.post(`/time-entries/update`, body);
}

export function deleteEntry(id) {
  return API.get(`/time-entries/destroy/${id}`);
}

export function fetchNotLoggedEntries(selectedWorkspaceId) {
  return API.get(
    `/time-entries/not-logged${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

// export function updateSpentTime(body) {
//     return API.post(`/time-entries/update-spent-time`, body);
// }

export function clickupCreateTimeEntry(body) {
  return API.post(`/clickup/create_time_entry`, body);
}

export function clickupCreateTimeEntries(body) {
  return API.post(`/clickup/create_time_entries`, body);
}

export function clickupGetTasks(body) {
  return API.post(`/clickup/get_tasks`, body);
}

export function teamWorkCreateTimeEntry(body) {
  return API.post(`/teamwork/create_time_entry`, body);
}

export function teamWorkCreateTimeEntries(body) {
  return API.post(`/teamwork/create_time_entries`, body);
}
