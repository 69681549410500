export const GET_ITEM_DETAILS = 'GET_ITEM_DETAILS';
export const SUCCESS_GET_ITEM_DETAILS = 'SUCCESS_GET_ITEM_DETAILS';
export const ERROR_GET_ITEM_DETAILS = 'ERROR_GET_ITEM_DETAILS';

export const CREATE_SITE_TASK = 'CREATE_SITE_TASK';
export const SUCCESS_CREATE_SITE_TASK = 'SUCCESS_CREATE_SITE_TASK';
export const ERROR_CREATE_SITE_TASK = 'ERROR_CREATE_SITE_TASK';

export const CREATE_GRAPHIC_TASK = 'CREATE_GRAPHIC_TASK';

export const ADD_NEW_BUBBLE = 'ADD_NEW_BUBBLE';
export const REMOVE_BUBBLE = 'REMOVE_BUBBLE';

export const SUCCESS_UPDATE_INTERNAL_TASK = 'SUCCESS_UPDATE_INTERNAL_TASK';

export const GET_SITE_TASKS = 'GET_SITE_TASKS';
export const SUCCESS_GET_SITE_TASKS = 'SUCCESS_GET_SITE_TASKS';
export const ERROR_GET_SITE_TASKS = 'ERROR_GET_SITE_TASKS';

export const FETCH_NEXT_SITE_TASKS = 'FETCH_NEXT_SITE_TASKS';
export const SUCCESS_FETCH_NEXT_SITE_TASKS = 'SUCCESS_FETCH_NEXT_SITE_TASKS';
export const ERROR_FETCH_NEXT_SITE_TASKS = 'ERROR_FETCH_NEXT_SITE_TASKS';

export const ADD_NEW_SITE = 'ADD_NEW_SITE';
export const SUCCESS_ADD_NEW_SITE = 'SUCCESS_ADD_NEW_SITE';
export const ERROR_ADD_NEW_SITE = 'ERROR_ADD_NEW_SITE';

export const UPDATE_TASK_ON_SCROLL = 'UPDATE_TASK_ON_SCROLL';

export const GET_SITE_THIS_PAGE_TASK = 'GET_SITE_THIS_PAGE_TASK';
export const ERROR_GET_SITE_CURRENT_PAGE_TASK =
  'ERROR_GET_SITE_CURRENT_PAGE_TASK';
export const SUCCESS_FETCHING_SITE_CURRENT_PAGE_TASK =
  'SUCCESS_FETCHING_SITE_CURRENT_PAGE_TASK';

export const GET_GRAPHIC_CURRENT_GRAPHIC_TASK =
  'GET_GRAPHIC_CURRENT_GRAPHIC_TASK';
export const ERROR_GET_GRAPHIC_CURRENT_GRAPHIC_TASK =
  'ERROR_GET_GRAPHIC_CURRENT_GRAPHIC_TASK';
export const SUCCESS_GET_GRAPHIC_CURRENT_GRAPHIC_TASK =
  'SUCCESS_GET_GRAPHIC_CURRENT_GRAPHIC_TASK';

export const FETCH_NEXT_THIS_PAGE_TASK = 'FETCH_NEXT_THIS_PAGE_TASK';
export const ERROR_FETCH_NEXT_CURRENT_PAGE_TASK =
  'ERROR_FETCH_NEXT_CURRENT_PAGE_TASK';
export const SUCCESS_FETCHING_NEXT_CURRENT_PAGE_TASK =
  'SUCCESS_FETCHING_NEXT_CURRENT_PAGE_TASK';

export const SELECT_COLLABORATE_NOTIFY_USER = 'SELECT_COLLABORATE_NOTIFY_USER';

export const CREATE_TASK_COMMENT = 'CREATE_TASK_COMMENT';
export const SUCCESS_CREATE_TASK_COMMENT = 'SUCCESS_CREATE_TASK_COMMENT';
export const ERROR_CREATE_TASK_COMMENT = 'ERROR_CREATE_TASK_COMMENT';

export const NEW_COLLABORATE_TASK_SELECTED = 'NEW_COLLABORATE_TASK_SELECTED';
export const SELECT_GENERAL_MODAL = 'SELECT_GENERAL_MODAL';

export const CONFIRM_SELECTED = 'CONFIRM_SELECTED';

export const SUCCESS_ATTRIBUTES_FETCHING = 'SUCCESS_ATTRIBUTES_FETCHING';
export const ERROR_ATTRIBUTES_FETCHING = 'ERROR_ATTRIBUTES_FETCHING';

export const UPDATE_STATUS_ATTRIBUTE = 'UPDATE_STATUS_ATTRIBUTE';
export const SUCCESS_UPDATE_STATUS = 'SUCCESS_UPDATE_STATUS';

export const ATTACH_FILE_UPLOAD = 'ATTACH_FILE_UPLOAD';
export const SUCCESS_ATTACH_FILE_UPLOAD = 'SUCCESS_ATTACH_FILE_UPLOAD';
export const ERROR_ATTACH_FILE_UPLOAD = 'ERROR_ATTACH_FILE_UPLOAD';

export const SET_SCROLL_TOP = 'SET_SCROLL_TOP';

export const DELETE_COLLABORATE_TASK = 'DELETE_COLLABORATE_TASK';
export const SUCCESS_DELETE_COLLABORATE_TASK =
  'SUCCESS_DELETE_COLLABORATE_TASK';
export const ERROR_DELETE_COLLABORATE_TASK = 'ERROR_DELETE_COLLABORATE_TASK';

export const DELETE_VERSION = 'DELETE_VERSION';
export const SUCCESS_DELETE_VERSION = 'SUCCESS_DELETE_VERSION';
export const ERROR_DELETE_VERSION = 'ERROR_DELETE_VERSION';

export const DELETE_DESIGN = 'DELETE_DESIGN';
export const SUCCESS_DELETE_DESIGN = 'SUCCESS_DELETE_DESIGN';
export const ERROR_DELETE_DESIGN = 'ERROR_DELETE_DESIGN';

export const SUCCESS_DELETE_COMMENT = 'SUCCESS_DELETE_COMMENT';
export const ERROR_DELETE_COMMENT = 'ERROR_DELETE_COMMENT';

export const DELETE_COLLABORATE_COMMENT = 'DELETE_COLLABORATE_COMMENT';

export const APPROVE_A_PAGE = 'APPROVE_A_PAGE';
export const SUCCESS_APPROVE_PAGE = 'SUCCESS_APPROVE_PAGE';
export const ERROR_APPROVE_PAGE = 'ERROR_APPROVE_PAGE';

export const SOCIAL_USER_REGISTRATION = 'SOCIAL_USER_REGISTRATION';
export const USER_REGISTRATION = 'USER_REGISTRATION';
export const SUCCESS_USER_REGISTRATION = 'SUCCESS_USER_REGISTRATION';
export const ERROR_USER_REGISTRATION = 'ERROR_USER_REGISTRATION';

export const USER_LOGIN = 'USER_LOGIN';
export const SUCCESS_USER_LOGIN = 'SUCCESS_USER_LOGIN';
export const ERROR_USER_LOGIN = 'ERROR_USER_LOGIN';

export const OPEN_GENERAL_MODAL = 'OPEN_GENERAL_MODAL';
export const CLOSE_GENERAL_MODAL = 'CLOSE_GENERAL_MODAL';

export const SET_POPOVER_POSITION = 'SET_POPOVER_POSITION';

export const EDIT_COLLABORATE_COMMENT = 'EDIT_COLLABORATE_COMMENT';
export const SUCCESS_EDIT_COMMENT = 'SUCCESS_EDIT_COMMENT';
export const ERROR_EDIT_COMMENT = 'ERROR_EDIT_COMMENT';
export const UPDATE_SHOW_POPOVER = 'UPDATE_SHOW_POPOVER';

export const SET_OPEN_LOGIN = 'SET_OPEN_LOGIN';

export const MAKE_TAKE_SCREENSHOT_FALSE = 'MAKE_TAKE_SCREENSHOT_FALSE';

export const SET_TASK_FOR_OPEN_TASK_BUTTON = 'SET_TASK_FOR_OPEN_TASK_BUTTON';

export const SET_SITE_ID = 'SET_SITE_ID';

export const UPDATE_SITE_URL = 'UPDATE_SITE_URL';
export const SUCCESS_UPDATE_SITE_URL = 'SUCCESS_UPDATE_SITE_URL';
export const ERROR_UPDATE_SITE_URL = 'ERROR_UPDATE_SITE_URL';

export const UPGRADE_ACCOUNT = 'UPGRADE_ACCOUNT';
export const SUCCESS_UPGRADE_ACCOUNT = 'SUCCESS_UPGRADE_ACCOUNT';
export const ERROR_UPGRADE_ACCOUNT = 'ERROR_UPGRADE_ACCOUNT';

export const SUCCESS_UPLOAD_IMAGE_GRAPHICS = 'SUCCESS_UPLOAD_IMAGE_GRAPHICS';
export const SUCCESS_UPLOAD_IMAGE_VERSIONS = 'SUCCESS_UPLOAD_IMAGE_VERSIONS';

export const ADD_GRAPHIC_VERSION = 'ADD_GRAPHIC_VERSION';
export const SUCCESS_ADD_GRAPHIC_VERSION = 'SUCCESS_ADD_GRAPHIC_VERSION';
export const ERROR_ADD_GRAPHIC_VERSION = 'ERROR_ADD_GRAPHIC_VERSION';

export const UPDATE_GRAPHIC_TITLE = 'UPDATE_GRAPHIC_TITLE';
export const SUCCESS_UPDATE_GRAPHIC_TITLE = 'SUCCESS_UPDATE_GRAPHIC_TITLE';
export const ERROR_UPDATE_GRAPHIC_TITLE = 'ERROR_UPDATE_GRAPHIC_TITLE';

export const GET_GRAPHIC_VERSIONS = 'GET_GRAPHIC_VERSIONS';
export const SUCCESS_GET_GRAPHIC_VERSIONS = 'SUCCESS_GET_GRAPHIC_VERSIONS';
export const ERROR_GET_GRAPHIC_VERSIONS = 'ERROR_GET_GRAPHIC_VERSIONS';

export const CREATE_HOTSPOT = 'CREATE_HOTSPOT';
export const SUCCESS_CREATE_HOTSPOT = 'SUCCESS_CREATE_HOTSPOT';
export const ERROR_CREATE_HOTSPOT = 'ERROR_CREATE_HOTSPOT';

export const UPDATE_HOTSPOT = 'UPDATE_HOTSPOT';
export const SUCCESS_UPDATE_HOTSPOT = 'SUCCESS_UPDATE_HOTSPOT';
export const ERROR_UPDATE_HOTSPOT = 'ERROR_UPDATE_HOTSPOT';

export const DELETE_HOTSPOT = 'DELETE_HOTSPOT';
export const SUCCESS_DELETE_HOTSPOT = 'SUCCESS_DELETE_HOTSPOT';
export const ERROR_DELETE_HOTSPOT = 'ERROR_DELETE_HOTSPOT';

export const SET_HOTSPOT_ANNOTATIONS = 'SET_HOTSPOT_ANNOTATIONS';

export const SET_SELECTED_GRAPHIC = 'SET_SELECTED_GRAPHIC';

export const UPDATE_GODADDY_DONE = 'UPDATE_GODADDY_DONE';
export const SUCCESS_UPDATE_GODADDY_DONE = 'SUCCESS_UPDATE_GODADDY_DONE';
export const ERROR_UPDATE_GODADDY_DONE = 'ERROR_UPDATE_GODADDY_DONE';

export const ADD_NEW_CUSTOM_SITE = 'ADD_NEW_CUSTOM_SITE';
export const SUCCESS_ADD_NEW_CUSTOM_SITE = 'SUCCESS_ADD_NEW_CUSTOM_SITE';
export const ERROR_ADD_NEW_CUSTOM_SITE = 'ERROR_ADD_NEW_CUSTOM_SITE';

export const CREATE_ANNOTATED_TASK = 'CREATE_ANNOTATED_TASK';
export const SUCCESS_CREATE_ANNOTATED_TASK = 'SUCCESS_CREATE_ANNOTATED_TASK';
export const ERROR_CREATE_ANNOTATED_TASK = 'ERROR_CREATE_ANNOTATED_TASK';

export const ADD_NEW_CUSTOM_SITE_EXIST = 'ADD_NEW_CUSTOM_SITE_EXIST';

export const START_INVITE_COLLAB = 'START_INVITE_COLLAB';
export const SUCCESS_START_INVITE_COLLAB = 'SUCCESS_START_INVITE_COLLAB';
export const ERROR_START_INVITE_COLLAB = 'ERROR_START_INVITE_COLLAB';

export const FETCH_INVITED_COLLAB = 'FETCH_INVITED_COLLAB';
export const SUCCESS_FETCH_INVITED_COLLAB = 'SUCCESS_FETCH_INVITED_COLLAB';
export const ERROR_FETCH_INVITED_COLLAB = 'ERROR_FETCH_INVITED_COLLAB';

export const REMOVE_INVITED_COLLAB = 'REMOVE_INVITED_COLLAB';
export const SUCCESS_REMOVE_INVITED_COLLAB = 'SUCCESS_REMOVE_INVITED_COLLAB';
export const ERROR_REMOVE_INVITED_COLLAB = 'ERROR_REMOVE_INVITED_COLLAB';

export const ACCEPT_INVITATION = 'ACCEPT_INVITATION';
export const SUCCESS_ACCEPT_INVITATION = 'SUCCESS_ACCEPT_INVITATION';
export const ERROR_ACCEPT_INVITATION = 'ERROR_ACCEPT_INVITATION';

export const ADD_STAMDARD_FLOW_SITE = 'ADD_STAMDARD_FLOW_SITE';
export const SUCCESS_ADD_STAMDARD_FLOW_SITE = 'SUCCESS_ADD_STAMDARD_FLOW_SITE';
export const ERROR_ADD_STAMDARD_FLOW_SITE = 'ERROR_ADD_STAMDARD_FLOW_SITE';
export const OPEN_CLOSE_NAVBAR = 'OPEN_CLOSE_NAVBAR';

export const COMMENT_EDIT_HISTORY = 'COMMENT_EDIT_HISTORY';
export const SUCCESS_COMMENT_EDIT_HISTORY = 'SUCCESS_COMMENT_EDIT_HISTORY';
export const ERROR_COMMENT_EDIT_HISTORY = 'ERROR_COMMENT_EDIT_HISTORY';

export const REMOVE_SELECTED_TASK = 'REMOVE_SELECTED_TASK';

export const REMOVE_ITEM_DETAILS = 'REMOVE_ITEM_DETAILS';

export const RESEND_COLLAB_INVITE = 'RESEND_COLLAB_INVITE';
export const SUCCESS_RESEND_COLLAB_INVITE = 'SUCCESS_RESEND_COLLAB_INVITE';
export const ERROR_RESEND_COLLAB_INVITE = 'ERROR_RESEND_COLLAB_INVITE';

// we are using task's action called CREATE_NEW_GENERAL_TASK for fetching pages
export const SUCCESS_FETCH_PAGES = 'SUCCESS_FETCH_PAGES';

export const ADD_PAGE = 'ADD_PAGE';
export const SUCCESS_ADD_PAGE = 'SUCCESS_ADD_PAGE';
export const ERROR_ADD_PAGE = 'ERROR_ADD_PAGE';

export const FETCH_PAGES = 'FETCH_PAGES';
export const SUCCESS_FETCH_ALL_PAGES = 'SUCCESS_FETCH_ALL_PAGES';
export const ERROR_FETCH_PAGES = 'ERROR_FETCH_PAGES';

export const DELETE_PAGE = 'DELETE_PAGE';
export const SUCCESS_DELETE_PAGE = 'SUCCESS_DELETE_PAGE';
export const ERROR_DELETE_PAGE = 'ERROR_DELETE_PAGE';

export const DELETE_COMMENT_FILE = 'DELETE_COMMENT_FILE';
// export const SUCCESS_DELETE_COMMENT_FILE = "SUCCESS_DELETE_COMMENT_FILE";
export const ERROR_DELETE_COMMENT_FILE = 'ERROR_DELETE_COMMENT_FILE';

export const SHOW_SHARE_POPUP = 'SHOW_SHARE_POPUP';

export const GET_OPEN_TASK_NUMBER = 'GET_OPEN_TASK_NUMBER';
export const SUCCESS_GET_OPEN_TASK_NUMBER = 'SUCCESS_GET_OPEN_TASK_NUMBER';
export const ERROR_GET_OPEN_TASK_NUMBER = 'ERROR_GET_OPEN_TASK_NUMBER';

export const EMPTY_FAILED_TASK_DATA = 'EMPTY_FAILED_TASK_DATA';

export const EXTENSION_METRICS = 'EXTENSION_METRICS';

export const FETCH_DUMMY_TASKS = 'FETCH_DUMMY_TASKS';
export const SUCCESS_FETCH_DUMMY_TASKS = 'SUCCESS_FETCH_DUMMY_TASKS';
export const ERROR_FETCH_DUMMY_TASKS = 'ERROR_FETCH_DUMMY_TASKS';
export const UPDATE_DUMMY_TASKS = 'UPDATE_DUMMY_TASKS';

export const UPDATE_CHANGE_URL_ON_LOGIN = 'UPDATE_CHANGE_URL_ON_LOGIN';

export const SET_2FA_COLLAB_STATUS = 'SET_2FA_COLLAB_STATUS';
export const SUCCESS_2FA_COLLAB_STATUS = 'SUCCESS_2FA_COLLAB_STATUS';
export const ERROR_2FA_COLLAB_STATUS = 'ERROR_2FA_COLLAB_STATUS';

export const VERIFY_COLLAB_CODE = 'VERIFY_COLLAB_CODE';
export const SUCCESS_VERIFY_COLLAB_CODE = 'SUCCESS_VERIFY_COLLAB_CODE';
export const ERROR_VERIFY_COLLAB_CODE = 'ERROR_VERIFY_COLLAB_CODE';

export const SET_OPEN_REGISTER = 'SET_OPEN_REGISTER';

export const UPDATE_ITEM_DETAILS_ROLE = 'UPDATE_ITEM_DETAILS_ROLE';
export const STORE_COLLAB_STATE = 'STORE_COLLAB_STATE';

export const UPDATE_GRAPHIC_TASK_COUNT = 'UPDATE_GRAPHIC_TASK_COUNT';

export const UPDATE_PAGE_TASK_COUNT = 'UPDATE_PAGE_TASK_COUNT';
