export function convertToRgb(color) {
  if (color.startsWith('#')) {
    let hex = color.replace(/^#/, '');
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map((hex) => hex + hex)
        .join('');
    }
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  } else if (color.startsWith('rgba')) {
    const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
    return rgba.slice(0, 3).map(Number);
  } else if (color.startsWith('hsla') || color.startsWith('hsl')) {
    const hsla = color.replace(/^hsla?\(|\s+|\)$/g, '').split(',');
    let h = parseFloat(hsla[0]);
    let s = parseFloat(hsla[1]) / 100;
    let l = parseFloat(hsla[2]) / 100;

    if (s === 0) {
      l = Math.round(l * 255);
      return [l, l, l];
    } else {
      const hueToRgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      const r = hueToRgb(p, q, h + 1 / 3);
      const g = hueToRgb(p, q, h);
      const b = hueToRgb(p, q, h - 1 / 3);
      return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    }
  }
  return [0, 0, 0];
}

export function getContrastingColor(colour) {
  var rgb = convertToRgb(colour);
  var luminance = 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];
  return luminance > 128 ? '#000000' : '#FFFFFF';
}

export function _stringAsciiPRNG(value, m) {
  // Xn+1 = (a * Xn + c) % m
  // 0 < a < m
  // 0 <= c < m
  // 0 <= X0 < m

  const charCodes = [...value].map((letter) => letter.charCodeAt(0));
  const len = charCodes.length;

  const a = (len % (m - 1)) + 1;
  const c = charCodes.reduce((current, next) => current + next) % m;

  let random = charCodes[0] % m;
  for (let i = 0; i < len; i++) random = (a * random + c) % m;

  return random;
}

export function _numberPRNG(seed, m, a = 42069, c = 9001) {
  // Xn+1 = (a * Xn + c) % m
  // 0 < a < m
  // 0 <= c < m
  // 0 <= X0 < m

  let random = seed;
  random = (a * random + c) % m;

  return random;
}
