import {
  CREATE_NEW_GENERAL_TASK,
  LEAVING_TASKS_PAGE,
  START_FILTERING_TASKS,
  SUCCESS_DELETE_TASK,
} from '../actions/tasks';
import API from '../api';

// eslint-disable-next-line no-unused-vars
const leaveTaskMiddleware = (store) => (next) => (action) => {
  if (
    [
      START_FILTERING_TASKS,
      CREATE_NEW_GENERAL_TASK,
      SUCCESS_DELETE_TASK,
      LEAVING_TASKS_PAGE,
    ].indexOf(action.type) !== -1
  ) {
    API.get('/user/left-tasks-page');
  }
  return next(action);
};

export default leaveTaskMiddleware;
