import React, { useState, useEffect, useRef } from 'react';
// Components
import LogTimeModal from './LogTimeModal';
// Styling
import Button from '@material-ui/core/Button';
import { FiPlay, FiPause } from 'react-icons/fi';
import { BiTimeFive } from 'react-icons/bi';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';
// Utils
import { secToTime, formatTime } from '../utils/functions';
import clsx from 'clsx';
import CustomToolTip from '../components/CustomToolTip';

const useClasses = makeStyles((theme) => ({
  timerItem: {
    display: 'flex',
    fontFamily: theme.text.normalText.fontFamily,
    padding: '5px',
    border: '1px solid #EFF2F6',
    borderRadius: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 8,
  },
  addBackgroundColor: {
    backgroundColor: 'rgba(109, 93, 243, 0.07)',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:hover': {
      cursor: 'pointer',
    },
    maxWidth: 170,
    minWidth: 170,
  },
  task: {
    fontSize: 14,
    color: '#272D3C',
    fontWeight: 500,
    maxWidth: '250px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  website: {
    fontWeight: 500,
    color: '#272D3C',
  },
  time: {
    display: 'flex',
    alignItems: 'center',
    color: '#272D3C',
  },
  timeSpent: {
    fontSize: '14px !important',
    fontWeight: 700,
  },
  startButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 8,
  },
  buttonSpan: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logButton: {
    color: '#FFF',
    backgroundColor: '#269AF1',
    borderRadius: 5,
    fontSize: 14,
    '&:hover': {
      backgroundColor: '#1472b8',
    },
  },
  maxWidth: {
    maxWidth: 73,
  },
}));

export default function TimerItem(props) {
  const {
    id,
    userId,
    // date,
    startTime,
    endTime,
    taskName,
    siteName,
    taskId,
    spentTime,
    description,
    billable,
    setDescription,
    setBillable,
    setShowDetails,
    setTimerEntry,
    selectedTimerEntry,
    showDetails,
    currentTimerTaskId,
    setCurrentTimerTaskId,
    timerPlay,
    setTimerPlay,
    updateFloatingSpentTime,
    updateEntry,
    localDescription,
    localBillable,
    // sendMessage,
    running,
    updateNotLogged,
    sendMessageWithoutPosting,
    timerItemIndex,
    setSelectedItem,
    selectedItem,
  } = props;

  const classes = useClasses();

  const [logTimeModal, setLogTimeModal] = useState(false);
  const [localTimer, setLocalTimer] = useState(parseInt(spentTime));
  const countRef = useRef(null);

  const handleClick = () => {
    if (id === selectedTimerEntry && showDetails === true) {
      setShowDetails(false);
    } else {
      setTimerEntry(id);
      setShowDetails(true);
      setDescription(description ? description : '');
      setBillable(billable);
      setSelectedItem(timerItemIndex);
    }
  };

  const handleTimerControl = () => {
    if (timerPlay) {
      // if play is true here, it means that the last line of this method's block will make it false i.e., pause
      updateFloatingSpentTime({
        id: id,
        user_id: userId,
        // date: date,
        start_time: startTime,
        end_time: endTime,
        spent_time: localTimer,
        description: description,
        billable: billable,
        running: false,
        started_at_spent_time: false,
        logged: false,
      });
      clearInterval(countRef.current);
      setTimerPlay(false);
    } else {
      updateNotLogged({
        id: id,
        user_id: userId,
        // date: date,
        start_time: startTime,
        end_time: endTime,
        spent_time: spentTime,
        description: description,
        billable: billable,
        running: true,
        started_at_spent_time: true,
        started_at: true,
      });
      setTimerPlay(true);
      setCurrentTimerTaskId(taskId);

      // setLocalTimer(parseInt(spentTime))
      countRef.current = setInterval(() => {
        setLocalTimer((prev) => prev + 1);
      }, 1000);
    }
  };

  const closeLogModal = () => {
    setLogTimeModal(false);
  };

  const onClickLog = () => {
    setLogTimeModal(true);
  };

  const removeTags = (str) => {
    if (str === null || str === '') return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, '');
  };

  useEffect(() => {
    if (running) {
      setTimerPlay(true);
      setCurrentTimerTaskId(taskId);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (running) {
      // setLocalTimer(parseInt(spentTime))
      countRef.current = setInterval(() => {
        setLocalTimer((prev) => prev + 1);
      }, 1000);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLocalTimer(parseInt(spentTime));
  }, [spentTime]);

  return (
    <div
      className={clsx(
        classes.timerItem,
        id === selectedTimerEntry && showDetails && classes.addBackgroundColor
      )}
    >
      <div className={classes.details} onClick={() => handleClick()}>
        <span className={classes.task}>{removeTags(taskName)}</span>

        <div className={classes.time}>
          <div className="at-cs-timer-logged-title">Total Time Logged:</div>
          <div className={classes.timeSpent}>
            {currentTimerTaskId === taskId
              ? formatTime(...secToTime(localTimer)) // when running
              : localTimer && formatTime(...secToTime(localTimer))}
          </div>
        </div>
      </div>
      <div className={classes.startButtonContainer}>
        <Button
          className="at-cs-btn btn-outline square"
          onClick={handleTimerControl}
          disabled={timerPlay && currentTimerTaskId !== taskId}
        >
          {timerPlay && currentTimerTaskId === taskId ? (
            <CustomToolTip title="Pause" placement="top">
              <span className={classes.buttonSpan}>
                <FiPause size="20px" />
              </span>
            </CustomToolTip>
          ) : (
            <CustomToolTip title="Start" placement="top">
              <span className={classes.buttonSpan}>
                <FiPlay size="20px" className={classes.startIcon} />
              </span>
            </CustomToolTip>
          )}
        </Button>

        <div>
          <CustomToolTip title="Log Time" placement="top">
            <button className="at-cs-btn btn-green square" onClick={onClickLog}>
              <BiTimeFive size="20px" className={classes.startIcon} />
            </button>
          </CustomToolTip>
        </div>
      </div>

      <Dialog
        open={Boolean(logTimeModal)}
        onClose={closeLogModal}
        className="at-cs-modal-popover"
      >
        <LogTimeModal
          closeLogModal={closeLogModal}
          updateEntry={updateEntry}
          id={id}
          userId={userId}
          startTime={startTime}
          endTime={endTime}
          spentTime={spentTime}
          // description =               {description}
          // billable =                  {billable}
          taskName={taskName}
          siteName={siteName}
          localDescription={localDescription}
          localBillable={localBillable}
          taskId={taskId}
          // sendMessage =               {sendMessage}
          // timerPlay =                 {timerPlay}
          sendMessageWithoutPosting={sendMessageWithoutPosting}
          localTimer={localTimer}
          selectedItem={selectedItem}
          timerItemIndex={timerItemIndex}
        />
      </Dialog>
    </div>
  );
}
