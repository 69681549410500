import {
  START_VERIFY_MAILBOX,
  SUCCESS_VERIFY_MAILBOX,
  ERROR_VERIFY_MAILBOX,
  SUCCESS_EMAIL_VERIFICATION,
  START_COMPOSE_EMAIL,
  SUCCESS_COMPOSE_EMAIL,
  ERROR_COMPOSE_EMAIL,
  START_REPLY_EMAIL,
  SUCCESS_REPLY_EMAIL,
  ERROR_REPLY_EMAIL,
} from '../actions/email';

const initState = {
  verifyMailboxIsLoading: false,
  verifyMailboxIsSuccess: false,
  errorMessage: '',
  verificationIsSuccess: null,
  composeMailboxIsLoading: false,
  replyMailboxIsLoading: false,
  composeMailStatus: false,
};

const email = (previousState = initState, action) => {
  switch (action.type) {
    case START_VERIFY_MAILBOX:
      return {
        ...previousState,
        verifyMailboxIsLoading: true,
      };
    case SUCCESS_VERIFY_MAILBOX:
      return {
        ...previousState,
        verifyMailboxIsSuccess: true,
      };
    case ERROR_VERIFY_MAILBOX:
      return {
        ...previousState,
        verifyMailboxIsSuccess: false,
        errorMessage: action.message,
      };
    case SUCCESS_EMAIL_VERIFICATION:
      return {
        ...previousState,
        verificationIsSuccess: action.verified,
      };
    case START_COMPOSE_EMAIL:
      return {
        ...previousState,
        composeMailboxIsLoading: true,
        composeMailStatus: false,
      };
    case SUCCESS_COMPOSE_EMAIL:
      return {
        ...previousState,
        composeMailboxIsLoading: false,
        composeMailStatus: true,
      };

    case ERROR_COMPOSE_EMAIL:
      return {
        ...previousState,
        composeMailboxIsLoading: false,
        composeMailStatus: false,
      };
    case START_REPLY_EMAIL:
      return {
        replyMailboxIsLoading: true,
      };
    case SUCCESS_REPLY_EMAIL:
    case ERROR_REPLY_EMAIL:
      return {
        replyMailboxIsLoading: false,
      };
    default:
      return previousState;
  }
};

export default email;
