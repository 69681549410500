export const NEW_EMAIL_SELECTED = 'NEW_EMAIL_SELECTED';
export const SUCCESS_EMAIL_TASKS = 'SUCCESS_EMAIL_TASKS';
export const ERROR_EMAIL_TASKS = 'ERROR_EMAIL_TASKS';

export const START_VERIFY_MAILBOX = 'START_MAILBOX_VERIFY';
export const SUCCESS_VERIFY_MAILBOX = 'SUCCESS_MAILBOX_VERIFY';
export const ERROR_VERIFY_MAILBOX = 'ERROR_MAILBOX_VERIFY';

export const START_EMAIL_TASK_TO_SITE = 'START_EMAIL_TASK_TO_SITE';
export const SUCCESS_EMAIL_TASK_TO_SITE = 'SUCCESS_EMAIL_TASK_TO_SITE';
export const ERROR_EMAIL_TASK_TO_SITE = 'ERROR_EMAIL_TASK_TO_SITE';

export const SUCCESS_EMAIL_VERIFICATION = 'SUCCESS_EMAIL_VERIFICATION';

export const START_REPLY_EMAIL = 'START_REPLY_EMAIL';
export const SUCCESS_REPLY_EMAIL = 'SUCCESS_REPLY_EMAIL';
export const ERROR_REPLY_EMAIL = 'ERROR_REPLY_EMAIL';

export const START_COMPOSE_EMAIL = 'START_COMPOSE_EMAIL';
export const SUCCESS_COMPOSE_EMAIL = 'SUCCESS_COMPOSE_EMAIL';
export const ERROR_COMPOSE_EMAIL = 'ERROR_COMPOSE_EMAIL';

export const SET_SELECTED_MAILBOX_ID = 'SET_SELECTED_MAILBOX_ID';
export const SET_EMAIL_SELECTED = 'SET_EMAIL_SELECTED';
export const SET_PLACEHOLDER_MAILBOX_SELECTED =
  'SET_PLACEHOLDER_MAILBOX_SELECTED';

export const FETCH_NEXT_EMAIL_TASKS = 'FETCH_NEXT_EMAIL_TASKS';
export const SUCCESS_FETCH_NEXT_EMAIL_TASKS = 'SUCCESS_FETCH_NEXT_EMAIL_TASKS';
export const ERROR_FETCH_NEXT_EMAIL_TASKS = 'ERROR_FETCH_NEXT_EMAIL_TASKS';
