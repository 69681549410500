import API from '../api';

export function fetchGlobalSettings(selectedWorkspaceId) {
  return API.get(
    `user/global-settings${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function updateGlobalSettings(paramObj, selectedWorkspaceId) {
  return API.post(
    `/user/update-global-settings${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    paramObj
  );
}

export function resetSettings() {
  return API.get('/user/reset-global-settings');
}

export function newWebhookToken() {
  return API.get('/new_webhook_token');
}

export function changeWebhookStatus(obj) {
  return API.post('/change_webhook_status', obj);
}

export function changeCustomWebhook(obj) {
  return API.post('/change_webhook_url', obj);
}

export function changeSlackSetting(obj) {
  return API.post('/slack/change_slack_settings', obj);
}

export function changeIntegrationSetting(obj, selectedWorkspaceId) {
  return API.post(
    `/user/update-global-settings${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    obj
  );
}

export function changeAutomaticTaskSetting(obj) {
  return API.post('/change_automatic_task_settings', obj);
}

export function verifyForwarding() {
  return API.post('/receive-incoming-email');
}

export function verifyDomain(paramObj, selectedWorkspaceId) {
  return API.post(
    `/user/verify-domain?workspace_id=${selectedWorkspaceId}`,
    paramObj
  );
}

// clickUp integration
export function clickupCompleteAuth(paramObj) {
  return API.post('/clickup/complete_authentication', paramObj);
}

export function clickupGetTeams() {
  return API.get('/clickup/get_clickup_teams');
}

export function clickupStoreTeam(paramObj) {
  return API.post('/clickup/team_id/store', paramObj);
}

export function clickupGetWorkspaces() {
  return API.get('/clickup/get_workspaces');
}

export function asanaGetWorkspaces() {
  return API.get('/asana/get_asana_workspace');
}

export function asanaGetTeam() {
  return API.get('/asana/get_team');
}

export function clickupStoreWorkspace(paramObj) {
  return API.post('/clickup/workspace/store', paramObj);
}

export function asanaStoreWorkspace(paramObj) {
  return API.post('/asana/workspace/store', paramObj);
}

export function asanaStoreTeams(paramObj) {
  return API.post('/asana/team/store', paramObj);
}

export function jiraGetWebsites() {
  return API.get('/jira/get_jira_sites');
}

export function jiraStoreWebsite(paramObj) {
  return API.post('/jira/store_jira_site', paramObj);
}

export function clickupGetFoldersSettings(params) {
  return API.get(`/clickup/get_folders/${params}`);
}

export function clickupGetTaskListSetting(params) {
  return API.post('/clickup/task_lists/3', params);
}

export function storeIntegrationRequirement(params) {
  return API.post('/store_integration_requirements', params);
}

export function asanaGetProjectsSettings(params) {
  return API.get(`/asana/get_projects/${params}`);
}

export function asanaGetSectionSetting(params) {
  return API.post('/asana/get_sections/6', params);
}

export function teamworkGetProjectsSettings(params) {
  return API.get(`/teamwork/get_teamwork_projects/${params}`);
}

export function teamworkGetTaskListSetting(params, num) {
  return API.get(`/teamwork/get_teamwork_task_list/${params}/${num}`);
}

export function jiraGetProjectsSettings(params) {
  return API.get(`/jira/get_projects/${params}`);
}

export function changeMainwpSetting(obj) {
  return API.post('/change_mainwp_settings', obj);
}

export function connectRocketSetting(obj) {
  return API.post('/rocket/connect', obj);
}

export function basecampGetProjectsSettings(params) {
  return API.get(`/basecamp/get_projects/${params}`);
}

export function basecampGetTaskSetting(params) {
  return API.post('/basecamp/get_todo_lists/6', params);
}

export function trelloGetProjectsSettings(params) {
  return API.get(`/trello/get_automatic_boards/${params}`);
}

export function trelloGetListSetting(params) {
  return API.post('/trello/get_automatic_lists/9', params);
}

export function changeInstawpSetting(obj) {
  return API.post('/instawp_api_key', obj);
}

export function createSnippetSetting(obj, selectedWorkspaceId) {
  return API.post(
    `/snippets/create${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    obj
  );
}

export function fetchAllSnippetSetting(selectedWorkspaceId) {
  return API.get(
    `/snippets${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function fetchSnippetDetailSetting(id) {
  return API.get(`/snippets/${id}/detail`);
}

export function deleteSnippetSetting(params) {
  return API.post(`/snippets/delete`, params);
}

export function updateSnippetSetting(obj) {
  return API.post(`/snippets/update`, obj);
}

export function createSnippetTemplateSetting(obj) {
  return API.post('/snippets/templates/create', obj);
}

export function fetchAllSnippetTemplateSetting() {
  return API.get(`/snippets/templates/all`);
}

export function importSnippetTemplateSetting(obj, selectedWorkspaceId) {
  return API.post(
    `snippets/templates/import${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    obj
  );
}

export function mondayGetWorkspace() {
  return API.get('/monday/get_workspace');
}

export function mondayStorespace(paramObj) {
  return API.post('/monday/store_workspace', paramObj);
}

export function changeMondaySetting(obj) {
  return API.post('/monday/store_key', obj);
}

export function mondayGetBoardsSettings(params) {
  return API.get(`/monday/get_automatic_boards/${params}`);
}

export function mondayGetGroupSetting(params) {
  return API.post('/monday/get_automatic_groups/2', params);
}

export function validateEmailInbox(paramObj) {
  return API.post('/mailbox/forward-email', paramObj);
}
