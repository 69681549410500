export const FETCH_WORKSPACES = 'FETCH_WORKSPACES';
export const SUCCESS_FETCH_WORKSPACES = 'SUCCESS_FETCH_WORKSPACES';
export const ERROR_FETCH_WORKSPACES = 'ERROR_FETCH_WORKSPACES';

export const CREATE_WORKSPACE = 'CREATE_WORKSPACE';
export const SUCCESS_CREATE_WORKSPACE = 'SUCCESS_CREATE_WORKSPACE';
export const ERROR_CREATE_WORKSPACE = 'ERROR_CREATE_WORKSPACE';

export const EMPTY_WORKSPACE_MESSAGE = 'EMPTY_WORKSPACE_MESSAGE';

export const DELETE_WORKSPACE = 'DELETE_WORKSPACE';
export const SUCCESS_DELETE_WORKSPACE = 'SUCCESS_DELETE_WORKSPACE';
export const ERROR_DELETE_WORKSPACE = 'ERROR_DELETE_WORKSPACE';

export const EDIT_WORKSPACE = 'EDIT_WORKSPACE';
export const SUCCESS_EDIT_WORKSPACE = 'SUCCESS_EDIT_WORKSPACE';
export const ERROR_EDIT_WORKSPACE = 'ERROR_EDIT_WORKSPACE';

export const SET_SELECTED_WORKSPACE = 'SET_SELECTED_WORKSPACE';

export const GET_WORKSPACE_DETAILS = 'GET_WORKSPACE_DETAILS';
export const SUCCESS_GET_WORKSPACE_DETAILS = 'SUCCESS_GET_WORKSPACE_DETAILS';
export const ERROR_GET_WORKSPACE_DETAILS = 'ERROR_GET_WORKSPACE_DETAILS';

export const UPDATE_WORKSPACE_DETAILS = 'UPDATE_WORKSPACE_DETAILS';
export const SUCCESS_UPDATE_WORKSPACE_DETAILS =
  'SUCCESS_UPDATE_WORKSPACE_DETAILS';
export const ERROR_UPDATE_WORKSPACE_DETAILS = 'ERROR_UPDATE_WORKSPACE_DETAILS';

export const FETCH_ACCOUNT_USERS = 'FETCH_ACCOUNT_USERS';
export const SUCCESS_FETCH_ACCOUNT_USERS = 'SUCCESS_FETCH_ACCOUNT_USERS';
export const ERROR_FETCH_ACCOUNT_USERS = 'ERROR_FETCH_ACCOUNT_USERS';

export const SET_SELECTED_WORKSPACE_SETTINGS =
  'SET_SELECTED_WORKSPACE_SETTINGS';

export const STORE_SELECTED_WORKSPACE = 'STORE_SELECTED_WORKSPACE';
export const SUCCESS_STORE_SELECTED_WORKSPACE =
  'SUCCESS_STORE_SELECTED_WORKSPACE';
export const ERROR_STORE_SELECTED_WORKSPACE = 'ERROR_STORE_SELECTED_WORKSPACE';

export const UPDATE_SELECTED_WORKSPACE_PROJECT_COUNT =
  'UPDATE_SELECTED_WORKSPACE_PROJECT_COUNT';

export const REDUCE_SELECTED_WORKSPACE_PROJECT_COUNT =
  'REDUCE_SELECTED_WORKSPACE_PROJECT_COUNT';

export const FETCH_WORKSPACE_OWNER = 'FETCH_WORKSPACE_OWNER';
export const SUCCESS_FETCH_WORKSPACE_OWNER = 'SUCCESS_FETCH_WORKSPACE_OWNER';
export const ERROR_FETCH_WORKSPACE_OWNER = 'ERROR_FETCH_WORKSPACE_OWNER';
