import { useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { LICENSE_ACTIVATION, SET_REDIRECT } from '../actions/user';
import { useAuth } from './useAuth';

const usePluginActivationRedirect = () => {
  const { isPending } = useAuth();
  const { userData } = useSelector((state) => state.user);
  const location = useLocation();
  const dispatch = useDispatch();
  const licenseActivationSuccess = useSelector(
    (state) => state.user.licenseActivationSuccess
  );
  const licenseActivationMessage = useSelector(
    (state) => state.user.licenseActivationMessage
  );
  const activateLicenseData = useSelector(
    (state) => state.user.activateLicenseData
  );
  const [searchParams] = useSearchParams();
  const activationCallback = searchParams.get('activation_callback');
  const pageRedirect = searchParams.get('page_redirect');
  const siteUrl = searchParams.get('site_url');

  useEffect(() => {
    if (!isPending && userData) {
      if (searchParams.has('activation_callback')) {
        dispatch({ type: SET_REDIRECT, param: pageRedirect });
        dispatch({
          type: LICENSE_ACTIVATION,
          params: { url: siteUrl ? siteUrl : activationCallback },
        });
      }
    }
  }, [userData, isPending, location.search]);

  useEffect(() => {
    const handleLicenseActivationSuccess = () => {
      if (pageRedirect === null || activationCallback === null) {
        return;
      }
      const page_redirect = atob(pageRedirect);
      const activation_callback = atob(activationCallback);

      let redirect_url = '';

      if (page_redirect === 'wpfeedback_page_settings') {
        redirect_url = 'admin.php?page=wpfeedback_page_settings&step_one=true&';
      } else {
        redirect_url = 'admin.php?page=wpfeedback_page_permissions&';
      }

      const url = `${activation_callback}/wp-admin/${redirect_url}wpf_site_id=${activateLicenseData.wpf_site_id}&atarim_response=${activateLicenseData.wpf_license}`;
      window.location.replace(url);
    };

    if (licenseActivationSuccess) {
      handleLicenseActivationSuccess();
    } else if (licenseActivationSuccess === false) {
      NotificationManager.error(licenseActivationMessage, '', 3000);
    }
  }, [licenseActivationSuccess, location.search, isPending, userData]);
};

export default usePluginActivationRedirect;
